import { Box, Divider, Grid, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LabelGruopList } from '../../components/Labels/LabelGruopList'
import { LabelsList } from '../../components/Labels/LabelsList'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { createLabel, getLabels } from '../../redux/labels/thunk'
import { LabelGroup, LabelObject } from '../../redux/labels/slice'
import AddIcon from '@mui/icons-material/Add';
import { PermissionsSidePanel } from '../Roles/SidePanel/PermissionsSidePanel'
import { PermissionsList } from '../../redux/permission/slice'
import { ObjectType } from '../../redux/permission/ObjectTypeEnum'
import { DraggableList } from '../../components/Labels/DragableList'

export const LabelsPage = () => {
    const [currentLabelGroup, setCurrentLabelGroup] = useState<null | LabelObject>(null)
    const [editingLabel, setEditingLabel] = useState<string>('')
    const [currentPermissions, setCurrentPermissions] = useState<any>()
    const [permissions, setPermissions] = useState<any[]>([])
    const [roles, setRoles] = useState<string[]>([])
    const [open, setOpen] = useState(false)


    const { permissionsList } = useAppSelector(state => state.permissions)

    const dispatch = useAppDispatch()
    const { tenantId } = useAppSelector(state => state.auth)
    const { status, labels } = useAppSelector(state => state.labels)

    const handleClose = () => {
        setOpen(!open)
    }

    const handleSelectCurrentLabelGroup = (labelGroup: LabelObject) => {
        setCurrentLabelGroup(labelGroup)
    }
    const handleCreateLabel = () => {
        if (currentLabelGroup)
            dispatch(createLabel(
                {
                    "labelGroupId": currentLabelGroup.labelGroup.id,
                    "tag": "New Label",
                    "hexColor": "#ffa400",
                    "token": ""
                },
                tenantId
            ))
    }

    useEffect(() => {
        dispatch(getLabels(tenantId))
    }, [])

    useEffect(() => {
        if (permissionsList) {
            setPermissions(permissionsList?.backend.labels)
            if (permissionsList.backend.roles)
                setRoles(permissionsList.backend.roles)
        }
        if (currentLabelGroup) {
            const permissionsObject = permissions.find(e => e.id === currentLabelGroup.labelGroup.id)
            if (permissionsObject) {
                setCurrentPermissions(permissionsObject)
            }

        }
    }, [currentLabelGroup, permissionsList])

    useEffect(() => {
        if (currentLabelGroup) {
            let selected = labels.find(e => e.labelGroup.id === currentLabelGroup.labelGroup.id)
            if (selected)
                setCurrentLabelGroup(selected)
        }
    }, [labels])

    return (
        <Box
            sx={{
                height: 'calc(100vh - 64px)',
                mt: 2,
                position: 'relative'
            }}
        >


            <Grid container height={'100%'}>
                <Grid item height={'100%'} xs={12} md={6} sx={{
                    border: 'solid rgba(0, 0, 0, 0.12) 1px'
                }}>
                    <LabelGruopList handleOpenPermissions={handleClose} permissions={permissions} roles={roles} handleSelectCurrentLabelGroup={handleSelectCurrentLabelGroup} currentLabelGroup={currentLabelGroup} />
                </Grid>
                <Grid item height={'calc(100vh - 64px)'} xs={12} md={6} sx={{ overflowY: 'scroll' }}>
                    {
                        (currentLabelGroup && currentPermissions) &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: '100%'
                            }}
                        >

                            {/* <LabelsList currentLabelGroup={currentLabelGroup} currentPermissions={currentPermissions} /> */}
                            <DraggableList currentLabelGroup={currentLabelGroup} currentPermissions={currentPermissions} editingLabel={editingLabel} setEditingLabel={setEditingLabel} roles={roles} />


                            {
                                ((currentPermissions.permissions.includes('CanCreate')) || (roles.includes('CanManageLabels') && currentPermissions.permissions.length === 0)) &&
                                <IconButton sx={{ height: 50, width: 50 }} onClick={handleCreateLabel} disabled={status === 'loading'}>
                                    <AddIcon sx={{ height: 50, width: 50 }} />
                                </IconButton>
                            }

                        </Box>
                    }
                </Grid>
            </Grid>
            {
                currentLabelGroup &&
                <PermissionsSidePanel dataName='labels' objectName='LabelGroup' objectId={currentLabelGroup.labelGroup.id} objectType={ObjectType.Label} open={open} handleClose={handleClose}></PermissionsSidePanel>
            }

        </Box >
    )
}
