import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { setUser } from "../../redux/auth/thunk";
import { parseJwt } from "../../functions/DecodeJWT";
import axios from "axios";
export const LoginPage = () => {
    const dispatch = useAppDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('jwtToken') as string;
    const tenantId = queryParams.get('tenantId') as string;
    const user = parseJwt(token)
    useEffect(() => {
        if (user) {

            axios.get(`${process.env.REACT_APP_TAUTH_URL}/api/account/getuserinformation?Id=${user.uid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    tenantId: tenantId
                }
            })
                .then(response => {

                    dispatch(setUser({ ...user, profileImage: response.data.profilePicture }))
                    if(tenantId){
                        navigate(`/${tenantId}`)
                    }
                    else{
                        navigate("/tenant-selector")
                    }
                })
                .catch((error): any => {
                    console.error(error);
                    navigate("/login")
                });

        } else {
            // navigate("/error")
        }
    }, [])
    useEffect(() => {
        if (token) {
            window.localStorage.setItem("jwt", token);

        }
    }, [token])

    return (
        <div>
            {/* <LoginForm /> */}
        </div>
    )
}
