import { Box, Grid, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { ReportBox } from "../../Boxes/ReportBox";
import { Handle, Position } from "reactflow";
import AddIcon from "@mui/icons-material/Add";

export function ReportNode({ data, isConnectable }: any) {
    const onChange = useCallback((evt: any) => {
    }, []);
    const tableId = data.value.setting.tableId;

    return (
        <div className="report-box-node">
            <Box
            // sx={{
            //     "&:hover .add-btn": {
            //         display: "block",
            //         opacity: 1,
            //         animation: "ease-in all 0.5s",
            //     },
            // }}
            >
                <Grid item margin={0} key={data.value.autoruleTaskId}>
                    {/* Render report box */}
                    <ReportBox content={data.value}
                        deleteComp={data.allTasks.length}
                    />
                    <Handle
                        type="source"
                        position={Position.Bottom}
                        id="a"
                        isConnectable={isConnectable}
                    />
                </Grid>

                {(data.permissions.includes("CanCreate") ||
                    (data.permissions.length == 0 && data.roles.includes("CanManageAutorules")))
                    && data.allTasks.length === 1 && (
                        <Box
                            className={"add-btn"}
                            sx={{
                                position: "absolute",
                                color: "rgba(0, 0, 0, 0.54)",
                                left: "50%",
                                transform: "translateX(-50%)",
                                cursor: "pointer",
                            }}
                            onClick={data.onClick}
                        >
                            <Tooltip title={"Agregar componente"}>
                                <AddIcon fontSize="large" />
                            </Tooltip>
                        </Box>
                    )}
            </Box>
        </div>
    );
}