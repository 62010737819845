import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { format, parse, isValid } from "date-fns";

export const DateType = ({ value, name, onChange }) => {
    let fechaArray; // Dividir la cadena en partes separadas por "-"
    let fechaObjeto; // Crear un objeto Date con los componentes de fecha en el orden deseado
    let nuevaFechaStr;
    let formattedDate = "";
    let newValue = ""; // [newValue, setNewValue] = useState(dayjs(formattedDate));
    if (value === null || value === undefined) {
        //const currentDate = new Date();
        //formattedDate = format(currentDate, 'yyyy-MM-dd');
        //formattedDate = format(new Date(null), 'yyyy-MM-dd');
        newValue = null;
    } else {
        try {
            fechaArray = value.split("-"); // Dividir la cadena en partes separadas por "-"
            fechaObjeto = new Date(
                fechaArray[2],
                fechaArray[1] - 1,
                fechaArray[0]
            ); // Crear un objeto Date con los componentes de fecha en el orden deseado
            newValue = dayjs(fechaObjeto.toISOString().slice(0, 10));
        } catch (e) {
            newValue = dayjs(value);
        }
    }

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
        >
            <DatePicker
                label={`${name}`}
                value={newValue}
                onChange={(val) => {
                    newValue = val;
                    onChange({
                        target: {
                            name: `${name}`,
                            value: val.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                        },
                    });
                }}
                sx={{
                    display: "block",
                    marginTop: "1rem",
                    marginBottom: "0.5rem",
                    width: "100%",
                    "& .MuiInputBase-root": {
                        width: "100%"
                    },
                }}
            />
        </LocalizationProvider>
    );
};
