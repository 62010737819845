import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const AutoRulesAgent = {
    getAutoRulesNames: (TenantId: string) => requests.get<any>(url + `/api/v1/TenantAutorule?TenantId=${TenantId}`),
    getAutoRules: (TenantId: string) => requests.get<any>(url + `/api/v1/TenantAutorule/${TenantId}`),
    postAutoRule: (body: any) => requests.post<any>(url + "/api/v1/TenantAutorule", body),
    delAutoRule: (autoRuleId: string) => requests.del<any>(url + `/api/v1/TenantAutorule/${autoRuleId}`),
    putAutoRule: (body: any) => requests.put<any>(url + "/api/v1/TenantAutorule", body),
    onOffAutoRule:(body: any) => requests.post<any>(url + "/api/v1/TenantAutorule/State", body),
    // settings
    putAutoRuleSettingName: (body: any) => requests.put<any>(url + "/api/v1/AutoruleTask", body),
    postReportBox: (body: any) => requests.post<any>(url + "/api/v1/AutoruleTask/Table", body),
    postEmailBox: (body: any) => requests.post<any>(url + "/api/v1/AutoruleTask/Email", body),
    putEmailBox: (body: any) => requests.put<any>(url + "/api/v1/EmailAutoruleSetting", body),
    delAutoRuleSetting: (autoRuleId: string) => requests.del<any>(url + `/api/v1/AutoruleTask/${autoRuleId}`),
}
export default AutoRulesAgent