import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import OrderAgent from "./agent"
import { setAllOrders, errorState, loading } from "./slice"


export const getAllOrders = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await OrderAgent.getAllOrders(tenantId)
            .then((response) => {
                dispatch(setAllOrders(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}