import { Box, Button, FormGroup, MenuItem, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { postTableColumn } from '../../../redux/tables/thunk';
import Swal from 'sweetalert2'
import { getLabels } from '../../../redux/labels/thunk';

interface Handles {
    handleClose: any
}

interface Types {
    Text: number,
    Number: number,
    Date: number,
    Boolean: number,
    Image: number,
    Client: number,
    Money: number,
}

interface UtilData {
    tableId: string
}

interface ValToBack {
    name: string,
    type: string
}

export const CreateColumn = ({ handles, types, data }: { handles: Handles, types: Types, data: UtilData }) => {
    const dispatch = useAppDispatch();

    const { tablesData }: { tablesData: any, tablesColumns: any} = useAppSelector((state) => state.tables);
    const { tenantId } = useAppSelector((state) => state.auth);
    const { labels, status } = useAppSelector((state) => state.labels);

    const [selectedLabelGroup, setSelectedLabelGroup] = useState('')
    const [valuesToBack, setValuesToBack] = useState<ValToBack>({
        name: "",
        type: "",
    })

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setValuesToBack({ ...valuesToBack, [name]: value });
    };
    const handleInputLabelChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setSelectedLabelGroup(value);
    };
    const handleSubmit = async () => {
        const columnType = types[valuesToBack.type as keyof Types];
        const veri = tablesData.columns.find((tabCol: any) => tabCol.columnName === valuesToBack.name)
        if (veri !== undefined) {
            Swal.fire({
                title: 'Nombre de columna ya en uso',
                text: "Debes elegir otro nombre!",
                icon: 'error',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Borrar'
            })
        } else {
            if (columnType === 11) {
                dispatch(postTableColumn({
                    tableId: data.tableId,
                    name: valuesToBack.name,
                    type: valuesToBack.type,
                    position: tablesData.columns.length,
                    labelGroupId: selectedLabelGroup,
                    token: ""
                }, tablesData.pageNumber, tablesData.pageSize))
            } else {
                dispatch(postTableColumn({
                    tableId: data.tableId,
                    name: valuesToBack.name,
                    type: valuesToBack.type,
                    position: tablesData.columns.length,
                    token: ""
                }, tablesData.pageNumber, tablesData.pageSize))
            }
        }

        handles.handleClose();
    };

    useEffect(() => {
        dispatch(getLabels(tenantId))
    }, [])

    return (
        <>
            <Typography variant="h5" fontWeight={"400"}>
                Create column
            </Typography>
            <Box
                component="form"
                noValidate={false}
                onSubmit={handleSubmit}
            >
                <FormGroup>
                    <TextField
                        margin="normal"
                        label="Nombre"
                        name="name"
                        value={valuesToBack.name}
                        helperText="Nombre de la columna"
                        variant="standard"
                        fullWidth
                        autoFocus
                        required
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="normal"
                        select
                        label="Categoria"
                        name="type"
                        value={valuesToBack.type}
                        helperText="Seleccione la el tipo de datos"
                        variant="standard"
                        fullWidth
                        autoFocus
                        required
                        onChange={handleInputChange}
                    >
                        {Object.keys(types).map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    {
                        valuesToBack.type === "Label"
                        &&
                        <TextField
                            margin="normal"
                            select
                            label="Label Group"
                            name="type"
                            value={selectedLabelGroup}
                            helperText="Select label group"
                            variant="standard"
                            fullWidth
                            autoFocus
                            required
                            onChange={handleInputLabelChange}
                        >
                            {
                                labels.map((item, zIndex) =>
                                    <MenuItem key={zIndex} value={item.labelGroup.id} >
                                        {item.labelGroup.icon} {item.labelGroup.name}
                                    </MenuItem>
                                )
                            }
                        </TextField>
                    }
                </FormGroup>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                    }}
                >
                    <Button
                        type="submit"
                    >
                        Create new column
                    </Button>
                </Box>
            </Box>
        </>
    )
}
