import { Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { LabelGroupItem } from './LabelGroupItem';
import { LabelGroup, LabelObject } from '../../redux/labels/slice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { createLabelGroup } from '../../redux/labels/thunk';
import { getPermissions } from '../../redux/permission/thunk';

interface Props {
    handleSelectCurrentLabelGroup: (labelGroup: LabelObject) => void
    currentLabelGroup: LabelObject | null
    handleOpenPermissions: () => void
    permissions: any[]
    roles: string[]
}
export const LabelGruopList = ({ handleSelectCurrentLabelGroup, currentLabelGroup, handleOpenPermissions, permissions, roles }: Props) => {

    const dispatch = useAppDispatch()
    const { status, labels } = useAppSelector(state => state.labels)
    const { tenantId } = useAppSelector(state => state.auth)
    const [editing, setEditing] = useState('')
    const [labelsArray, setLabelsArray] = useState(labels)

    const handleCreateLabelGroup = () => {
        const newLabelGroup = {
            "token": "",
            "icon": "🛡️",
            "name": "New LabelGroup"
        }
        dispatch(createLabelGroup(newLabelGroup, tenantId))
    }
    useEffect(() => {
        dispatch(getPermissions())
        setLabelsArray(labels)
    }, [labels])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                p: 2
            }}
        >
            {/* {
                (permissions.includes('CanRead') || role === 'CanManageLabels') &&
                
               } */}
            {
                labelsArray.map((labelGroup, zIndex) => {
                    const permissionsObject = permissions.find(e => e.id === labelGroup.labelGroup.id)

                    if ((permissionsObject && permissionsObject.permissions.includes('CanRead')) || (roles.includes('CanManageLabels') && (permissionsObject && permissionsObject.permissions.length === 0)))

                        return (
                            <LabelGroupItem setEditing={setEditing} editing={editing} handleOpenPermissions={handleOpenPermissions} permissionsObject={permissionsObject} roles={roles} labelGroup={labelGroup} currentLabelGroup={currentLabelGroup} key={zIndex} handleSelectCurrentLabelGroup={handleSelectCurrentLabelGroup} />
                        )
                })
            }
            {
                (roles.includes('CanManageLabels')) &&
                <IconButton sx={{ height: 50, width: 50 }} onClick={handleCreateLabelGroup} disabled={status === 'loading'}>
                    <AddIcon sx={{ height: 50, width: 50 }} />
                </IconButton>
            }
        </Box>
    )
}
