import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User, errorState, loading, setAllUsers, setCurrentUser } from "./slice";
import NewsAgent from "./agent";

export const checkingNews = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any) => {
        dispatch(loading());
    }
}
export const getAllUsers = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any) => {
        dispatch(loading())
        await NewsAgent.getAll(tenantId)
            .then((response) => {
                dispatch(setAllUsers(response));
            })
            .catch((error) => {
                console.log(error);
                dispatch(errorState())
            })
    }
}

export const modifyUser = (modifiedUser: User): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any) => {
        dispatch(loading())
        await NewsAgent.modifyUser(modifiedUser)
            .then((response) => {
                dispatch(getAllUsers(modifiedUser.tenantId))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}

export const removeUser = (id: string, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any) => {
        dispatch(loading())
        await NewsAgent.removeUserId(id, tenantId)
            .then((response) => {
                dispatch(getAllUsers(tenantId))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}

export const getUserById = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any) => {
        dispatch(loading())

        await NewsAgent.getUserById(id)
            .then((response) => {
                dispatch(setCurrentUser(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}