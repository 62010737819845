import { Box, Button, Divider, Grid, IconButton, Modal, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { PermissionComponent } from '../Componentes/PermissionComponent';
import { CreateModal } from './CreateModal';
import { deletePermission, getPermissionsByObject, updatePermission } from '../../../redux/permission/thunk';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { RoleComponent } from './RoleComponent';
import { UserComponent } from './UserComponent';
import { ObjectPermissions } from '../../../redux/permission/slice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';

interface Props {
    open: boolean
    handleClose: () => void,
    objectType: number
    objectId: string
    objectName: string
    dataName: string
}

export const PermissionsSidePanel = ({ open, handleClose, objectType, objectId, objectName, dataName }: Props) => {
    const dispacth = useAppDispatch()

    const { objectPermissions } = useAppSelector(state => state.permissions)
    const [permision, setPermission] = useState<ObjectPermissions>({
        "authorityId": "",
        "authorityType": 0,
        "canCreate": false,
        "canRead": false,
        "canUpdate": false,
        "canUpdateData": false,
        "canDelete": false,
        "canDeleteData": false,
        "canReadRows": false,
        createdBy: "",
        createdDate: "",
        editedBy: '',
        id: '',
        modifiedDate: ""
    })

    const [openAddPermission, setOpenAddPermission] = useState(false)
    const handleChangePermissions = (permission: string, value: boolean) => {
        setPermission({ ...permision, [permission]: value })
        return
    }
    const handleChangeAuthority = (permission: ObjectPermissions) => {
        setPermission(permission)
    }
    const handleClean = () => {
        setPermission({
            "authorityId": "",
            "authorityType": 0,
            "canCreate": false,
            "canRead": true,
            "canUpdate": false,
            "canUpdateData": false,
            "canDelete": false,
            "canDeleteData": false,
            "canReadRows": false,
            createdBy: "",
            createdDate: "",
            editedBy: '',
            id: '',
            modifiedDate: ""
        })
    }
    const handleSaveChanges = () => {
        dispacth(updatePermission({ ...permision, token: 'a' }, objectId))
    }
    const handleDeletPermission = () => {
        Swal.fire({
            title: "Are you sure you want to delete this permission?",
            text: "Once deleted there is no going back",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Borrar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispacth(deletePermission(permision.id, objectId, handleClean))
            }
        });

    }
    useEffect(() => {
        dispacth(getPermissionsByObject(objectId))
    }, [objectId])

    return (
        <Box
            sx={{
                color: '#000',
                maxWidth: open ? 500 : 0,
                width: '100%',
                backgroundColor: '#fff',
                display: open ? 'block' : 'none',
                transition: 'ease-out 0.5s all',
                position: 'absolute',
                height: 'calc(100vh - 64px)',
                right: 0,
                top: 0,
                zIndex: 1000
            }}
        >

            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <Grid container
                spacing={2}
                sx={{
                    width: '100%',
                    p: 2,
                    display: 'flex'
                }}
            >
                <Grid item xs={6}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Typography color={"#757575"}>Roles/Users</Typography>
                        <IconButton onClick={() => setOpenAddPermission(true)}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {
                            objectPermissions.map((per, zIndex) => {
                                if (per.authorityType === 1)
                                    return (

                                        <RoleComponent key={zIndex} permission={per} handleChangeAuthority={handleChangeAuthority} selectedPermission={permision} />
                                    )
                                else {
                                    return (
                                        <UserComponent key={zIndex} permission={per} handleChangeAuthority={handleChangeAuthority} selectedPermission={permision} />

                                    )
                                }
                            })
                        }
                    </Box>
                </Grid>
                <Divider></Divider>
                {
                    permision.id != '' &&
                    <Grid item xs={6}>
                        <Typography color={"#757575"} mb={2}>Persmissions</Typography>

                        {objectType === 9 && (
                            
                            <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            param='canRead'
                            name='Can Read data'                
                            disabled={objectType !== 9}
                            value={permision.canRead}
                            description='Allows reading data in the table.'
                        />
                        )}
                        {objectType !== 9 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            param='canRead'
                            name='Can Read data'                
                            disabled={objectType !== 9}
                            value={permision.canRead}
                            description='Allows reading data in the table.'
                        />
                        )}
                        {objectType !== 9 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            param='canCreate'
                            name={`Can create ${dataName}`}
                            disabled={false}
                            value={permision.canCreate}
                            description='Allows creation.'
                        />
                        )}
                        {objectType !== 9 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            param='canUpdate'
                            name={`Can update ${objectName}`}
                            disabled={false}
                            value={permision.canUpdate}
                            description='Allows updating.'
                        />
                        )}    
                        {objectType !== 9 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            param='canUpdateData'
                            disabled={false}
                            name={`Can update ${dataName}`}
                            value={permision.canUpdateData}
                            description='Allows updating data.'
                        />
                        )}
                        {objectType !== 9 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            param='canDelete'
                            disabled={false}
                            name={`Can delete ${objectName}`}
                            value={permision.canDelete}
                            description='Allows deleting.'
                        />
                        )}
                        {objectType !== 9 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            disabled={false}
                            param='canDeleteData'
                            name={`Can delete ${dataName}`}
                            value={permision.canDeleteData}
                            description='Allows deleting data.'
                        />
                        )}
                        {objectType === 0 && (
                        <PermissionComponent
                            handleChangePermissions={handleChangePermissions}
                            disabled={false}
                            param='canReadRows'
                            name={`Can read created user rows ${dataName}`}
                            value={permision.canReadRows}
                            description='Read rows data created by user.'
                        />
                        )}
                        <Button variant="contained" color="success" onClick={handleSaveChanges} sx={{ mt: 2 }}>
                            Save changes
                        </Button>
                        <Button color='error' variant='contained' onClick={handleDeletPermission} sx={{ mt: 2 }}>
                            <DeleteForeverIcon />
                            Delete permission
                        </Button>
                    </Grid>
                }

            </Grid>
            <CreateModal openAddPermission={openAddPermission} setOpenAddPermission={setOpenAddPermission} objectType={objectType} objectId={objectId} />
        </Box >
    )
}
