import React, { Suspense, useEffect, useState } from 'react';
import { Paper, Typography, Button, List, ListItem, ListItemText, Box, MenuItem, Select, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getAllSubscriptions } from '../../redux/subscriptionPayment/thunk';
import { SubscriptionCard } from '../subscriptions/SubscriptionCard';
import axios from 'axios';
import { te } from 'date-fns/locale';
import Swal from 'sweetalert2';
import { getSubscription } from '../../redux/payment/slice';
import { PaymentMethods } from '../Enums/PaymentMethods';
import { getFullPrice } from '../../redux/payment/thunk';
import { getAllSeats } from '../../redux/seat/thunk';
const url = process.env.REACT_APP_NEXUS_BACK_URL;
const idFreelancer = process.env.REACT_APP_FREELANCER_ID;
const idCorporation = process.env.REACT_APP_CORPORATION_ID?.toLocaleLowerCase();
export const currencies = [
    "USD", "CLP"
]



export const ManageSubscription = () => {
    const tenantId = window.localStorage.getItem("tenantId")
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
    const { subscriptions } = useAppSelector(state => state.subscriptionPayment);
    const { suscription: userSuscription } = useAppSelector(state => state.auth);
    const { currentPrice } = useAppSelector(state => state.payment);
    const { seats } = useAppSelector(state => state.seat);

    const dispatch = useAppDispatch();

    const handleSubscription = async (subscriptionId: string) => {
        const tenantId = window.localStorage.getItem("tenantId");    
        const confirmationResult = await Swal.fire({
            title: subscriptionId === idFreelancer?.toLocaleLowerCase() ?
                  "Are you sure you want to cancel the subscription?" :
                  "Are you sure you want to change the subscription?",
            text: subscriptionId === idFreelancer?.toLocaleLowerCase() ?
            "Once accepted you will have access limits to Nexus" :
            "When changing subscriptions, a charge will be made for the current subscription and seats.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, proceed"
        });
    
        if (confirmationResult.isConfirmed) {
            const body = {
                id: tenantId,
                ...(subscriptionId !== idFreelancer?.toLocaleLowerCase() && { suscriptionId: subscriptionId })
            };
    
            try {
                const endpoint = subscriptionId ===  idFreelancer?.toLocaleLowerCase() ? "/Cancel" : "/Manager";
                let response = await axios.post(`${url}/api/v1/Payment${endpoint}`, body);
                if (response.status === 200) {
                    window.location.href = "/";
                }
            } catch (error) {

            }
        }
    };
    
    useEffect(() => {
        dispatch(getAllSubscriptions());
        dispatch(getAllSeats(tenantId!));
    }, [dispatch]);

    useEffect(() => {
        if (subscriptions){
        const defaultSubscription = subscriptions.find(sub => sub.id === userSuscription?.id);
        setSelectedSubscription(defaultSubscription);
        }
    }, [subscriptions, userSuscription]);

    const handleSelectSubscription = (subscriptionId: string) => {
        if (subscriptions){
            const newSelectedSubscription = subscriptions.find(sub => sub.id === subscriptionId);
            const paymentMethod = selectedCurrency === 'USD' ? 0 : 1;
            dispatch(getFullPrice  ({
                paymentMethod: 1,
                suscriptionId: newSelectedSubscription?.id,
                seatsQuantity: seats.length,
                currency: paymentMethod
            }))
            setSelectedSubscription(newSelectedSubscription);
        }

    };

    const handleCurrencyChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setSelectedCurrency(event.target.value);
        if (selectedSubscription) {
            const paymentMethod = event.target.value === 'USD' ? 0 : 1;
            dispatch(getFullPrice({
                paymentMethod: 1,
                suscriptionId: selectedSubscription.id,
                seatsQuantity: 1,
                currency: paymentMethod
            }));
        }
    };


    return (
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box pr={15}>
                    <List>
                    {(userSuscription && subscriptions) && subscriptions.filter(subscription => subscription.id !== idCorporation).map((subscription) => (
                            <ListItem key={subscription.id} button onClick={() => handleSelectSubscription(subscription.id)} disabled={userSuscription?.id === subscription.id}>
                                <ListItemText primary={subscription.name} />
                            </ListItem>
                        ))}
                    </List>
                    { (userSuscription && selectedSubscription) &&
                        <Button variant="contained" color="primary" disabled={userSuscription.id === selectedSubscription.id} onClick={() => handleSubscription(selectedSubscription.id)}>
                            {selectedSubscription.id === idFreelancer?.toLocaleLowerCase() ? "Switch to Free" : "Switch Subscription"}
                        </Button>
                    }
                </Box>
                
                {selectedSubscription && (
                    <Box pr={15}>
                        <SubscriptionCard suscription={selectedSubscription} isRenderButton={false} />
                    </Box>
                )}
                {
                     selectedSubscription && (selectedSubscription.id !== idFreelancer?.toLocaleLowerCase()) &&
                    <Box>
    <FormControl variant="outlined">
        <InputLabel id="currency-label">Currency</InputLabel>
        <Select
            labelId="currency-label"
            id="currency-select"
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            label="Currency"
        >
            {currencies.map(currency => (
                <MenuItem key={currency} value={currency}>{currency}</MenuItem>
            ))}
        </Select>
    </FormControl>
    
<Box flexDirection="column" mt={2}>
    <Box flexDirection="row" mb={1}>
        <Typography variant="h6" fontWeight={450}>
            Price:              {
                selectedCurrency === 'CLP' ?
                    `$ ${currentPrice ? (currentPrice.priceMonth).toLocaleString('es-CL') : ""}`
                    :
                    `$ ${currentPrice ? currentPrice.priceMonth : ""}`
            }
        </Typography>
    </Box>
    <Box flexDirection="row" mb={1}>
        <Typography variant="h6" fontWeight={450}>
            Seats:              {
                selectedCurrency === 'CLP' ?
                    `$ ${currentPrice ? (currentPrice.seatPrice).toLocaleString('es-CL') : ""}`
                    :
                    `$ ${currentPrice ? currentPrice.seatPrice : ""}`
            }
        </Typography>
    </Box>
    <Box flexDirection="row" mb={1}>
        <Typography variant="h6" fontWeight={450}>
            Subtotal:              {
                selectedCurrency === 'CLP' ?
                    `$ ${currentPrice ? (currentPrice.subTotal).toLocaleString('es-CL') : ""}`
                    :
                    `$ ${currentPrice ? currentPrice.subTotal : ""}`
            }
        </Typography>
    </Box>
    <Box flexDirection="row" mb={1}>
        <Typography variant="h6" fontWeight={450}>
            Tax:              {
                selectedCurrency === 'CLP' ?
                    `$ ${currentPrice ? (currentPrice.taxAmount).toLocaleString('es-CL') : ""}`
                    :
                    `$ ${currentPrice ? currentPrice.taxAmount : ""}`
            }
        </Typography>
    </Box>
    <Box flexDirection="row">
        <Typography variant="h6" fontWeight={450}>
            Total:              {
                selectedCurrency === 'CLP' ?
                    `$ ${currentPrice ? (currentPrice.total).toLocaleString('es-CL') : ""}`
                    :
                    `$ ${currentPrice ? currentPrice.total : ""}`
            }
        </Typography>
    </Box>
</Box>
                    </Box>
            }

    </Box>
    );
};