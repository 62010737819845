import { Box, useTheme, Theme, Button, Chip, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { FormEvent, useEffect, useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { DateType } from '../../fields/DateType';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import zIndex from '@mui/material/styles/zIndex';
import { isEmail } from '../../../functions/EmailVerificator';
import { createClient } from '../../../redux/contacts/thunk';
import axios from 'axios';
import { MuiTelInput } from 'mui-tel-input';
import { getAllCompanies } from '../../../redux/account/thunk';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const AddContact = ({ open, setOpen, }: { open: boolean, setOpen: any }) => {
    const { contacts, status, pageSize, pageNumber } = useAppSelector(state => state.contacts)
    const { tenantId } = useAppSelector(state => state.auth)
    const [newContact, setNewContact] = useState({
        "countryId": "",
        "name": "",
        "email": null,
        "address": null,
        "phoneNumber": "",
        "website": null,
        "linkedin": null,
        "instagram": null,
        "facebook": null,
        "twitter": null,
        "tikTok": null,
        "youtube": null,
        "twitch": null,
        "threads": null,
        "notes": null,
        "labels": null,
        "workPosition": null,
        "endUserId": null,

    })
    const [accountList, setAccountList] = useState<string[]>([])

    const theme = useTheme();

    const dispatch = useAppDispatch()

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewContact({ ...newContact, [name]: value })
    }

    const handleListChange = (event: SelectChangeEvent<typeof accountList>) => {
        const {
            target: { value },
        } = event;
        setAccountList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        dispatch(createClient({ ...newContact, accountsIds: accountList, tenantId }, setOpen, pageSize, pageNumber))
    }



    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                boxShadow: 24,
                p: 10,
                padding: 2,
                paddingBottom: 1,
                maxWidth: 500,
                width: "100%",
                // height: 600,
                overflowY: 'scroll'
            }} component="form" onSubmit={handleSubmit}>
                <Typography variant='h4' fontSize={25} fontWeight={500}>
                    New Contact
                </Typography>
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={12} >
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newContact.name}
                                name='name'
                                label="Contact name"
                                required
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                            }}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="outlined"
                            disabled={newContact.countryId.length > 50 || status === 'loading'}
                            type='submit'
                            sx={{
                                backgroundColor: "lightblue",
                            }}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </Modal>
    )
}
