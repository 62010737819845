import React, { useEffect, useState } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Box, Button, Typography } from '@mui/material';
import { LoadingCube } from '../../components/custom/loader/LoadingCube';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { tokenLogin } from '../../redux/auth/thunk';
import { getDomainKey, setVoidDomainKey } from '../../redux/keys/thunk';

export const VerifyPayment = () => {
    const navigate = useNavigate()

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const state = query.get('state');


    // useEffect(() => {
    //     dispatch(getDomainKey(tenantId))
    //     if (status === "logged" && tenant.tenant !== null) {
    //         setLoading(true)
    //         if (method === "paypal") {
    //             const paymentId = queryParameters.get("paymentId") as string;
    //             enviarConfirmacion(paymentId, "Paypal", "")

    //         } else if (method === "stripe") {
    //             const paymentId = queryParameters.get("paymentId") as string;
    //             enviarConfirmacion(paymentId, "Stripe", "")
    //         } else if (method === "webpay") {

    //             const paymentId = queryParameters.get("paymentId") as string;
    //             let token = queryParameters.get("token_ws") as string;
    //             enviarConfirmacion(paymentId, "Webpay", token)
    //         }
    //     }
    // }, [status, tenant.status])
    // useEffect(() => {
    //     if (confirmed && !seatId) {
    //         dispatch(tokenLogin())
    //     }
    // }, [seatId, confirmed])

    if (!state)
        return (
            <Box
                sx={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <LoadingCube />
            </Box>
        )
    else if (parseInt(state) === 1) {
        return (
            <Box
                sx={{
                    // height: 450,
                    px: { xs: 2, sm: 3, md: 10, lg: 20, xl: 40 },
                    mt: { xs: 0, md: 4 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        py: 2
                    }}
                >
                    <Typography variant='h2' color={"#000"}>
                        ¡Compra realizada!
                    </Typography>
                    <CheckCircleOutlineIcon color='success'
                        sx={{
                            height: 400,
                            width: 400
                        }}
                    />
                    {/* <Typography variant='overline'>Id de la transacción: {paymentId}</Typography> */}
                    <Box>
                        <Button variant='contained' color='info' onClick={() => navigate("/")}>
                            Volver al inicio
                        </Button>
                    </Box>
                </Box>

            </Box>

        )
    }
    else if (parseInt(state) === 0) {
        return (
            <Box
                sx={{
                    // height: 450,
                    px: { xs: 2, sm: 3, md: 10, lg: 20, xl: 40 },
                    mt: { xs: 0, md: 4 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        py: 2
                    }}
                >
                    <Typography variant='h2' color={"#000"}>
                        ¡Compra Rechazada!
                    </Typography>
                    <CancelOutlinedIcon color='error'
                        sx={{
                            height: 400,
                            width: 400
                        }}
                    />
                    {/* <Typography variant='overline'>Id de la transacción: {paymentId}</Typography> */}
                    <Box>
                        <Button variant='contained' color='info' onClick={() => navigate("/")}>
                            Volver al inicio
                        </Button>
                    </Box>
                </Box>

            </Box>

        )
    }
    else if (parseInt(state) === 2) {
        return (
            <Box
                sx={{
                    // height: 450,
                    px: { xs: 2, sm: 3, md: 10, lg: 20, xl: 40 },
                    mt: { xs: 0, md: 4 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        py: 2
                    }}
                >
                    <Typography variant='h2' color={"#000"}>
                        ¡Cambio de tarjeta exitoso!
                    </Typography>
                    <CheckCircleOutlineIcon color='success'
                        sx={{
                            height: 400,
                            width: 400
                        }}
                    />
                    {/* <Typography variant='overline'>Id de la transacción: {paymentId}</Typography> */}
                    <Box>
                        <Button variant='contained' color='info' onClick={() => navigate("/")}>
                            Volver al inicio
                        </Button>
                    </Box>
                </Box>

            </Box>

        )
    }
    else if (parseInt(state) === 3) {
        return (
            <Box
                sx={{
                    // height: 450,
                    px: { xs: 2, sm: 3, md: 10, lg: 20, xl: 40 },
                    mt: { xs: 0, md: 4 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        py: 2
                    }}
                >
                    <Typography variant='h2' color={"#000"}>
                        ¡Cambio de tarjeta rechazado!
                    </Typography>
                    <CancelOutlinedIcon color='error'
                        sx={{
                            height: 400,
                            width: 400
                        }}
                    />
                    {/* <Typography variant='overline'>Id de la transacción: {paymentId}</Typography> */}
                    <Box>
                        <Button variant='contained' color='info' onClick={() => navigate("/")}>
                            Volver al inicio
                        </Button>
                    </Box>
                </Box>

            </Box>

        )
    }
    else {
        return (
            <Box
                sx={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <LoadingCube />
            </Box>
        )
    }
}
