import React, { useEffect, useRef, useState } from 'react';
import { findIndex, usePositionReorder } from './Hooks/usePositionReorder';
import { DraggableItem } from './DraggableItem';
import { Label, LabelObject } from '../../redux/labels/slice';
import { Box } from '@mui/material';
import './style.css'
import move from "array-move";

interface Props {
    currentLabelGroup: LabelObject
    currentPermissions: any
    editingLabel: string
    setEditingLabel: React.Dispatch<React.SetStateAction<string>>
    roles: string[]
}

export const DraggableList = ({ currentLabelGroup, currentPermissions, editingLabel, setEditingLabel,roles }: Props) => {
    const [order, setOrder] = useState(currentLabelGroup.labels);
    const positions = useRef<number[]>([]).current;

    // const [, updatePosition] = usePositionReorder(item);

    const updateOrder = (i: number, dragOffset: number) => {
        const targetIndex = findIndex(i, dragOffset, positions);
        if (targetIndex !== i) setOrder(move(order, i, targetIndex));
    };
    const updatePosition = (i: number, offset: number) => (positions[i] = offset);

    useEffect(() => {
        setOrder(currentLabelGroup.labels)
    }, [currentLabelGroup.labels])


    return (
        <ul>
            {order.map((label: Label, i: number) => (
                <DraggableItem
                    key={label.id}
                    height={(label.state + 1) * 50}
                    label={label}
                    i={i}
                    updatePosition={updatePosition}
                    updateOrder={updateOrder}
                    currentPermissions={currentPermissions}
                    currentLabelGroup={currentLabelGroup}
                    editingLabel={editingLabel}
                    setEditingLabel={setEditingLabel}
                    roles={roles}
                />
            ))}
        </ul>
    );
};

