import { createSlice } from "@reduxjs/toolkit";

export interface Invoice{
    amount: number,
    subtotal: number,
    tax: number,
    billTo: string,
    emailTo: string,
    buyOrder: string,
    htmlContent: string
    buyOrderId: string,
}

export interface Order {
    id: string,
    buyOrder: string,
    status: number
    date: string
    isPaid: boolean,
    reason: string
    invoice: Invoice
    buyOrderId: string,
}

export interface orderStore {
    status: string,
    orders: Invoice[],
}

const initialState: orderStore = {
    status: "loading",
    orders: [],
}

export const orderSlice = createSlice({
    name: 'Orders',
    initialState,
    reducers: {
        setAllOrders: (state, { payload }) => {
            state.status = "confirmed"
            state.orders = payload.result
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAllOrders, loading, errorState} = orderSlice.actions