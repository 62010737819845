import { createSlice } from "@reduxjs/toolkit";

export interface LoginData {
    email: string,
    password: string
}
export interface UserAuth {
    sub: string,
    email: string,
    token: string,
    roles: string,
    family_name: string,
    birthdate: string,
    profileImage: string
}
export interface User {
    status: string,
    token: string | null,
    email: string,
    name: string,
    lastname: string
    birthdate: string,
    roles: string,
    profileImage: string
}
export interface UserStore {
    status: string,
    username: string
    token: string | null,
    nexusRoles: string[],
    email: string,
    name: string,
    lastname: string
    id: string,
    tesseractId: string,
    tenantId: string,
    isOwner: boolean,
    roles: string[],
    profileImage: string
    errorMessage: string,
    gender: string,
    phoneNumber: string,
    address: string,
    city: string,
    state: string,
    postalCode: string,
    country: string,
    suscription: {
        suscriptionId: string,
        id: string,
        "name": string,
        "price": number,
        "seatPrice": number,
        "storage": number,
        "apiCalls": number,
        "roles": number,
        "tables": number,
        "maxSeats": number,
        "autorules": number
    } | null
}
const initialState: UserStore = {
    status: "loading",
    token: null,
    nexusRoles: [],
    email: "",
    name: "",
    lastname: "",
    tesseractId: "",
    tenantId: "",
    roles: [],
    profileImage: "",
    username: "",
    id: "",
    isOwner: false,
    errorMessage: "",
    gender: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    suscription: null,
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            state.status = "logged"
            // state.news = payload.data.news
            window.localStorage.setItem("jwt", payload.jwTtoken)
            state.nexusRoles = payload.nexusRoles
            state.token = payload.jwTtoken;
            state.email = payload.email;
            state.name = payload.name;
            state.id = payload.id;
            state.lastname = payload.lastname;
            if (typeof payload.roles == "string") {
                state.roles = [payload.roles]
            } else
                state.roles = payload.roles;
            state.tenantId = payload.tenantId;
            state.username = payload.userName
            state.isOwner = payload.isOwner;
            state.tesseractId = payload.tesseractId;
            state.address = payload.address
            state.city = payload.city
            state.country = payload.country
            state.state = payload.state
            state.phoneNumber = payload.phoneNumber
            state.gender = payload.gender
            state.postalCode = payload.postalCode
            state.suscription = payload.suscription
            // state.profileImage = payload.profileImage;
        },
        loginTenant: (state, { payload }) => {
            state.status = "loggedTenant"
            // state.news = payload.data.news
            window.localStorage.setItem("jwt", payload.jwTtoken)
            state.nexusRoles = payload.nexusRoles
            state.token = payload.jwTtoken;
            state.email = payload.email;
            state.name = payload.name;
            state.id = payload.id;
            state.lastname = payload.lastname;
            if (typeof payload.roles == "string") {
                state.roles = [payload.roles]
            } else
                state.roles = payload.roles;
            state.tenantId = payload.tenantId;
            state.username = payload.userName
            state.isOwner = payload.isOwner;
            state.tesseractId = payload.tesseractId;
            state.address = payload.address
            state.city = payload.city
            state.country = payload.country
            state.state = payload.state
            state.phoneNumber = payload.phoneNumber
            state.gender = payload.gender
            state.postalCode = payload.postalCode
            state.suscription = payload.suscription
            // state.profileImage = payload.profileImage;
        },
        loading: (state) => {
            state.status = "loading"
            state.errorMessage = ""

        },
        logout: (state) => {
            state.status = "notLogged"
            // state.news = payload.data.news
            state.token = ""
            state.email = ""
            state.name = ""
            state.id = "";
            state.lastname = "";
            state.roles = [];
            state.tenantId = "";
            state.username = ""
            state.tesseractId = "";
            state.isOwner = false;
            state.address = ""
            state.city = ""
            state.country = ""
            state.phoneNumber = ""
            state.gender = ""
            state.postalCode = ""
            state.suscription = null
        },
        error: (state, { payload }) => {
            state.status = "error"
            state.errorMessage = payload

        },
    }
})
export const { login, loginTenant, loading, logout, error } = authSlice.actions
