import React, { FormEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getTablesColumns, getTablesNames } from '../../redux/tables/thunk'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { TableNames } from '../../redux/tables/slice'
import { types } from '../Enums/ColumnsTypes'
import axios from 'axios'
import { LabelObject } from '../../redux/labels/slice'
import { createWidget, updateWidget } from '../../redux/dashboards/thunk'
import { useParams } from 'react-router-dom'
import { Colorful } from '@uiw/react-color'
import { WidgetLeaderboard } from '../../redux/dashboards/slice'

interface Props {
    dashboardId: string
    widget: WidgetLeaderboard
}

export const EditLeaderboard = ({ dashboardId, widget }: Props) => {
    const [widgetData, setWidgetData] = useState(widget)
    const dispatch = useAppDispatch()
    const { tablesName, tablesColumns } = useAppSelector(state => state.tables)
    const { status } = useAppSelector(state => state.dashboards)
    const { tenantId } = useAppSelector(state => state.auth)

    const handleChange = (event: SelectChangeEvent) => {
        let { value, name } = event.target
        setWidgetData({ ...widgetData, [name]: value })
    }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        dispatch(updateWidget(
            {
                ...widgetData,
                token: ''
            },
            dashboardId,
            "/Widget/Leaderboard"
        ))
    }
    useEffect(() => {
        dispatch(getTablesNames(tenantId))
    }, [])
    useEffect(() => {
        if (widgetData.tableId)
            dispatch(getTablesColumns(widgetData.tableId))
    }, [widgetData.tableId])
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                marginBottom: '100px'
            }}
            component={'form'}
            onSubmit={handleSubmit}
        >
            <FormControl fullWidth>
                <InputLabel required>Table</InputLabel>
                <Select
                    value={widgetData.tableId}
                    label="Table"
                    name='tableId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesName.map((e) => {
                            return (
                                <MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel required>Contacts Column</InputLabel>
                <Select
                    value={widgetData.contactColumnId}
                    label="Contacts Column"
                    name='contactColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.Contact)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel required>Control Column</InputLabel>
                <Select
                    value={widgetData.numberColumnId}
                    label="Control Column"
                    name='numberColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.Number)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <Typography fontSize={18} fontWeight={400} color={"#131313"} mb={1}>
                    Select Background Color:
                </Typography>
                <Box sx={{ border: '1px solid #ced4da', borderRadius: '4px', p: 1 }}>
                    <TextField
                    label="Hex"
                    value={widgetData.backgroundColor}
                    onChange={(e) => setWidgetData({ ...widgetData, backgroundColor: e.target.value })}
                    inputProps={{ maxLength: 7 }}
                    sx={{ width: '150px', marginBottom: 2 }}
                    />
                    <Colorful
                        disableAlpha
                        color={widgetData.backgroundColor}
                        onChange={(color) => setWidgetData({ ...widgetData, backgroundColor: color.hex })}
                    />
                </Box>
            </FormControl>

            <FormControl fullWidth>
                <Typography fontSize={18} fontWeight={400} color={"#131313"} mb={1}>
                    Select Font Color:
                </Typography>
                <Box sx={{ border: '1px solid #ced4da', borderRadius: '4px', p: 1 }}>
                    <TextField
                    label="Hex"
                    value={widgetData.fontColor}
                    onChange={(e) => setWidgetData({ ...widgetData, fontColor: e.target.value })}
                    inputProps={{ maxLength: 7 }}
                    sx={{ width: '150px', marginBottom: 2 }}
                    />
                    <Colorful
                        disableAlpha
                        color={widgetData.fontColor}
                        onChange={(color) => setWidgetData({ ...widgetData, fontColor: color.hex })}
                    />
                </Box>
            </FormControl>

            <Button type='submit' disabled={status === 'loading'} variant='contained'>
                Update Widget
            </Button>
        </Box>
    )
}