import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setAllSeats, loading, errorState } from "./slice";
import SeatAgent from "./agent";

export const getAllSeats = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await SeatAgent.getAllSeats(tenantId)
            .then((response) => {
                dispatch(setAllSeats(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}

export const deleteSeat = (id: string, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await SeatAgent.delSeat(id)
            .then((response) => {
                dispatch(getAllSeats(tenantId))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })

    }
}