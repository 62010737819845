import { Box, Divider, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { ListDrawer } from './List'
import { Dropdown, Section } from './Models'
import { ItemDrawer } from './Item'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Items {
    path: string,
    name: string | any
}
interface SimpleItem {
    path: string,
    name: string
    icon: React.ReactNode
}
interface ItemSingle {
    title: string,
    items: Items[],
    icon: React.ReactNode
}
export interface Item {
    title: string,
    items:
    ItemSingle[],

}
interface Props {
    item: Section
    open: boolean
    handleOpenDrawer: () => void,
    subscription: string

}
export const ListwithTitle = ({ item, open, handleOpenDrawer, subscription }: Props) => {
    const [openSection, setOpenSection] = React.useState(true)
    const maxHeight = (item.dropdownItems.length + 1 + item.simpleItems.length + 1) * 40 + "px"
    useEffect(() => {
        if (!open) {
            setOpenSection(false)
        }
    }, [open])

    if (item.authorizedSubscriptions.includes(subscription))
        return (
            <Box

            >
                <Divider />

                <Box
                    px={open ? 2 : 0}
                    my={2}
                    sx={{
                        // maxHeight: openSection ? maxHeight : "40px",
                        transition: 'ease-in 0.15s all',
                    }}
                >

                    <Box
                        onClick={() => {
                            setOpenSection(!openSection)
                            if (!open)
                                handleOpenDrawer()
                        }}
                        px={1}
                        py={1}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            transition: "ease-in 0.25s",
                            justifyContent: open ? 'space-between' : 'center',
                            alignItems: 'center',
                            minHeight: '40px',
                            borderRadius: 2,
                            ":hover": {
                                // backgroundColor: "rgba(255,255,255,0.1)",
                                color: "#fff",

                            },
                            color: "#6a7187"
                        }}
                    // onMouseEnter={handleOpenMenu}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                            {item.icon}
                            <Typography sx={{ display: open ? "block" : "none", fontSize: 20 }} variant='caption' >{item.title}</Typography>
                        </Box>
                        {
                            open &&
                            <ExpandMoreIcon sx={{
                                transform: openSection ? 'rotate(180deg)' : "",
                                transition: 'ease-in 0.25s transform'
                            }} />
                        }
                    </Box>
                    {
                        openSection &&
                        <Box
                            sx={{
                                maxHeight: open ? "100%" : "0px",
                                opacity: open ? "1" : "0",
                                // display: open ? "block" : "none",
                                overflow: 'hidden',
                                // transition: 'ease-in 1s all'
                                transition: "all 0.25s ease-in",
                                pl: 1

                            }}
                        >
                            {
                                item.dropdownItems.map((element, zIndex) => {
                                    if (element.authorizedSubscriptions.includes(subscription))
                                        return <ListDrawer icon={element.icon} key={"list " + zIndex} title={element.title} items={element.items} openDrawer={open} handleOpenDrawer={handleOpenDrawer} />
                                }

                                )
                            }
                            {
                                item.simpleItems.map((element, zIndex) => {
                                    if (element.authorizedSubscriptions.includes(subscription))
                                        return <ItemDrawer title={element.name} key={"item " + zIndex} pathLink={element.path} icon={element.icon} open={open} />
                                }

                                )
                            }
                        </Box>
                    }


                </Box>

            </Box>
        )
    else {
        return (<></>)
    }
}
