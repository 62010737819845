import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { cleanCPanel, error, loading, setCPanel } from "./slice";
import AuthAgent from "./agent";
import { parseJwt } from "../../functions/DecodeJWT";
import cpanelAgent from "./agent";
import Swal from "sweetalert2";


export const setSettings = (newCpanelData: any, oldCpanelData: any): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading());

        if (oldCpanelData.host === "", oldCpanelData.port === "", oldCpanelData.user === "") {
            //Create cpanel settings
            cpanelAgent.createSetting(newCpanelData)
                .then((result) => {
                    Swal.fire({
                        title: 'Configuración actualizada',
                        showCancelButton: true,
                        text: `La configuración de su CPanel fue actualizada con exito.`,
                        icon: 'success',
                        cancelButtonText: "Volver atras",
                        showConfirmButton: false
                    })
                    dispatch(setCPanel({
                        smtpHost: newCpanelData.SMTPHost,
                        smtpPort: newCpanelData.SMTPPort,
                        smtpUser: newCpanelData.SMTPUser
                    }))
                }).catch((err) => {
                    Swal.fire({
                        title: "Ups! Something's wrong",
                        showCancelButton: true,
                        cancelButtonText: "Go back",
                        // text: `Error Message: ${err.response.data.message}`,
                        icon: 'error',
                        showConfirmButton: false
                    })
                    //console.log(err);
                    dispatch(error())

                });
        }
        else {

            //Edit cpanel settings
            cpanelAgent.updateSetting(newCpanelData)
                .then((result) => {
                    Swal.fire({
                        title: 'Configuración actualizada',
                        showCancelButton: true,
                        text: `La configuración de su CPanel fue actualizada con exito.`,
                        icon: 'success',
                        cancelButtonText: "Volver atras",
                        showConfirmButton: false
                    })
                    dispatch(setCPanel({
                        smtpHost: newCpanelData.SMTPHost,
                        smtpPort: newCpanelData.SMTPPort,
                        smtpUser: newCpanelData.SMTPUser
                    }))
                }).catch((err) => {
                    Swal.fire({
                        title: "Ups! Something's wrong",
                        showCancelButton: true,
                        cancelButtonText: "Go back",
                        // text: `Error Message: ${err.response.data.message}`,
                        icon: 'error',
                        showConfirmButton: false
                    })
                    //console.log(err);
                    dispatch(error())

                });
        }
    }
}

export const getSettings = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading());
        cpanelAgent.getSettings(tenantId)
            .then((result) => {

                dispatch(setCPanel(result.result))
            }).catch((err) => {
                // Swal.fire({
                //     title: "Ups! Something's wrong",
                //     showCancelButton: true,
                //     cancelButtonText: "Go back",
                //     text: `No pudimos recopilar su información, intente recargando la pagina.`,
                //     icon: 'error',
                //     showConfirmButton: false
                // })
                // console.log(err);
                dispatch(error())

            });
    }
}


export const cleanCPanelData = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(cleanCPanel());
    }
}

