import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    Button,
    Grid,
    IconButton,
    Modal,
    TextField,
    Typography,
    Tooltip,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Card,
    CardContent,
    Box,
    FormControlLabel,
    Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import { EmailBox } from "./Boxes/EmailBox";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { ReportBox } from "./Boxes/ReportBox";
import SouthIcon from "@mui/icons-material/South";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
    OnOffAutoRuleSettings,
    delAutorule,
    getAutorulesData,
    postAutoruleBoxEmail,
    postAutoruleBoxReport,
} from "../../redux/autoRules/thunk";
import Swal from "sweetalert2";
import { EditAutoRule } from "./Components/EditAutoRule";
import { LoadingCube } from "../custom/loader/LoadingCube";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

import ReactFlow, {
    Controls,
    Handle,
    MiniMap,
    Position,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
} from "reactflow";
import "reactflow/dist/style.css";
import "./flowStyles.css";
import { ReportNode } from "./ReactFlow/Types/ReportNode";
import { EmailNode } from "./ReactFlow/Types/EmailNode";
import { TriggerButton } from "./ReactFlow/Types/AddTriggerButtonNode";
import { MiddleWareNode } from "./ReactFlow/Types/MiddleWareNode";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomSwitch } from "../custom/CustomSwitch";
import GppGoodIcon from "@mui/icons-material/GppGood";
const nodeTypes = {
    reportNode: ReportNode,
    emailNode: EmailNode,
    triggerButton: TriggerButton,
    middleWareNode: MiddleWareNode,
};
export const AutorulesComponent = ({
    handleOpenPermissions,
    permissionsObject,
    roles,
}) => {
    const { id: autoRuleId } = useParams();
    let tableId;

    const [items, setItems] = useState([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Menu var
    const [anchorSetting, setAnchorSetting] = React.useState(null);
    const openSettingMenu = Boolean(anchorSetting);
    const handleClickSettingMenu = (event) => {
        setAnchorSetting(event.currentTarget);
    };
    const handleCloseSettingMenu = () => {
        setAnchorSetting(null);
    };

    //auto rule crud
    const [openEditTable, setOpenEditTable] = useState(false);

    //reports
    const [anchorElReports, setAnchorElReports] = React.useState(null);
    const openReports = Boolean(anchorElReports);
    const handleClickReports = (event) => {
        setAnchorElReports(event.currentTarget);
    };
    const handleCloseReports = () => {
        setAnchorElReports(null);
    };

    //autorule crud
    const { tablesName: tablesNameState } = useAppSelector(
        (state) => state.tables
    );
    const { autoRule: autoRuleState, status } = useAppSelector(
        (state) => state.autoRules
    );
    const { tenantId } = useAppSelector((state) => state.auth);

    const [autorules, setAutorules] = useState(autoRuleState);

    // trigger menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // sequence
    const [anchorElSequences, setAnchorElSequences] = React.useState(null);
    const openSequences = Boolean(anchorElSequences);
    const handleClickSequences = (event) => {
        setAnchorElSequences(event.currentTarget);
    };
    const handleCloseSequences = () => {
        setAnchorElSequences(null);
    };

    // Nodes
    const initialNodes = [
        {
            id: "node-trigger-btn",
            type: "triggerButton",
            position: { x: 0, y: 0 },
            data: {
                handleClick: handleClick,
                open: open,
                permissions: permissionsObject,
                roles,
            },
        },
    ];

    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState([]);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => {
            // console.log(changes);
            // return setEdges((eds) => applyEdgeChanges(changes, eds));
            return;
        },
        [setEdges]
    );
    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );
    const handleChangeSwitch = (event, checked) => {
        dispatch(OnOffAutoRuleSettings(checked, autorules.tenantAutorule.id));
    };

    useEffect(() => {
        dispatch(getAutorulesData(autoRuleId));
    }, [autoRuleId]);

    useEffect(() => {
        setAutorules(autoRuleState);
    }, [autoRuleState]);
    useEffect(() => {
        if (autorules?.autoruleTaks.length != 0) {
            var rulesNodes = [
                // {
                //     id: "node-1",
                //     type: "textUpdater",
                //     position: { x: 0, y: 0 },
                //     data: { value: 123 },
                // },
            ];
            var rulesEdges = [
                // {
                //     id: "node-1",
                //     type: "textUpdater",
                //     position: { x: 0, y: 0 },
                //     data: { value: 123 },
                // },
            ];
            autorules?.autoruleTaks.map((item, index) => {
                if (item.setting.type === 0) {
                    rulesNodes.push({
                        id: `${item.sequencePosition}`,
                        type: "reportNode",
                        position: { x: 0, y: 200 * item.sequencePosition },
                        data: {
                            value: item,
                            allTasks: autorules?.autoruleTaks,
                            onClick: handleClickSequences,
                            permissions: permissionsObject,
                            roles,
                        },
                        selectable: false,
                    });
                } else if (item.setting.type === 1) {
                    tableId = autorules.autoruleTaks.find(
                        (auto) => auto.sequencePosition === 0
                    );
                    rulesNodes.push({
                        id: `${item.sequencePosition}`,
                        type: "emailNode",
                        position: { x: 0, y: 200 * item.sequencePosition },
                        data: {
                            value: item,
                            tableId: tableId.setting.tableId,
                            allTasks: autorules?.autoruleTaks,
                            onClick: handleClickSequences,
                            permissions: permissionsObject,
                            roles,
                        },
                        selectable: false,
                    });
                } else if (item.setting.type === 2) {
                    rulesNodes.push({
                        id: `${item.sequencePosition}`,
                        type: "middleWareNode",
                        position: { x: 0, y: 200 * item.sequencePosition },
                        data: {
                            value: item,
                            allTasks: autorules?.autoruleTaks,
                            onClick: handleClickSequences,
                            permissions: permissionsObject,
                            roles,
                        },
                        selectable: false,
                    });
                }
                if (index != autorules?.autoruleTaks.length) {
                    rulesEdges.push({
                        id: "edge-1",
                        source: `${item.sequencePosition}`,
                        sourceHandle: "a",
                        target: `${item.sequencePosition + 1}`,
                        markerEnd: {
                            type: "arrow",
                            color: "#369BFE",
                            width: 40,
                            height: 40,
                        },
                    });
                }
            });
            setEdges(rulesEdges);
            setNodes(rulesNodes);
        } else {
            setEdges([]);
            setNodes(initialNodes);
        }
    }, [autorules, autoRuleId, permissionsObject]);
    useEffect(() => {
        let initial = [
            {
                id: "node-trigger-btn",
                type: "triggerButton",
                position: { x: 0, y: 0 },
                data: {
                    handleClick: handleClick,
                    open: open,
                    permissions: permissionsObject,
                    roles,
                },
            },
        ];
        if (nodes[0].id === "node-trigger-btn") {
            setNodes(initial);
        }
    }, [permissionsObject]);

    const rfStyle = {
        backgroundColor: "#fff",
        minHeight: "100vh",
    };
    //loading
    if (status === "loading" || autorules === null) {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoadingCube />
            </Box>
        );
    }

    return (
        <Grid
            container
            spacing={1}
            sx={{
                display: "flex",
                justifyContent: "space-around",
                mt: 2,
            }}
        >
            <Grid
                item
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "100%", sm: "95%", md: "90%", lg: "80%" },
                }}
            >
                <Card sx={{ width: "auto", mb: 2 }}>
                    <CardContent>
                        <Box
                            sx={{
                                display: "flex",
                                marginLeft: 1,
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{ display: "flex", fontSize: 24 }}
                                    variant="h3"
                                >
                                    {autorules?.tenantAutorule.name}
                                </Typography>
                                {(permissionsObject.includes("CanUpdate") ||
                                    (permissionsObject.length == 0 &&
                                        roles.includes(
                                            "CanManageAutorules"
                                        ))) && (
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Typography>Off</Typography>
                                        <CustomSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                                autorules.tenantAutorule.state
                                            }
                                            onChange={handleChangeSwitch}
                                        />
                                        <Typography>On</Typography>
                                    </Stack>
                                )}

                                {/* <FormControlLabel
                                    control={<CustomSwitch sx={{ m: 1 }} />}
                                    label="iOS style"
                                /> */}
                            </Box>
                            <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={
                                        openSettingMenu
                                            ? "basic-menu"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        openSettingMenu ? "true" : undefined
                                    }
                                    onClick={handleClickSettingMenu}
                                >
                                    <MoreHorizIcon></MoreHorizIcon>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorSetting}
                                    open={openSettingMenu}
                                    onClose={handleCloseSettingMenu}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    {(permissionsObject.includes("CanUpdate") ||
                                        (permissionsObject.length == 0 &&
                                            roles.includes(
                                                "CanManageAutorules"
                                            ))) && (
                                        <MenuItem
                                            onClick={() => {
                                                setOpenEditTable(true);
                                                handleCloseSettingMenu();
                                            }}
                                        >
                                            Edit{" "}
                                            <EditIcon
                                                sx={{
                                                    height: 18,
                                                    width: 18,
                                                    ml: 1,
                                                }}
                                            />
                                        </MenuItem>
                                    )}
                                    {(permissionsObject.includes("CanDelete") ||
                                        (permissionsObject.length == 0 &&
                                            roles.includes(
                                                "CanManageAutorules"
                                            ))) && (
                                        <MenuItem
                                            onClick={() => {
                                                handleCloseSettingMenu();
                                                Swal.fire({
                                                    title: "¿Seguro quieres borrar la auto rule?",
                                                    text: "Una vez borrada no hay vuelta atras!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor:
                                                        "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Borrar",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        dispatch(
                                                            delAutorule(
                                                                autoRuleId,
                                                                tenantId
                                                            )
                                                        );
                                                        navigate("/");
                                                    }
                                                });
                                            }}
                                        >
                                            Delete{" "}
                                            <DeleteIcon
                                                sx={{
                                                    height: 18,
                                                    width: 18,
                                                    ml: 1,
                                                }}
                                            />
                                        </MenuItem>
                                    )}
                                    {roles.includes("CanManageRoles") && (
                                        <MenuItem
                                            onClick={() => {
                                                handleCloseSettingMenu();
                                                handleOpenPermissions();
                                            }}
                                        >
                                            Permissions{" "}
                                            <GppGoodIcon
                                                sx={{
                                                    height: 18,
                                                    width: 18,
                                                    ml: 1,
                                                }}
                                            />
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        </Box>
                    </CardContent>
                </Card>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    fitView
                    style={rfStyle}
                >
                    <Controls />
                </ReactFlow>
            </Grid>
            <Menu
                id="TriggerMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={handleClickReports} key={"report"}>
                    <ListItemIcon>
                        <NoteAddIcon />
                    </ListItemIcon>
                    <ListItemText>Reporte</ListItemText>
                </MenuItem>
            </Menu>

            <Menu
                id="basic-menu2"
                anchorEl={anchorElReports}
                open={openReports}
                onClose={handleCloseReports}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {tablesNameState.map((colEmail) => {
                    return (
                        <MenuItem
                            key={colEmail.id}
                            onClick={() => {
                                //console.log("colEmail", colEmail)
                                dispatch(
                                    postAutoruleBoxReport({
                                        autoruleId:
                                            "E1D9A71D-9D03-4A06-8D86-78784261AB63",
                                        tenantAutoruleId: autoRuleId,
                                        name: colEmail.name,
                                        sequencePosition: 0,
                                        tableId: colEmail.id,
                                        token: "A",
                                    })
                                );
                                handleCloseReports();
                                handleClose();
                                //handleItemAdd("Seat")
                            }}
                        >
                            <ListItemText>{colEmail.name}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>

            <Menu
                id="sequencesMenu"
                anchorEl={anchorElSequences}
                open={openSequences}
                onClose={handleCloseSequences}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleCloseSequences();
                        dispatch(
                            postAutoruleBoxEmail({
                                autoruleId:
                                    "E1D9A71D-9D03-4A06-8D86-78784261AB63",
                                tenantAutoruleId: autoRuleId,
                                name: "Default email service",
                                sequencePosition: autorules.autoruleTaks.length,
                                token: "A",
                                emailSetting: {
                                    emailReceiver: "",
                                    emailSender: "",
                                    emailIssue: "Asunto creado por defecto",
                                    emailBody:
                                        "<h1>Esto es un mensaje auto generado</h1><p>Por favor modifique a su gusto</p>",
                                    emailAttachement: "",
                                },
                            })
                        );

                        //handleItemAdd("Email")
                    }}
                >
                    <ListItemIcon>
                        <EmailIcon />
                    </ListItemIcon>
                    <ListItemText>Email</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleCloseSequences();
                        /*dispatch(postAutoruleBoxEmail({
                        autoruleId: "E1D9A71D-9D03-4A06-8D86-78784261AB63",
                        tenantAutoruleId: autoRuleId,
                         taskName: "Default email service",
                         sequencePosition: 1,
                           token: "A",
                           emailSetting: {
                            emailReceiver: "",
                            emailSender: "",
                            emailIssue: "Asunto creado por defecto",
                             emailBody: "<h1>Esto es un mensaje auto generado</h1><p>Por favor modifique a su gusto</p>",
                              emailAttachement: ""
                              }
                              }))*/
                        //handleItemAdd("Email")
                    }}
                >
                    <ListItemIcon>
                        <DisplaySettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Middleware</ListItemText>
                </MenuItem>
            </Menu>
            {openEditTable === true && (
                <>
                    <EditAutoRule
                        open={openEditTable}
                        setOpen={setOpenEditTable}
                        autoruleName={autorules?.tenantAutorule.autoruleName}
                        autoRuleId={autoRuleId}
                    />
                </>
            )}
        </Grid>
    );
};
