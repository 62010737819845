import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./Table.css";
import {
    Button,
    Grid as MuiGrid,
    IconButton,
    MenuItem,
    Modal,
    TextField,
    Typography,
    Menu,
    Tooltip,
    Select
} from "@mui/material";
import Swal from "sweetalert2";
import { PermissionsSidePanel } from '../../Roles/SidePanel/PermissionsSidePanel';
//Columns icons
import LooksOneIcon from "@mui/icons-material/LooksOne";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TodayIcon from "@mui/icons-material/Today";
import RuleIcon from "@mui/icons-material/Rule";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PaidIcon from "@mui/icons-material/Paid";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import Filter1Icon from "@mui/icons-material/Filter1";
import NumbersIcon from "@mui/icons-material/Numbers";
import LabelIcon from "@mui/icons-material/Label";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    delTables,
    getTableNameById,
    getTablesColumns,
    getTablesData,
    modifyTableColumnName,
    setVoidAll,
} from "../../../redux/tables/thunk";
import { EditTableName } from "./EditTableName";
import { ColumnMenuItem } from "./ColumnMenuItem";
import { CreateColumn } from "./CreateColumn";
import { Link } from "react-router-dom";
import { types } from "../../../components/Enums/ColumnsTypes";
import MUIXTable from "./TableMUIX";
import { getAllClients } from "../../../redux/contacts/thunk";
import { getAllCompanies } from "../../../redux/account/thunk";
import { getAllEndUsers } from "../../../redux/endUser/thunk";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { blue, grey } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { LoadingCube } from "../../../components/custom/loader/LoadingCube";
import { ImageItemColumn } from "./ImageItemColumn";
import { ContactModal } from "../../../components/Contacts/components/ContactModal";
import { AccountModal } from "../../Accounts/Componentes/AccountModal";
import { AccountEditCell } from "./AccountEditSingle";
import { ContactEditCell } from "./ContactEditSingle";
import axios from "axios";
import { LabelEditCell } from "./LabelEditCell";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { ClockIcon } from "@mui/x-date-pickers";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateTimeEditCell } from "./DateTimeEditSingle"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { delTableRow} from '../../../redux/tables/thunk';
import { GridFilterInputValue } from '@mui/x-data-grid-pro';
import { getGridNumericOperators, getGridDateOperators } from '@mui/x-data-grid';
import { CutomAutocomplete } from "../../../components/custom/CutomAutocomplete";
dayjs.extend(utc);


//modal style
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    pt: 2,
    px: 4,
    pb: 3,
};

const url = process.env.REACT_APP_NEXUS_BACK_URL;

function CustomFilterInputDateRange(props) {
    const { item, applyValue} = props;

    const [selectedDateRange, setSelectedDateRange] = React.useState({
        startDate: item.value?.startDate ? new Date(item.value.startDate) : null,
        endDate: item.value?.endDate ? new Date(item.value.endDate) : null,
    });

    const handleApplyFilter = () => {
        if (selectedDateRange.startDate && selectedDateRange.endDate) {
            const formatForCSharp = (date) => 
                `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

            const formattedStartDate = formatForCSharp(selectedDateRange.startDate);
            const formattedEndDate = formatForCSharp(selectedDateRange.endDate);

            const formattedValue = `${formattedStartDate};${formattedEndDate}`;
            applyValue({ ...item, value: formattedValue });
        }
    };


    return (
        // <LocalizationProvider dateAdapter={AdapterDateFns}>
        //     <Box sx={{ display: "flex", gap: 2, alignItems: 'center' }}>
        //         <DateTimePicker
        //             renderInput={(props) => (
        //                 <TextField
        //                     {...props}
        //                     variant="standard"
        //                     InputLabelProps={{
        //                         shrink: true,
        //                     }}
        //                     label={"Start Date and Time"}
        //                 />
        //             )}
        //             ampm={false}
        //             views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
        //             value={selectedDateRange.startDate}
        //             onChange={(newValue) => setSelectedDateRange({ ...selectedDateRange, startDate: newValue })}
        //         />
        //         <DateTimePicker
        //             renderInput={(props) => (
        //                 <TextField
        //                     {...props}
        //                     variant="standard"
        //                     InputLabelProps={{
        //                         shrink: true,
        //                     }}
        //                     label={"End Date and Time"}
        //                 />
        //             )}
        //             ampm={false}
        //             views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
        //             value={selectedDateRange.endDate}
        //             onChange={(newValue) => setSelectedDateRange({ ...selectedDateRange, endDate: newValue })}
        //         />
        //         <Button onClick={handleApplyFilter} variant="contained" color="primary">
        //             Apply
        //         </Button>
        //     </Box>
        // </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Box sx={{ display: "flex", gap: 2, alignItems: 'center' }}>
        <DateTimePicker
            renderInput={(props) => (
                <TextField
                    {...props}
                    fullWidth  // Esto hará que el TextField ocupe todo el ancho disponible
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Start Date and Time"
                />
            )}
            ampm={false}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            value={selectedDateRange.startDate}
            onChange={(newValue) => setSelectedDateRange({ ...selectedDateRange, startDate: newValue })}
        />
        <DateTimePicker
            renderInput={(props) => (
                <TextField
                    {...props}
                    fullWidth  // Esto hará que el TextField ocupe todo el ancho disponible
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="End Date and Time"
                />
            )}
            ampm={false}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            value={selectedDateRange.endDate}
            onChange={(newValue) => setSelectedDateRange({ ...selectedDateRange, endDate: newValue })}
        />
        <Button
            onClick={handleApplyFilter}
            variant="contained"
            color="primary"
            disabled={!selectedDateRange.startDate || !selectedDateRange.endDate}
        >
            Apply
        </Button>
    </Box>
</LocalizationProvider>
    );
}

function CustomFilterInputSingleSelect(props) {
    const {
        item,
        applyValue,
        type,
        apiRef,
        focusElementRef,
        columnId,
        options,
        ...others
    } = props;

    const [inputValue, setInputValue] = useState('');
    const [labels, setLabels] = useState(options);
    const [selectedLabel, setSelectedLabel] = useState(labels.find(label => label.id === item.value));

    useEffect(() => {
        fetchLabels('');
    }, [columnId]);

    useEffect(() => {
        setSelectedLabel(labels.find(label => label.id === item.value));
    }, [labels, item.value]);

    const fetchLabels = async (searchTerm) => {
        try {
            const response = await axios.get(`${url}/api/v1/PublicFeature/Labels?Id=${columnId}&Tag=${searchTerm}`);
            setLabels(response.data.result.labels);
        } catch (error) {
            console.error("Error fetching labels:", error);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        fetchLabels(newInputValue);
    };

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedLabel(newValue);

        applyValue({ ...item, value: newValue ? newValue.id : "" });
    };

    return (
        <Box sx={{ marginRight: 3, marginTop: 0, marginLeft: 2 }}>
            <CutomAutocomplete
                options={labels}
                title="tag"
                onChange={handleAutocompleteChange}
                onInputChange={handleInputChange}
                inputValue={inputValue}
                value={selectedLabel}
                ref={focusElementRef}
                type={type || "text"}
                {...others}
            />
        </Box>
    );

}


function CustomFilterInputDateTimePicker(props) {
    const { item, applyValue, apiRef, focusElementRef, options, ...others } = props;
  
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={focusElementRef}
              label={"Select Date and Time"}
            />
          )}
          ampm={false}
          views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
          value={item.value ? new Date(item.value) : null}
          onChange={(newValue) => {
            let dateValue = null;
            if (newValue instanceof Date) {
              // Convertir la fecha a formato ISO 8601
              dateValue = newValue.toISOString();
            } else if (newValue) {
              // Intentar convertir la cadena a fecha y luego a formato ISO 8601
              const parsedDate = new Date(newValue);
              if (!isNaN(parsedDate)) {
                dateValue = parsedDate.toISOString();
              }
            }
            applyValue({ ...item, value: dateValue });
          }}
        />
      </LocalizationProvider>
    );
  }

// function CustomFilterInputDateRangePicker(props) {
//     const { item, applyValue, apiRef, focusElementRef, options, ...others } = props;

//     const dateRangeValues = item.value ? item.value.split(';') : [null, null];
  
//     return (
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer components={['SingleInputDateRangeField']}>
//           <DateRangePicker
//             slots={{ field: SingleInputDateRangeField }}
//             value={
//                 dateRangeValues.map(dateString =>
//                   dateString ? dayjs(dateString, 'MM/DD/YYYY HH:mm:ss').toDate() : null
//                 )
//               }
//               onChange={(newValue) => {
//                 const formattedString = newValue
//                   ? `${dayjs(newValue[0]).format("MM/DD/YYYY HH:mm:ss")};${dayjs(newValue[1]).format("MM/DD/YYYY HH:mm:ss")}`
//                   : '';
//                 applyValue({ ...item, value: formattedString });
//               }}
//             renderInput={(startProps, endProps) => (
//               <>
//                 <TextField
//                   {...startProps}
//                   variant="standard"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   inputRef={focusElementRef}
//                   label="Start Date"
//                 />
//                 <TextField
//                   {...endProps}
//                   variant="standard"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   label="End Date"
//                 />
//               </>
//             )}
//             slotProps={{ textField: { variant: 'outlined' } }}
//           />
//         </DemoContainer>
//       </LocalizationProvider>
//     );
//   }

function formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export function isURL(cadena) {
    // Expresión regular actualizada para verificar si la cadena es una URL válida
    var regex = /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

    // Verifica si la cadena cumple con la expresión regular y no es un número con punto
    return regex.test(cadena) && isNaN(parseFloat(cadena));
}

function limpiarCerosInnecesarios(numero) {
    const numeroStr = numero.toString();
    const numeroLimpioStr = numeroStr.replace(/(\.\d*?[1-9])0*$/g, "$1");
    const numeroLimpio = parseFloat(numeroLimpioStr);
    return numeroLimpio;
}
const ColumnEditName = (id, name, editingId, tableId, Icon, pageNumber, pageSize) => {
    const [tempEditedName, setTempEditedName] = useState(name);
    const [editing, setEditing] = useState(false);
    const dispatch = useDispatch();

    const handleEditColumn = () => {
        setEditing(true);
    };

    const handleCancelEdit = () => {
        setTempEditedName(name);
        setEditing(false);
    };

    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            // Ejecutar acción al presionar Enter
            setEditing(false);
            dispatch(
                modifyTableColumnName({
                    id,
                    columnName: tempEditedName,
                    tableId,
                }, pageNumber, pageSize)
            );
        }
    };

    const handleEscKeyPress = (event) => {
        if (event.key === "Escape") {

            handleCancelEdit();
        }
    };
    return (
        <div>
            {editing ? (
                <Box
                    onKeyDown={(e) => {
                        handleEnterKeyPress(e);
                        handleEscKeyPress(e);
                    }}
                >
                    <TextField
                        label={"Column name"}
                        value={tempEditedName}
                        variant="standard"
                        required
                        onChange={(e) => setTempEditedName(e.target.value)}
                    />
                </Box>                
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",

                        alignItems: "center",
                        ".editIcon": {
                            display: "none",
                        },
                        ":hover": {
                            ".editIcon": {
                                display: "block",
                            },
                        },
                        ".MuiDataGrid-columnHeaderTitleContainerContent": {
                            width: "100%",
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <Icon sx={{ color: "#2196f3" }} />
                        {tempEditedName}
                    </Box>
                    <EditIcon
                        className="editIcon"
                        onClick={handleEditColumn}
                        sx={{
                            height: 20,
                            color: "#2196f3",
                            cursor: "pointer",
                        }}
                    ></EditIcon>
                </Box>
            )}
        </div>
    );
};
const ColumnEditNameLabel = (id, name, editingId, tableId, emoji, pageNumber, pageSize) => {
    const [tempEditedName, setTempEditedName] = useState(name);
    const [editing, setEditing] = useState(false);
    const dispatch = useDispatch();

    // Función para manejar el inicio de la edición
    const handleEditColumn = () => {
        setEditing(true);
    };

    // Función para confirmar los cambios
    const handleConfirmEdit = () => {
        // Realiza acciones para aplicar los cambios
        // setEditingColumn({
        //     id: "",
        //     originalName: "",
        // });
    };

    // Función para cancelar la edición
    const handleCancelEdit = () => {
        setTempEditedName(name);
        setEditing(false);
    };

    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            // Ejecutar acción al presionar Enter
            setEditing(false);
            dispatch(
                modifyTableColumnName({
                    id,
                    columnName: tempEditedName,
                    tableId,
                },pageNumber, pageSize)
            );
        }
    };

    const handleEscKeyPress = (event) => {
        if (event.key === "Escape") {
            // Ejecutar acción al presionar Esc
            handleCancelEdit();
        }
    };
    return (
        <div>
            {editing ? (
                <Box
                    onKeyDown={(e) => {
                        handleEnterKeyPress(e);
                        handleEscKeyPress(e);
                    }}
                >
                    <TextField
                        label={"Column name"}
                        value={tempEditedName}
                        variant="standard"
                        required
                        onChange={(e) => setTempEditedName(e.target.value)}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",

                        alignItems: "center",
                        ".editIcon": {
                            display: "none",
                        },
                        ":hover": {
                            ".editIcon": {
                                display: "block",
                            },
                        },
                        ".MuiDataGrid-columnHeaderTitleContainerContent": {
                            width: "100%",
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ color: "#2196f3" }}>
                            {emoji}
                        </Typography>
                        {tempEditedName}
                    </Box>
                    <EditIcon
                        className="editIcon"
                        onClick={handleEditColumn}
                        sx={{
                            height: 20,
                            color: "#2196f3",
                            cursor: "pointer",
                        }}
                    ></EditIcon>
                </Box>
            )}
        </div>
    );
};
export const TableComponent = ({
    handleOpenPermissions,
    permissionsObject,
    roles,
    handleOpenColumnPermissions,
    columnId,
    setColumnId
}) => {
    const { id: tableIdAux } = useParams();
    const tableId = tableIdAux;
    const navigate = useNavigate();
    const apiRefTable = useGridApiRef();

    // Edit Column name
    const [editingColumnId, setEditingColumnId] = useState("");
    const handleSetEditingColumnId = (id) => {
        setEditingColumnId(id);
    };

    //Filter
    const getCustomFilterOperators = () => [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
              return null;
            }
            return (params) => {
              return params.value != null
                ? ('' + params.value)
                    .toLowerCase()
                    .includes(filterItem.value.toLowerCase())
                : false;
            };
          },
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'not contains',
          value: 'notcontains',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
              return null;
            }
            return (params) => {
              return params.value != null
                ? !('' + params.value)
                    .toLowerCase()
                    .includes(filterItem.value.toLowerCase())
                : true;
            };
          },
          InputComponent: GridFilterInputValue,
        },
      ];
    const getBooleanFilterOperators = () => [
    {
        label: 'is',
        value: 'is',
        getApplyFilterFn: (filterItem) => {
        if (filterItem.value === 'true') {
            return (params) => params.value === true;
        } else if (filterItem.value === 'false') {
            return (params) => params.value === false;
        }
        return null;
        },
        InputComponent: ({ item, applyValue }) => {
            return (
              <Select
                value={item.value || ''}
                onChange={(event) => applyValue({ ...item, value: event.target.value })}
                style={{ width: '90%', marginTop: 16 }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            );
          },
    },
    ];
    //Permission Columns

    const handleOpenColumnPermissionsInternal = (columnId) => {
        setColumnId(columnId);
        handleOpenColumnPermissions(columnId); 
    };

    // Selected Contact
    const [selectedContact, setSelectedContact] = useState(null);
    const handleCloseContactModal = () => {
        setSelectedContact(null);
    };
    // Selected Account
    const [selectedAccount, setSelectedAccount] = useState(null);
    const handleCloseAccountModal = () => {
        setSelectedAccount(null);
    };

    // File Modal
    const [openFileModal, setOpenFileModal] = useState(false);
    const handleOpenFileModal = () => setOpenFileModal(true);
    const handleCloseFileModal = () => setOpenFileModal(false);
    const handleFileDeleteIconClick = (params) => {

        let currentColumn = tablesData.columns.find(
            (column) => column.name === params.colDef.field
        );
        let field = currentColumn?.fields.find((field) => field.rowId === params.row.id)

        Swal.fire({
            title: "Are you sure you want to delete the File?",
            text: "Once deleted there is no going back!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {

                const deleteFieldsObject = {
                    deleteFields: [
                      {
                        id: field.id,
                        columnId: field.columnId,
                        type: types.File,
                      },
                    ],
                    tableId: tableId
                  };
                if (status !== "loading")
                    dispatch(delTableRow(
                        deleteFieldsObject
                    ), tablesData.pageNumber, tablesData.pageSize)
            }
        });
      };

    // DateTimeRange Modal
    const [openDateRange, setOpenDateRange] = useState(false);
    const handleOpenDateRange = () => setOpenDateRange(true);
    const handleCloseDateRange = () => setOpenDateRange(false);

    //redux
    const { permissions } = useAppSelector((state) => state.users);
    const { tenantId, id: userId } = useAppSelector((state) => state.auth);
    const {
        tablesData: tablesDataState,
        tablesColumns: tableColumnsState,
        status,
        currentTableName,
        statusData,
    } = useAppSelector((state) => state.tables);
    const [tablesData, setTablesData] = useState(tablesDataState);
    const [tablesColumns, setTablesColumns] = useState(tableColumnsState);

    const dispatch = useAppDispatch();

    //table data
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(
        tablesColumns.map((column) => ({
            name: column.name,
            title: <ColumnMenuItem column={column} />,
            width: 5,
            id: column.id,
        }))
    );

    //add column modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //table crud
    const [openEditTable, setOpenEditTable] = useState(false);

    const [filters, setFilters] = React.useState({});

    const handleFilterModelChange = (model) => {
        setFilters(model);
    };

    const customFilterLogic = (params) => {
        const { itemsToFilter, filterValue } = params;

        // Implementa tu lógica de filtrado personalizada aquí
        // En este ejemplo, estamos filtrando si algún objeto en el array contiene el valor de filtro
        const filteredItems = itemsToFilter.filter((item) =>
            item.propertyToFilter.includes(filterValue)
        );

        return filteredItems.length > 0;
    };
    const DeleteTableButton = () => (
        <IconButton
            onClick={() => {
                // eslint-disable-next-line
                Swal.fire({
                    title: "¿Seguro quieres borrar la tabla?",
                    text: "Una vez borrada no hay vuelta atras!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Borrar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/");
                        dispatch(
                            delTables({
                                tenantId: tenantId,
                                tableId: tableId,
                            })
                        );
                    }
                });
            }}
            title="Borrar tabla"
            size="large"
        >
            <DeleteIcon />
        </IconButton>
    );

    const EditTableButton = () => (
        <IconButton onClick={() => setOpenEditTable(true)}>
            <EditIcon sx={{ display: "flex", justifyContent: "center" }} />
        </IconButton>
    );

    // Clients, Companies, EndUsers
    const { contacts } = useAppSelector((state) => state.contacts);
    const { accounts } = useAppSelector((state) => state.accounts);
    const { endUser } = useAppSelector((state) => state.endUser);
    const { permissionsList } = useAppSelector((state) => state.permissions);

    const getLabels = async (columnId) => {
        const url = process.env.REACT_APP_NEXUS_BACK_URL;
        let req = await axios.get(
            url + `/api/v1/DynamicColumn/Label?Id=${columnId}`
        );
        let labels = req.data.result;
        return labels;
    };
    //table configuration
    useEffect(() => {
        // dispatch(setVoidAll());
        // if (tablesDataState.id !== tableId) {
        dispatch(getTableNameById(tableId));
        dispatch(getTablesColumns(tableId));
        dispatch(getTablesData(tableId, tablesData.pageNumber, tablesData.pageSize));
        dispatch(getAllClients(tenantId, 0, 100000));
        dispatch(getAllCompanies(tenantId, 0, 100000));
        dispatch(getAllEndUsers(tenantId));
        // }
    }, [tableId]);

    useEffect(() => {
        setTablesData(tablesDataState);
        setTablesColumns(tableColumnsState);
    }, [tablesDataState, tableColumnsState]);

    const ResetTable = async () => {
        await obtenerColumnas();
    };
    const obtenerColumnas = async () => {


        const columns = await Promise.all(
            tablesDataState.columns.map(async (column) => {
                if (column.type === types.Text) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        type: "text",
                        filterOperators: getCustomFilterOperators(),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.colDef.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                TextFormatIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                        renderCell: (params) => {
                            if (!isURL(params.row[params.field]))
                                return <div>{params.row[params.field]}</div>;
                            else {
                                return (
                                    <a
                                        href={
                                            params.row[params.field].startsWith(
                                                "http"
                                            )
                                                ? params.row[params.field]
                                                : `http://${
                                                      params.row[params.field]
                                                  }`
                                        }
                                        target="blank"
                                    >
                                        {params.row[params.field]}
                                    </a>
                                );
                            }
                        },
                    };
                } else if (column.type === types.Number) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        type: "number",
                        filterOperators: getGridNumericOperators().filter(
                            (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>' || operator.value === '>=' || operator.value === '<' || operator.value === '<=',
                          ),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        sortable: false,
                        headerAlign: "align",
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.colDef.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                LooksOneIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Date) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        type: "date",
                        filterOperators: getGridDateOperators().filter(
                            (operator) => operator.value === 'is' || operator.value === 'after' || operator.value === 'before',
                          ),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        hideable: false,
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.colDef.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                TodayIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Bool) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 200,
                        id: column.id,
                        position: column.position,
                        type: "boolean",
                        filterOperators: getBooleanFilterOperators(),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.colDef.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                RuleIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.File) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 200,
                        id: column.id,
                        position: column.position,
                        columnType: column.type,
                        filterOperators: getCustomFilterOperators(),
                        // editable: true,
                        renderCell: (params) => {
                            let currentColumn = tablesData.columns.find(
                                (column) => column.name === params.colDef.field
                            );
                            let field = currentColumn?.fields.find((field) => field.rowId === params.row.id);
                            return (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                    id={params.row.id}
                                    className="file-cell"
                                >
                                    <AddCircleIcon
                                        onClick={handleOpenFileModal}
                                        sx={{
                                            opacity: "0",
                                            cursor: "pointer",
                                            color: blue[400],
                                            display: "none",
                                        }}
                                    ></AddCircleIcon>
                                    {field && ( // Renderiza DeleteIcon solo si field existe
                                        <DeleteIcon
                                            onClick={() => handleFileDeleteIconClick(params)}
                                            sx={{
                                                opacity: "0",
                                                cursor: "pointer",
                                                color: blue[400],
                                                display: "none",
                                            }}
                                        ></DeleteIcon>
                                    )}
                                    {" "}
                                    {params.row[params.field]}
                                </Box>
                            );
                        },
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.colDef.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                AttachFileIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Contact) {
                    return {
                        name: column.name,
                        field: column.name,
                        headerName: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        valueFormatter: ({ value }) =>
                            value ? value.map((e) => e.name).join("/") : "",
                        // filterOperators: [
                        //     {
                        //         value: "contains",
                        //         getApplyFilterFn: (filterItem) => {
                        //             if (
                        //                 filterItem.value == null ||
                        //                 filterItem.value === ""
                        //             ) {
                        //                 return null;
                        //             }
                        //             return ({ value }) => {
                        //                 // if one of the cell values corresponds to the filter item
                        //                 if (value)
                        //                     return value.some(
                        //                         (cellValue) =>
                        //                             cellValue.name ===
                        //                             filterItem.value
                        //                     );
                        //             };
                        //         },
                        //         InputComponent: (props) =>
                        //             CustomFilterInputSingleSelect({
                        //                 ...props,
                        //                 options: contacts.map((e) => e.name),
                        //             }),
                        //     },
                        // ],
                        filterOperators: getCustomFilterOperators(),
                        filterable: true,
                        renderCell: (params) => {
                            if (params.row[params.field] === null)
                                return <div>Invalid Contact</div>;
                            else
                                return (
                                    <Box sx={{ display: "flex", gap: 2 }}>
                                        {params.row[params.field].map(
                                            (contact) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            cursor: "pointer",
                                                            textDecoration:
                                                                "underline",
                                                            color: "blue",
                                                        }}
                                                        onClick={() => {
                                                            setSelectedContact(
                                                                contact
                                                            );
                                                        }}
                                                    >
                                                        {contact.name}
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </Box>
                                );
                        },

                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        type: "singleSelect",
                        valueOptions: [
                            ...contacts.map((contact) => ({
                                value: contact.id,
                                label: contact.name,
                            })),
                        ],
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                SupervisedUserCircleIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                        renderEditCell: (params) => {
                            return (
                                <ContactEditCell {...params}></ContactEditCell>
                            );
                        },
                    };
                } else if (column.type === types.Money) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        type: "number",
                        filterOperators: getGridNumericOperators().filter(
                            (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>' || operator.value === '>=' || operator.value === '<' || operator.value === '<=',
                          ),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        renderCell: (params) => {
                            return <Box>$ {params.row[column.name]}</Box>;
                        },
                        sortable: false,

                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                PaidIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Account) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        valueFormatter: ({ value }) =>
                            value ? value.name : "",
                        // filterOperators: [
                        //     {
                        //         value: "is",
                        //         getApplyFilterFn: (filterItem) => {
                        //             if (
                        //                 filterItem.value == null ||
                        //                 filterItem.value === ""
                        //             ) {
                        //                 return null;
                        //             }
                        //             return ({ value }) => {
                        //                 // if one of the cell values corresponds to the filter item
                        //                 if (value)
                        //                     return (
                        //                         value.name === filterItem.value
                        //                     );
                        //             };
                        //         },
                        //         InputComponent: (props) =>
                        //             CustomFilterInputSingleSelect({
                        //                 ...props,
                        //                 options: accounts.map((e) => e.name),
                        //             }),
                        //     },
                        // ],
                        filterOperators: getCustomFilterOperators(),
                        renderCell: (params) => {
                            if (params.row[params.field] === null)
                                return <div>Invalid Account</div>;
                            else
                                return (
                                    <Box
                                        sx={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            color: "blue",
                                        }}
                                        onClick={() => {
                                            setSelectedAccount(
                                                params.row[params.field]
                                            );
                                        }}
                                    >
                                        {params.row[params.field].name}
                                    </Box>
                                );
                        },

                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        type: "singleSelect",
                        valueOptions: [
                            ...accounts.map((account) => ({
                                value: account.id,
                                label: account.name,
                            })),
                        ],
                        renderEditCell: (params) => {
                            return (
                                <AccountEditCell
                                    {...params}
                                    apiRefTable={apiRefTable}
                                    ResetTable={ResetTable}
                                ></AccountEditCell>
                            );
                        },
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                ApartmentIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.EndUser) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        filterOperators: getCustomFilterOperators(),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        type: "singleSelect",
                        valueOptions: [
                            ...endUser.map((endUser) => ({
                                value: endUser.id,
                                label: endUser.username,
                            })),
                        ],
                        renderCell: (params) => {
                            if (params)
                                if (params.formattedValue != "")
                                    return <div>{params.formattedValue}</div>;
                            return <div>{params.row[params.field]}</div>;
                        },
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                PeopleOutlineIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Decimal) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        type: "number",
                        filterOperators: getGridNumericOperators().filter(
                            (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>' || operator.value === '>=' || operator.value === '<' || operator.value === '<=',
                          ),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        // toFixed:3
                        renderCell: (params) => {
                            return <div>{params.row[params.field]}</div>;
                        },
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                Filter1Icon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Id) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        filterOperators: getCustomFilterOperators(),
                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        type: "string",
                        renderCell: (params) => {
                            return <div>{params.row[params.field]}</div>;
                        },
                        sortable: false,
                        renderHeader: (params) => {
                            return ColumnEditName(
                                params.id,
                                params.colDef.name,
                                editingColumnId,
                                tableIdAux,
                                NumbersIcon,
                                tablesData.pageNumber,
                                tablesData.pageSize
                            );
                        },
                    };
                } else if (column.type === types.Label) {
                    let labels = await getLabels(column.id);
                    const valueOptions = labels && labels.labels
                        ? labels.labels.map(label => ({
                            value: label.id,
                            label: label.tag,
                        }))
                        : [];
                    return {
                        name: column.name,
                        field: column.name,
                        width: 150,
                        id: column.id,
                        position: column.position,
                        // filterOperators: ["contains"],

                        editable:
                            permissionsObject.includes("CanUpdateData") ||
                            permissionsObject.length == 0,
                        type: "singleSelect",
                        valueOptions: valueOptions,
                        valueFormatter: ({ value }) => (value ? value.tag : ""),
                        filterOperators: [
                            {
                                value: "is",
                                getApplyFilterFn: (filterItem) => {
                                    if (
                                        filterItem.value == null ||
                                        filterItem.value === ""
                                    ) {
                                        return null;
                                    }
                                    return ({ value }) => {
                                        // if one of the cell values corresponds to the filter item
                                        if (value)
                                            return (
                                                value.tag === filterItem.value
                                            );
                                    };
                                },
                                InputComponent: (props) =>
                                    CustomFilterInputSingleSelect({
                                        ...props,
                                        columnId : column.id,
                                        options: labels.labels
                                    }),
                            },
                        ],
                        renderCell: (params) => {
                            if (params.row[params.field] === null)
                                return <div></div>;
                            else {
                                return (
                                    <Box
                                        sx={{
                                            color: params.row[params.field]
                                                .hexColor,
                                        }}
                                    >
                                        {" "}
                                        {params.row[params.field].tag}
                                    </Box>
                                );
                            }
                        },
                        sortable: false,
                        renderEditCell: (params) => {
                            return (
                                <LabelEditCell
                                    columnId={column.id}
                                    {...params}
                                ></LabelEditCell>
                            );
                        },
                        renderHeader: (params) => {
                            if (labels !== null && labels.labelGroup) {
                                if (labels.labelGroup.icon) {
                                    return ColumnEditNameLabel(
                                        params.id,
                                        params.colDef.name,
                                        editingColumnId,
                                        tableIdAux,
                                        labels.labelGroup.icon,
                                        tablesData.pageNumber,
                                        tablesData.pageSize
                                    );
                                } else {
                                    return ColumnEditName(
                                        params.id,
                                        params.colDef.name,
                                        editingColumnId,
                                        tableIdAux,
                                        LabelIcon,
                                        tablesData.pageNumber,
                                        tablesData.pageSize
                                    );
                                }
                            } else {
                                return ColumnEditName(
                                    params.id,
                                    "Deleted LabelGroup",
                                    editingColumnId,
                                    tableIdAux,
                                    LabelIcon,
                                    tablesData.pageNumber,
                                    tablesData.pageSize
                                );
                            }
                        },
                    };
                } else if (column.type === types.ExactTime){
                    return {
                        name: column.name,
                        field: column.name,
                        width: 200,
                        type: "exactTime",
                        id: column.id,
                        position: column.position,
                        editable:
                          permissionsObject.includes("CanUpdateData") ||
                          permissionsObject.length === 0,
                        sortable: false,
                        renderHeader: (params) => {
                          return ColumnEditName(
                            params.colDef.id,
                            params.colDef.name,
                            editingColumnId,
                            tableIdAux,
                            ClockIcon,
                            tablesData.pageNumber,
                            tablesData.pageSize
                          );
                        },
                        filterOperators: [
                            {
                              value: "is",
                              getApplyFilterFn: (filterItem) => {

                                if (filterItem.value == null || filterItem.value === "") {
                                  return null;
                                }
                                return ({ value }) => {
                                  // Manejar el caso en que value es null
                                  if (value == null) {
                                    return false;
                                  }
                          
                                  const filterDate = dayjs(filterItem.value);

                                  
                                  const utcFilterDate = dayjs(filterDate).utc();
                          
                                  const utcValue = dayjs(value).utc();

                                  // Cambiar la condición para verificar igualdad exacta
                                  return utcValue.isSame(utcFilterDate);
                                };
                              },
                              InputComponent: CustomFilterInputDateTimePicker,
                            },
                            {
                                value: "before",
                                label: "is before",
                              getApplyFilterFn: (filterItem) => {

                                if (filterItem.value == null || filterItem.value === "") {
                                  return null;
                                }
                                return ({ value }) => {
                                  // Manejar el caso en que value es null
                                  if (value == null) {
                                    return false;
                                  }
                          
                                  const filterDate = dayjs(filterItem.value);
                                  const utcFilterDate = dayjs(filterDate).utc();
                          
                                  const utcValue = dayjs(value).utc();
                          
                                  // Cambiar la condición para verificar si es antes de la fecha dada
                                  return utcValue.isBefore(utcFilterDate);
                                };
                              },
                              InputComponent: CustomFilterInputDateTimePicker,
                            },
                            {
                                value: "after",
                                label: "is after",
                              getApplyFilterFn: (filterItem) => {

                                if (filterItem.value == null || filterItem.value === "") {
                                  return null;
                                }
                                return ({ value }) => {
                                  // Manejar el caso en que value es null
                                  if (value == null) {
                                    return false;
                                  }
                          
                                  const filterDate = dayjs(filterItem.value);
                                  const utcFilterDate = dayjs(filterDate).utc();
                          
                                  const utcValue = dayjs(value).utc();
                          
                                  // Cambiar la condición para verificar si es después de la fecha dada
                                  return utcValue.isAfter(utcFilterDate);
                                };
                              },
                              InputComponent: CustomFilterInputDateTimePicker,
                            },
                          ],
                        renderEditCell: (params) => {

                            const defaultDate = new Date();
                          
                            return (
                              <DateTimeEditCell
                                value={params.value ? new Date(params.value) : defaultDate}
                                onUpdate={(newValue) => {
                                  apiRefTable.current.setEditCellValue({
                                    id: params.row.id,
                                    field: column.name,
                                    value: newValue,
                                  });
                                }}
                                onEditComplete={(id, field) => {
                                  apiRefTable.current.stopCellEditMode({ id, field });
                                }}
                                id={params.row.id}
                                field={column.name}
                              />
                            );
                          },                        
                      };
 
                } else if (column.type === types.TimeSpan) {
                    return {
                        name: column.name,
                        field: column.name,
                        width: 300,
                        id: column.id,
                        position: column.position,
                        columnType: column.type,
                        sortable: false,
                        renderHeader: (params) => {
                        return ColumnEditName(
                            params.colDef.id,
                            params.colDef.name,
                            editingColumnId,
                            tableIdAux,
                            DateRangeIcon,
                            tablesData.pageNumber,
                            tablesData.pageSize
                        );
                        },
                        filterOperators: [
                            {
                                value: "range",
                                label: "is between",
                                getApplyFilterFn: (filterItem) => {
                                
                                    if (
                                        !filterItem.value ||
                                        !filterItem.value.startDate ||
                                        !filterItem.value.endDate
                                    ) {
                                        return null;
                                    }

                                    const parseDateString = (dateString) => {
                                        if (typeof dateString !== 'string') {
                                            return null;  // Devuelve null si no es una cadena válida
                                        }
                                    
                                        // Asumiendo que el formato de dateString es "dd-mm-yyyy hh:mm:ss"
                                        const [day, month, year, time] = dateString.split(/[-\s:]/);
                                    
                                        if (day && month && year) {
                                            const parsedDate = new Date(year, month - 1, day);
                                            if (time) {
                                                const [hours, minutes, seconds] = time.split(':');
                                                parsedDate.setHours(hours || 0, minutes || 0, seconds || 0);
                                            }
                                            return parsedDate;
                                        }
                                    
                                        return null;  // Devuelve null si no se pudo analizar la cadena correctamente
                                    };

                                    const filterStartDate = dayjs(filterItem.value.startDate).utc();
                                    const filterEndDate = dayjs(filterItem.value.endDate).utc();
                                
                                    return ({ value }) => {
                                        if (!value || isNaN(parseDateString(value).getTime())) {
                                            return false;  
                                        }
                                
                                        const utcValue = dayjs(parseDateString(value)).utc();
                                
                                
                                        const isAfterStart = utcValue.isAfter(filterStartDate);
                                        const isBeforeEnd = utcValue.isBefore(filterEndDate);
                                                                    
                                        return isAfterStart && isBeforeEnd;
                                    };
                                },
                                InputComponent: CustomFilterInputDateRange,
                            },
                        ],
                        renderCell: (params) => {
                            return (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                    id={params.row.id}
                                    className="file-cell"
                                >
                                    <DateRangeIcon
                                        onClick={handleOpenDateRange}
                                        sx={{
                                            opacity: "0",
                                            cursor: "pointer",
                                            color: blue[400],
                                            display: "none",
                                        }}
                                    ></DateRangeIcon>
                                    {" "}{params.row[params.field]}
                                </Box>
                            );
                        },
                    };
                    
                }
                })
            );
        setColumns([
            ...columns,
        ]);
        let rowsAux = [];
        let position = [];
        if (tablesDataState.columns && tablesDataState.columns.length !== 0) {
            tablesDataState.rows.forEach((row, i) => {
                const rowId = tablesDataState.rows.find(
                    (x) => x.position === i
                );
                tablesDataState.columns.forEach((data) => {
                    const datCol = data.fields.find(
                        (dataCol) => dataCol.position === i
                    );

                    if (datCol === undefined) {

                        rowsAux[i] = {
                            ...rowsAux[i],
                            position: i,
                            [data.name]: null,
                            id: rowId.id,
                        };

                    } else {
                        if (
                            data.type === types.Text ||
                            data.type === types.Id
                        ) {
                            rowsAux[i] = {
                                ...rowsAux[i],
                                position: i,
                                [data.name]: datCol.value,
                                id: datCol.rowId ? datCol.rowId : datCol.id,
                            };
                        } else if (data.type === types.Number) {
                            rowsAux[i] = {
                                ...rowsAux[i],
                                position: i,
                                [data.name]: datCol.value,
                                id: datCol.rowId ? datCol.rowId : datCol.id,
                            };
                        } else if (data.type === types.Date) {
                            rowsAux[i] = {
                                ...rowsAux[i],
                                position: i,
                                [data.name]: new Date(datCol.value),

                                id: datCol.rowId ? datCol.rowId : datCol.id,
                            };
                        } else if (data.type === types.Bool) {
                            if (datCol.value === "False") {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: null,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            } else {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: datCol.value,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (data.type === types.File) {
                            //File
                            const valueParsed = JSON.parse(datCol.value);
                            const url = valueParsed.Url;
                            const urlObj = new URL(url);
                            const pathname = urlObj.pathname;
                            const parts = pathname.split("/");
                            const desiredString = parts[parts.length - 1];
                            const extension = desiredString.split(".").pop();
                            if (
                                extension === "pdf" ||
                                extension === "docx" ||
                                extension === "doc" ||
                                extension === "xlsx" ||
                                extension === "csv" ||
                                extension === "txt" ||
                                extension === "xslm"
                            ) {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: (
                                        <Box
                                            sx={{
                                                "& a": {
                                                    color: blue[500],
                                                },
                                            }}
                                        >
                                            <Tooltip
                                                title={`${valueParsed.Name}`}
                                            >
                                                <a target="_blank" href={url}>
                                                    {/*<TruncateString props={{
                                                            text: valueParsed.Name,
                                                            maxLength: 20
                                                        }} />*/}
                                                    {valueParsed.Name}
                                                </a>
                                            </Tooltip>
                                            {/* <HandleDownload urlFile={url} /> */}
                                        </Box>
                                    ),
                                    id: datCol.rowId,
                                };
                            } else if (
                                extension === "png" ||
                                extension === "jpg" ||
                                extension === "jpeg" ||
                                extension === "PNG"
                            ) {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: (
                                        <ImageItemColumn
                                            url={valueParsed.Url}
                                        ></ImageItemColumn>
                                    ),
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (data.type === types.Contact) {
                            let obj = JSON.parse(datCol.value);
                            function convertirPrimeraLetraAMinuscula(str) {
                                return (
                                    str.charAt(0).toLowerCase() + str.slice(1)
                                );
                            }
                            if (obj !== null) {
                                const arrayConMinusculas = obj.map(
                                    (element) => {
                                        const objetoConMinusculas = {};

                                        for (const clave in element) {
                                            if (element.hasOwnProperty(clave)) {
                                                const nuevaClave =
                                                    convertirPrimeraLetraAMinuscula(
                                                        clave
                                                    );
                                                objetoConMinusculas[
                                                    nuevaClave
                                                ] = element[clave];
                                            }
                                        }

                                        return objetoConMinusculas;
                                    }
                                );
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: arrayConMinusculas,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            } else {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: [],
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (data.type === types.Account) {
                            let obj = JSON.parse(datCol.value);
                            function convertirPrimeraLetraAMinuscula(str) {
                                return (
                                    str.charAt(0).toLowerCase() + str.slice(1)
                                );
                            }

                            const objetoConMinusculas = {};

                            for (const clave in obj) {
                                if (obj.hasOwnProperty(clave)) {
                                    const nuevaClave =
                                        convertirPrimeraLetraAMinuscula(clave);
                                    objetoConMinusculas[nuevaClave] =
                                        obj[clave];
                                }
                            }
                            if (obj !== null) {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: objetoConMinusculas,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            } else {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: null,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (data.type === types.EndUser) {
                            let obj = JSON.parse(datCol.value);
                            if (obj !== null) {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: obj.Username,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            } else {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: "Invalid END USER",
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (
                            data.type === types.Decimal ||
                            data.type === types.Money
                        ) {
                            if (
                                Number.isInteger(datCol.value) ||
                                datCol.value % 1 === 0
                            ) {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: parseInt(datCol.value),
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            } else {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: limpiarCerosInnecesarios(
                                        datCol.value
                                    ),
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (data.type === types.Label) {
                            let obj = JSON.parse(datCol.value);
                            function convertirPrimeraLetraAMinuscula(str) {
                                return (
                                    str.charAt(0).toLowerCase() + str.slice(1)
                                );
                            }

                            const objetoConMinusculas = {};

                            for (const clave in obj) {
                                if (obj.hasOwnProperty(clave)) {
                                    const nuevaClave =
                                        convertirPrimeraLetraAMinuscula(clave);
                                    objetoConMinusculas[nuevaClave] =
                                        obj[clave];
                                }
                            }
                            if (obj !== null) {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: objetoConMinusculas,
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            } else {
                                rowsAux[i] = {
                                    ...rowsAux[i],
                                    position: i,
                                    [data.name]: "Invalid Label",
                                    id: datCol.rowId ? datCol.rowId : datCol.id,
                                };
                            }
                        } else if (data.type === types.ExactTime){
                            rowsAux[i] = {
                                ...rowsAux[i],
                                position: i,
                                [data.name]: datCol.value,
                                id: datCol.rowId ? datCol.rowId : datCol.id,
                            };
                        } else if (data.type === types.TimeSpan) {
                            rowsAux[i] = {
                                ...rowsAux[i],
                                position: i,
                                [data.name]: datCol.value,
                                id: datCol.rowId ? datCol.rowId : datCol.id,
                            };
                        }
                    }
                });
            });
        } else if (tablesDataState.rows) {
            tablesDataState.rows.forEach((row, i) => {
                rowsAux[i] = {
                    position: i,
                    id: row.id,
                };
            });
        }
        // if (rowsAux.length !== 0) {
        setRows(rowsAux);
        // }
    };

    useEffect(() => {
        obtenerColumnas();
    }, [tablesData, tablesColumns, tableId, permissionsObject]);

    //loading
    if (
        tablesDataState.id !== tableId ||
        status === "deleting" ||
        permissionsList === null
    ) {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <LoadingCube />
            </Box>
        );
    }
    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Box
                sx={{
                    p: 5,
                    overflowX: "scroll",
                    maxWidth: "calc(100vw - 240px)",
                    minWidth: 300,
                }}
            >
                <Paper>
                    <Typography
                        sx={{ marginLeft: 3 }}
                        variant="h6"
                        gutterBottom
                    >
                        {currentTableName.name}
                        {(permissionsObject.includes("CanUpdate") ||
                            permissionsObject.length == 0) && (
                            <EditTableButton />
                        )}
                        {(permissionsObject.includes("CanDelete") ||
                            permissionsObject.length == 0) && (
                            <DeleteTableButton />
                        )}
                    </Typography>
                    <MUIXTable
                        apiRefTable={apiRefTable}
                        permissionsObject={permissionsObject}
                        columns={columns}
                        roles={roles}
                        rows={rows}
                        handleCreateColumn={() => {
                            setOpen(true);
                        }}
                        openFileModal={openFileModal}
                        openDateRangeModal = {openDateRange}
                        handleCloseFileModal={handleCloseFileModal}
                        handleCloseDateRangeModal={handleCloseDateRange}
                        handleOpenPermissions={handleOpenPermissions}
                        handleOpenColumnPermissions = {handleOpenColumnPermissionsInternal}

                    ></MUIXTable>
                </Paper>
            </Box>
            {openEditTable === true && (
                <EditTableName
                    open={openEditTable}
                    setOpen={setOpenEditTable}
                    tableName={currentTableName.name}
                    tableId={tableId}
                />
            )}
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <CreateColumn
                        handles={{
                            handleClose,
                        }}
                        types={types}
                        data={{
                            tableId: tableId,
                        }}
                    />
                </Box>
            </Modal>
            {selectedContact != null && (
                <ContactModal
                    open={selectedContact != null}
                    setOpen={handleCloseContactModal}
                    contact={selectedContact}
                ></ContactModal>
            )}

            {selectedAccount != null && (
                <AccountModal
                    open={selectedAccount != null}
                    setOpen={handleCloseAccountModal}
                    account={selectedAccount}
                ></AccountModal>
            )}
        </Box>
    );
};
