import { Box, Button, CircularProgress, Modal } from '@mui/material'
import React from 'react'
import { FileHandle } from '../../../components/Reports/FieldsComponents'
import { useAppSelector } from '../../../hooks/hooks'

interface handleChageProps {
    target: {
        name: string,
        value: any
    }
}
interface Props {
    handleClose: () => void,
    open: boolean,
    handleSubmit: () => void,
    fileValue: any,
    fileName: string,
    fileHandleChange: ({ target }: handleChageProps) => void
}
export const UploadFileModal = ({ handleClose, open, handleSubmit, fileValue, fileHandleChange, fileName }: Props) => {
    const { status } = useAppSelector(state => state.tables)
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                pt: 2,
                px: 4,
                pb: 3,
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: 2
            }}>
                {
                    status === 'loading' ?
                        <CircularProgress />
                        :
                        <>
                    <Box sx={{ margin: 'auto', width: 'fit-content' }}>
                        <FileHandle
                            value={fileValue}
                            name={fileName}
                            onChange={fileHandleChange}
                            tooltip={''}
                            isMandatory={false}
                            tenantId={''}
                            reset={false}
                        />
                    </Box>
                    <Button
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={status === 'loading'}
                        sx={{ margin: 'auto', width: 'fit-content', textAlign: 'center' }}
                    >
                        Upload File
                    </Button>
                        </>
                }

            </Box>
        </Modal>
    )
}
