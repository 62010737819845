import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";

// eslint-disable-next-line
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { LoadingCube } from "../custom/loader/LoadingCube";
import { format, parse, isValid } from "date-fns";
import { getAllEndUsers, getEndUserRecords } from "../../redux/endUser/thunk";
import { StyledDataGrid } from "../../pages/Tables/Componentes/TableMUIX";
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer
            sx={{
                mb: 4,
            }}
        >
            {/* <GridToolbarColumnsButton /> */}
            <GridToolbarFilterButton />
            {/* {showDeleteRows && (
                <Button size="small" onClick={handleDeleteRows}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                        }}
                    >
                        <DeleteForeverIcon fontSize="small" />
                        Delete selected rows
                    </Box>
                </Button>
            )} */}
        </GridToolbarContainer>
    );
};

export const EnduserComponent = () => {
    const getRowId = (row) => row.id;
    //redux
    const { endUser: endUserState, status } = useAppSelector(
        (state) => state.endUser
    );
    const { tenantId, id, token } = useAppSelector((state) => state.auth);

    const [endUser, setEndUserState] = useState(endUserState);
    const dispatch = useAppDispatch();

    const columns = [
        {
            field: "id",
            headerName: "Id",
            position: 0,
            type: "text",
            editable: false,
        },
        {
            field: "username",
            headerName: "Username",
            position: 1,
            type: "text",
            editable: false,
        },
        {
            field: "name",
            headerName: "Name",
            position: 2,
            type: "text",
            editable: false,
        },
        {
            field: "lastName",
            headerName: "Lastname",
            position: 3,
            type: "text",
            editable: false,
        },
        {
            field: "email",
            headerName: "Email",
            position: 4,
            type: "text",
            editable: false,
            width: 200,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 200,
            position: 5,
            type: "text",
            editable: false,
        },
        {
            field: "birthDate",
            headerName: "Birthdate",
            position: 6,
            type: "date",
            editable: false,
        },
        {
            field: "lastLoginDate",
            headerName: "Last login Date",
            position: 7,
            type: "date",
            width: 150,
            editable: false,
        },
    ];

    // const [columns, setColumns] = useState(dataColumns);

    const [rows, setRows] = useState(endUser);

    useEffect(() => {
        dispatch(getAllEndUsers(tenantId));
        dispatch(getEndUserRecords(tenantId));
    }, []);

    useEffect(() => {
        setEndUserState(
            endUserState.map((client) => {
                return {
                    ...client,
                    birthDate: format(new Date(client.birthDate), "dd-MM-yyyy"),
                    lastLoginDate: format(
                        new Date(client.lastLoginDate),
                        "dd-MM-yyyy"
                    ),
                };
            })
        );
    }, [endUserState]);

    useEffect(() => {
        let tempRows = endUserState.map((e) => {
            return {
                ...e,
                birthDate: new Date(e.birthDate),
                lastLoginDate: new Date(e.lastLoginDate),
            };
        });
        setRows(tempRows);
    }, [endUserState]);

    //loading
    if (status == "loading") {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoadingCube />
            </Box>
        );
    }

    return (
        <Paper
            sx={{
                p: { xs: 0, md: 3 },
            }}
        >
            <StyledDataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </Paper>
    );
};
