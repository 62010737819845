import React, { useEffect, useState } from 'react'
import { ObjectPermissions } from '../../../redux/permission/slice'
import axios from 'axios'
import { Box, Typography } from '@mui/material'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
interface Props {
    permission: ObjectPermissions,
    handleChangeAuthority: (permission: ObjectPermissions) => void
    selectedPermission: ObjectPermissions

}
export const RoleComponent = ({ permission, handleChangeAuthority, selectedPermission }: Props) => {

    const [name, setName] = useState('')

    useEffect(() => {
        const url = process.env.REACT_APP_NEXUS_BACK_URL as string
        axios.get(
            url + `/api/v1/Role/${permission.authorityId}`
        )
            .then((result) => {


                let roleData = result.data.result
                setName(roleData.name)

            }).catch((err) => {
                setName("<Deleted>")


            });
    }, [])


    return (
        <Box
            onClick={() => handleChangeAuthority(permission)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                p: 1,
                transition: 'all ease-in 0.1s',
                "&:hover": {
                    backgroundColor: 'rgba(0,0,0,0.1)'
                },
                backgroundColor: selectedPermission.id === permission.id ? 'rgba(33, 150, 243,0.2)' : ''

            }}
        >
            <LocalPoliceIcon />
            <Typography>
                {name}
            </Typography>
        </Box >
    )
}
