import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
        config.headers!['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'

    }
    return config
})
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const subscriptionPaymentAgent = {
    getAllSubscriptions: () => requests.get<any>(url + `/api/v1/Suscription?PageNumber=1&PageSize=10`),
    getSubscriptionConsumption: (tentantId: string) => requests.get<any>(url + `/api/v1/DataManagement/Status?id=${tentantId}`),


}
export default subscriptionPaymentAgent