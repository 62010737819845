import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Renewal, PaymentStatus, Currency } from '../Enums/CommersEnums'
import { useAppDispatch } from '../../hooks/hooks'
import { editSubscriptions } from '../../redux/commerce/thunk'

interface Props {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    preData: {
        name: string,
        price: number,
        currency: number,
        renewal: number,
        state: number
    }
}
const currencyEnumIndex: { [key: string]: number } = {
    CLP: Currency.CLP,
    USD: Currency.USD
};

const renewalEnumIndex: { [key: string]: number } = {
    Daily: Renewal.Daily,
    Weekly: Renewal.Weekly,
    Biweekly: Renewal.Biweekly,
    Monthly: Renewal.Monthly,
};
const statusEnumIndex: { [key: string]: number } = {
    Completed: PaymentStatus.Completed,
    Active: PaymentStatus.Active,
    Deleted: PaymentStatus.Deleted,
    Pending: PaymentStatus.Pending
};
export const EditSubscriptionModal = ({ open, setOpen, preData }: Props) => {
    const dispatch = useAppDispatch()
    const [data, setData] = useState(preData)
    const minValue = 0

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => { setData({ ...data, [event.target.name]: event.target.value as string }) }

    const handleChangeMoney = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseFloat(event.target.value);

        // Comprueba si el valor ingresado es menor que el valor mínimo
        if (isNaN(inputValue) || inputValue < minValue) {
            // Si es menor o no es un número válido, establece el valor en el valor mínimo
            setData({ ...data, price: minValue });
        } else {
            // Si es mayor o igual y es un número válido, actualiza el estado con el nuevo valor
            setData({ ...data, price: inputValue });
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        dispatch(editSubscriptions(data, setOpen))
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1059

            }}
        >
            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}
            // onClick={() => setOpen(false)}
            > */}
            <Paper elevation={6}
                sx={{
                    width: { xs: '90vw', sm: '80vw', md: '50vw', lg: '30vw', xl: '20vw' }
                }}
            >
                <Box component={'form'} onSubmit={handleSubmit} sx={{ display: 'flex', justifyContent: 'center', height: '100%', p: 2 }}>
                    <Box

                        sx={{ display: 'flex', flexDirection: 'column', px: 2, gap: 2, alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Typography sx={{ textAlign: 'center', fontSize: '1.5rem', }} fontWeight={600} >Add subscription</Typography>

                        <TextField
                            label="Name"
                            name='name'
                            required
                            value={data.name}
                            onChange={handleChange}

                        />
                        <TextField
                            label="Price"
                            name='price'
                            type='number'
                            required
                            value={data.price}
                            onChange={handleChangeMoney}
                            inputProps={{
                                min: minValue.toString(), // Establece el valor mínimo permitido en el input
                            }}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" required>Currency</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                required
                                // value={age}
                                label="Currency"
                                name='currency'
                                value={data.currency}
                                onChange={(e) => { setData({ ...data, currency: e.target.value as number }) }}

                            >
                                {Object.keys(currencyEnumIndex).map((key) => (
                                    <MenuItem key={currencyEnumIndex[key]} value={currencyEnumIndex[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" required>Renewal</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                required
                                label="Renewal"
                                name='renewal'
                                value={data.renewal}
                                onChange={(e) => { setData({ ...data, renewal: e.target.value as number }) }}
                            >
                                {Object.keys(renewalEnumIndex).map((key) => (
                                    <MenuItem key={renewalEnumIndex[key]} value={renewalEnumIndex[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" required>State</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                required
                                label="State"
                                name='state'
                                value={data.state}
                                onChange={(e) => { setData({ ...data, state: e.target.value as number }) }}
                            >
                                {Object.keys(statusEnumIndex).map((key) => (
                                    <MenuItem key={statusEnumIndex[key]} value={statusEnumIndex[key]}>
                                        {key}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <Box>
                            <Button type='submit' variant='contained'>Save changes</Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            {/* </Box> */}
        </Modal>
    )
}
