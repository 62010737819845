import { Alert, Box, Button, Divider, Grid, IconButton, InputLabel, MenuItem, MenuList, Paper, Switch, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import './RolesList.css'
import { getAllRoles } from '../../../redux/roles/thunk'
import { Role } from '../../../redux/roles/slice'
import { Slider, Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome } from '@uiw/react-color';
import { PermissionComponent } from './PermissionComponent'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
interface Props {
    handleChangeSelectedRole: (role: Role) => void
    handleChangeName: (name: string) => void
    handleChangeColor: (color: string) => void
    handleCreateRole: () => void
    handleDeleteRole: () => void
    handleChangePermissions: (permission: string, value: boolean) => void
    roleData: Role
    roles: Role[]
    contentRef: React.MutableRefObject<HTMLDivElement | null>

}

export const RolesList = ({ handleChangeSelectedRole, handleChangeName, handleChangeColor, handleCreateRole, handleDeleteRole, handleChangePermissions, roleData, roles, contentRef }: Props) => {


    return (
        <Paper elevation={3} sx={{ height: '100%' }}>

            <Grid container height={"100%"}>
                <Grid item xs={12} md={3} sx={{ borderRight: "0.5px rgba(0, 0, 0, 0.12) solid" }}>

                    <MenuList sx={{ px: 1, display: { xs: 'flex', md: 'block' }, overflowX: { xs: 'scroll', sm: 'scroll', md: 'none !important', lg: 'none !important', xl: 'none !important' } }}>
                        <Box
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: { xs: 0, md: 2 } }}
                        >
                            <InputLabel
                                sx={{ fontSize: '1rem', fontWeight: 600 }}

                            >
                                Roles list
                            </InputLabel>
                            <Tooltip title="Create role" onClick={handleCreateRole}>
                                <IconButton>
                                    <AddCircleIcon sx={{ color: "rgba(0,0,0,0.6)", cursor: "pointer" }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {
                            roles.map(e =>
                                <MenuItem selected={roleData.id === e.id} onClick={() => handleChangeSelectedRole(e)}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <span className="dot" style={{ backgroundColor: e.hexColor }}></span>
                                        {e.name}
                                    </Box>
                                </MenuItem>
                            )
                        }

                    </MenuList>
                </Grid>
                <Grid item xs={12} md={9} height={"100%"} sx={{ overflowY: "scroll", pr: 2 }}>
                    <Box pl={2} sx={{ display: 'flex', flexDirection: 'column', gap: 3, py: 2 }} ref={contentRef}>
                        <Typography variant='h2' sx={{ fontSize: '2rem', fontWeight: 500 }} > Role Settings</Typography>
                        <TextField
                            variant='outlined'
                            label='Role name'
                            required
                            value={roleData.name}
                            onChange={(e) => handleChangeName(e.target.value)}
                        />
                        <Divider />
                        <Box my={2} display={'flex'} flexDirection={'column'}>

                            <InputLabel
                                sx={{ fontSize: '1rem', fontWeight: 600, mb: 2 }}
                                required
                            >
                                Role color
                            </InputLabel>
                            <Box
                                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
                            >
                                <Colorful
                                    // style={{ marginLeft: 20 }}
                                    disableAlpha
                                    color={roleData.hexColor}
                                    onChange={(color) => {
                                        handleChangeColor(color.hex);
                                    }}
                                />
                                <Box>

                                    <Circle
                                        colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"]}
                                        style={{ marginLeft: 20, maxWidth: 250 }}
                                        color={roleData.hexColor}
                                        onChange={(color) => {
                                            handleChangeColor(color.hex);
                                        }}
                                    />
                                </Box>

                            </Box>
                        </Box>
                        <Divider />
                        <Box>
                            <InputLabel
                                sx={{ fontSize: '1rem', fontWeight: 600, mb: 2 }}
                                required
                            >
                                Role permissions
                            </InputLabel>
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageRoles'
                                name='Management roles'
                                value={roleData.canManageRoles}
                                description='This permission allows users to create, modify, and delete roles within the system. This is essential for administrators who need to assign different levels of access and responsibilities to various users.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageTables'
                                name='Allow handling of tables'
                                value={roleData.canManageTables}
                                description='Users with this permission have the ability to create, update, and delete tables in the database. This is crucial for users who need to structure and organize data effectively.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageBlobRecords'
                                name='Manage blob records'
                                value={roleData.canManageBlobRecords}
                                description='This grants the ability to manage Binary Large Object (BLOB) records, which are typically used to store large data items like images, audio files, or other multimedia content.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageTenant'
                                name='Manege Tenant'
                                value={roleData.canManageTenant}
                                description='This permission is vital for managing the overall settings and configurations of the tenant in a multi-tenant architecture. It includes setting up tenant-specific data, configurations, and preferences.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageUsers'
                                name='Manege Users'
                                value={roleData.canManageUsers}
                                description='Users with this permission can create new user accounts, update existing user details, and deactivate or delete users. This is key for maintaining an up-to-date user base and managing access rights.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageSuscriptions'
                                name='Manege Subscriptions'
                                value={roleData.canManageSuscriptions}
                                description=' This enables the management of user subscriptions, including creating new subscription plans, modifying existing plans, and managing user subscriptions.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageEntities'
                                name='Manege Entities'
                                value={roleData.canManageEntities}
                                description='With this permission, users can manage various entities within the system. This could involve creating new entities, modifying existing ones, and setting relationships between them.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageAutorules'
                                name='Manege Autorules'
                                value={roleData.canManageAutorules}
                                description='This allows users to set up and manage automatic rules that trigger specific actions within the system, based on certain conditions or events.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageReports'
                                name='Manege reports'
                                value={roleData.canManageReports}
                                description='Users can generate, customize, and distribute various types of reports. This is essential for data analysis, tracking performance metrics, and making informed decisions.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageLabels'
                                name='Manege Labels'
                                value={roleData.canManageLabels}
                                description='This permission grants the ability to create, modify, and delete labels used for categorizing and organizing data. It´s important for maintaining a structured and easily navigable system.'
                            />
                            {/* <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canViewAccounts'
                                name='Can view Accounts'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canViewContacts'
                                name='Can view Contacts'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canViewUsers'
                                name='Can view Users'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canViewEndUsers'
                                name='Can view EndUsers'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageAccounts'
                                name='Can manage Accounts'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageContacts'
                                name='Can manage Contacts'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageUsers'
                                name='Can manage Users'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            />
                            <PermissionComponent
                                disabled={false}
                                handleChangePermissions={handleChangePermissions}
                                param='canManageEndUsers'
                                name='Can manage EndUsers'
                                value={roleData.canManageEntities}
                                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo consequuntur laboriosam iure deserunt corrupti maiores dolor, repellendus fugit officia alias aspernatur obcaecati laudantium eveniet, eos incidunt sint, autem reiciendis similique.'
                            /> */}
                        </Box>
                        <Box>
                            <Button color='error' variant='contained' onClick={handleDeleteRole}>
                                <DeleteForeverIcon />
                                Delete role
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </Paper>
    )
}
