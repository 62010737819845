import { createSlice } from "@reduxjs/toolkit";


export interface Subscription {
    id: string
    name: string
    priceYear: number
    priceMonth: number
    seatPrice: number
    maxSeats: number
    storage: number,
    apiCalls: number,
    roles: number,
    tables: number,
    autorules: number
}
export interface SubscriptionConsumption {
    "consumedAccountStorage": number,
    "consumedApiCalls": number,
    "consumedRoles": number,
    "consumedTables": number,
    "consumedAutorules": number,
    suscription: Subscription
}

export interface SubscriptionPaymentStore {
    status: string,
    subscription: Subscription | null,
    subscriptions: Subscription[] | null,
    subscriptionConsumption: SubscriptionConsumption | null


}
const initialState: SubscriptionPaymentStore = {
    status: "confirmed",
    subscription: null,
    subscriptions: null,
    subscriptionConsumption: null
}
export const subscriptionPaymentSlice = createSlice({
    name: 'subscriptionPayment',
    initialState,
    reducers: {
        setSubscription: (state, { payload }) => {
            state.subscription = payload
            state.status = "confirmed";
        },
        setSuscriptions: (state, { payload }) => {

            state.subscriptions = payload
            state.status = "confirmed";
        },
        cleanSub: (state) => {
            state.subscription = null
            state.status = "confirmed";
        },
        loading: (state) => {
            state.status = "loading";
        },
        error: (state) => {
            state.status = "error";
        },
        setSubscriptionConsumption: (state, { payload }) => {
            state.subscriptionConsumption = payload
            state.status = "confirmed";
        },
    }
})
export const { loading, error, setSubscription, cleanSub, setSuscriptions, setSubscriptionConsumption } = subscriptionPaymentSlice.actions
