import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { GridItem } from '../../components/custom/Grid/GridItem';
import { ContactsComponent } from '../../components/Contacts/ContactsComponent';

export const ContactsPage = () => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [sizeValue, setSizeValue] = useState<number>(0)




  const handleResize = () => {

    const element = elementRef.current;
    if (element) {
      const width = element.getBoundingClientRect().width;
      setSizeValue((width / 12))
    }
  };
  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, []);
  return (
    // <Box sx={{ mt: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
    //     <ClientsComponent />
    // </Box>
    <Box p={2}>

      <Box
        sx={{
          display: 'flex',
          flexWrap: "wrap",
          ".custom-grid-item": {
            padding: 2
          }
        }}
        ref={elementRef}
      >
        <GridItem
          sizeValue={sizeValue}
          width={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
          }}
          height={{
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6
          }}
        >

          <ContactsComponent />
        </GridItem>

      </Box>
    </Box>
  )
}
