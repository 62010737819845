import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { deleteDashboard, getDashboardbyId, updateDashboard } from '../../redux/dashboards/thunk'
import { Box, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material'
import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import { AddWidgetButton } from '../../components/Dashboard/AddWidgetButton'
import { TimerWidget } from '../../components/Dashboard/TimerWidget'
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { RightSidePanel } from '../../components/custom/CustomRightSidePanel'
import { TimerForm } from '../../components/Dashboard/TimerForm'
import { Circle, Colorful } from '@uiw/react-color';
import { WidgetActivity, WidgetLeaderboard, WidgetTimer } from '../../redux/dashboards/slice'
import { LeaderboardTable } from '../../components/Dashboard/LidearboardItem'
import { ActivityItem } from '../../components/Dashboard/ActivityItem'

export const DashboardPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { currentDashboard, status } = useAppSelector(state => state.dashboards)
    const { token, tenantId } = useAppSelector(state => state.auth)
    const [dashboardName, setDashboardName] = useState(currentDashboard?.name)
    const [dashboardColor, setDashboardColor] = useState(currentDashboard?.backgroundColor ?? '#ffffff')
    const [dashboardFont, setDashboardFont] = useState(currentDashboard?.fontColor ?? '#131313')

    const [openEdit, setOpenEdit] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [rightSidePanelOpen, setRightSidePanelOpen] = useState(false);
    const fonts = ['Roboto', 'Nunito'];
    const [fontFamily, setFontFamily] = useState(currentDashboard?.fontColor ?? 'Roboto'); 

    const handleCloseRightSidePanel = () => {
      setRightSidePanelOpen(false);
    };

    const handleOpenRightSidePanel = () => {
        setRightSidePanelOpen(true);
      };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDeleteDashboard = () => {
        dispatch(deleteDashboard(id as string, tenantId, navigate))
    }
    const handleEditDashboard = () => {
        dispatch(updateDashboard(
            {
                "id": id, "name": dashboardName, "token": "", "backgroundColor": dashboardColor, "fontColor": dashboardFont , "fontStyle": fontFamily
            },
            id as string,
            tenantId,
            setOpenEdit
        ))
    }

    const copyToClipboard = () => {
        const currentUrl = window.location.href;
        const parts = currentUrl.split('/');
        const filteredParts = parts.filter(part => !part.match(/[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}/));
        let baseUrl = filteredParts.slice(0, 3).join('/'); 
        if (baseUrl.endsWith('main')) {
            baseUrl = filteredParts.slice(0, 2).join('/'); 
        }
        if (id) {
            const publicUrl = `${baseUrl}/main/dashboard/${id}`;
            const tempInput = document.createElement('input');
            tempInput.value = publicUrl;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (id) {
                dispatch(getDashboardbyId(id))
            }
        }, 600000); // 600000 ms = 10 min
    
        if (id) {
            dispatch(getDashboardbyId(id));
        }

        return () => clearInterval(interval);
    }, [id, dispatch]);
    useEffect(() => {
        if (id)
            dispatch(getDashboardbyId(id))
    }, [id])
    useEffect(() => {
        if (currentDashboard)
            setDashboardName(currentDashboard.name)
            setDashboardColor(currentDashboard?.backgroundColor ?? '#ffffff')
            setDashboardFont(currentDashboard?.fontColor ?? '#131313')
    }, [currentDashboard])
    if (status === "loading" && currentDashboard === null) {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoadingCube />
            </Box>
        );
    } else {
        return (
            <Box
                p={2}
                sx={{
                    fontFamily: fontFamily,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    background: dashboardColor,
                    height: '100%'
                    //marginRight: '300px',
                }}
            >
                <Paper elevation={4} sx={{ p: 2, background: dashboardColor, mt: 3}}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                            , background: dashboardColor,
                            height: '100%',
                            fontFamily: fontFamily
                        }}
                    >

                        {
                            openEdit ?
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', background: dashboardColor, fontFamily: fontFamily }}>
                                    <TextField
                                        value={dashboardName}
                                        variant='standard'
                                        size='small'
                                        onChange={(event) => { setDashboardName(event.target.value) }}
                                        inputProps={{
                                            style: { color: dashboardFont, fontFamily: fontFamily } 
                                        }}
                                    />
                                <Box
                                sx={{ display: 'flex', gap: 2, alignItems: 'center', background: dashboardColor, fontFamily: fontFamily }}
                                    >
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', background: dashboardColor, fontFamily: fontFamily }}>
                                            <Typography fontSize={20} fontWeight={100} color={dashboardFont} sx={{fontFamily: fontFamily}}>
                                                Select Background Color:
                                            </Typography>
                                            <TextField
                                            label="Hex"
                                            value={dashboardColor}
                                            onChange={(e) => setDashboardColor(e.target.value)}
                                            inputProps={{ maxLength: 7 }}
                                            sx={{ width: '150px', marginBottom: 2, fontFamily: fontFamily }}
                                            />
                                            <Colorful
                                            // style={{ marginLeft: 20 }}
                                            disableAlpha
                                            color={dashboardColor}
                                            onChange={(color) => {
                                                setDashboardColor(color.hex)
                                            }}
                                            />
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', background: dashboardColor, fontFamily: fontFamily }}>
                                            <Typography fontSize={20} fontWeight={150} color={dashboardFont} fontStyle={fontFamily} sx={{fontFamily: fontFamily}}>
                                            Select Font Color:    
                                            </Typography>
                                            <TextField
                                            label="Hex"
                                            value={dashboardFont}
                                            onChange={(e) => setDashboardFont(e.target.value)}
                                            inputProps={{ maxLength: 7 }}
                                            sx={{ width: '150px', marginBottom: 2, fontFamily: fontFamily }}
                                            />
                                            <Colorful
                                            disableAlpha
                                            color={dashboardFont}
                                            onChange={(color) => {
                                                setDashboardFont(color.hex)
                                            }}
                                        />
                                    </Box>
                                    <FormControl fullWidth>
                                    <InputLabel id="font-selector-label">Font Family</InputLabel>
                                    <Select
                                        labelId="font-selector-label"
                                        id="font-selector"
                                        value={fontFamily}
                                        label="Font Family"
                                        onChange={(event) => setFontFamily(event.target.value)}
                                    >
                                        {fonts.map((font) => (
                                            <MenuItem key={font} value={font}>{font}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                    <Box sx={{ display: 'flex', gap: 0, background: dashboardColor, fontFamily: fontFamily}}>
                                    <Tooltip title={'Save'} >
                                        <IconButton onClick={handleEditDashboard} disabled={status === 'loading'} style={{ color: dashboardFont }}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Cancel'} >
                                        <IconButton onClick={() => { setOpenEdit(false) }} style={{ color: dashboardFont, fontFamily: fontFamily }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                    </Box>
                                </Box>
                                </Box>
                                :
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <Typography fontSize={30} fontWeight={500} color={dashboardFont} fontStyle={fontFamily} sx={{fontFamily: fontFamily}}>
                                        {currentDashboard?.name}
                                    </Typography>
                                </Box>
                        }
                        {
                            (token && !openEdit) &&
                            <Box>

                                <IconButton
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    style={{ color: dashboardFont, fontFamily: fontFamily }}
                                >
                                    <SettingsIcon />
                                </IconButton >
                                <Tooltip title='Copy public url'>
                                    <IconButton
                                        onClick={copyToClipboard}
                                        style={{ color: dashboardFont, fontFamily: fontFamily}}
                                    >
                                        <ScreenShareIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        }

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            style={{fontFamily: fontFamily}}
                        >
                            <MenuItem onClick={() => { setOpenEdit(true); handleClose() }} style={{fontFamily: fontFamily}}>Edit</MenuItem>
                            <MenuItem onClick={handleDeleteDashboard} style={{fontFamily: fontFamily}}>Delete</MenuItem>
                        </Menu>

                    </Box>
                </Paper>
                {
                    currentDashboard && currentDashboard.widgets
                        .filter(widget => widget.widgetType === 0)
                        .map((widget) => {
                            const timerWidget = widget.widget as WidgetTimer;
                            return <TimerWidget widget={timerWidget} handleOpenRightSidePanel={handleOpenRightSidePanel} fontFamily={fontFamily} />;
                        })
                }
                {
                    currentDashboard && currentDashboard.widgets
                        .filter(widget => widget.widgetType === 1)
                        .map((widget) => {
                            const timerWidget = widget.widget as WidgetLeaderboard;
                            return <LeaderboardTable key={timerWidget.id} dashboardId={currentDashboard.id} widget={timerWidget} isShow={!!token} fontFamily={fontFamily} />;
                        })
                }
                {/* {
                    currentDashboard && currentDashboard.widgets
                        .filter(widget => widget.widgetType === 2)
                        .map((widget) => {
                            const timerWidget = widget.widget as WidgetActivity;
                            return <ActivityItem key={timerWidget.id} dashboardId={currentDashboard.id} widget={timerWidget} isShow={!!token} />;
                        })
                } */}
                {
                    token &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontFamily: fontFamily
                        }}
                    >
                        <AddWidgetButton dashboardId={id as string}  color={dashboardFont || '#ffffff'} />
                    </Box>
                }
                {
                    !token &&
                    <Box
                        sx={{
                            mt: 90  
                        }}
                    >
                        <Typography variant="h2" component="h3" sx={{ color: "#6a7187", fontSize: "0.875rem", textAlign: "center", fontFamily: fontFamily }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com" target='_blank'>Tesseract</a> ® Nexus Backend</Typography>
                    </Box>
                }
            </Box>
            
        )
    }

}
