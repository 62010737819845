export const types = {
    Text: 0,
    Number: 1,
    Date: 2,
    Bool: 3, 
    File: 4,
    Contact: 5,
    Money: 6,
    Account: 7,
    EndUser: 8,
    Decimal: 9,
    Id: 10,
    Label: 11,
    TimeSpan: 12,
    ExactTime: 13
};