import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, purple, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ChangeEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Menu, MenuItem, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Link, useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { delAutoruleSetting, putAutoruleSettingName } from '../../../redux/autoRules/thunk';
import Swal from 'sweetalert2';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import "./BoxStyles.css"

interface content {
    autoruleTaskId: string,
    sequencePosition: number,
    taskName: string,
    setting: any,
}

export const ReportBox = ({ content, deleteComp }: { content: content, deleteComp: number }) => {
    const { id: autoRuleId } = useParams();
    const [openTitle, setOpenTitle] = useState(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenEditTitle = () => {
        setOpenTitle(true);
    };
    const handleCloseEditTitle = () => {
        setOpenTitle(false);
    };

    const { tablesName } = useAppSelector((state) => state.tables);
    const dispatch = useAppDispatch();

    const tableName: any = tablesName.find((table: any) => table.id === content.setting.tableId)
    //console.log("tablesName",tablesName)


    const handleDelete = () => {
        //const updatedItems = items.filter((item: any) => item.id !== content.id);
        //setItems(updatedItems);
    };

    const [item, setItem] = useState(content)

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setItem({ ...item, [name]: value })
    }

    const onSave = () => {
        dispatch(putAutoruleSettingName({
            autoruleTaskId: item.autoruleTaskId,
            taskName: item.taskName,
            autoRuleId: autoRuleId
        }))
        handleCloseEditTitle()
    };

    useEffect(() => {
        setItem(content)
    }, [content])

    return (
        <Card className='node-box'>
            <CardContent >
                <Box
                    sx={{
                        display: "flex",
                        marginLeft: 1,
                        justifyContent: "space-between",
                        alignItems: "center"

                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <Avatar sx={{ bgcolor: blue[500] }} >
                            <NoteAddIcon />
                        </Avatar>
                        <Box>
                            <Typography variant='caption'>
                                REPORTE
                            </Typography>
                            <Typography sx={{ display: "flex" }} variant='h6'>
                                {content.taskName}
                            </Typography>
                        </Box>
                    </Box>
                    <div>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MoreHorizIcon></MoreHorizIcon>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleOpenEditTitle}>
                                Editar <EditIcon sx={{ height: 18, width: 18, ml: 1 }} />
                            </MenuItem>

                            {
                                deleteComp - 1 === content.sequencePosition &&
                                <MenuItem onClick={() => {
                                    Swal.fire({
                                        title: "¿Seguro quieres borrar el componente auto rule?",
                                        text: "Una vez borrada no hay vuelta atras!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Borrar",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            dispatch(delAutoruleSetting(item.autoruleTaskId, autoRuleId))
                                        }
                                    });
                                }}>
                                    Eliminar <DeleteIcon sx={{ height: 18, width: 18, ml: 1 }} />
                                </MenuItem>
                            }

                        </Menu>
                    </div>
                </Box>

                <Typography variant="body2" color="text.secondary" marginTop={2}
                    sx={{
                        "a": {
                            color: blue[500],
                            fontWeight: 600
                        }
                    }}
                >
                    Tabla asociada:&nbsp;
                    <Link to={`/main/tables/dynamicTable/${content.setting.tableId}`} rel="noopener">
                        {tableName?.name}
                    </Link>
                </Typography>
            </CardContent>
        </Card>
    );
}