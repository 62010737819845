import { Box, Button, CircularProgress, Grid, Modal } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../hooks/hooks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { enGB } from 'date-fns/locale';

interface Props {
  handleClose: () => void;
  open: boolean;
  handleSubmit: (dateRangeString: string) => void;
  value: any;
  name: string;
}

export const DateRangeModal = ({ handleClose, open, handleSubmit, value, name }: Props) => {
  const { status } = useAppSelector((state) => state.tables);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const handleDateTimeChange = (date: Date | null, isStart: boolean) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleSendDate = () => {
    if (startDate && endDate && startDate <= endDate) {
      const dateRangeString = `${startDate.toISOString()};${endDate.toISOString()}`;
      handleSubmit(dateRangeString);
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          pt: 2,
          px: 4,
          pb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {status === 'loading' ? (
          <CircularProgress />
        ) : (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <Grid container spacing={4} justifyContent="center">
                <Grid item>
                  <DateTimePicker
                    views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
                    value={startDate}
                    onChange={(date) => handleDateTimeChange(date, true)}
                    ampm={false}
                    label="Start Date"
                    timeSteps={{ minutes: 1, seconds: 1 }}
                  />
                </Grid>
                <Grid item>
                  <DateTimePicker
                    views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
                    value={endDate}
                    onChange={(date) => handleDateTimeChange(date, false)}
                    ampm={false}
                    label="End Date"
                    timeSteps={{ minutes: 1, seconds: 1 }}  
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handleSendDate}
              disabled={!startDate || !endDate || status === 'loading' || startDate > endDate}
            >
              Send Date
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};
