import { createSlice } from "@reduxjs/toolkit";


export interface AutoRuleName {
    id: string,
    autoruleName: string,
}

export interface TenantAutoRule {
    id: string,
    autoruleName: string,
    state: boolean
}

export interface AutoruleTaks {
    id: string,
    taskName: string,
    sequencePosition: number,
    setting: any
}

export interface AutoRule {
    tenantAutorule: TenantAutoRule,
    autoruleTaks: AutoruleTaks[]
}

export interface AutoRuleStore {
    status: string,
    autoruleNames: AutoRuleName[] | [],
    autoRule: AutoRule | null
}

const initialState: AutoRuleStore = {
    status: "loading",
    autoruleNames: [],
    autoRule: null
}

export const autoRuleSlice = createSlice({
    name: 'AutoRule',
    initialState,
    reducers: {
        setAutoRulesData: (state, { payload }) => {
            state.status = "confirmed";
            state.autoRule = payload.result;
        },
        setAutoRulesName: (state, { payload }) => {
            state.status = "confirmed"
            state.autoruleNames = payload.result
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAutoRulesName, loading, errorState, setAutoRulesData } = autoRuleSlice.actions

