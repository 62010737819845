import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, setNewTenant, setTenant, error, clean, setSeatsCounter } from "./slice";
import TenantAgent from "./agent";
import { getAllUsers } from "../user/thunk";

export const checkingNews = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}
export const setNewTenantData = (tenant: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await TenantAgent.setNewTenat(tenant)
            .then((response) => {
                dispatch(setNewTenant(response.result));
                dispatch(getTenant(response.result));
            })
            .catch((err) => {
                dispatch(error());
                //console.log(error);
            })

    }
}
export const cleanTenantData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(clean())
    }
}
export const getTenant = (tenant: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await TenantAgent.getByID(tenant)
            .then((response) => {
                dispatch(setTenant(response.result));
            })
            .catch((err) => {
                dispatch(error());
                //console.log(error);
            })

    }
}

export const getSeats = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        await TenantAgent.getSeats(tenantId)
            .then((response) => {
                dispatch(setSeatsCounter(response));
            })
            .catch((err) => {
                dispatch(error());
                //console.log(error);
            })

    }
}

export const cancelSeats = (user: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        await TenantAgent.cancelSeat(user.id)
            .then((response) => {
                dispatch(getAllUsers(user.tenantId));
            })
            .catch((err) => {
                dispatch(error());
                //console.log(error);
            })

    }
}