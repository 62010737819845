import { Box, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react'
import { CutomAutocomplete } from '../custom/CutomAutocomplete';
import { useAppSelector } from '../../hooks/hooks';
import { TimerForm } from '../Dashboard/TimerForm';
import { QuestionFieldForm } from './QuestionFieldForm';

interface Props {
    reportId: string
    tableId: string 
}
export const AddQuestionFieldButton = ({ reportId, tableId}: Props) => {
    const { token } = useAppSelector(state => state.auth)
    const [open, setOpen] = useState(false)
    return (
        <>
            <Tooltip title={'Add Question Field'}>
                <IconButton onClick={() => setOpen(true)}>
                    <AddIcon sx={{ width: 50, height: 50 }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 10,
                    padding: 5,
                    paddingBottom: 1,
                    width: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }} elevation={4}>

                    {
                        (token) &&
                        <QuestionFieldForm reportId={reportId} tableId= {tableId} setOpen={setOpen} />
                    }
                </Paper>
            </Modal>
        </>
    )
}

