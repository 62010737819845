import { createSlice } from "@reduxjs/toolkit";

export interface Seat {
    id: string,
    activationDate: string,
    cancelationDate: string,
    status: number
    name: string
}

export interface seatStore {
    status: string,
    seats: Seat[],
}

const initialState: seatStore = {
    status: "loading",
    seats: [],
}

export const seatSlice = createSlice({
    name: 'Seats',
    initialState,
    reducers: {
        setAllSeats: (state, { payload }) => {
            state.status = "confirmed"
            state.seats = payload.result
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAllSeats, loading, errorState} = seatSlice.actions