export const ObjectType = {
    DynamicTable: 0,
    Report: 1,
    Dashboard: 2,
    Autorule: 3,
    Contact: 4,
    Account: 5,
    User: 6,
    EndUser: 7,
    Label: 8
};