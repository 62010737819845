import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { LoadingCube } from '../../../custom/loader/LoadingCube'
import { Box, Button, Typography } from '@mui/material'
import { useAppSelector } from '../../../../hooks/hooks'

interface Props {
    handleNext: () => void
}

export const SeatsVerify = ({ handleNext }: Props) => {
    const { tenant } = useAppSelector(state => state.tenant)
    const { token } = useAppSelector(state => state.auth)
    const [status, setStatus] = useState('loading')
    const [paymentStatus, setPaymentStatus] = useState('initial')
    const [seats, setSeats] = useState({
        used: 0,
        available: 0
    })
    const handlePay = () => {
        setPaymentStatus('loading')
        axios.post(`${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/Seat/Pay`, { token: token }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        },
        )
            .then(response => {

                setPaymentStatus('success')

            })
            .catch((error): any => {
                setPaymentStatus('error')

            });
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/Tenant/seat/${tenant?.id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {

                setStatus('success')
                setSeats({
                    used: response.data.result.seatsOcuped,
                    available: response.data.result.seatsAvailable
                })
            })
            .catch((error): any => {
                setStatus('error')

            });
    }, [])

    if (status === 'loading') {
        return (
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingCube />
            </Box>
        )
    }
    else {
        if (seats.available === 0) {
            return (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>


                    {
                        paymentStatus === 'loading' &&
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: 4
                        }}>
                            <LoadingCube />
                            Realizando compra...
                        </Box>
                    }
                    {
                        paymentStatus === "initial" &&
                        <>
                            <Typography textAlign={'center'} fontSize={23} color={'red'}>
                                No hay sillas disponibles
                            </Typography>
                            <Typography textAlign={'center'}>
                                Si continua con el proceso se le cobrara automaticamente una silla para asignar al nuevo usuario
                            </Typography>
                            <Button
                                onClick={handlePay}
                                sx={{
                                    mt: 1
                                }}
                                variant='contained'
                            >
                                Adquirir silla
                            </Button>
                        </>
                    }
                    {
                        paymentStatus === "success" &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                justifyContent: 'center',
                                mt: 1

                            }}
                        >
                            <Typography textAlign={'center'} color={'green'}>
                                Compra realizada con exito
                            </Typography>
                            <Button
                                sx={{
                                    mt: 1
                                }}
                                variant='contained'
                                onClick={handleNext}
                                color='success'
                            >
                                Continuar
                            </Button>

                        </Box>
                    }
                    {
                        paymentStatus === "error" &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                justifyContent: 'center',
                                mt: 1

                            }}
                        >
                            <Typography textAlign={'center'} color={'red'}>
                                La compra no se concreto. Intentelo nuevamente.
                            </Typography>
                            <Button
                                onClick={handlePay}

                                sx={{
                                    mt: 1
                                }}
                                variant='contained'
                                color='info'
                            >
                                Intentar nuevamente
                            </Button>
                        </Box>
                    }

                </Box>
            )
        }
        else {
            handleNext()
            return (
                <Box p={2}>
                    <Typography variant='h6'>
                        Tienes {seats.available} disponibles
                    </Typography>
                </Box>
            )
        }
    }

}
