import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, errorState, setPermissionsList, setObjectPermissions, successState } from "./slice";
import permissionAgent from "./agent";
import Swal from "sweetalert2";

export const checkingRoles = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}

export const getPermissions = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        permissionAgent.getPermissions()
            .then((result) => {
                dispatch(setPermissionsList(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}


export const createPermission = (data: any, setOpenAddPermission: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        permissionAgent.createPermission(data)
            .then((result) => {
                dispatch(getPermissions())
                dispatch(successState())
                dispatch(getPermissionsByObject(data.objectPermission.objectId))
                setOpenAddPermission(false)
                if (process.env.NODE_ENV === 'development')
                    console.log(result);
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}

export const getPermissionsByObject = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        permissionAgent.getPermissionsByObject(id)
            .then((result) => {
                dispatch(setObjectPermissions(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}


export const updatePermission = (data: any, objectId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        permissionAgent.updatePermission(data)
            .then((result) => {
                // dispatch(setPermissionsList(result.result))
                dispatch(
                    getPermissionsByObject(objectId)
                )
                dispatch(getPermissions())
                if (process.env.NODE_ENV === 'development')
                    console.log(result);
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}


export const deletePermission = (id: string, objectId: string, handleClean: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        permissionAgent.deltePermission(id)
            .then((result) => {
                handleClean()
                dispatch(
                    getPermissionsByObject(objectId)
                )
                dispatch(getPermissions())
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}