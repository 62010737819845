import { Box, Button, Grid, IconButton, Modal, SelectChangeEvent, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BasicInfo } from './BasicInfo';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { AddUserRoles } from './AddUserRoles';
import { Payment } from './Payment';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getAllUsers } from '../../../../redux/user/thunk';
import { useNavigate } from 'react-router-dom';
import { setSubscription } from '../../../../redux/subscriptionPayment/slice';
import { errorState } from '../../../../redux/user/slice';
import CloseIcon from '@mui/icons-material/Close';
import { isEmail } from '../../../../functions/EmailVerificator';
import dayjs from 'dayjs';
import { getSeats } from '../../../../redux/tenant/thunk';
import { Confirmation } from './Confirmation';
import { SeatsVerify } from './SeatsVerify';

//device enviroment
const userAgent = window.navigator.userAgent;
const isMobile = /Mobile/.test(userAgent);
const isTablet = /Tablet/.test(userAgent);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // width: isMobile || isTablet ? "99vw" : "auto",
    width: { xs: "90%", md: 'auto' },
    height: { xs: "500", md: 'auto' },
    p: 4,
};

export interface User {
    name: string,
    lastName: string,
    gender: string,
    birthDate: string | null,
    phoneNumber: string,
    username: string,
    workPosition: string,
    email: string,
    alias: string,

}
interface userResponse {
    seatId: string,
    status: string,
    userId: string
}
export const AddUser = ({ open, setOpen }: { open: boolean, setOpen: any }) => {

    const [userStatus, setUserStatus] = useState("")
    const [user, setUser] = useState<userResponse>({
        seatId: "",
        status: "",
        userId: ""
    })

    //snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { token, tenantId, suscription } = useAppSelector(state => state.auth)
    const [page, setPage] = useState(0)
    const [response, setResponse] = useState<any>()
    const [newUser, setNewUser] = useState<User>({
        name: "",
        username: "",
        lastName: "",
        email: "",
        workPosition: "",
        gender: "",
        birthDate: null,
        phoneNumber: "",
        alias: "",
    })
    const [roles, setRoles] = useState<string[]>([])
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewUser({ ...newUser, [name]: value })
    }

    const handleChange = (event: SelectChangeEvent) => {
        setNewUser({ ...newUser, [event.target.name]: event.target.value as string })
    };
    const handleCreateUser = async () => {
        return await axios.post(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/User`,
            {
                "tenantId": tenantId,
                "username": newUser.username,
                "name": newUser.name,
                "lastname": newUser.lastName,
                "email": newUser.email,
                "workPosition": newUser.workPosition,
                "token": token,
                "suscriptionId": suscription?.id,
                "gender": parseInt(newUser.gender),
                "birthDate": newUser.birthDate,
                "phoneNumber": newUser.phoneNumber,
                "alias" : newUser.alias
            }
            , {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
    }
    // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault()
    //     if (page === 2) {
    //         setUserStatus("loading")
    //         handleCreateUser()
    //             .then((result) => {
    //                 if (process.env.NODE_ENV === 'development')
    //                     console.log(result);
    //                 // setUser(result.data.result)
    //                 // setUserStatus(result.data.result.status)
    //                 setResponse(result)
    //                 setPage(page + 1)

    //             }).catch((err) => {
    //                 if (process.env.NODE_ENV === 'development')
    //                     console.log(err);
    //                 setResponse(err)
    //                 setUserStatus("error")
    //                 setPage(page + 1)
    //             });
    //     } else {
    //         setPage(page + 1)
    //     }

    // }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (page === 1) {
            setUserStatus("loading");
            handleCreateUser()
                .then((result) => {
                    setResponse(result);
                    setPage(page + 1);
                }).catch((err) => {
                    setResponse(err);
                    setUserStatus("error");
                    setPage(page + 1);
                });
        } else {
            setPage(page + 1);
        }
    }
    
    const renderContent = () => {
        switch (page) {
            case 0:
                return <SeatsVerify handleNext={() => { setPage(page + 1) }} />
            case 1:
                return <BasicInfo handleChange={handleChange} handleInputChange={handleInputChange} setNewUser={setNewUser} newUser={newUser} />
            case 2:
                return <Confirmation status={userStatus} response={response} />;
        }
    };

    //age bigger than 13 y/o
    const currentDate = dayjs();
    let selectedDate: any = "";
    let diffInYears: number = 0;
    if (newUser.birthDate !== null) {
        selectedDate = dayjs(newUser.birthDate);
        diffInYears = currentDate.diff(selectedDate, 'year');
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
                dispatch(getAllUsers(tenantId))

            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} onSubmit={handleSubmit} component={'form'} >
                <Grid container spacing={2} >
                    <Grid item md={3} xs={3} sx={{ display: isMobile || isTablet ? "flex" : "auto", padding: isMobile || isTablet ? 0 : "auto", marginTop: 3 }}>
                        <Grid container spacing={1} >
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "flex-end", flexWrap: isMobile || isTablet ? "wrap" : "nowrap" }}>
                                <Typography variant='subtitle1' >
                                    Disponibilidad de sillas
                                </Typography>
                                {
                                    page === 0
                                        ? <CircleIcon fontSize={isMobile || isTablet ? "small" : "large"} sx={{ color: "#005bff", marginRight: 1 }} />
                                        : <CircleOutlinedIcon fontSize={isMobile || isTablet ? "small" : "large"} sx={{ marginRight: 1, color: "grey" }} />
                                }
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", paddingRight: "40px" }}>
                                < MoreVertOutlinedIcon />
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "flex-end", flexWrap: isMobile || isTablet ? "wrap" : "nowrap" }}>
                                <Typography variant='subtitle1' >
                                    Datos basicos
                                </Typography>
                                {
                                    page === 1
                                        ? <CircleIcon fontSize={isMobile || isTablet ? "small" : "large"} sx={{ color: "#005bff", marginRight: 1 }} />
                                        : <CircleOutlinedIcon fontSize={isMobile || isTablet ? "small" : "large"} sx={{ marginRight: 1, color: "grey" }} />
                                }
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", paddingRight: "40px" }}>
                                < MoreVertOutlinedIcon />
                            </Grid>

                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "flex-end", flexWrap: isMobile || isTablet ? "wrap" : "nowrap" }}>
                                <Typography variant='subtitle1' >
                                    Finalizar
                                </Typography>
                                {
                                    page === 2
                                        ? <CircleIcon fontSize={isMobile || isTablet ? "small" : "large"} sx={{ color: "#005bff", marginRight: 1 }} />
                                        : <CircleOutlinedIcon fontSize={isMobile || isTablet ? "small" : "large"} sx={{ marginRight: 1, color: "grey" }} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={9} xs={9}>
                        {
                            renderContent()
                        }

                    </Grid>
                    <Grid item md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Grid sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <Button variant="outlined"
                                disabled={page != 1 || userStatus === 'error' ? false : true}
                                onClick={() => setPage(page - 1)}
                                sx={{
                                    backgroundColor: "lightgrey",
                                    color: "black",
                                    borderColor: "lightgrey",
                                    marginRight: 1
                                }}>
                                Anterior
                            </Button>

                            {
                                page === 1
                                    ? <Button variant="contained" type='submit' disabled={userStatus === "loading" || !isEmail(newUser.email) || diffInYears < 13}>
                                        Siguiente
                                    </Button>
                                    :
                                    <Button disabled={roles.length === 0 || page === 3 || userStatus === "loading"} type='submit' variant="contained">
                                        Siguiente
                                    </Button>
                            }
                        </Grid>
                        <Button variant="outlined"
                            onClick={() => { setOpen(false); if (page === 3) dispatch(getAllUsers(tenantId)) }}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                                marginLeft: 1
                            }}>
                            Cerrar
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message="Error al crear usuario"
                    action={action}
                />
            </Box>
        </Modal>
    )
}
