import { createSlice } from "@reduxjs/toolkit";
import { User } from "../user/slice";


export interface Permission {
}
export interface PermissionsList {
    backend: {
        autorules: any[],
        reports: any[],
        roles: string[],
        tables: any[]
        labels: any[]
    }
    community: {

    }
}
export interface ObjectPermissions {
    authorityId: string
    authorityType: number
    canCreate: boolean
    canDelete: boolean
    canDeleteData: boolean
    canRead: boolean
    canUpdate: boolean
    canUpdateData: boolean
    canReadRows: boolean
    createdBy: string
    createdDate: string
    editedBy: string
    id: string
    modifiedDate: string
}


export interface PermissionStore {
    status: string,
    permissionsList: PermissionsList | null
    objectPermissions: ObjectPermissions[]

}
const initialState: PermissionStore = {
    status: "loading",
    permissionsList: null,
    objectPermissions: []
}
export const permissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        setPermissionsList: (state, { payload }) => {
            state.status = "success"
            state.permissionsList = payload
        },
        setObjectPermissions: (state, { payload }) => {
            state.status = "success"
            state.objectPermissions = payload
        },
        loading: (state) => {
            state.status = "loading"
        },
        successState: (state) => {
            state.status = "success"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { loading, errorState, setPermissionsList, setObjectPermissions, successState } = permissionSlice.actions
