import { Box, Chip, useTheme, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getAllCompanies } from '../../../redux/account/thunk'
import { CutomAutocomplete } from '../../../components/custom/CutomAutocomplete';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { isEscapeKey } from '@mui/x-data-grid/utils/keyboardUtils';
import { GridCellEditStopReasons } from '@mui/x-data-grid';
import axios from 'axios';


export const AccountEditCell = React.forwardRef<HTMLDivElement, { value: any; api: GridApiPro; field: string, id: string, row: any, hasFocus: boolean, apiRefTable: any }>(
    function AccountEditCell(props, ref) {
        const { value, api, field, id, row, hasFocus, apiRefTable } = props;

        const { tenantId } = useAppSelector(state => state.auth)
        const [ accounts, setAccounts] = useState<any[]>([]);
        const [searchTerm, setSearchTerm] = useState<string>("");
        const [account, setAccount] = useState<string | null>(value)


        useEffect(() => {
            const fetchContacts = async () => {
              try {
                let url = `${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/PublicFeature/Accounts?TenantId=${tenantId}&Name=${searchTerm}`;
                const response = await axios.get(url);
                setAccounts(response.data.result)
              } catch (error) {
                console.error("Error fetching contacts:", error);
              }
            };
      
            fetchContacts();
          }, [searchTerm, tenantId]);



        const handleChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
            setAccount(value)
            if (value)
                setTimeout(() => {
                    api.setEditCellValue({ id, field, value: value });
                }, 0);
            else
                setTimeout(() => {
                    api.setEditCellValue({ id, field, value: "" });
                }, 0);
        };

        const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
            setSearchTerm(newInputValue);
        };

        useEffect(() => {
            if (!hasFocus) {
                if (value) {
                    api.setEditCellValue({
                        id,
                        field,
                        value: value,
                    });
                } else
                    api.setEditCellValue({
                        id,
                        field,
                        value: "",
                    });
                api.stopCellEditMode({ field: field, id: row.id })
            }

        }, [hasFocus])


        return (
            <CutomAutocomplete
                options={accounts}
                title='name'
                label="Accounts"
                onChange={handleChange}
                value={account}
                inputValue={searchTerm}
                onInputChange={handleInputChange}
            />
        );
    },
);