import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    handleOpen: boolean;
    handleClose: () => void;
    customComponent?: React.ReactNode;
}

export const RightSidePanel = ({handleOpen, handleClose, customComponent } :Props) => {

    const theme = useTheme();

        return (
          <Box
            sx={{
              
              position: "fixed",
              top: theme.mixins.toolbar.minHeight,  
              right: handleOpen ? 0 : "-400px",
              height: "100%",
              width: "300px",
              bgcolor: "background.paper",
              p: 2,
              transition: "right 0.3s ease-in-out",
              zIndex: 999,
              overflowY: 'auto'
            }}  
          >
              <IconButton
                  sx={{ position: 'absolute', top: 5, left: 0 }}
                  onClick={handleClose}
                  aria-label="close"
              >
              <CloseIcon />
              </IconButton>
              <Box sx={{ mt: 5}}>
                {customComponent && customComponent}
              </Box>
            
          </Box>
        );

}