import { createSlice } from "@reduxjs/toolkit";

export interface Settings {
    webpayMallCode: string
    oneclickMallCode: string
    correctRedirect: string
    incorrectRedirect: string
}
export interface Subscriptions {
    id: string
    name: string
    price: number
    currency: number
    renewal: number
    state: number
}
export interface CommerceStore {
    status: string
    settings: Settings | null,
    subscriptions: Subscriptions[],


}
const initialState: CommerceStore = {
    status: "loading",
    settings: null,
    subscriptions: []
}
export const commerceSlice = createSlice({
    name: 'commerce',
    initialState,
    reducers: {

        loading: (state) => {
            state.status = "loading"
        },
        setSetting: (state, { payload }) => {
            state.status = "confirmed"
            state.settings = payload.result
        },
        setSubscriptions: (state, { payload }) => {
            state.status = "confirmed"
            state.subscriptions = payload.result
        },

    }
})
export const { loading, setSetting, setSubscriptions } = commerceSlice.actions
