import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Input, Typography, IconButton } from '@mui/material';
import { Close, PhotoCamera } from '@mui/icons-material';
import { Tenant } from '../../models/Teanant';

interface EditTenantModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (updatedTenant: Tenant) => void;
  tenant: Tenant;
}

export const EditTenantModal: React.FC<EditTenantModalProps> = ({ open, onClose, onSave, tenant }) => {
  const [updatedTenant, setUpdatedTenant] = useState<Tenant>({ ...tenant });
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedTenant(prevTenant => ({
      ...prevTenant,
      [name]: value
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpdatedTenant(prevTenant => ({
        ...prevTenant,
        profileImage: file  // Asignar el archivo directamente a profileImage
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {

    updatedTenant.id = tenant.id
    onSave(updatedTenant);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 8,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <Typography variant="h5" gutterBottom>
          Edit Tenant
        </Typography>
        <form>
          <TextField
            name="name"
            label="Name"
            fullWidth
            value={updatedTenant.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            value={updatedTenant.description}
            onChange={handleChange}
            margin="normal"
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-upload-button"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="image-upload-button">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>
          {imagePreview && (
            <div style={{ textAlign: 'center', marginTop: '8px' }}>
              <img src={imagePreview} alt="Preview" style={{ maxWidth: '50%', maxHeight: '100px' }} />
            </div>
          )}
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
