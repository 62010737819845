import { Box, Paper } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react'
import { GridItem } from '../../components/custom/Grid/GridItem';
import { SellsGraph } from '../../components/Commmerce/Dashboard/SellsGraph';
import PaymentTableOrder from '../../components/Commmerce/Dashboard/PaymentTableOrder';

export const CommerceDashboardPage = () => {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [sizeValue, setSizeValue] = useState<number>(0)

    const handleResize = () => {

        const element = elementRef.current;
        if (element) {
            const width = element.getBoundingClientRect().width;
            setSizeValue((width / 12))
        }
    };
    useEffect(() => {
        const element = elementRef.current;

        if (element) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(element);

            return () => {
                resizeObserver.unobserve(element);
            };
        }
    }, []);
    return (
        <Box p={2}>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: "wrap",
                    ".custom-grid-item": {
                        padding: 2
                    }
                }}
                ref={elementRef}
            >
                <GridItem
                    sizeValue={sizeValue}
                    width={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12
                    }}
                    height={{
                        xs: 12,
                        sm: 12,
                        md: 5,
                        lg: 5,
                        xl: 3
                    }}
                >
                    <SellsGraph />

                </GridItem>
                <GridItem
                    sizeValue={sizeValue}
                    width={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12
                    }}
                    height={{
                        xs: 12,
                        sm: 12,
                        md: 5,
                        lg: 5,
                        xl: 3
                    }}
                >
                    <Paper elevation={6}>

                        <PaymentTableOrder />
                    </Paper>

                </GridItem>

            </Box>
        </Box>
    )
}
