import { Box, Chip } from '@mui/material'
import React, { SetStateAction } from 'react'
import { Account } from '../../../redux/account/slice'

interface Props {
    accounts: Account[]
    setAccount: React.Dispatch<SetStateAction<null | Account>>

}
export const AccountsCell = ({ accounts, setAccount }: Props) => {

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1
            }}
        >
            {
                accounts.map((account) => {
                    return (
                        <Box
                            sx={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setAccount(account)
                            }
                            }
                        >
                            <Chip label={account.name} ></Chip>
                        </Box>
                    )
                })
            }
        </Box>
    )
}
