import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { TenantMainCard } from '../../components/tenant/TenantMainCard'
import { MembersListCard } from '../../components/tenant/MembersListCard'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getById } from '../../redux/account/thunk'
import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import { getClientByCompanyId } from '../../redux/contacts/thunk'
import { ContactByCompany } from '../../redux/contacts/slice'



export const Tenant = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const { currentAccount, status } = useAppSelector(state => state.accounts)
    const { contactsByCompany } = useAppSelector(state => state.contacts)

    useEffect(() => {
        if (id != undefined) {
            dispatch(getById(id))
            dispatch(getClientByCompanyId(id))
        }
    }, [])

    if (status === "loading") {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                }}
                justifyContent={"center"}
            >
                <LoadingCube />
            </Box>
        )
    }
    return (
        <Box
            sx={{
                px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 },
                mt: 3
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {
                        currentAccount &&
                        <TenantMainCard company={currentAccount} />
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        contactsByCompany !== null
                        && <MembersListCard members={contactsByCompany} />

                    }
                </Grid>
            </Grid>
        </Box>
    )
}
