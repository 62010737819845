import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Payment, getAll, getPrices, getSubscription, loading } from "./slice";
import Agent from "./agent"

export const checkingPayment = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}
export const getAllTransactions = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading());
        Agent.getAll()
            .then((result) => {
                dispatch(getAll(result));

            }).catch((err) => {
                //console.log(err);
            });
    }
}
export const getFullPrice = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading());
        Agent.getSubscription(body)
            .then((result) => {
                // console.log(result);

                dispatch(getPrices(result.result));

            }).catch((err) => {
                console.log(err);
            });
    }
}

export const createPaymentOrder = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading());
        Agent.createPaymentOrder(body)
            .then((result) => {
                const url = result.result.url
                const paymentToken = result.result.token
                const redirect = `${url}?TBK_TOKEN=${paymentToken}`
                window.location.replace(redirect)
            }).catch((err) => {
                console.log(err);
            });
    }
}