import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { EnduserComponent } from '../../components/EndUser/EnduserComponent'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getEndUserRecords } from '../../redux/endUser/thunk'
import { GridItem } from '../../components/custom/Grid/GridItem'
import { Line } from 'react-chartjs-2'
import dayjs from 'dayjs'
import { faker } from '@faker-js/faker'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { EndUsersLoginsChart } from './EndUsersLoginsChart'
import { EnUsersRegisterChart } from './EnUsersRegisterChart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface datasets {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}
export const EndUsersPage = () => {
  const dispatch = useAppDispatch()
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [sizeValue, setSizeValue] = useState<number>(0)

  const { tenantId, id, token } = useAppSelector((state) => state.auth);
  const { endUserRecord } = useAppSelector((state) => state.endUser);



  useEffect(() => {
    dispatch(getEndUserRecords(tenantId));
  }, []);



  const handleResize = () => {
    const element = elementRef.current;
    if (element) {
      const width = element.getBoundingClientRect().width;
      setSizeValue((width / 12))
    }
  };
  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, []);





  return (
    <Box p={2}>

      <Box
        sx={{
          display: 'flex',
          flexWrap: "wrap",
          ".custom-grid-item": {
            padding: 2
          }
        }}
        ref={elementRef}
      >
        <GridItem sizeValue={sizeValue}
          width={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            xl: 6
          }}
          height={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 3,
            xl: 2
          }}
        >
          <Paper sx={{ height: '100%' }}>
            <EndUsersLoginsChart />
          </Paper>
        </GridItem>
        <GridItem sizeValue={sizeValue}
          width={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            xl: 6
          }}
          height={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 3,
            xl: 2
          }}
        >
          <Paper sx={{ height: '100%' }}>
            <EnUsersRegisterChart />
          </Paper>
        </GridItem>
        <GridItem
          sizeValue={sizeValue}
          width={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
          }}
          height={{
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6
          }}
        >

          <EnduserComponent />
        </GridItem>

      </Box>
    </Box>

  )
}
