import axios, { AxiosResponse } from "axios";

const url = process.env.REACT_APP_NEXUS_BACK_URL;

const responseBody = (response) => response.data;

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Access-Control-Allow-Headers"] =
            "X-Requested-With, privatekey";
    }
    return config;
});
const specialAxios = axios.create();

specialAxios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Access-Control-Allow-Headers"] =
            "X-Requested-With, privatekey";
        config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
});

const requests = {
    get: (url) => axios.get(url).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
    postFile: (url, body, headers) =>
        specialAxios.post(url, body, headers).then(responseBody),
    put: (url, body) => axios.put(url, body).then(responseBody),
    putFile: (url, body, headers) =>
        specialAxios.put(url, body, headers).then(responseBody),
    del: (url) => axios.delete(url).then(responseBody),
};
const TablesAgent = {
    //table
    getTablesName: (tenantId) =>
        requests.get(url + `/api/v1/DynamicTables?TenantId=${tenantId}`),
    getTableNameById: (tableId) =>
        requests.get(url + `/api/v1/DynamicTables/Tablename/${tableId}`),
    posTable: (body) => requests.post(url + "/api/v1/DynamicTables", body),
    delTable: (tableId) =>
        requests.del(url + `/api/v1/DynamicTables/${tableId}`),
    putTable: (body) => requests.put(url + "/api/v1/DynamicTables", body),

    //table column
    getTablesColumns: (tableId) =>
        requests.get(url + `/api/v1/DynamicColumn?TableId=${tableId}`),
    postTableColumn: (body, pageNumber, pageSize) =>
        requests.post(url + "/api/v1/DynamicColumn", body, pageNumber, pageSize),
    putTableColumnName: (body, pageNumber, pageSize) =>
        requests.put(url + "/api/v1/DynamicColumn", body, pageNumber, pageSize),
    delTableColumn: (columnId, pageNumber, pageSize) =>
        requests.del(url + `/api/v1/DynamicColumn/${columnId}`,pageNumber, pageSize),

    //table row
    postTableRow: (body) => requests.post(url + "/api/v1/DynamicField", body),
    createTableRow: (body) =>
        requests.post(url + "/api/v1/DynamicRow/CreateRow", body),
    getTablesDataBytableId: (tableId, pageNumber, pageSize, filters, logicOperator) => {
        let queryString = `/api/v1/DynamicTables/${tableId}?PageNumber=${pageNumber}&PageSize=${pageSize}`
        if (filters) {
            queryString += `&${filters}&LogicOperator=${logicOperator}`; 
          }
        return requests.get(url + queryString);
    },
    postFileRow: (body, headers) =>
        requests.postFile(url + "/api/v1/DynamicField/File", body, headers),
    modifyFileRow: (body, headers) =>
        requests.putFile(url + "/api/v1/DynamicField/File", body, headers),
    putTableRow: (body) => requests.put(url + "/api/v1/DynamicField", body),
    putColumnPosition: (body) =>
        requests.post(url + "/api/v1/DynamicColumn/Dragable", body),

    delSingleTableRow: (body) =>
        requests.del(
            url +
                `/api/v1/DynamicField/${body.id}?columnId=${body.columnId}&position=${body.position}`
        ),
    delTableRow: (body) =>
        requests.post(url + "/api/v1/DynamicField/Delete", body),
    delTableRow2: (rowId) =>
        requests.del(url + "/api/v1/DynamicRow/Delete?id=" + rowId),
    delMultipleTableRow: (body) =>
        requests.post(url + "/api/v1/DynamicRow/Delete", body),

    importCSV: (body) =>
        requests.post(url + "/api/v1/DynamicTables/Import", body),
};
export default TablesAgent;
