import React from 'react'
import { User } from '../../../redux/user/slice'
import { Avatar, Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppDispatch } from '../../../hooks/hooks';
import { disassocieteUserRole } from '../../../redux/roles/thunk';
import { Role } from '../../../redux/roles/slice';
interface Props {
    user: User
    role: Role
}
export const UserComponent = ({ user, role }: Props) => {
    const dispatch = useAppDispatch()
    const handleDelete = () => {
        dispatch(disassocieteUserRole({ roleId: role.id, userId: user.id }))
    }
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 1,
                maxWidth: { xs: '100%', md: 400 },
                justifyContent: 'space-between',
                ":hover": {
                    backgroundColor: "rgba(33, 150, 243, 0.08)"
                },
                borderRadius: 1,
                transition: 'ease-in 0.25s all'
            }}
        >
            <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}

            >
                <Avatar alt={`${user.name} ${user.lastName}`} src="" />

                {`${user.name} ${user.lastName}`}
            </Box>

            <Tooltip title="Delete user">
                <IconButton onClick={handleDelete}>
                    <CancelIcon sx={{ color: "#bdbdbd", height: 15 }}></CancelIcon>
                </IconButton>
            </Tooltip>
        </Box>
    )
}
