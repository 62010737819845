import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
    deleteCompany,
    getAllCompanies,
    modifyCompany,
} from "../../../redux/account/thunk";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import TableCell from "@mui/material/TableCell";
import { Button, MenuItem, Tooltip, Typography } from "@mui/material";
import { AddAccount } from "./AddAccount";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Cancel from "@mui/icons-material/Close";
import { DetailCell, DetailContent, DetailEditCell } from "./EditFields";
import { format, parse, isValid } from "date-fns";
import {
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarFilterButton,
    gridPaginationModelSelector,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { StyledDataGrid } from "../../Tables/Componentes/TableMUIX";
import { Box } from "@mui/system";
import { EditAccount } from "./EditAccount";
import { AccountPopUp } from "./AccountPopUp";
import { AccountSizes } from "../../../components/Enums/AccountSizes";
import { AccountModal } from "./AccountModal";
import { getAllClients } from "../../../redux/contacts/thunk";
import { ImageItemColumn } from "../../Tables/Componentes/ImageItemColumn";
import { blue } from "@mui/material/colors";
import { isURL } from "../../Tables/Componentes/TableComponent";
import { GridFilterInputValue } from '@mui/x-data-grid-pro';

const newNames = {
    id: "Id",
    rut: "Rut",
    address: "Dirección",
    companyNameLink: "Nombre de la compañia",
    createdDate: "Creada en",
    modifiedDate: "Última modificación",
    editedBy: "Modificado por",
};

const getRowId = (row) => row.id;

const CustomToolbar = (handleCreateRow, permissions) => {
    return (
        <GridToolbarContainer
            sx={{
                mb: 4,
            }}
        >
            {/* <GridToolbarColumnsButton /> */}
            <GridToolbarFilterButton />
            {/* {permissions && ( */}
            <Button size="small" onClick={handleCreateRow}>
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                    }}
                >
                    <TableRowsRoundedIcon fontSize="small" />
                    Add Account
                </Box>
            </Button>
            {/* )} */}
        </GridToolbarContainer>
    );
};

export const AccountComponent = () => {
    //open add user modal
    const [openAddUser, setOpenAddUser] = useState(false);

    // Selected Account
    const [selectedAccount, setSelectedAccount] = useState(null);
    const handleCloseAccountModal = () => {
        setSelectedAccount(null);
    };

    //Pagination 
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
        rowsCount: 0,
    });

    //Filter

    const filterColumns = ({ field, columns, currentFilters }) => {
        const filteredFields = currentFilters?.map((item) => item.field);
        return columns.filter(
          (colDef) =>
            colDef.filterable &&
            (colDef.field === field || !filteredFields.includes(colDef.field)),
        ).map((column) => column.field);
      };
      
      const getColumnForNewFilter = ({ currentFilters, columns }) => {
        const filteredFields = currentFilters?.map(({ field }) => field);
        const columnForNewFilter = columns.find(
          (colDef) => colDef.filterable && !filteredFields.includes(colDef.field),
        );
        return columnForNewFilter?.field ?? null;
      };

      const getCustomFilterOperators = () => [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
              return null;
            }
            return (params) => {
              return params.value != null
                ? ('' + params.value)
                    .toLowerCase()
                    .includes(filterItem.value.toLowerCase())
                : false;
            };
          },
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'not contains',
          value: 'notcontains',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
              return null;
            }
            return (params) => {
              return params.value != null
                ? !('' + params.value)
                    .toLowerCase()
                    .includes(filterItem.value.toLowerCase())
                : true;
            };
          },
          InputComponent: GridFilterInputValue,
        },
      ];

    const [filterModel, setFilterModel] = useState({ items: [] });

    const handleFilterChange = (newFilterModel) => {
        setFilterModel(newFilterModel);
    };

    const fetchFilteredData = (page, pageSize, filters) => {
        const filterParams = getFilterParams(filters);
        const filterQuery = Object.entries(filterParams).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
        dispatch(getAllCompanies(tenantId, pageSize, page, filterQuery));
    };

    const getFilterParams = (filters) => {
        let params = {};
        if (Array.isArray(filters.items)) {
            filters.items.forEach((filter) => {
                if (filter.operator === 'contains') {
                    params['Name'] = filter.value;
                } else if (filter.operator === 'notcontains') {
                    params['NameNotContains'] = filter.value;
                }
            });
        }
        return params;
    };

    // File Modal
    const [openFileModal, setOpenFileModal] = useState(false);
    const handleOpenFileModal = () => setOpenFileModal(true);
    const handleCloseFileModal = () => setOpenFileModal(false);

    //redux
    const { users } = useAppSelector((state) => state.users);
    const { permissionsList } = useAppSelector((state) => state.permissions);
    const {
        tenantId,
        id: userId,
        token,
    } = useAppSelector((state) => state.auth);
    const { accounts: companiesState, status, totalCount, pageNumber, pageSize} = useAppSelector(
        (state) => state.accounts
    );
    const [companies, setCompanies] = useState(companiesState);
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState(companies);
    const [columns, setColumns] = useState([]);


    useEffect(() => {
        if (Array.isArray(filterModel.items) && filterModel.items.length > 0) {
            fetchFilteredData(paginationModel.page, paginationModel.pageSize, filterModel);
        } else {
            dispatch(getAllCompanies(tenantId, paginationModel.pageSize, paginationModel.page));
        }
    }, [paginationModel, filterModel]);

    useEffect(() => {
        if (permissionsList) {
            const columns = [
                permissionsList.backend.roles.includes("CanManageEntities") && {
                    field: "actions",
                    disableColumnMenu: true,
                    type: "actions",
                    width: 50,
                    getActions: (params) => [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => {
                                // eslint-disable-next-line
                                Swal.fire({
                                    title: "¿Seguro quieres borrar la fila?",
                                    text: "Una vez borrada no hay vuelta atras!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Borrar",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(
                                            deleteCompany({
                                                companyId: params.row.id,
                                                tenantId: tenantId,
                                            }, pageSize, pageNumber)
                                        );
                                    }
                                });
                            }}
                        />,
                    ],
                },

                {
                    field: "name",
                    headerName: "Account name",
                    position: 0,
                    type: "text",
                    disableColumnMenu: true,
                    width: 200,
                    filterOperators: getCustomFilterOperators(),
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        // return <AccountPopUp params={params} />;
                        return (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "blue",
                                }}
                                onClick={() => {
                                    setSelectedAccount(params.row);
                                }}
                            >
                                {params.row[params.field]}
                            </Box>
                        );
                    },
                },
                {
                    field: "businessName",
                    headerName: "Business name",
                    position: 1,
                    disableColumnMenu: true,
                    type: "text",
                    width: 200,
                    filterable: false,
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                },
                {
                    name: "Image",
                    field: "profileImage",
                    width: 200,
                    filterable: false,
                    editable: false,
                    // editable: permissionsList.backend.roles.includes("CanManageEntities"),
                    renderCell: (params) => {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                                // id={params.row.id}
                                className="file-cell"
                            >
                                <ImageItemColumn
                                    url={params.row[params.field]}
                                ></ImageItemColumn>
                            </Box>
                        );
                    },
                    sortable: false,
                },
                {
                    field: "countryId",
                    headerName: "Country Id",
                    disableColumnMenu: true,
                    position: 2,
                    type: "text",
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    width: 200,
                    filterable: false, 
                },
                {
                    field: "address",
                    headerName: "Address",
                    width: 200,
                    filterable: false, 
                    position: 3,
                    disableColumnMenu: true,
                    type: "text",
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                },

                {
                    field: "email",
                    headerName: "Email",
                    position: 4,
                    disableColumnMenu: true,
                    type: "text",
                    width: 150,
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                },
                {
                    field: "phoneNumber",
                    headerName: "Phone Number",
                    position: 5,
                    disableColumnMenu: true,
                    type: "text",
                    width: 150,
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                },
                {
                    field: "website",
                    headerName: "Website",
                    position: 7,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "linkedin",
                    headerName: "Linkedin",
                    position: 8,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "instagram",
                    headerName: "Instagram",
                    position: 9,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "facebook",
                    headerName: "Facebook",
                    position: 10,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "twitter",
                    headerName: "Twitter",
                    position: 11,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "tikTok",
                    headerName: "TikTok",
                    position: 12,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "youtube",
                    headerName: "Youtube",
                    position: 13,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "twitch",
                    headerName: "Twitch",
                    position: 14,
                    type: "text",
                    filterable: false, 
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "threads",
                    headerName: "Threads",
                    position: 15,
                    type: "text",
                    filterable: false, 
                    editable: false,
                    renderCell: (params) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${
                                                  params.row[params.field]
                                              }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "notes",
                    headerName: "Notes",
                    position: 14,
                    disableColumnMenu: true,
                    type: "singleSelect",
                    filterable: false, 
                    valueOptions: [
                        ...Object.keys(AccountSizes)
                            .filter((key) => !isNaN(Number(key)))
                            .map((key) => AccountSizes[key]),
                    ],
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    renderCell: (params) => {
                        return <div>{params.row[params.field]}</div>;
                    },
                },
                {
                    field: "size",
                    headerName: "Size",
                    position: 15,
                    type: "singleSelect",
                    filterable: false, 
                    disableColumnMenu: true,
                    editable:
                        permissionsList.backend.roles.includes(
                            "CanManageEntities"
                        ),
                    valueOptions: [
                        ...Object.keys(AccountSizes)
                            .filter((key) => !isNaN(Number(key)))
                            .map((value) => ({
                                value: parseInt(value),
                                label: AccountSizes[value],
                            })),
                    ],
                    renderCell: (params) => {
                        if (params)
                            if (params.formattedValue != "")
                                return <div>{params.formattedValue}</div>;
                        return (
                            <div>{AccountSizes[params.row[params.field]]}</div>
                        );
                    },
                },
            ];
            setColumns(columns);
            let tempRows = companiesState.map((e) => {
                return {
                    ...e,
                    createdDate: new Date(e.createdDate),
                    modifiedDate: new Date(e.modifiedDate),
                    editedBy: (
                        <Link to={`/user/${e.editedBy}`} rel="noopener">
                            {
                                users.find((user) => user.id === e.editedBy)
                                    ?.username
                            }
                        </Link>
                    ),
                };
            });

            setRows(tempRows);
        }
    }, [companiesState, permissionsList]);
    return (
        <Paper
            sx={{
                p: { xs: 0, md: 3 },
            }}
        >
            <StyledDataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                slots={{
                    toolbar: () => {
                        if (permissionsList)
                            return CustomToolbar(() => {
                                setOpenAddUser(true);
                            }, permissionsList.backend.roles.includes("CanManageEntities"));
                    },
                }}
                slotProps={{
                    filterPanel: {
                      filterFormProps: {
                        filterColumns, 
                      },
                      getColumnForNewFilter,
                    },
                  }
                }
                processRowUpdate={(params) => {
                    dispatch(
                        modifyCompany({
                            ...params,
                            token: "",
                        })
                    );
                    return params;
                }}
                pageSizeOptions={[10, 25, 50]}
                paginationMode="server"
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                rowCount={totalCount}    
                filterMode="server"
                onFilterModelChange={handleFilterChange}        
            />

            {openAddUser === true && (
                <AddAccount open={openAddUser} setOpen={setOpenAddUser} />
            )}
            {selectedAccount != null && (
                <AccountModal
                    open={selectedAccount != null}
                    setOpen={handleCloseAccountModal}
                    account={selectedAccount}
                ></AccountModal>
            )}
        </Paper>
    );
};
