import { createSlice } from "@reduxjs/toolkit";
import { Tenant } from "../../models/Teanant";



export interface tenantStore {
    status: string,
    tenant: Tenant | null,
    seatsCounter: number | string,
    purchasedSeats: number | string
}
const initialState: tenantStore = {
    status: "loading",
    tenant: null,
    seatsCounter: "N/A",
    purchasedSeats: "N/A"
}
export const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        setTenant: (state, { payload }) => {
            state.status = "confirmed"
            state.tenant = payload
        },
        setNewTenant: (state, { payload }) => {
            state.status = "confirmed"
            state.tenant = {
                name: "",
                id: payload,
                email: "",
                profileImage: "",
                description: "",
                isOwner: false,
                canManageTenant: ""
            }
        },
        clean: (state) => {
            state.status = "initial"
            state.tenant = null
        },
        setSeatsCounter: (state, {payload}) => {
            state.seatsCounter = payload.result.seatAvailable
            state.purchasedSeats = payload.result.purchasedSeats
        },
        loading: (state) => {
            state.status = "loading"
        },
        error: (state) => {
            state.status = "error"
        }
    }
})
export const { loading, setTenant, setNewTenant, error, clean, setSeatsCounter } = tenantSlice.actions
