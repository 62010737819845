import axios, { AxiosResponse } from 'axios';
import { User } from './slice';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        // config.headers!['Authorization'] = `Bearer ${token}`
    }
    return config

})

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: User) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const UsersAgent = {
    getAll: (tenantId: string) => requests.get<any>(url + `/api/v1/User?TenantId=${tenantId}&PageNumber=1&PageSize=10000`),
    modifyUser: (body: any) => requests.put<any>(url + `/api/v1/User`, body),
    getUserById: (id: string) => requests.get<any>(url + `/api/v1/User/${id}`),
    removeUserId: (id: string, tenantId: string) => requests.del<any>(url + `/api/v1/User/${id}?TenantId=${tenantId}`),
}
export default UsersAgent

