import { IconButton, Tooltip } from "@mui/material";
import { useCallback } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export function TriggerButton({ data, isConnectable }: any) {
    const onChange = useCallback((evt: any) => {
    }, []);
    const open = data.open;

    return (
        <div className="text-updater-node">

            {
                (data.permissions.includes("CanCreate")) &&
                <Tooltip title={"Agregar trigger"}>
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={data.handleClick}
                    >
                        <DoubleArrowIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            }

        </div>
    );
}
