import React, { useState } from 'react'
import { Box, Paper, Typography, IconButton, Input, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { useAppSelector } from '../../../../hooks/hooks'
import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../../../../components/Enums/ColumnsTypes'

interface Props {
    handleCloseExportModal: () => void
}
export const ExportModal = ({ handleCloseExportModal }: Props) => {
    const { tablesData } = useAppSelector(state => state.tables)

    const [loading, setLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedIds([]);
        } else {
            const allItemIds = tablesData.columns.map((item) => item.id);
            setSelectedIds(allItemIds);
        }
        setSelectAll(!selectAll);
    };
    const handleCheckBoxChange = (id: string) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((item) => item !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };


    const handleDownloadCSV = async () => {
        const url = process.env.REACT_APP_NEXUS_BACK_URL;
        setLoading(true)
        axios.post(
            url + `/api/v1/DynamicTables/Export/Columns`,
            {
                "tableId": tablesData.id,
                "columnIds": selectedIds
            }
        )
            .then((result) => {
                const data = result.data.result
                
                // Crear un objeto que mapee los nombres de columna a los valores en cada fila
                const columnToValueMap: any = {};
                data.columns.forEach((column: string, index: number) => {
                    columnToValueMap[column] = index;
                });

                // Procesar los datos y generar un array de arrays para las filas
                const csvData = data.data.map((row: any) => {
                    return data.columns.map((column: any) => {

                        const columnIndex = columnToValueMap[column];
                        const cell = row.find((cellData: any) => cellData.columnName === column.columnName);
                        if (cell)
                            if (typeof cell.value === 'string') {
                                return cell ? cell.value.replaceAll(";", ":") : '';
                            } else {
                                return cell ? cell.value : '';
                            }
                        else {
                            if (column.columnType === types.Bool) {

                                return cell ? cell.value : 'false';
                            } else {
                                return cell ? cell.value : '';
                            }
                        }
                    });
                });
                // Crear el contenido CSV
                const csvContent =
                    [...data.columns.map((e: any) => e.columnName)].join(";") + "\n" +
                    csvData.map((row: any) => row.join(";")).join("\n");

                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvContent));
                a.setAttribute('download', `${tablesData.name}.csv`);
                window.document.body.appendChild(a);
                a.click();
                setLoading(false)
                handleCloseExportModal()
            }).catch((err) => {
                setLoading(false)
                Swal.fire({
                    title: 'Something went wrong!',
                    text: "",
                    icon: 'error',
                    cancelButtonColor: '#d33',
                    showConfirmButton: false
                })
            });

    };
    return (
        <Paper
            elevation={6}
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: {
                    xs: '95vw',
                    sm: '90vw',
                    md: '60vw',
                    lg: '40vw',
                    xl: '30vw'
                },
                pt: 2,
                px: 4,
                pb: 3,
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: "center",
                gap: 2
            }}>
            <Box>
                <Typography variant='h3' fontSize={"2em"} fontWeight={500} textAlign={"center"}>
                    Export table
                </Typography>
                <Typography variant='h3' fontSize={"1em"}>
                    Please select the columns you want to export.
                </Typography>

            </Box>
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={loading}
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                }
                label="Select all"
            />
            <Grid
                container
                sx={{
                    maxHeight: 300,
                    overflowY: "scroll",
                }}
            >

                {tablesData.columns.map((item) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <FormControlLabel
                            sx={{ m: 0 }}
                            key={item.id}
                            control={
                                <Checkbox
                                    disabled={loading}
                                    checked={selectedIds.includes(item.id)}
                                    onChange={() => handleCheckBoxChange(item.id)}
                                />
                            }
                            label={item.name}
                        />
                    </Grid>
                ))}
            </Grid>
            <Button variant='contained' onClick={handleDownloadCSV} disabled={selectedIds.length === 0 || loading}>
                Confirm export
            </Button>
        </Paper>
    )
}
