import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const axiosInstanceWithOutHeader = axios.create();

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
        config.headers!['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'

    }
    return config
})


const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const dashboardAgent = {
    getDashboards: (tenantId: string) => requests.get<any>(url + `/api/v1/Dashboard?TenantId=${tenantId}`),
    getDashboard: (id: string) => requests.get<any>(url + `/api/v1/Dashboard/${id}`),
    postDashboards: (data: any) => requests.post<any>(url + `/api/v1/Dashboard`, data),
    postWidget: (data: any, endpoint: string) => requests.post<any>(url + `/api/v1${endpoint}`, data),
    putWidget: (data: any, endpoint: string) => requests.put<any>(url + `/api/v1${endpoint}`, data),
    desactiveWidget: (data: any) => requests.post<any>(url + `/api/v1/Widget/Desactive`, data),
    moreTimeWidget: (data: any) => requests.post<any>(url + `/api/v1/Widget/MoreTime`, data),
    putDashboard: (data: any) => requests.put<any>(url + `/api/v1/Dashboard`, data),
    deleteWidget: (id: string, endpoint: string) => requests.del<any>(url + `/api/v1${endpoint}/${id}`),
    deleteDashboard: (id: string) => requests.del<any>(url + `/api/v1/Dashboard/${id}`),

}
export default dashboardAgent