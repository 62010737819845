import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { Box, Button, Tooltip, Typography } from "@mui/material";

import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';

import { LoadingCube } from "../custom/loader/LoadingCube";
import { Link } from "react-router-dom";
import {
    deleteClient,
    editContact,
    getAllClients,
} from "../../redux/contacts/thunk";
import Swal from "sweetalert2";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { AddContact } from "./components/AddContact";
import { getAllCompanies } from "../../redux/account/thunk";
import { format, parse, isValid } from "date-fns";
import { StyledDataGrid } from "../../pages/Tables/Componentes/TableMUIX";
import { FilterColumnsArgs, GetColumnForNewFilterArgs, GridColDef, GridFilterInputValue, GridFilterModel, GridRowId, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { EditContact } from "./components/EditContact";
import { AccountsCell } from "./components/AccountsCell";
import { GridValidRowModel } from "@mui/x-data-grid";
import { AccountEditCell } from "./components/AccountEditCell";
import { Contact } from "../../redux/contacts/slice";
import { ContactModal } from "./components/ContactModal";
import { isURL } from "../../pages/Tables/Componentes/TableComponent";
import { AccountModal } from "../../pages/Accounts/Componentes/AccountModal";
import { Account } from "../../redux/account/slice";
import { ImageItemColumn } from "../../pages/Tables/Componentes/ImageItemColumn";
import moment from "moment";

const newNameClients = {
    clientNameLink: "Nombre",
    email: "Correo",
    phoneNumber: "Numero telefonico",
    workPosition: "Cargo",
    createdDate: "Creada en",
    modifiedDate: "Última modificación",
    editedBy: "Modificado por",
};

interface FilterItem {
    operator: string;
    value: string;
}
  
interface FilterModel {
items: FilterItem[];
}

interface FilterParams {
    [key: string]: string;
  }

// const getRowId = (row) => row.id;

const CustomToolbar = (rows: any[], handleCreateRow: () => void, permissions: any) => {

    return (
        <GridToolbarContainer
            sx={{
                mb: 4
            }}
        >
            {/* <GridToolbarColumnsButton /> */}
            <GridToolbarFilterButton />
            {permissions && permissions.backend.roles.includes("CanManageEntities") && (
                <Button size='small' onClick={handleCreateRow}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}
                    >
                        <TableRowsRoundedIcon fontSize='small' />
                        Add Contact
                    </Box>
                </Button>
            )}

        </GridToolbarContainer>
    );
};




export const ContactsComponent = () => {
    const { permissionsList } = useAppSelector((state) => state.permissions);



        //Filter

        const filterColumns = ({ field, columns, currentFilters } : FilterColumnsArgs) => {
            const filteredFields = currentFilters?.map((item) => item.field);
            return columns.filter(
              (colDef) =>
                colDef.filterable &&
                (colDef.field === field || !filteredFields.includes(colDef.field)),
            ).map((column) => column.field);
          };
          
          const getColumnForNewFilter = ({ currentFilters, columns } : GetColumnForNewFilterArgs) => {
            const filteredFields = currentFilters?.map(({ field }) => field);
            const columnForNewFilter = columns.find(
              (colDef) => colDef.filterable && !filteredFields.includes(colDef.field),
            );
            return columnForNewFilter?.field ?? null;
          };
    
          const getCustomFilterOperators = () => [
            {
              label: 'contains',
              value: 'contains',
              getApplyFilterFn: (filterItem : any) => {
                if (!filterItem.columnField || !filterItem.value) {
                  return null;
                }
                return (params : any) => {
                  return params.value != null
                    ? ('' + params.value)
                        .toLowerCase()
                        .includes(filterItem.value.toLowerCase())
                    : false;
                };
              },
              InputComponent: GridFilterInputValue,
            },
            {
              label: 'not contains',
              value: 'notcontains',
              getApplyFilterFn: (filterItem : any) => {
                if (!filterItem.columnField || !filterItem.value) {
                  return null;
                }
                return (params : any) => {
                  return params.value != null
                    ? !('' + params.value)
                        .toLowerCase()
                        .includes(filterItem.value.toLowerCase())
                    : true;
                };
              },
              InputComponent: GridFilterInputValue,
            },
          ];

        const [filterModel, setFilterModel] = useState<FilterModel>({ items: [] });

        const handleFilterChange = (model: GridFilterModel): void => {
            const newFilterModel: FilterModel = transformGridFilterModelToFilterModel(model);
            setFilterModel(newFilterModel);
        };

        const transformGridFilterModelToFilterModel = (model: GridFilterModel): FilterModel => {
            const items = model.items.map(item => ({
                operator: item.operator,
                value: item.value || ''
            }));
            return { items };
        };

        const getFilterParams = (filters: FilterModel): FilterParams => {
            let params: FilterParams = {};
            if (Array.isArray(filters.items)) {
                filters.items.forEach((filter: FilterItem) => {
                    if (filter.operator === 'contains') {
                        params['Name'] = filter.value;
                    } else if (filter.operator === 'notcontains') {
                        params['NameNotContains'] = filter.value;
                    }
                });
            }
            return params;
        };

        const fetchFilteredData = (page: number, pageSize: number, filters: FilterModel): void => {
            const filterParams: FilterParams = getFilterParams(filters);
            const filterQuery: string = Object.entries(filterParams).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
            dispatch(getAllClients(tenantId, pageSize, page, filterQuery));
        };
    

    //open add user modal
    const [openAddUser, setOpenAddUser] = useState(false);

    //open edit user modal
    const [openEditUser, setOpenEditUser] = useState(false);
    const [editingRow, setEditingRow] = useState<any>();

    // Selected Contact
    const [selectedContact, setSelectedContact] = useState<null | Contact>(null);
    const handleCloseContactModal = () => {
        setSelectedContact(null);
    };
    // Selected Account
    const [selectedAccount, setSelectedAccount] = useState<null | Account>(null);
    const handleCloseAccountModal = () => {
        setSelectedAccount(null);
    };

    //redux
    const { contacts: clientsState, status } = useAppSelector(
        (state) => state.contacts
    );
    const { users } = useAppSelector((state) => state.users);
    const { tenantId, id, token, nexusRoles } = useAppSelector((state) => state.auth);
    const { pageNumber, pageSize, totalCount } = useAppSelector((state) => state.contacts);

    //Pagination
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
        rowsCount: totalCount,
    });
    
    const dispatch = useAppDispatch();


    const [columns, setColumns] = useState<any[]>([]);

    const [rows, setRows] = useState<any[]>([]);


    useEffect(() => {
        if (Array.isArray(filterModel.items) && filterModel.items.length > 0) {
            fetchFilteredData(paginationModel.page, paginationModel.pageSize, filterModel);
        } else {
            dispatch(getAllClients(tenantId, paginationModel.pageSize, paginationModel.page, ''));
        }
    }, [paginationModel, filterModel]);


    useEffect(() => {
        if (permissionsList) {
            const columns = [
                permissionsList?.backend.roles.includes("CanManageEntities") === true && {
                    field: 'actions',
                    type: 'actions',
                    width: 50,
                    getActions: (params: any) => [
                        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                            // eslint-disable-next-line
                            Swal.fire({
                                title: "¿Seguro quieres borrar la fila?",
                                text: "Una vez borrada no hay vuelta atras!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Borrar",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch(
                                        deleteClient(params.row.id, tenantId, pageSize, pageNumber)
                                    );
                                }
                            });
                        }} />,
                    ],
                },
                {
                    field: "name",
                    headerName: "Contact name",
                    position: 0,
                    type: "text",
                    width: 200,
                    filterOperators: getCustomFilterOperators(),
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        // return <AccountPopUp params={params} />;
                        return (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "blue",
                                }}
                                onClick={() => {
                                    setSelectedContact(params.row);
                                }}
                            >
                                {params.row[params.field]}
                            </Box>
                        );
                    },
                },
                {
                    name: "Image",
                    field: "profileImage",
                    width: 200,
                    editable: false,
                    filterable: false, 
                    // editable: nexusRoles.includes("CanManageAccounts"),
                    renderCell: (params: any) => {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                                // id={params.row.id}
                                className="file-cell"
                            >
                                <ImageItemColumn
                                    url={params.row[params.field]}
                                ></ImageItemColumn>
                            </Box>
                        );
                    },
                    sortable: false,
                },
                {
                    name: "Birthdate",
                    field: "birthdate",
                    width: 200,
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    type: "date",
                    sortable: false,
                    valueFormatter: ({ value }: any) =>
                        value ? moment(value).format('DD/MM/YYYY') : "",
                },
                {
                    field: "workPosition",
                    headerName: "Work Position",
                    position: 1,
                    type: "text",
                    width: 200,
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    // renderCell: (params: any) => {
                    //     return <AccountPopUp params={params} />;
                    // },
                },
                {
                    field: "accounts",
                    headerName: "Accounts",
                    position: 2,
                    type: "text",
                    width: 200,
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        let accounts = params.row[params.field]
                        return <AccountsCell accounts={accounts} setAccount={setSelectedAccount}></AccountsCell>;
                    },
                    renderEditCell: (params: any) => {

                        return (
                            <AccountEditCell {...params}></AccountEditCell>
                        )
                    }

                },
                {
                    field: "countryId",
                    headerName: "Country Id",
                    position: 3,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    width: 200,
                },
                {
                    field: "address",
                    headerName: "Address",
                    width: 200,
                    position: 4,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                },
                {
                    field: "email",
                    headerName: "Email",
                    position: 5,
                    type: "text",
                    width: 150,
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                },
                {
                    field: "phoneNumber",
                    headerName: "Phone Number",
                    position: 6,
                    type: "text",
                    width: 150,
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                },
                {
                    field: "website",
                    headerName: "Website",
                    position: 7,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "linkedin",
                    headerName: "Linkedin",
                    position: 8,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "instagram",
                    headerName: "Instagram",
                    position: 9,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "facebook",
                    headerName: "Facebook",
                    position: 10,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "twitter",
                    headerName: "Twitter",
                    position: 11,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "tikTok",
                    headerName: "TikTok",
                    position: 12,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "youtube",
                    headerName: "Youtube",
                    position: 13,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "twitch",
                    headerName: "Twitch",
                    position: 14,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "threads",
                    headerName: "Threads",
                    position: 15,
                    type: "text",
                    editable: false,
                    filterable: false, 
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "notes",
                    headerName: "Notes",
                    position: 16,
                    type: "text",
                    filterable: false, 
                    editable: permissionsList?.backend.roles.includes("CanManageEntities"),
                    renderCell: (params: any) => {
                        if (!isURL(params.row[params.field]))
                            return <div>{params.row[params.field]}</div>;
                        else {
                            return (
                                <a
                                    href={
                                        params.row[params.field].startsWith(
                                            "http"
                                        )
                                            ? params.row[params.field]
                                            : `http://${params.row[params.field]
                                            }`
                                    }
                                    target="blank"
                                >
                                    {params.row[params.field]}
                                </a>
                            );
                        }
                    },
                },
                {
                    field: "createdDate",
                    headerName: "Creation date",
                    position: 17,
                    type: "date",
                    width: 150,
                    filterable: false, 
                    editable: false,
                },
                {
                    field: "modifiedDate",
                    headerName: "Modification date",
                    position: 18,
                    type: "date",
                    width: 150,
                    filterable: false, 
                    editable: false,
                },
                {
                    field: "editedBy",
                    headerName: "Modified by",
                    position: 19,
                    type: "text",
                    editable: false,
                    filterable: false, 
                    renderCell: (params: any) => {
                        return <div>{params.row[params.field]}</div>;
                    },
                },
            ];
            setColumns(columns);
            let tempRows = clientsState.map((e) => {
                return ({
                    ...e,
                    createdDate: new Date(e.createdDate),
                    modifiedDate: new Date(e.modifiedDate),
                    editedBy:
                        (
                            <Link to={`/user/${e.editedBy}`} rel="noopener">
                                {users.find((user) => user.id === e.editedBy)?.username}
                            </Link>
                        ),
                });
            });

            setRows(tempRows);
        }

    }, [clientsState, permissionsList]);


    return (
        <Paper
            sx={{
                p: { xs: 0, md: 3 }
            }}
        >

            <StyledDataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                slots={{
                    toolbar: () => CustomToolbar(rows, () => { setOpenAddUser(true) }, permissionsList)
                }}
                slotProps={{
                    filterPanel: {
                      filterFormProps: {
                        filterColumns, 
                      },
                      getColumnForNewFilter,
                    },
                  }
                }
                processRowUpdate={(params) => {
                    dispatch(editContact({
                        "id": params.id,
                        "countryId": params.countryId,
                        "name": params.name,
                        "workPosition": params.workPosition,
                        "email": params.email,
                        "address": params.address,
                        "phoneNumber": params.phoneNumber,
                        "website": params.website,
                        "linkedin": params.linkedin,
                        "instagram": params.instagram,
                        "facebook": params.facebook,
                        "twitter": params.twitter,
                        "tikTok": params.tikTok,
                        "youtube": params.youtube,
                        "twitch": params.twitch,
                        "threads": params.threads,
                        "notes": params.notes,
                        "labels": params.labels,
                        accountsIds: params.accounts.map((account: any) => account.id),
                        token: "",
                    },
                        tenantId, pageSize, pageNumber))
                    return params
                }}
                pageSizeOptions={[10, 25, 50]}
                paginationMode="server"
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={(model, details) => {
                    const updatedModel = {
                        ...model,
                        rowsCount: totalCount
                    };
                    setPaginationModel(updatedModel);
                }}
                rowCount={totalCount}  
                filterMode="server"
                onFilterModelChange={handleFilterChange}    

            />
            {openAddUser === true && (
                <AddContact open={openAddUser} setOpen={setOpenAddUser} />
            )}
            {selectedContact != null && (
                <ContactModal
                    open={selectedContact != null}
                    setOpen={handleCloseContactModal}
                    contact={selectedContact}
                ></ContactModal>
            )}

            {selectedAccount != null && (
                <AccountModal
                    open={selectedAccount != null}
                    setOpen={handleCloseAccountModal}
                    account={selectedAccount}
                ></AccountModal>
            )}
        </Paper>
    );
};
