import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'

interface Props {
    sizeValue: number,
    sx?: SxProps<Theme> | undefined
    width: {
        xs: number,
        sm: number,
        md: number,
        lg: number,
        xl: number,
    },
    height: {
        xs: number,
        sm: number,
        md: number,
        lg: number,
        xl: number,
    },
    children: React.ReactNode
}

export const GridItem = ({ sizeValue, width, height, children, sx }: Props) => {
    return (
        <Box
            className="custom-grid-item"

            sx={{
                ...sx,
                width: { xs: sizeValue * width.xs, sm: sizeValue * width.sm, md: sizeValue * width.md, lg: sizeValue * width.lg, xl: sizeValue * width.xl },
                height: { xs: sizeValue * height.xs, sm: sizeValue * height.sm, md: sizeValue * height.md, lg: sizeValue * height.lg, xl: sizeValue * height.xl },
            }}>
            {children}
        </Box>
    )
}
