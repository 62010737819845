import React from 'react'
import { Box } from "@mui/material"
import VerifiedIcon from '@mui/icons-material/Verified';

interface Props{
    feature: string
}
export const FeatureSubscription = ({feature}:Props) => {
    return (
        <Box sx={{
            backgroundColor: "#e3e2e2",
            height: 50,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 0.5
        }}>
            <VerifiedIcon color='secondary' />
            {feature}
        </Box>
    )
}
