
import { Box, Modal, IconButton, ThemeProvider, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '500px',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '5px', 
    p: 4,
};

interface UpdatePopupProps {
    setModalOpen: (open: boolean) => void;
    content: string;
    updateLink: string;
    version: string;
    imageURL: string;
  }

  export const UpdatePopup: React.FC<UpdatePopupProps> = ({ setModalOpen, content, updateLink, version, imageURL }) => {
    
    const renderHTMLContent = (htmlContent: string) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
      };

    return (
        <Modal
            open={true}
            onClose={(event, reason) => {
                setModalOpen(false);
            }}
            aria-labelledby="login-modal-title"
            aria-describedby="login-modal-description"
        >

            <Box sx={{ ...modalStyle, border: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IconButton
                    aria-label="close"
                    onClick={() => setModalOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '8px',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <style>
                    {`
                        ::-webkit-scrollbar {
                            width: 5px; 
                        }

                        ::-webkit-scrollbar-thumb {
                            background-color: #2a3042; 
                            border-radius: 10px; 
                        }

                    `}
                </style>
                <h1>Latest news</h1>
                <img src={imageURL} alt="Image" style={{display: 'block', margin: '0 auto', width: '100%', borderRadius: 5}}></img> 
                {renderHTMLContent(content)}
                <h4 style={{}}>Version: {version}</h4>
                <Button variant="contained" href={updateLink} style={{ backgroundColor: '#2a3042', color: '#fff' }}>Read More</Button>
            </Box>
        </Modal>
    );
}

