import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const EndUserAgent = {
    getAllEndUsers: (TenantId: string) => requests.get<any>(url + "/api/v1/EndUser/All?TenantId=" + TenantId + "&PageNumber=1&PageSize=10000"),
    getRecordsEndUsers: (TenantId: string) => requests.get<any>(url + "/api/v1/DataManagement/Users?id=" + TenantId),

    /*
    deletClient: (ClientId: string) => requests.del<any>(url + "/api/v1/Client/" + ClientId),
    postClient: (Client: any) => requests.post<any>(url + "/api/v1/Client", Client),
    putClient: (Client: any) => requests.put<any>(url + "/api/v1/Client", Client),
    getClientById: (id: string) => requests.get<any>(url + `/api/v1/Client/${id}`),
    getClientsByCompany: (companyId: string) => requests.get<any>(url + `/api/v1/ClientCompany?companyId=${companyId}`)
*/


}
export default EndUserAgent