import { createSlice } from "@reduxjs/toolkit";

export interface Payment {
    id: string
    price: number
    paymentMethod: string
    createdAt: string
}
export interface Price {
    paymentMethod: number
    priceMonth: number
    seatPrice: number
    tax: number
    taxAmount: number
    total: number
    pricePerSeat: number
    subTotal:number
}
export interface SubscriptionPrice {
    seatPrice: number,
    priceMonth: number
}
export interface PaymentStore {
    status: string,
    payments: Payment[],
    currentPrice: Price | null,
    subscriptionPrice: SubscriptionPrice | null

}
const initialState: PaymentStore = {
    status: "loading",
    payments: [],
    currentPrice: null,
    subscriptionPrice: null

}
export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        getAll: (state, { payload }) => {
            state.status = "loaded"
            state.payments = payload
        },
        getPrices: (state, { payload }) => {
            state.status = "loaded"
            state.currentPrice = payload
        },
        getSubscription: (state, { payload }) => {
            state.status = "loaded"
            state.subscriptionPrice = payload
        },
        loading: (state) => {
            state.status = "loading"
        }
    }
})
export const { getAll, loading, getPrices, getSubscription } = paymentSlice.actions
