import React, { useEffect, useState } from 'react'
import { TableComponent } from './Componentes/TableComponent'
import { tables } from '../Roles/interfaces/RoleInterface';
import { Box, Button } from '@mui/material';
import { PermissionsSidePanel } from '../Roles/SidePanel/PermissionsSidePanel';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';


export const Tables = () => {
    const [openTablePanel, setOpenTablePanel] = useState(false);
    const [openColumnPanel, setOpenColumnPanel] = useState<null | string>(null);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [role, setRole] = useState<string>();
    const [roles, setRoles] = useState<string[]>([]);
    const [columnId, setColumnId] = useState<string>('');
    const [lastOpenedColumnId, setLastOpenedColumnId] = useState<string>('');

    const handleClose = () => {
        setOpenTablePanel((prevOpen) => !prevOpen);
    };

    const handleCloseColumnPermission = (columnId: string) => {

        if (openColumnPanel === columnId) {

            setOpenColumnPanel(null);
        } else {

            setOpenColumnPanel(columnId);
        }

        setLastOpenedColumnId(columnId);
    };

    const handleResetLastOpenedColumnId = () => {
        setLastOpenedColumnId('');
    };

    useEffect(() => {
        if (!openTablePanel) {
            handleResetLastOpenedColumnId();
        }
    }, [openTablePanel]);

    const { id } = useParams();
    const { permissionsList } = useAppSelector((state) => state.permissions);
    
    useEffect(() => {
        if (openTablePanel) {
            handleClose();
        } 
        else if (openColumnPanel) {
            handleCloseColumnPermission(openColumnPanel);
        }
    }, [id]);
    
    useEffect(() => {
        if (permissionsList && id) {
            let permissionsData = permissionsList?.backend.tables.find((e) => e.id === id);
            if (permissionsData) {
                setRole(permissionsData.role);
                setPermissions(permissionsData.permissions);
            }
            if (permissionsList.backend.roles) setRoles(permissionsList.backend.roles);
        }
    }, [id, permissionsList]);

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                mt: 2,
                position: 'relative',
            }}
        >
            {id && (permissions.includes('CanRead') || role === 'CanManageTables') && (
                <>
                    <TableComponent
                        roles={roles}
                        handleOpenPermissions={handleClose}
                        permissionsObject={permissions}
                        handleOpenColumnPermissions={(columnId: string) => handleCloseColumnPermission(columnId)}
                        columnId={columnId}
                        setColumnId={setColumnId}
                    />
                    {id && openTablePanel && (
                        <PermissionsSidePanel dataName='data' objectName='Table' objectId={id} objectType={0} open={openTablePanel} handleClose={handleClose}></PermissionsSidePanel>
                    )}
                    {lastOpenedColumnId && (
                        <PermissionsSidePanel
                            dataName='column'
                            objectName='Column'
                            objectId={lastOpenedColumnId}
                            objectType={9}
                            open={openColumnPanel === lastOpenedColumnId}
                            handleClose={() => handleCloseColumnPermission(lastOpenedColumnId)}
                        ></PermissionsSidePanel>
                    )}
                </>
            )}
        </Box>
    );
};

