import React, { useEffect, useState } from 'react'
import { ObjectPermissions } from '../../../redux/permission/slice'
import axios from 'axios'
import { Box, IconButton, Typography } from '@mui/material'
import FaceIcon from '@mui/icons-material/Face';
interface Props {
    permission: ObjectPermissions
    handleChangeAuthority: (permission: ObjectPermissions) => void
    selectedPermission: ObjectPermissions

}
export const UserComponent = ({ permission, handleChangeAuthority, selectedPermission }: Props) => {

    const [userName, setUserName] = useState('')

    useEffect(() => {
        const url = process.env.REACT_APP_NEXUS_BACK_URL as string
        axios.get(
            url + `/api/v1/User/${permission.authorityId}`
        )
            .then((result) => {

                let userData = result.data.result
                setUserName(`${userData.name} ${userData.lastName}`)
            }).catch((err) => {
                setUserName("<Deleted>")
            });
    }, [])

    return (
        <Box
            onClick={() => handleChangeAuthority(permission)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: 1,
                p: 1,
                transition: 'all ease-in 0.1s',
                "&:hover": {
                    backgroundColor: 'rgba(0,0,0,0.1)'
                },
                backgroundColor: selectedPermission.id === permission.id ? 'rgba(33, 150, 243,0.2)' : ''
            }}
        >
            <FaceIcon />
            <Typography>
                {userName}
            </Typography>
        </Box >
    )
}
