import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface Props {
    options: any[],
    title: string,
    label: string,
    onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void
    value: any
    onLeave?: () => void
    inputValue: string
    onInputChange: (event: React.ChangeEvent<{}>, value: string, reason: string) => void;
}
export const CutomAutocomplete = ({ label, onChange, title, options, value, onLeave, inputValue, onInputChange }: Props) => {
    return (
        <Autocomplete
            value={value}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={options}
            getOptionLabel={(option) => option[title]}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label={label} onBlur={() => {
            }}
            />}

        />
    )
}
