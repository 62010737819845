import { Box, Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import EventIcon from '@mui/icons-material/Event';

interface Props {
    company: any
}
export const TenantMainCard = ({ company }: Props) => {
    return (
        <Paper
            elevation={2}
            sx={{
                width: "100%",
                minHeight: 300,
                // p: 2
            }}
        >
            <Box p={3}>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            height: 60,
                            "img": {
                                height: '100%',
                                aspectRatio: "1/1",
                                objectFit: 'contain',
                                // mixBlendMode:'color-burn'
                            }
                        }}
                    >
                        <img src={"https://tesseractwebresources.blob.core.windows.net/webimages/Logo/TesseractLogo.png"} alt="" />
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                color: "#000"
                            }}
                        >
                            {company.companyName}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: "#555b6d",
                                maxWidth: '100%',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum vel, quas suscipit accusamus facilis possimus. */}
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 2
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: 18,
                            color: "#000"
                        }}
                    >
                        Detalles de la empresa :
                    </Typography>
                    <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "#000"
                            }}
                        >
                            Rut :
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: "#000"
                            }}
                        >
                            &nbsp;{company.rut}
                        </Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "#000"
                            }}
                        >
                            Dirección :
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: "#000"
                            }}
                        >
                            &nbsp;{company.address}
                        </Typography>
                    </Grid>

                    {/*<Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: "#555b6d",
                            maxWidth: '95%',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 6,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {detail}
                    </Typography>*/}
                </Box>

                <Box
                    mt={2}
                >
                    <Box
                        sx={{ maxWidth: 200 }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                            }}
                        >
                            <EventIcon color='primary' />
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#000"
                                }}
                            >
                                Fecha de incorporación
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#555b6d",
                            }}
                        >
                            {
                                moment(company.createdDate).format("DD/MM/YYYY")
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}
