import { createSlice } from "@reduxjs/toolkit";
import { User } from "../user/slice";


export interface Label {
    id: string
    labelGroupId: string
    tag: string
    state: number
    hexColor: string
}
export interface LabelGroup {
    id: string
    name: string
    icon: string
    tenantId: string
    labels: Label[]
}
export interface LabelObject {
    labelGroup: LabelGroup
    labels: Label[]
}
export interface LabelsStore {
    status: string,
    labels: LabelObject[],

}
const initialState: LabelsStore = {
    status: "loading",
    labels: [],
}

export const labelsSlice = createSlice({
    name: 'labels',
    initialState,
    reducers: {
        setLabelsGroup: (state, { payload }) => {
            state.status = "success"
            state.labels = payload
        },
        loading: (state) => {
            state.status = "loading"
        },
        successState: (state) => {
            state.status = "success"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { loading, errorState, setLabelsGroup, successState } = labelsSlice.actions
