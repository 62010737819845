import { createSlice } from "@reduxjs/toolkit";
import { User } from "../user/slice";


export interface Role {
    id: string,
    tenantId: string,
    name: string,
    nameLocked: string,
    hexColor: string,
    canManageTables: boolean,
    canManageBlobRecords: boolean,
    canManageTenant: boolean,
    canManageUsers: boolean,
    canManageSuscriptions: boolean,
    canManageEntities: boolean,
    canManageSettings: boolean,
    canManageRoles: boolean,
    canManageReports: boolean,
    canManageAutorules: boolean,
    canManageLabels: boolean,
    createdDate: string,
    updatedDate: string,
    lastModifier: any
}

export interface roleStore {
    status: string,
    roles: Role[],
    rolesUsers: User[]
}
const initialState: roleStore = {
    status: "loading",
    roles: [],
    rolesUsers: []
}
export const roleSlice = createSlice({
    name: 'Role',
    initialState,
    reducers: {
        setAllRoles: (state, { payload }) => {
            state.status = "confirmed"
            state.roles = payload.result
        },
        setUsers: (state, { payload }) => {
            state.status = "confirmed"
            state.rolesUsers = payload.result
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAllRoles, loading, errorState, setUsers } = roleSlice.actions
