import React, { ChangeEvent, FormEvent, useState } from 'react'
import { ThemeProvider, Box, Paper, TextField, Typography, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText, FormControlLabel, Checkbox } from '@mui/material'
import { Link } from "react-router-dom"
import { isEmail } from '../../functions/EmailVerificator'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import axios from 'axios'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'

import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import { RootState } from '../../redux/store'
import { appTheme } from '../../styles/MUI/Theme'
import { loginUser } from '../../redux/auth/thunk'

export const LoginForm = () => {
    const [user, setUser] = useState({
        email: "",
        password: ""
    })
    const [emailError, setEmailError] = useState({ state: false, message: "" })
    const [passwordError, setPasswordError] = useState({ state: false, message: "" })
    const [showPassword, setShowPassword] = React.useState(false);
    const dispatch = useAppDispatch()
    const { status, errorMessage } = useAppSelector(state => state.auth)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setEmailError({ state: false, message: "" })
        setPasswordError({ state: false, message: "" })

    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isEmail(user.email)) {
            setEmailError({
                state: true, message: "Por favor ingrese un correo valido."
            })
        }
        if (!emailError.state && !passwordError.state) {
            //Action Login
            dispatch(loginUser(user))
        }
    };
    // const handleReConfirmAcc = async () => {
    //     if (!isEmail(user.email)) {
    //         setEmailError({
    //             state: true, message: "Por favor ingrese un correo valido."
    //         })
    //     }
    //     setReqStatus({ state: "loading", message: "Cargando..." })
    //     if (!emailError.state) {
    //         //Action Login
    //         axios.post(`${process.env.REACT_APP_TAUTH_URL}/api/account/confirmationemail?email=${user.email}`)
    //             .then((res) => {
    //                 setReqStatus({ state: "success", message: "" })
    //                 console.log(res);
    //             })
    //             .catch((error) => {
    //                 // console.log(error.response.data.message);
    //                 // let errorMessage = error.response.data.message
    //                 setReqStatus({ state: "error", message: "" })
    //                 setLoginError({ state: true, message: "" })
    //             })
    //     }
    //     // dispatch(startLoginWithEmailPassword(loginUser));
    // };
    if (status == "loading") {
        return (
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <LoadingCube />
            </Box>
        )
    }
    return (
        <ThemeProvider theme={appTheme}>

            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden'
                }}
            >

                <Box>
                    <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" } }}>
                        {/* <img src="https://tesseractwebresources.blob.core.windows.net/webimages/Icons/T_v2.png" alt="" /> */}
                        <Typography variant='h5' fontWeight={"400"}>
                            Inicio sesión
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <FormHelperText
                                className={status === "error" ? 'Mui-error' : ""}
                                sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                            >
                                {errorMessage}
                                {/* {
                                    errorMessage == "User not confirmed" &&
                                    <Typography sx={{ fontSize: '0.9rem', cursor: 'pointer' }} onClick={handleReConfirmAcc}>
                                        <a>Reenviar correo de confirmacion </a>
                                    </Typography>
                                } */}
                            </FormHelperText>
                        </Box>
                        <Box component="form" noValidate={false} sx={{ px: 4 }} onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo electronico"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                variant="standard"
                                onChange={handleInputChange}
                                helperText={emailError.message}
                                error={emailError.state}
                            />
                            <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password" required>Contraseña</InputLabel>
                                <Input
                                    required
                                    onChange={handleInputChange}
                                    name='password'
                                    error={passwordError.state}
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText >{passwordError.message}</FormHelperText>
                            </FormControl>

                            <Box my={2}
                                sx={{
                                    "a": { color: "#ffa400", fontWeight: 500 }
                                }}
                            >
                                <Typography sx={{ fontSize: '0.9rem' }}>
                                    ¿No tiene una cuenta? <a href={`${process.env.REACT_APP_TAUTHWEB as string}/register`}>Registrate ahora!</a>
                                </Typography>
                                <Typography sx={{ fontSize: '0.9rem' }}>
                                    <a href={`${process.env.REACT_APP_TAUTHWEB as string}/forgot-password`}>¿No puede acceder a su cuenta? </a>
                                </Typography>
                            </Box>


                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    type='submit'
                                    disabled={status === 'loading'}
                                >
                                    Iniciar sesión
                                </Button>
                            </Box>

                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
