import { createSlice } from "@reduxjs/toolkit";


export interface User {
    id: string,
    tenantId: string,
    name: string,
    lastName: string,
    gender: string,
    birthdate: string,
    phoneNumber: string,
    username: string,
    address: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    editedBy: string,
    tesseractId: string
    seatId: string,
    status: number | null
    workPosition: string,
    lastLogin: string,
    email: string,
    alias: string
}

export interface authCount {
    date: string
    userLogins: number
    userRegisters: number
}
export interface usersStore {
    status: string,
    users: User[] | [],
    currentUser: User | null,
    counts: authCount | null,
    permissions: any
}
const initialState: usersStore = {
    status: "loading",
    users: [],
    counts: null,
    currentUser: null,
    permissions: {
        canManageOtherRoles: false,
        canManageTables: true,
        canManageBlobRecords: false,
        canManageTenant: false,
        canManageUsers: true,
        canManageSuscription: false,
        canManageCompany: true,
        canManageClients: true,
        canManageSettings: false,
    }
}
export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setAllUsers: (state, { payload }) => {
            state.status = "confirmed"
            state.users = payload.result
        },
        setCurrentUser: (state, {payload}) => {
            state.status = "confirmed"
            state.currentUser = payload.result
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAllUsers, loading, errorState, setCurrentUser } = usersSlice.actions
