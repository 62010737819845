import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Tabs, Tab, Paper } from '@mui/material';
import { SeatTable } from './SeatTable';
import { OrderTable } from './OrderTable';
import { ManageCard } from './ManageCard';
import { ManageSubscription } from './ManageSuscription';

// Simulación de componentes para Orders, Seats, y Subscription
const Orders = () => <OrderTable/>;
const Seats = () => <SeatTable/>;
const Card = () => <ManageCard/>;
const Subscription = () => <ManageSubscription/>;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const SettingTabs = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const elementRef = useRef<HTMLDivElement | null>(null);
  const [sizeValue, setSizeValue] = useState<number>(0);

  const handleResize = () => {
    const element = elementRef.current;
    if (element) {
      const width = element.getBoundingClientRect().width;
      setSizeValue(width / 12);
    }
  };

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, []);

  return (
    <Paper
    sx={{
        p: { xs: 0, md: 3 },
        flexGrow: 1
    }}
>
    <Box p={2}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Orders" />
          <Tab label="Seats" />
          <Tab label="Manage Card" />
          <Tab label="Manage Subscription" />
        </Tabs>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '.custom-grid-item': {
            padding: 2,
          },
        }}
        ref={elementRef}
      >
        <TabPanel value={value} index={0}>
          <Orders />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Seats />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Card />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Subscription />
        </TabPanel>
      </Box>
    </Box>
    </Paper>
  );
};
