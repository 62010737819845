import React from 'react'
import { Box, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

interface Props {
    pathLink: string,
    title: string,
    icon: React.ReactNode,
    open: Boolean
}
export const ItemDrawer = ({ pathLink, title, icon, open }: Props) => {
    const location = useLocation()
    const path = location.pathname
    return (
        <Box
            my={1}
            sx={{
                // opacity: open ? "1" : "0",
                // display: open ? "block" : "none",
                overflow: 'hidden',
                // transition: 'ease-in 1s all'
                transition: "all 0.25s ease-in",
                "a": {
                    color: '#6a7187',
                    textDecoration: 'none',
                },
            }}
        >

            <Link to={pathLink}>
                <Box
                    px={1}
                    py={1}
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        transition: "ease-in 0.25s",
                        justifyContent: open ? 'space-between' : "center",
                        color: pathLink === path ? `#fff` : "#6a7187",
                        minHeight: '40px',
                        borderRadius: 2,
                        ":hover": {
                            // backgroundColor: "rgba(255,255,255,0.1)",
                            color: "#fff",

                        }
                    }}
                // onMouseEnter={handleOpenMenu}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}

                    >
                        {icon}
                        {
                            open &&
                            <Typography>
                                {title}
                            </Typography>
                        }
                    </Box>
                </Box>

            </Link>

        </Box >
    )
}
