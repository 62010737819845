import { FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DateType } from '../../../fields/DateType'
import { User } from './AddUser'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs';
import { isEmail } from '../../../../functions/EmailVerificator'
import { genders } from '../../../Enums/Genders'

interface Props {
    handleChange: (event: SelectChangeEvent) => void,
    handleInputChange: any,
    setNewUser: React.Dispatch<React.SetStateAction<User>>,
    newUser: User
}

//device enviroment
const userAgent = window.navigator.userAgent;
const isMobile = /Mobile/.test(userAgent);
const isTablet = /Tablet/.test(userAgent);


export const DateTypeUser = ({ value, name, onChange }: { value: any, name: any, onChange: any }) => {
    let fechaArray; // Dividir la cadena en partes separadas por "-"
    let fechaObjeto; // Crear un objeto Date con los componentes de fecha en el orden deseado
    let nuevaFechaStr;
    let formattedDate = "";
    let newValue: any = ""
    if (value === null || value === undefined) {
        newValue = null
    } else {
        try {
            fechaArray = value.split("-"); // Dividir la cadena en partes separadas por "-"
            fechaObjeto = new Date(fechaArray[2], fechaArray[1] - 1, fechaArray[0]); // Crear un objeto Date con los componentes de fecha en el orden deseado
            newValue = dayjs(fechaObjeto.toISOString().slice(0, 10))
        } catch (e) {
            newValue = dayjs(value)
        }
    }

    //age validation
    const [error, setError] = React.useState<DateValidationError | null>(null);
    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'maxDate':
            case 'minDate': {
                return 'La edad debe ser mayor a 13 años';
            }

            case 'invalidDate': {
                return 'Fecha no valida';
            }

            default: {
                return '';
            }
        }
    }, [error]);
    const currentDate = dayjs(); // Obtener la fecha actual con Day.js
    const yearsAgo13 = currentDate.subtract(13, 'year');

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
        <DatePicker
            label={`${name}`}
            value={newValue}
            disableFuture
            onChange={(val) => {
                newValue = val
                onChange({
                    target: { name: `${name}`, value: val.format('YYYY-MM-DDTHH:mm:ss.SSS') },
                })
            }
            }
            onError={(newError) => setError(newError)}
            slotProps={{
                textField: {
                    helperText: errorMessage,
                },
            }}
            maxDate={yearsAgo13}
            sx={{
                display: 'block',
                marginTop: 0,
                marginBottom: 0,
            }}
        />
    </LocalizationProvider>
}

export const BasicInfo = ({ handleChange, handleInputChange, setNewUser, newUser }: Props) => {

    return (
        <div style={{ marginBottom: 0, height:"100%", overflowY: "scroll" }}>
            <Typography variant='h4' marginLeft={2} marginBottom={2}>
                Información básica
            </Typography>
            <Typography variant='subtitle1' marginLeft={2}>
                Ingrese la información basica del nuevo usuario
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={6} >
                    <FormGroup>
                        <TextField
                            margin="normal"
                            value={newUser.name}
                            name='name'
                            label="Name"
                            onChange={handleInputChange}
                            required
                            sx={{ marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} >
                    <FormGroup>
                        <TextField
                            margin="normal"
                            value={newUser.lastName}
                            name='lastName'
                            label="LastName"
                            required
                            onChange={handleInputChange}
                            sx={{ marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} >
                    <FormGroup>
                        <TextField
                            margin="normal"
                            name="username"
                            label="Username"
                            value={newUser.username}
                            onChange={handleInputChange}
                            sx={{ marginTop: 0, marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} >
                    <FormGroup>
                        <TextField
                            margin="normal"
                            value={newUser.workPosition}
                            name='workPosition'
                            label="Work Position"
                            required
                            onChange={handleInputChange}
                            sx={{ marginTop: 0, marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} >
                    <FormGroup>
                        <TextField
                            margin="normal"
                            value={newUser.alias}
                            name='alias'
                            label="Alias"
                            required
                            onChange={handleInputChange}
                            sx={{ marginTop: 0, marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} >
                    <FormGroup>
                        <TextField
                            margin="normal"
                            value={newUser.email}
                            name='email'
                            label="Email"
                            required
                            error={!isEmail(newUser.email) && newUser.email.length > 0}
                            helperText={!isEmail(newUser.email) && newUser.email.length > 0 ? 'Ingrese un correo valido' : ''}
                            onChange={handleInputChange}
                            sx={{ marginTop: 0, marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <DateTypeUser value={newUser.birthDate} name={"birthDate"} onChange={handleInputChange} />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormControl fullWidth margin="normal" sx={{ marginTop: 0, marginBottom: 0 }}>
                            <InputLabel required >Gender</InputLabel>
                            <Select
                                required
                                value={newUser.gender}
                                label="Gender"
                                name='gender'
                                onChange={handleInputChange}
                            >
                                <MenuItem value={genders.Masculino}>Masculino</MenuItem>
                                <MenuItem value={genders.Femenino}>Femenino</MenuItem>
                                <MenuItem value={genders.Otro}>Otro</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <TextField
                            required
                            margin="normal"
                            name="phoneNumber"
                            label="Phone"
                            value={newUser.phoneNumber}
                            onChange={handleInputChange}
                            sx={{ marginTop: 0, marginBottom: 0 }}
                        />
                    </FormGroup>
                </Grid>
                


            </Grid>
        </div>
    )
}
