import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { postTable } from '../../../redux/tables/thunk';
import { postAutorule } from '../../../redux/autoRules/thunk';
import { createDashboard } from '../../../redux/dashboards/thunk';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 10,
    padding: 5,
    paddingBottom: 1
};

interface Props {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const AddDashboard = ({ open, setOpen }: Props) => {

    const { tenantId } = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()
    const [dashboard, setDashboard] = useState<any>({
        name: ""
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { name, value } = event.target;
        setDashboard({ ...dashboard, [name]: value })
    }

    const handleSubmit = () => {
        dispatch(createDashboard({
            name: dashboard.name,
            token: ""
        },
            tenantId))
        setOpen(false)
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                New Dashboard
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={dashboard.name}
                                name='name'
                                label="Dashboard Name"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                            }}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="outlined"
                            onClick={handleSubmit}
                            sx={{
                                backgroundColor: "lightblue",
                            }}>
                            Create
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
