import { createSlice } from "@reduxjs/toolkit";


export interface CPanelStore {
    status: string,
    host: string,
    port: string,
    user: string,
    password: string,
}
const initialState: CPanelStore = {
    status: "confirmed",
    host: "",
    port: "",
    user: "",
    password: "",

}
export const cpanleSlice = createSlice({
    name: 'cpanel',
    initialState,
    reducers: {
        setCPanel: (state, { payload }) => {
            state.host = payload.smtpHost
            state.port = payload.smtpPort
            state.user = payload.smtpUser
            // state.password = payload.smtpPassword
            state.status = "confirmed";
        },
        cleanCPanel: (state) => {
            state.host = ""
            state.port = ""
            state.user = ""
            state.password = ""
            state.status = "confirmed";
        },
        loading: (state) => {
            state.status = "loading";
        },
        error: (state) => {
            state.status = "error";
        },
    }
})
export const { loading, error, setCPanel, cleanCPanel } = cpanleSlice.actions
