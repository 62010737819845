import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    MenuItem,
    Tooltip,
    Box,
    Modal,
    Paper,
    Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "dayjs/locale/en-gb";
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    Grid as MuiGrid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { truncateString } from "../../functions/TruncStrings";
import { getAllEndUsers } from "../../redux/endUser/thunk";
import Swal from "sweetalert2";
import { CutomAutocomplete } from "../custom/CutomAutocomplete";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CutomMultiAutocomplete } from "../custom/CutomMultiAutocomplete";
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'dayjs/locale/en-gb';
const url = process.env.REACT_APP_NEXUS_BACK_URL;

export const DefaultQuestionAccount = ({defaultValue, handleFieldChange, tenantId, name, reset}) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchInitialAccount = async () => {
      if (defaultValue) {
        const response = await axios.get(`${url}/api/v1/PublicFeature/Accounts?TenantId=${tenantId}&Id=${defaultValue}`);
        if (response.data && response.data.result.length > 0) {
          setSelectedAccount(response.data.result.find(account => account.id === defaultValue) || null);
        }
      }
    };

    fetchInitialAccount();
  }, [defaultValue, tenantId]);

  useEffect(() => {
    getAccounts('');
  }, [tenantId]);

  const getAccounts = async (searchTerm) => {
    try {
      const response = await axios.get(`${url}/api/v1/PublicFeature/Accounts?TenantId=${tenantId}&Name=${searchTerm}`);
      setAccounts(response.data.result);
      if (defaultValue && !searchTerm) {
        const defaultAccount = response.data.result.find(account => account.id === defaultValue);
        if (defaultAccount) {
          setSelectedAccount(defaultAccount);
        }
      }
    } catch (error) {
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    getAccounts(newInputValue);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedAccount(newValue);
    handleFieldChange(name, newValue ? newValue.id : '');
  };

  return (
    <Box>
      <CutomAutocomplete
        options={accounts}
        title="name"
        label="Default Account"
        onChange={handleAutocompleteChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={selectedAccount}
      />
    </Box>
  );
};

export const DefaultQuestionContact = ({defaultValue, handleFieldChange, tenantId, name, reset}) => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchInitialAccount = async () => {
      if (defaultValue) {
        const response = await axios.get(`${url}/api/v1/PublicFeature/Contacts?TenantId=${tenantId}&Id=${defaultValue}`);
        if (response.data && response.data.result.length > 0) {
          setSelectedContact(response.data.result.find(contact => contact.id === defaultValue) || null);
        }
      }
    };

    fetchInitialAccount();
  }, [defaultValue, tenantId]);

  useEffect(() => {
    getAccounts('');
  }, [tenantId]);

  const getAccounts = async (searchTerm) => {
    try {
      const response = await axios.get(`${url}/api/v1/PublicFeature/Contacts?TenantId=${tenantId}&Name=${searchTerm}`);
      setContacts(response.data.result);
      if (defaultValue && !searchTerm) {
        const defaultAccount = response.data.result.find(account => account.id === defaultValue);
        if (defaultAccount) {
          setSelectedContact(defaultAccount);
        }
      }
    } catch (error) {
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    getAccounts(newInputValue);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedContact(newValue);
    handleFieldChange(name, newValue ? newValue.id : '');
  };

  return (
    <Box>
      <CutomAutocomplete
        options={contacts}
        title="name"
        label="Default Contact"
        onChange={handleAutocompleteChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={selectedContact}
      />
    </Box>
  );
};

export const DefaultQuestionLabel = ({defaultValue, handleFieldChange, tenantId, name, reset, column}) => {
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchInitialLabel = async () => {
      if (defaultValue) {
        const response = await axios.get(`${url}/api/v1/PublicFeature/Labels?Id=${column}&LabelId=${defaultValue}`);
        if (response.data && response.data.result.length > 0) {
          setSelectedLabel(response.data.result.labels.find(label => label.id === defaultValue) || null);
        }
      }
    };

    fetchInitialLabel();
  }, [defaultValue, tenantId]);

  useEffect(() => {
    getLabels('');
  }, [tenantId]);

  const getLabels = async (searchTerm) => {
    try {
      const response = await axios.get(`${url}/api/v1/PublicFeature/Labels?Id=${column}&Tag=${searchTerm}`);
      setLabels(response.data.result.labels);
      if (defaultValue && !searchTerm) {
        const defaultLabel = response.data.result.labels.find(label => label.id === defaultValue);
        if (defaultLabel) {
          setSelectedLabel(defaultLabel);
        }
      }
    } catch (error) {
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    getLabels(newInputValue);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedLabel(newValue);
    handleFieldChange(name, newValue ? newValue.id : '');
  };

  return (
    <Box>
      <CutomAutocomplete
        options={labels}
        title="tag"
        label="Default Label"
        onChange={handleAutocompleteChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={selectedLabel}
      />
    </Box>
  );
};

export const DefaultQuestionEndUser = ({defaultValue, handleFieldChange, tenantId, name, reset}) => {
  const [endUsers, setEndUsers] = useState([]);
  const [value, setValue] = useState(defaultValue || '');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const getAccounts = async () => {
      try {
        const response = await axios.get(url + `/api/v1/PublicFeature/EndUsers?TenantId=${tenantId}`);
        setEndUsers(response.data.result);
      } catch (error) {
      }
    };
    getAccounts();
  }, [tenantId]);

  const handleBlur = () => {
    setTouched(true);
  };

  const handleAutocompleteChange = (event, newValue) => {
    let newIdValue = newValue ? newValue.id : '';
    setValue(newIdValue);
    setTouched(true);
    handleFieldChange(name, newIdValue);
  };

  return (
    <Box>
      <CutomAutocomplete
        options={endUsers}
        title="name"
        label="Default EndUser"
        onChange={handleAutocompleteChange}
        onOpen={() => setTouched(false)}
        onClose={handleBlur}
        value={endUsers.find(account => account.id === value) || null}
        renderInput={(params) => (
          <TextField {...params} label="Default EndUser" onBlur={handleBlur} />
        )}
      />
    </Box>
  );
};

export const DefaultQuestionText = ({ defaultValue,handleFieldChange, tenantId, name, reset}) => {

  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [touched, setTouched] = useState(false);

  const handleBlur = () => {
      if (!touched) setTouched(true);
  };

  const handleInputChange = (event) => {
      const newValue = event.target.value;
      handleFieldChange(name, newValue);
      setInputValue(newValue)
  };

  return (
      <TextField
          margin="normal"
          name={'defaultValue'}
          label={'DefaultValue Text'}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          type="text"
          autoComplete="off"
      />
  );
};

export const DefaultQuestionNumber = ({ defaultValue,handleFieldChange, tenantId, name, reset}) => {

  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (reset) {
        setInputValue(''); 
        setTouched(false); 
    }
}, [reset]);

  const handleBlur = () => {
      if (!touched) setTouched(true);
  };

  const handleInputChange = (event) => {
      const newValue = event.target.value;
      handleFieldChange(name, newValue);
      setInputValue(newValue)
  };

  return (
      <TextField
          margin="normal"
          name={'defaultValue'}
          label={'DefaultValue Text'}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          type="number"
          autoComplete="off"
      />
  );
};


export const DefaultQuestionMoney = ({ defaultValue, name, handleFieldChange, tenantId, reset}) => {
  const [inputValue, setInputValue] = useState(defaultValue || '');

  const handleInputChange = (event) => {
      const newValue = event.target.value;
      // Verificar que el valor ingresado es un número válido
      if (/^-?\d*\.?\d*$/.test(newValue)) {
          setInputValue(newValue);
          if (newValue !== inputValue) {
              // Propagar los cambios usando handleFieldChange
              handleFieldChange(name, newValue);
          }
      }
  };

  return (
      <TextField
          margin="normal"
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          type="text"
          autoComplete="off"
          inputProps={{
              pattern: "^-?\\d*\\.?\\d*$",
          }}
          sx={{
              marginLeft: 2, 
              marginRight: 2,
              '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                      borderColor: '#2196f3',
                  },
              },
          }}
      />
  );
};

export const DefaultQuestionDate = ({ defaultValue, name, handleFieldChange, tenantId, reset }) => {

  const isCurrentTime = defaultValue === '[CurrentTime]';
  const [value, setValue] = useState(isCurrentTime ? null : dayjs(defaultValue));
  const [useCurrentDate, setUseCurrentDate] = useState(isCurrentTime);

  useEffect(() => {
      if (defaultValue === '[CurrentTime]') {
          setUseCurrentDate(true);
          setValue(null);
      } else if (defaultValue) {
          setUseCurrentDate(false);
          setValue(dayjs(defaultValue));
      }
  }, [defaultValue]);

  const handleDateChange = (val) => {
      setUseCurrentDate(false);
      const formattedValue = val ? val.format("YYYY-MM-DDTHH:mm:ss.SSS") : null;
      setValue(val);
      handleFieldChange(name, formattedValue);
  };

  const handleCurrentDateCheckboxChange = (event) => {
      setUseCurrentDate(event.target.checked);

      if (event.target.checked) {
          handleFieldChange(name, '[CurrentTime]');
          setValue('[CurrentTime]');
      } else {
          setValue(null);
          handleFieldChange(name, null);
      }
  };

  return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
          control={
              <Checkbox
                  checked={!useCurrentDate}
                  onChange={() => {
                      setUseCurrentDate(false); 
                      handleFieldChange(name, null); 
                      setValue(null); 
                  }}
                  name="customDate"
              />
          }
          label="Choose a date"
      />
          { !useCurrentDate && (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                value={value !== '[CurrentTime]' ? value : null}
                onChange={handleDateChange}
                renderInput={(params) => (
                <TextField
                {...params}
                fullWidth
                name={name}
                disabled={useCurrentDate}
              />
            )}
              />
          </LocalizationProvider>
          )}
          <FormControlLabel
              control={
                  <Checkbox
                      checked={useCurrentDate}
                      onChange={(event) => {
                          setUseCurrentDate(event.target.checked);
                          if (event.target.checked) {
                              handleFieldChange(name, '[CurrentTime]');
                              setValue('[CurrentTime]');
                          } else {
                              setValue(null); 
                              handleFieldChange(name, null);
                          }
                      }}
                      name="currentDate"
                  />
              }
              label="Take the current date when the report is sent"
          />
          </div>
  );
};


export const DefaultQuestionBoolean = ({ defaultValue, name, handleFieldChange, tenantId , reset}) => {
    // Convertir el string defaultValue a booleano
    const [selectedValue, setSelectedValue] = useState(defaultValue === 'true');

    const handleCheckboxChange = (e) => {
        const newValue = e.target.checked;
        setSelectedValue(newValue);

        handleFieldChange(name, newValue ? 'true' : 'false');
    };

    return (
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectedValue}
                        onChange={handleCheckboxChange}
                    />
                }
                label="Yes"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!selectedValue}
                        onChange={() => handleCheckboxChange({ target: { checked: false } })}
                    />
                }
                label="No"
            />
        </Box>
    );
};

export const DefaultQuestionDateTime = ({ defaultValue, name, handleFieldChange, tenantId , reset }) => {
  const isCurrentTime = defaultValue === '[CurrentTime]';
  const [value, setValue] = useState(isCurrentTime ? null : dayjs(defaultValue));
  const [useCurrentDate, setUseCurrentDate] = useState(isCurrentTime);

  useEffect(() => {
    if (defaultValue === '[CurrentTime]') {
      setUseCurrentDate(true);
      setValue(null);
    } else if (defaultValue) {
      setUseCurrentDate(false);
      setValue(dayjs(defaultValue));
    }
  }, [defaultValue]);

  const handleDateTimeChange = (val) => {
    setUseCurrentDate(false);
    const formattedValue = val ? val.format("YYYY-MM-DDTHH:mm:ss.SSS") : null;
    setValue(val);
    handleFieldChange(name, formattedValue);
  };

  const handleCurrentDateCheckboxChange = (event) => {
    setUseCurrentDate(event.target.checked);

    if (event.target.checked) {
      handleFieldChange(name, '[CurrentTime]');
      setValue('[CurrentTime]');
    } else {
      setValue(null);
      handleFieldChange(name, null);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!useCurrentDate}
            onChange={() => {
              setUseCurrentDate(false);
              handleFieldChange(name, null);
              setValue(null);
            }}
            name="customDate"
          />
        }
        label="Choose a date and time"
      />
      {!useCurrentDate && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            ampm={false}
            value={value !== '[CurrentTime]' ? value : null}
            timeSteps={{ minutes: 1, seconds: 1 }}
            onChange={handleDateTimeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                name={name}
                disabled={useCurrentDate}
              />
            )}
          />
        </LocalizationProvider>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={useCurrentDate}
            onChange={(event) => {
              setUseCurrentDate(event.target.checked);
              if (event.target.checked) {
                handleFieldChange(name, '[CurrentTime]');
                setValue('[CurrentTime]');
              } else {
                setValue(null);
                handleFieldChange(name, null);
              }
            }}
            name="currentDate"
          />
        }
        label="Take the current date and time when the report is sent"
      />
    </div>
  );
};

export const DefaultQuestionDateRange = ({ defaultValue, name, handleFieldChange, tenantId, reset }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [useCurrentDate, setUseCurrentDate] = useState(defaultValue === '[CurrentTime]');
  const [useCustomRange, setUseCustomRange] = useState(false);

  useEffect(() => {
    if (defaultValue && defaultValue !== '[CurrentTime]') {
      const [start, end] = defaultValue.split(';');
      setStartDate(new Date(start));
      setEndDate(new Date(end));
      setUseCustomRange(true);
      setUseCurrentDate(false);
    } else if (defaultValue === '[CurrentTime]') {
      setUseCurrentDate(true);
      setUseCustomRange(false);
    }
  }, [defaultValue]);

  const handleDateTimeChange = (date, isStart) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }

    if (startDate && endDate && !useCurrentDate) {
      const dateRangeString = `${startDate.toISOString()};${endDate.toISOString()}`;
      handleFieldChange(name, dateRangeString);
    }
  };

  const toggleCustomRange = (event) => {
    const isChecked = event.target.checked;
    setUseCustomRange(isChecked);
    setUseCurrentDate(!isChecked); // Automatically uncheck "use current date" if custom range is checked

    if (!isChecked) {
      setStartDate(null);
      setEndDate(null);
      handleFieldChange(name, '[CurrentTime]');
    } else {
      handleFieldChange(name, null);
    }
  };

  const toggleCurrentDate = (event) => {
    const isChecked = event.target.checked;
    setUseCurrentDate(isChecked);
    setUseCustomRange(!isChecked); // Automatically uncheck custom range if current date is checked

    if (isChecked) {
      handleFieldChange(name, '[CurrentTime]');
      setStartDate(null);
      setEndDate(null);
    } else {
      handleFieldChange(name, null);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: 2,
        marginRight: 80,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={useCustomRange}
            onChange={toggleCustomRange}
            name="useCustomRange"
          />
        }
        label="Choose a custom date range"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={useCurrentDate}
            onChange={toggleCurrentDate}
            name="useCurrentDate"
          />
        }
        label="Take the current date and time"
      />
      {useCustomRange && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
            <DateTimePicker
              views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
              value={startDate}
              onChange={(date) => handleDateTimeChange(date, true)}
              ampm={false}
              label="Start Date"
              timeSteps={{ minutes: 1, seconds: 1 }}
            />
            <DateTimePicker
              views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
              value={endDate}
              onChange={(date) => handleDateTimeChange(date, false)}
              ampm={false}
              label="End Date"
              timeSteps={{ minutes: 1, seconds: 1 }}
            />
          </Box>
        </LocalizationProvider>
      )}
    </Box>
  );
};
// export const DefaultQuestionDateRange = ({ defaultValue, name, handleFieldChange, tenantId,reset }) => {
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
  
//   useEffect(() => {
//     if (defaultValue) {
//       const [start, end] = defaultValue.split(';');
//       setStartDate(new Date(start));
//       setEndDate(new Date(end));
//     }
//   }, [defaultValue]);

//   const handleDateTimeChange = (date, isStart) => {
//     if (isStart) {
//       setStartDate(date);
//     } else {
//       setEndDate(date);
//     }

//     if (startDate && endDate) {
//       const dateRangeString = `${startDate.toISOString()};${endDate.toISOString()}`;
//       handleFieldChange(name, dateRangeString);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         gap: 4,
//         marginTop: 2,
//         marginRight: 80,
//       }}
//     >
//       <LocalizationProvider dateAdapter={AdapterDateFns}>
//         <DateTimePicker
//           views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
//           value={startDate}
//           onChange={(date) => handleDateTimeChange(date, true)}
//           ampm={false}
//           label="Start Date"
//           timeSteps={{ minutes: 1, seconds: 1 }}
//         />
//         <DateTimePicker
//           views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
//           value={endDate}
//           onChange={(date) => handleDateTimeChange(date, false)}
//           ampm={false}
//           label="End Date"
//           timeSteps={{ minutes: 1, seconds: 1 }}
//         />
//       </LocalizationProvider>
//     </Box>
//   );
// };

