import { Box, Grid, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { Handle, Position } from "reactflow";
import { EmailBox } from "../../Boxes/EmailBox";
import AddIcon from "@mui/icons-material/Add";
import { MiddlewareBox } from "../../Boxes/MiddlewareBox";

export function MiddleWareNode({ data, isConnectable }: any) {
    const onChange = useCallback((evt: any) => {
    }, []);
    const tableId = data.value.setting.tableId;
    
    return (
        <div className="text-updater-node" style={{ height: "100%" }}>
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={isConnectable}
                id="a"
            />
            <Grid item margin={0} key={data.value.autoruleTaskId}>
                {/* Render middleware box */}
                <MiddlewareBox content={data.value} tableId={tableId}
                    deleteComp={data.allTasks.length}
                />
            </Grid>
            {data.allTasks.length < 5 && data.allTasks.length - 1 === data.value.sequencePosition && (
                <Box
                    className={"add-btn"}
                    sx={{
                        position: "absolute",
                        color: "rgba(0, 0, 0, 0.54)",
                        left: "50%",
                        transform: "translateX(-50%)",
                        cursor: "pointer",
                    }}
                    onClick={data.onClick}
                >
                    <Tooltip title={"Agregar componente"}>
                        <AddIcon fontSize="large" />
                    </Tooltip>
                </Box>
            )}
            <Handle
                type="source"
                position={Position.Bottom}
                id="b"
                isConnectable={isConnectable}
            />

        </div>
    );
}