import { createSlice } from "@reduxjs/toolkit";
import { Account } from "../account/slice";


export interface Contact {
    "id": string,
    "tenantId": string,
    "countryId": string,
    "name": string,
    "workPosition": string,
    "email": string,
    "address": string,
    "phoneNumber": string,
    "profileImage": string,
    "website": string,
    "linkedin": string,
    "instagram": string,
    "facebook": string,
    "twitter": string,
    "tikTok": string,
    "youtube": string,
    "twitch": string,
    "threads": string,
    "notes": string,
    "labels": string,
    "createdBy": string,
    "editedBy": string,
    "createdDate": string,
    "modifiedDate": string,
    "birthdate": string,
    accounts: Account[]
}

export interface ContactByCompany {
    id: string,
    name: string
}

export interface contactStore {
    status: string,
    contacts: Contact[],
    currentContact: Contact | null,
    contactsByCompany: ContactByCompany[] | null
    totalCount: number,
    pageSize: number,
    pageNumber: number
}
const initialState: contactStore = {
    status: "loading",
    contacts: [],
    currentContact: null,
    contactsByCompany: null,
    totalCount: 0,
    pageSize: 10,
    pageNumber: 0
}
export const contactSlice = createSlice({
    name: 'Contact',
    initialState,
    reducers: {
        setAllClients: (state, { payload }) => {
            state.status = "confirmed"
            state.contacts = payload.result
            state.pageNumber = payload.pageNumber
            state.pageSize = payload.pageSize
            state.totalCount = payload.totalCount
        },
        setcurrentContact: (state, { payload }) => {
            state.status = "confirmed"
            state.currentContact = payload
        },
        setClientsByCompany: (state, { payload }) => {
            state.status = "confirmed"
            state.contactsByCompany = payload
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAllClients, setcurrentContact, loading, errorState, setClientsByCompany} = contactSlice.actions
