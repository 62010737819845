import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { format, differenceInSeconds, parseISO, addMilliseconds } from 'date-fns';

interface CountdownProps {
    targetDate: string;
    setFinished: any
    fontColor: string
    fontFamyly: string
}
export const TimerCountDown = ({ targetDate, setFinished, fontColor, fontFamyly }: CountdownProps) => {

    const calculateRemainingTime = () => {
        const targetDateTime = new Date(targetDate);
        const now = new Date();
        return Math.max(0, (targetDateTime.getTime() - now.getTime()) / 1000);
    };

    const [remainingTime, setRemainingTime] = useState<number>(calculateRemainingTime);

    useEffect(() => {
        if (remainingTime <= 0) {
            setFinished(true);
            return;
        }

        const intervalId = setInterval(() => {
            setRemainingTime(prevTime => {
                const newTime = Math.max(0, prevTime - 1);
                if (newTime <= 0) {
                    setFinished(true);
                    clearInterval(intervalId);
                }
                return newTime;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <Typography variant="h4" color={fontColor} sx={{fontFamily: fontFamyly}}>
            {remainingTime > 0 ? formatTime(remainingTime) : 'Finished'}
        </Typography>
    );
}
