import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, errorState, setTenants, successState} from "./slice";
import tenantsUserAgent from "./agent";

export const getTenantsUser = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        tenantsUserAgent.getTenantsUser()
            .then((result) => {
                dispatch(setTenants(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const putTenant = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await tenantsUserAgent.putTenant(body)
            .then((result) => {
                console.log(body)
                dispatch(getTenantsUser())
            })
            .catch((error) => {
                dispatch(errorState())
            })
    }
}