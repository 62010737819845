import React, { useEffect, useState } from 'react'
import { WidgetTimer } from '../../redux/dashboards/slice'
import axios from 'axios'
import { Box, Button, Grid, IconButton, Menu, MenuItem, Paper, Typography } from '@mui/material'
import { TimerCountDown } from './TimerCountDown'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { modifyTableRow } from '../../redux/tables/thunk'
import SettingsIcon from '@mui/icons-material/Settings';
import { deleteWidget, desactiveTimer, moreTime } from '../../redux/dashboards/thunk'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { EditTimer } from './EditTimer'
import { TimerItem } from './TimerItem'
import { RightSidePanel } from '../custom/CustomRightSidePanel'
import { Background } from 'reactflow'

export interface TimerData {
    "date": Date,
    "timeStamp": string,
    "contacts": string[],
    "status": boolean,
    "dynamicBoolFieldId": string
    columnBoolId: string
    rowId: string
    customData: string
}

interface Props {
    widget: WidgetTimer
    handleOpenRightSidePanel: (isOpen: boolean) => void;
    fontFamily: string
}
export const TimerWidget = ({ widget, handleOpenRightSidePanel, fontFamily }: Props) => {
    const { id } = useParams()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [timerData, setTimerData] = useState<TimerData[]>([])
    const [openEdit, setOpenEdit] = useState(false)
    const { token, tenantId } = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const fetchData = async () => {
        if (widget) {
            const url = process.env.REACT_APP_NEXUS_BACK_URL as string;

            try {
                const result = await axios.get(`${url}/api/v1/Widget?WidgetId=${widget.id}`);
                if (process.env.NODE_ENV === 'development')
                    console.log(result);
                if (result.data.result) {

                const sortedData = result.data.result.sort((a: any, b: any) => {
                    const now = new Date();
                    const timeA = new Date(a.timeStamp).getTime() - now.getTime();
                    const timeB = new Date(b.timeStamp).getTime() - now.getTime();
                    return timeA - timeB;
                });
                
                    setTimerData(result.data.result);                    
                }
            } catch (error) {
                if (process.env.NODE_ENV === 'development')
                    console.log(error);
            }
        }
    };

    const handleDesactive = (rowId: string) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        dispatch(desactiveTimer({
            "widgetId": widget.id,
            "rowId": rowId,
            "timeZone": timeZone
        }, id as string))
    }

    const handleMoreTime = (rowId: string, countDown: string, label: string) => {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        dispatch(moreTime({
            "widgetId": widget.id,
            "rowId": rowId,
            "timeZone": timeZone,
            "countDown": countDown,
            "label": label
        }, id as string))
    }

    const handleDeleteWidget = () => {
        Swal.fire({
            title: "Are you sure you want to delete this widget?",
            text: "Once deleted it is not possible to recover the information",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteWidget(widget.id, id as string, "/Widget"))

            }
        });
    }
    useEffect(() => {
        // Llamamos a la función fetchData al principio
        fetchData();

        // Establecemos un intervalo para llamar a fetchData cada 30 segundos
        const intervalId = setInterval(fetchData, 30000);

        // Limpiamos el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
    }, [widget]); // Dependencia del efecto

    return (
<>
            <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', fontFamily: fontFamily }}
            >
                {
                    token && <IconButton
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <SettingsIcon style={{ color: widget.backgroundColor, fontFamily: fontFamily }}/>
                    </IconButton>
                }

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => { setOpenEdit(true); handleClose() }}>Edit</MenuItem>
                    <MenuItem onClick={handleDeleteWidget}>Delete</MenuItem>
                </Menu>
            </Box>
            <Box
                sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3, fontFamily: fontFamily}}
            >

                {
                    timerData.map((e) => {

                        return (
                            <TimerItem key={e.rowId} item={e} handleDesactive={handleDesactive} handleMoreTime={handleMoreTime} timeColumnId={widget.controlColumnId} backgroundColor={widget.backgroundColor} fontColor={widget.fontColor} finishedColor={widget.finishedColor} fontFamily={fontFamily} />
                        )
                    })
                }
            </Box>
            {
                token &&
                <RightSidePanel
                handleOpen={openEdit}
                handleClose={() => setOpenEdit(false)}
                customComponent={<EditTimer dashboardId={id as string} widget={widget} />}
                />
            }
            </>
    )
}
