import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const ClientAgent = {
    getAllClients: (TenantId: string, pageSize: number, pageNumber: number, query: string) => {
        let queryString = `/api/v1/Contact?TenantId=${TenantId}&PageNumber=${pageNumber}&PageSize=${pageSize}`
        if (query) {
            queryString += `&${query}`;
          }
        return requests.get<any>(url + queryString);
    },
    deletClient: (ClientId: string) => requests.del<any>(url + "/api/v1/Contact/" + ClientId),
    postClient: (Client: any) => requests.post<any>(url + "/api/v1/Contact", Client),
    postPublicClient: (Client: any) => requests.post<any>(url + "/api/v1/PublicFeature/Contact", Client),
    putClient: (Client: any) => requests.put<any>(url + "/api/v1/Contact", Client),
    getClientById: (id: string) => requests.get<any>(url + `/api/v1/Contact/${id}`),
    getClientsByCompany: (accountId: string) => requests.get<any>(url + `/api/v1/ContactAccount?AccountId=${accountId}`)
}
export default ClientAgent