import { ChangeEvent, FormEvent, useState } from "react";
import { isEmail } from "../../functions/EmailVerificator";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ThemeProvider, Box, Paper, TextField, Typography, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText, FormControlLabel, Checkbox, Modal, useTheme } from '@mui/material'
import { appTheme } from "../../styles/MUI/Theme";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import WorkOffIcon from '@mui/icons-material/WorkOff';
import axios from "axios";

const url = process.env.REACT_APP_NEXUS_BACK_URL;

export const LoginModal = ({ setModalOpen }: { setModalOpen: (open: boolean) => void }) => {
    const [user, setUser] = useState({
        email: "",
        password: ""
    })
    const [emailError, setEmailError] = useState({ state: false, message: "" })
    const [passwordError, setPasswordError] = useState({ state: false, message: "" })
    const [showPassword, setShowPassword] = useState(false);
    const { email } = useAppSelector(state => state.auth)
    const isButtonDisabled = !user.email || !user.password;
    const [loginError, setLoginError] = useState("");

    const handleClose = () => setModalOpen(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setEmailError({ state: false, message: "" })
        setPasswordError({ state: false, message: "" })

    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        window.localStorage.removeItem("jwt");

        if (!isEmail(user.email)) {
            setEmailError({
                state: true, message: "Enter valid email"
            })
        }
        if (email !== user.email) {
            setEmailError({
                state: true, message: "Write current session credentials"
            })
        }
        try {
            if (!emailError.state && !passwordError.state && email === user.email) {
                let response = await axios.post(`${url}/api/v1/Authenticate`, user);
                if (response.status === 200) {
                    window.localStorage.setItem("jwt", response.data.result.jwTtoken)
                    handleClose()
                }
    
                setLoginError("Invalid credentials, please try again.");
              
            }
        }
        catch{
            setLoginError("Invalid credentials, please try again.")
        }
    };

    const handleExit = () => {
        window.localStorage.removeItem("tenantId");
        window.location.href = "/"
    }

    // Modal structure
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '4px solid #2a3042', 
        borderRadius: '8px', 
        boxShadow: 24,
        p: 4,
    };
    
    return (
        <ThemeProvider theme={appTheme}>
            <Modal
                open={true}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') { 
                        handleClose();
                    }
                }}
                aria-labelledby="login-modal-title"
                aria-describedby="login-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="login-modal-title" variant="h6" component="h2" align="center">
                        <WorkOffIcon sx={{ mr: 1, verticalAlign: 'middle' }} /> {/* Session expired icon */}
                        Your session has expired!
                    </Typography>
                    <Typography variant="subtitle1" component="p" align="center" sx={{ mt: 2 }}>
                        Enter your credentials to log in and continue using Nexus
                    </Typography>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {loginError && (
                        <Typography color="error" align="center">
                            {loginError}
                        </Typography>
                    )}
                    <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                variant="standard"
                                onChange={handleInputChange}
                                helperText={emailError.message}
                                error={emailError.state}
                            />
                            <FormControl sx={{ width: "100%", my: 3 }} required fullWidth variant="standard">
                                <InputLabel htmlFor="standard-adornment-password" required>Password</InputLabel>
                                <Input
                                    required
                                    onChange={handleInputChange}
                                    name='password'
                                    error={passwordError.state}
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText >{passwordError.message}</FormHelperText>
                            </FormControl>
                            <Box display="flex" justifyContent="center" mt={4}>
                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleExit}
                                    sx={{ mr: 10 }}
                                > Exit
                                </Button>

                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    color="primary" 
                                    disabled={isButtonDisabled}
                                > Login
                                </Button>
                            </Box>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};