import { Box, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import React, { ChangeEvent, useEffect, useState } from 'react'
import InputEmoji from "react-input-emoji";
import { Label, LabelGroup, LabelObject } from '../../redux/labels/slice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { UpdateLabelGroup, deleteLabelGroup } from '../../redux/labels/thunk';
import SecurityIcon from '@mui/icons-material/Security';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { ObjectPermissions, Permission } from '../../redux/permission/slice';
import Swal from 'sweetalert2';
interface Props {
    labelGroup: LabelObject
    handleSelectCurrentLabelGroup: (labelGroup: LabelObject) => void
    currentLabelGroup: LabelObject | null
    handleOpenPermissions: () => void
    permissionsObject: any
    roles: string[]
    editing: string
    setEditing: any
}

export const LabelGroupItem = ({ editing, setEditing, labelGroup, handleSelectCurrentLabelGroup, currentLabelGroup, handleOpenPermissions, permissionsObject, roles }: Props) => {
    const { tenantId } = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const [labelData, setLabelData] = useState(labelGroup.labelGroup)

    const handleChangeIcon = (value: EmojiClickData) => {
        setLabelData({ ...labelData, icon: value.emoji });
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setLabelData({ ...labelData, name: input });
    };
    const handleSave = () => {
        const data = {
            "token": "",
            "id": labelData.id,
            "icon": labelData.icon,
            "name": labelData.name
        }
        dispatch(UpdateLabelGroup(data, tenantId, setEditing))
    }

    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure you want to delete this Label group?",
            text: "Once deleted it is not possible to recover the information",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteLabelGroup(labelData.id, tenantId))
            }
        });
    }
    const handleClick = () => {
        handleSelectCurrentLabelGroup(labelGroup)
    }

    useEffect(() => {
        setLabelData(labelGroup.labelGroup)
    }, [labelGroup])

    return (
        <Paper
            elevation={currentLabelGroup?.labelGroup.id === labelGroup.labelGroup.id ? 10 : 4}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: currentLabelGroup?.labelGroup.id === labelGroup.labelGroup.id ? 3 : 2,
                width: '100%',
                cursor: 'pointer',
                transition: "all in-ease 1s"
            }}
            onClick={handleClick}
        >
            {
                editing === labelData.id ?
                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        position: 'relative',
                        width: '100%',
                        "& .react-emoji-picker--wrapper": {
                            zIndex: 1000,
                            left: 0,
                        },
                        "& .react-emoji": {
                            width: 100
                        }
                    }}>
                        {/* <TextField
                            value={labelData.icon}
                            variant='standard'
                            size='small'
                            onChange={handleChangeIcon}
                            sx={{
                                width: 30
                            }}
                        /> */}
                        {/* <InputEmoji
                            value={labelData.icon}
                            onChange={handleChangeIcon}
                            cleanOnEnter
                            // onEnter={handleOnEnter}
                            maxLength={20}

                        /> */}

                        <EmojiPicker onEmojiClick={handleChangeIcon} previewConfig={{ showPreview: false }} height={400} width={300} />
                        <Typography fontSize={30}>
                            {labelData.icon}
                        </Typography>
                        <TextField
                            value={labelData.name}
                            variant='standard'
                            size='small'
                            onChange={handleChange}
                        // sx={{
                        //     width: 30
                        // }}
                        />
                    </Box>
                    :
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography fontSize={30}>
                            {labelData.icon}
                        </Typography>
                        <Typography>
                            {labelData.name}
                        </Typography>
                    </Box>
            }

            {
                editing === labelData.id ?
                    <Box sx={{ display: 'flex', gap: 0, alignItems: 'center' }} >
                        <Box sx={{ display: 'flex' }} >
                            <Tooltip title={'Save'} onClick={handleSave}>
                                <IconButton>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Cancel'} onClick={() => { setEditing('') }}>
                                <IconButton>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    :
                    <Box
                        sx={{ display: 'flex', gap: 0 }}
                    >
                        <Tooltip title={'Permissions'} onClick={() => { handleOpenPermissions(); handleClick() }}>
                            <IconButton>
                                <SecurityIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            ((permissionsObject && permissionsObject.permissions.includes('CanUpdate')) || (roles.includes('CanManageLabels') && permissionsObject.permissions.length === 0)) &&
                            <Tooltip title={'Edit'} onClick={() => { setEditing(labelData.id) }}>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>

                        }
                        {
                            ((permissionsObject && permissionsObject.permissions.includes('CanDelete')) || (roles.includes('CanManageLabels') && permissionsObject.permissions.length === 0)) &&
                            <Tooltip title={'Delete'} onClick={handleDelete}>
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }

                    </Box>
            }


        </Paper >
    )
}
