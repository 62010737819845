import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    Grid,
    Typography,
    MenuItem,
    Tooltip,
    Box,
    Modal,
    Paper,
    Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "dayjs/locale/en-gb";
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    Grid as MuiGrid,
} from "@mui/material";
import "dayjs/locale/en-gb";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { truncateString } from "../../functions/TruncStrings";
import { getAllEndUsers } from "../../redux/endUser/thunk";
import Swal from "sweetalert2";
import { CutomAutocomplete } from "../custom/CutomAutocomplete";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from '@mui/material/Autocomplete';
import "dayjs/locale/en-gb";
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';

const url = process.env.REACT_APP_NEXUS_BACK_URL;

export const StringType = ({ value, name, onChange, tooltip, isMandatory, tenantId, reset }) => {

    const [inputValue, setInputValue] = useState(value);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        if (reset) {
            setInputValue(''); 
            setTouched(false); 
        }
    }, [reset]);

    const handleBlur = () => {
        if (!touched) setTouched(true);
    };

    const error = touched && isMandatory && !inputValue;

    const handleInputChange = (event) => {
        setInputValue(event.target.value); 
        onChange(event);
    };

    return (
        <TextField
            margin="normal"
            name={name}
            label={tooltip}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            type="text"
            required={isMandatory}
            error={error}
            autoComplete="off"
            sx={{
                marginLeft: 2, 
                marginRight: 2,
                '& .MuiOutlinedInput-root': {
                    '&.Mui-error fieldset': {
                        borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: error ? 'red' : '#2196f3',
                    },
                },
            }}
        />
    );
};

export const NumberType = ({ value, name, onChange, tooltip, isMandatory, tenantId, reset }) => {
    const [inputValue, setInputValue] = useState(value);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        if (reset) {
            setInputValue(''); 
            setTouched(false); 
        }
    }, [reset]);

    const handleBlur = () => {
        if (!touched) setTouched(true);
    };

    const handleChange = (event) => {
        const newValue = event.target.value.replace(/[^0-9]/g, '');
        setInputValue(newValue); 
        onChange(event); 
    };

    const error = touched && isMandatory && !inputValue;

    return (
            <TextField
                margin="normal"
                name={name}
                label={tooltip}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                required={isMandatory}
                autoComplete="off"
                error={error}
                sx={{
                    marginLeft: 2, 
                    marginRight: 2,
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-error fieldset': {
                            borderColor: 'red',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: error ? 'red' : '#2196f3',
                        },
                    },
                }}
            />
    );
};


export const MoneyType = ({ value, name, onChange, tooltip, isMandatory, tenantId, reset }) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        if (reset) {
            setInputValue(''); // Reinicia el valor del input
            setTouched(false); // Reinicia el estado de 'tocado'
        }
    }, [reset]);

    var number = "";

    if (/^-?\d*\.?\d*$/.test(value) || value === "") {
        number = value;
    }

    const [touched, setTouched] = useState(false);


    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (/^-?\d*\.?\d*$/.test(newValue)) {
            setInputValue(newValue);

            if (newValue !== inputValue) {
                onChange({
                    target: {
                        name: name,
                        value: newValue,
                    },
                });
            }
        }
    };

    const handleBlur = () => {
        if (!touched) setTouched(true);
    };

    const error = touched && isMandatory && !value;

    return (
        <TextField
            margin="normal"
            name={`${name}`}
            label={`${tooltip}`}
            value={number}
            onChange={handleInputChange}
            required={isMandatory}
            type="text"
            onBlur={handleBlur}
            error={error}
            autoComplete="off"
            inputProps={{
                pattern: "^-?\\d*\\.?\\d*$",
            }}
            sx={{
                marginLeft: 2, 
                marginRight: 2,
                '& .MuiOutlinedInput-root': {
                    '&.Mui-error fieldset': {
                        borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: error ? 'red' : '#2196f3',
                    },
                },
            }}
        />
    );
};

export const BooleanType = ({ value, onChange, tooltip, isMandatory, tenantId, reset }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        if (reset) {
            setSelectedValue(false);
        }
    }, [reset]);

    const handleCheckboxChange = (e) => {
        const newValue = e.target.value;
        setSelectedValue(newValue);
        onChange({
            target: {
                name: tooltip,
                value: newValue === 'true', 
            },
        });
    };

    return (
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        required={isMandatory}
                        checked={selectedValue === 'true'}
                        onChange={handleCheckboxChange}
                        value="true"
                    />
                }
                label="Yes"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        required={isMandatory}
                        checked={selectedValue === 'false'}
                        onChange={handleCheckboxChange}
                        value="false"
                    />
                }
                label="No"
            />
        </Box>
    );
};


export const FileHandle = ({ value, name, onChange, tooltip, isMandatory, tenantId, reset}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(value?.name || "");
    const fileInputId = `file-input-${name}`;
    useEffect(() => {
        if (reset) {
            setSelectedFile(null); 
            setFileName("");
        }
    }, [reset]);

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB
        //const formData = new FormData();
        //formData.append(name, selectedFile);
        if (file && file.size > maxSizeInBytes) {
            Swal.fire({
                title: "File exceeds limits",
                text: "The file must not be more than 25 MB",
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Back",
                zIndex: 100000,
            });

            event.target.value = null; // Borra la selección del archivo
        } else {
            const allowedExtensions = [
                ".jpg",
                ".jpeg",
                ".png",
                ".pdf",
                ".xlsx",
                ".csv",
                ".xslm",
                ".docx",
                ".txt",
                ".doc ",
            ]; // Agrega las extensiones que desees permitir
            const fileExtension = file.name.slice(
                ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
            );

            if (allowedExtensions.includes(`.${fileExtension}`)) {
                setSelectedFile(file || null);
                setFileName(file?.name || "");
                onChange({
                    target: { name: `${name}`, value: file },
                });
            } else {
                Swal.fire({
                    title: "File type not allowed",
                    text: `Los tipos permitidos son: ${allowedExtensions
                        .map((e) => `"${e}"`)
                        .join(", ")}`,
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Back",
                    zIndex: 100000,
                });
            }
        }
    };
    const handleDeleteFile = () => {
        setSelectedFile(null);
        setFileName("");
    };

    return (
        <Grid container spacing={2} alignItems="center" sx={{ marginLeft: 2, marginRight: 2 }}>
            <Grid item xs={12}>
                <Typography variant="subtitle1">{tooltip}</Typography>
            </Grid>
            {selectedFile ? (
                <Grid container spacing={2} alignItems="center">
                    <Tooltip title={fileName}>
                        <Typography variant="body2" noWrap>
                            {fileName}
                        </Typography>
                    </Tooltip>
                    <IconButton onClick={handleDeleteFile}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            ) : (
                <Grid item>
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                    >
                        Upload File
                        <input
                            id={fileInputId}
                            hidden
                            type="file"
                            onChange={handleFileChange}
                            required={isMandatory}
                        />
                    </Button>
                </Grid>
            )}
            {isMandatory && !selectedFile && (
                <Typography color="error" sx={{ml:2}}>File is required.</Typography>
            )}
        </Grid>
    );
};


export const ClientsDropDown = ({ value, name, onChange, tooltip, isMandatory, tenantId,reset }) => {
    const [contacts, setContacts] = useState([]);
    const [newContact, setNewContact] = useState({
        name: "",
        tenantId: tenantId
    });
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const autocompleteRef = useRef(null);
    const [openOptions, setOpenOptions] = useState(false);

    useEffect(() => {
        if (inputValue) {
            setOpenOptions(true);
        } else {
            setOpenOptions(false);
        }
    }, [inputValue]);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                let response;
                if (!inputValue) {
                    response = await axios.get(`${url}/api/v1/PublicFeature/Contacts?TenantId=${tenantId}`);
                } else {
                    response = await axios.get(`${url}/api/v1/PublicFeature/Contacts?TenantId=${tenantId}&Name=${inputValue}`);
                }
                setContacts(response.data.result);
            } catch (error) {
                console.error("Error get contacts:", error);
            }
        };

        const debouncedFetchContacts = debounce(fetchContacts, 500);

        if (inputValue || inputValue === "") {
            debouncedFetchContacts();
        }
    

        return () => {
            debouncedFetchContacts.cancel();
        };
    }, [tenantId, inputValue]); 


    const handleAutocompleteInputChange = (event, newInputValue, reason) => {
        if (reason === "input") {
            setInputValue(prevInputValue => {
                return newInputValue;
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewContact({ ...newContact, [name]: value === "" ? null : value });
    };

    const handleSubmit = async () => {

        const contactData = {
            name: newContact.name,
            tenantId: tenantId
        };
    
        if (newContact.email) contactData.email = newContact.email;
        if (newContact.phoneNumber) contactData.phoneNumber = newContact.phoneNumber;
        if (newContact.birthdate) contactData.birthdate = dayjs(newContact.birthdate).format("YYYY-MM-DDTHH:mm:ss.SSS");

        try {
            // Envías los datos del nuevo contacto al backend
            let response = await axios.post(`${url}/api/v1/PublicFeature/Contact`, newContact);
    
            const addedContact = {
                id: response.data.result,
                name: newContact.name, 
            };
    
            setContacts(prevContacts => [addedContact, ...prevContacts]);

            onChange({
                target: {
                    name: name,
                    value: [...value, addedContact],
                },
            });
    
            setNewContact({ name: "", tenantId: tenantId });
            setOpen(false);
        } catch (error) {
            console.error("Error add contact:", error);
        }
    };
    const handleMultiAutocompleteChange = (event, newValue) => {
        onChange({
            target: {
                name: name,
                value: newValue,
            },
        });

        setInputValue("");
    };

    const renderDatePicker = () => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Contact BirthDate"
                value={newContact.birthdate ? dayjs(newContact.birthdate, "YYYY-MM-DDTHH:mm:ss.SSS") : null}
                onChange={(val) => {
                    setNewContact({
                        ...newContact,
                        birthdate: val ? val.format("YYYY-MM-DDTHH:mm:ss.SSS") : null
                    });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
            />
        </LocalizationProvider>
    );


    useEffect(() => {
        if (reset) {
            setContacts([]);
            value = []
        }
    }, [reset]);

    const displayError = isMandatory && (!value || value.length === 0);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {displayError && (
                <Typography color="error" sx={{ mt: 2, mb: -2 }}>
                   Contact is required.
                </Typography>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* <CutomMultiAutocomplete
                options={contacts}
                title="name"
                label={`${tooltip}`}
                onChange={handleMultiAutocompleteChange}
                value={value}
            /> */}
            <Autocomplete
                ref={autocompleteRef}
                open={openOptions}
                onOpen={() => {
                    if (autocompleteRef.current) {
                        autocompleteRef.current.focus();
                    }
                }}
                onClose={() => {
                    setOpenOptions(false)
                }}
                onClickCapture={() => {
                    setOpenOptions(true)}}           
                multiple
                fullWidth
                id="contacts-autocomplete"
                options={contacts}
                getOptionLabel={(option) => option.name}
                value={value || []}
                onChange={handleMultiAutocompleteChange}
                inputValue={inputValue}
                onInputChange={handleAutocompleteInputChange}
                renderInput={(params) => <TextField {...params} label="Contacts" />}
            />

            <IconButton onClick={() => setOpen(true)} sx={{ alignSelf: "normal"}}>
                <AddIcon style={{ color: "#2196f3", fontSize: '40px'}}/>
            </IconButton>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Paper sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", p: 4, width: "auto", display: "flex", flexDirection: "column", gap: 2 }} elevation={4}>
                    <Typography variant="h6" component="h2">New Contact</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                value={newContact.name}
                                name="name"
                                label="Contact Name"
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                value={newContact.email || ""}
                                name="email"
                                label="Contact Email"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                value={newContact.phoneNumber || ""}
                                name="phoneNumber"
                                label="Phone Number"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderDatePicker()}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={!newContact.name}
                                fullWidth
                            >
                                Add Contact
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
            </Box>
    );
};


export const CompanyDropDown = ({ value, name, onChange, tooltip, isMandatory, tenantId, reset}) => {

    const [accounts, setAccounts] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);

    useEffect(() => {
        fetchAccounts('');
    }, [tenantId]);

    useEffect(() => {
        // Busca el account seleccionado en la lista actualizada
        const account = accounts.find(account => account.id === value);
        setSelectedAccount(account || null);
    }, [accounts, value]);

    const fetchAccounts = async (searchTerm) => {
        try {
            const response = await axios.get(`${url}/api/v1/PublicFeature/Accounts?TenantId=${tenantId}&Name=${searchTerm}`);
            setAccounts(response.data.result);
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        fetchAccounts(newInputValue);
    };

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedAccount(newValue); // Actualiza el estado con el account seleccionado
        let newIdValue = newValue ? newValue.id : "";
        onChange({
            target: {
                name: name,
                value: newIdValue,
            },
        });
    };

    useEffect(() => {
        if (reset) {
            setSelectedAccount(null);
            onChange({ 
                target: {
                    name: name,
                    value: "",
                },
            });
        }
    }, [reset, name, onChange]);

    const [touched, setTouched] = React.useState(false);

    const handleBlur = () => {
        setTouched(true);
        if (!value) {
            onChange({
                target: {
                    name: name,
                    value: "", 
                },
            });
        }
    };

    return (
        <Box>
        {isMandatory && !value && (
            <Typography color="error" sx={{ mt: 2, mb: 1 }}>
                Account is required.
            </Typography>
        )}
        <CutomAutocomplete
            options={accounts}
            title="name"
            label={`${tooltip}`}
            onChange= {handleAutocompleteChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            value={accounts.find(account => account.id === value) || null}
        />
        </Box>
    );
};

export const EndUserDropDown = ({ value, name, onChange, tooltip, isMandatory, tenantId, reset}) => {

    const [endUser, setEndUsers] = useState([]);

    useEffect(() => {
        const getEndUsers = async () => {
            let req = await axios.get(
                url + `/api/v1/PublicFeature/EndUsers?TenantId=${tenantId}`
            );
            let endUser = req.data.result;
            setEndUsers(endUser);
        };
        getEndUsers();
    }, []);

    useEffect(() => {
        if (reset) {
            onChange({
                target: {
                    name: name,
                    value: '',
                },
            });
        }
    }, [reset, name, onChange]);

    const handleChange = (event) => {
        let newValue = event.target.value;
        onChange({
            target: {
                name: name,
                value: newValue,
            },
        });
    };

    return (
        <Box sx={{ marginRight: 4, marginTop: 0 }}>
            {isMandatory && !value && (
                <Typography color="error" sx={{ mt: 0, mb: 2, marginLeft: 2 }}>
                    EndUser is required.
                </Typography>
            )}
            <TextField
                fullWidth
                margin="normal"
                select
                label={tooltip}
                name={name}
                variant="standard"
                value={value || ''} // Aquí usamos value en lugar de defaultValue
                onChange={handleChange}
                sx={{ marginLeft: 2, marginRight: 2, marginTop: 0 }}
            >
                {endUser.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export const LabelDropDown = ({ value, name, onChange, tooltip, isMandatory, column, reset}) => {
    const [labels, setLabels] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedLabel, setSelectedLabel] = useState(null);

    useEffect(() => {
        fetchLabels('');
    }, [column]);

    useEffect(() => {
        const label = labels.find(label => label.id === value);
        setSelectedLabel(label || null);
    }, [labels, value]);

    const fetchLabels = async (searchTerm) => {
        try {
            const response = await axios.get(`${url}/api/v1/PublicFeature/Labels?Id=${column}&Tag=${searchTerm}`);
            setLabels(response.data.result.labels);
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        fetchLabels(newInputValue);
    };

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedLabel(newValue);
        let newIdValue = newValue ? newValue.id : "";
        onChange({
            target: {
                name: name,
                value: newIdValue,
            },
        });
    };

    useEffect(() => {
        if (reset) {
            setSelectedLabel(null);
            onChange({ 
                target: {
                    name: name,
                    value: "",
                },
            });
        }
    }, [reset, name, onChange]);

    return (
        <Box sx={{ marginRight: 4, marginTop: 0 }}>
            {isMandatory && !value && (
                <Typography color="error" sx={{ mt: 0, mb: 2, marginLeft: 2 }}>
                    Label is required.
                </Typography>
            )}
        <CutomAutocomplete
            options={labels}
            title="tag"
            label={`${tooltip}`}
            onChange= {handleAutocompleteChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            value={labels.find(account => account.id === value) || null}
        />
        </Box>
    );
};
