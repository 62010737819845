import { Button } from '@mui/material'
import React from 'react'

interface Props {
    label: string
    onClick: (() => void) | undefined
    color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined
    type: "button" | "reset" | "submit" | undefined
    disabled: boolean
}
export const CustomButton = ({ label, onClick, color, type, disabled }: Props) => {
    return (
        <Button onClick={onClick}
            color={color}
            sx={{
                textTransform: "none",
            }}
            type={type}
            variant='contained'
            disabled={disabled}
        >
            {label}
        </Button>
    )
}
