import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Swal from "sweetalert2";
import {
    cleanData,
    deleting,
    errorState,
    loading,
    loadingData,
    readyState,
    setCurrentTableName,
    setTables,
    setTablesColumns,
    setTablesData,
    setTablesName,
} from "./slice";
import TablesAgent from "./agent";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissions } from "../permission/thunk";

//table
export const setVoidAll = () => {
    return async (dispatch) => {
        dispatch(loading());

        dispatch(cleanData());
    };
};

export const getTablesNames = (tenantId) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.getTablesName(tenantId)
            .then((response) => {
                dispatch(getPermissions());
                dispatch(setTablesName(response));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const getTableNameById = (tableId) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.getTableNameById(tableId)
            .then((response) => {
                dispatch(setCurrentTableName(response));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const postTable = (body) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.posTable(body)
            .then((response) => {
                if (!response.success) {
                    Swal.fire({
                        title: "Something went wrong",
                        text: response.message,
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
                dispatch(getTablesNames(body.tenantId));
            })
            .catch((error) => {
                if (!error.response.data.success) {
                    Swal.fire({
                        title: "Something went wrong",
                        text: `Message: ${error.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    });
                }
                dispatch(errorState());
            });
    };
};

export const getTables = (tenantId) => {
    return async (dispatch) => {
        dispatch(loading());

        await TablesAgent.getTablesName(tenantId)
            .then((response) => {
                dispatch(setTablesName(response));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const delTables = (body) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.delTable(body.tableId)
            .then((response) => {
                dispatch(setVoidAll());
                dispatch(getTablesNames(body.tenantId));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const modifyTable = (body) => {
    return async (dispatch) => {
        dispatch(loading());

        await TablesAgent.putTable(body)
            .then((response) => {
                dispatch(getTablesNames(body.tenantId));
                dispatch(getTableNameById(response));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const modifyColumnPosition = (body) => {
    return async (dispatch) => {
        // dispatch(loading());

        await TablesAgent.putColumnPosition(body)
            .then((response) => {
                // dispatch(getTablesNames(body.tenantId));
                // dispatch(getTableNameById(response.result));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

//table column
// export const getTablesColumns = (tableId) => {
//     return async (dispatch) => {
//         dispatch(loading());
//         await TablesAgent.getTablesColumns(tableId)
//             .then((response) => {
//                 dispatch(setTablesColumns(response));
//                 return response
//             })
//             .catch((error) => {
//                 ////
//                 dispatch(errorState());
//             });
//     };
// };

export const getTablesColumns = (tableId) => {
    return async (dispatch) => {
        dispatch(loading());
        try {
            const response = await TablesAgent.getTablesColumns(tableId);
            dispatch(setTablesColumns(response));
            return response; // Devuelve la respuesta para poder capturarla en el then
        } catch (error) {
            dispatch(errorState());
            throw error; // Puedes relanzar el error si es necesario
        }
    };
};

export const postTableColumn = (body, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.postTableColumn(body)
            .then((response) => {
                // dispatch(getTablesColumns(body.tableId));
                dispatch(getTablesData(body.tableId, pageNumber, pageSize));

                // window.location.href = `/main/tables/dynamicTable/${body.tableId}`;
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const modifyTableColumnName = (body, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.putTableColumnName(body)
            .then((response) => {
                // window.location.href = `/main/tables/dynamicTable/${body.tableId}`;
                dispatch(getTablesColumns(body.tableId));
                dispatch(getTablesData(body.tableId, pageNumber, pageSize));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const delTableColumn = (body, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.delTableColumn(body.columnId)
            .then((response) => {
                dispatch(getTablesColumns(body.tableId));
                dispatch(getTablesData(body.tableId, pageNumber , pageSize));
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

//table row
export const getTablesData = (tableId, pageNumber, pageSize, filters, logicOperator) => {
    return async (dispatch) => {
        // dispatch(cleanData());
        dispatch(loadingData());

        await TablesAgent.getTablesDataBytableId(tableId, pageNumber, pageSize, filters, logicOperator)
            .then(async (response) => {
                dispatch(setTablesData(response));
            })
            .catch((error) => {
                //
                dispatch(errorState());
            });
    };
};

export const postTableRowField = (body, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.postTableRow(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, pageNumber , pageSize, '', ''));
                dispatch(readyState());
            })
            .catch((error) => {
                dispatch(getTablesData(body.tableId, pageNumber ,pageSize, '', ''));
                dispatch(errorState());
            });
    };
};

export const postTableRowFileField = (body, handleClose, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.postFileRow(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, pageNumber , pageSize, '', ''));
                handleClose();
            })
            .catch((error) => {
                // //
                dispatch(errorState());
            });
    };
};

export const modifyTableRow = (body, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.putTableRow(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, pageNumber ,pageSize, '', ''));
                dispatch(readyState());
            })
            .catch((error) => {
                ////
                dispatch(getTablesData(body.tableId, pageNumber ,pageSize, '', ''));
                dispatch(errorState());
            });
    };
};

export const modifyTableRowFileField = (body, handleClose, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.modifyFileRow(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, pageNumber ,pageSize, '', ''));
                handleClose();
            })
            .catch((error) => {
                dispatch(errorState());
            });
    };
};

export const delSingleTableRow = (body) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.delTableRow(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, 0 ,10, '', ''));
                dispatch(readyState());
            })
            .catch((error) => {
                ////
                dispatch(errorState());
            });
    };
};

export const delTableRow2 = (rowId, tableId) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.delTableRow2(rowId)
            .then((response) => {
                dispatch(getTablesData(tableId, 0 ,10, '', ''));
                dispatch(readyState());
            })
            .catch((error) => {
                //
                dispatch(errorState());
            });
    };
};

export const delTableRow = (body, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.delTableRow(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, pageNumber ,pageSize, '', ''));
            })
            .catch((error) => {
                //
                dispatch(errorState());
            });
    };
};

export const createTableRow = (tableId, lastPosition, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.createTableRow({
            tableId: tableId,
            position: lastPosition,
        })
            .then(async (response) => {
                dispatch(getTablesData(tableId, pageNumber ,pageSize, '', ''));
                // dispatch(setTablesData(response));
            })
            .catch((error) => {
                //
                dispatch(errorState());
            });
    };
};

export const delMultipleTableRow = (body, tableId, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(deleting());
        await TablesAgent.delMultipleTableRow(body)
            .then((response) => {
                //dispatch(delTableRow2(body.rowId, body.tableId));
                dispatch(getTablesData(tableId, pageNumber , pageSize, '', ''));
                //dispatch(readyState())
            })
            .catch((error) => {
                //
                dispatch(errorState());
            });
    };
};

export const importCSVThunk = (body, closeModal) => {
    return async (dispatch) => {
        dispatch(loading());
        await TablesAgent.importCSV(body)
            .then((response) => {
                dispatch(getTablesData(body.tableId, 0 ,10, '', ''));
                closeModal();
                // dispatch(readyState());
            })
            .catch((error) => {
                //
                dispatch(errorState());
            });
    };
};
