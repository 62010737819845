import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Button, Grid as MuiGrid, Typography, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { Box } from "@mui/material";
import { LoadingCube } from "../../../custom/loader/LoadingCube";
import { getAllUsers, modifyUser, removeUser } from "../../../../redux/user/thunk";
import { AddUser } from "./AddUser";
import { getAllRoles } from "../../../../redux/roles/thunk";
import { useNavigate } from "react-router-dom";
import { setSubscription } from "../../../../redux/subscriptionPayment/slice";
import { cancelSeats, getSeats } from "../../../../redux/tenant/thunk";
import Swal from "sweetalert2";
import { StyledDataGrid } from "../../../../pages/Tables/Componentes/TableMUIX";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridActionsCellItem,
} from "@mui/x-data-grid";
import axios from "axios";

const newNameUsers = {
    id: "Id",
    tesseractId: "Tesseract ID",
    status: "Status",
    name: "Nombre",
    lastName: "Apellido",
    username: "Username",
    email: "Email",
    birthDateDisplay: "Cumpleaños",
    gender: "Genero",
    phoneNumber: "Teléfono",
    createdDate: "Creada en",
    modifiedDate: "Última modificación",
};

const CustomToolbar = (
    permissions,
    isOwner,
    handleCreateRow,
    permissionsList
) => {
    const { token } = useAppSelector((state) => state.auth);
    const { tenant } = useAppSelector((state) => state.tenant);
    const [seats, setSeats] = useState({
        used: 0,
        available: 0,
    });
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/Tenant/seat/${tenant?.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setSeats({
                    used: response.data.result.seatsOcuped,
                    available: response.data.result.seatsAvailable,
                });
            })
            .catch((error) => {});
    }, []);
    return (
        <GridToolbarContainer
            sx={{
                mb: 4,
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            {/* <GridToolbarColumnsButton /> */}
            <Box>
                <GridToolbarFilterButton />
                {permissionsList &&
                    permissionsList.backend.roles.includes(
                        "CanManageEntities"
                    ) && (
                        <Button size="small" onClick={handleCreateRow}>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <TableRowsRoundedIcon fontSize="small" />
                                Add User
                            </Box>
                        </Button>
                    )}
            </Box>
            <Box>Seats available: {seats.available}</Box>
        </GridToolbarContainer>
    );
};

export const UserTableComponent = () => {
    //open add user modal
    const [openAddUser, setOpenAddUser] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const { permissionsList } = useAppSelector((state) => state.permissions);

    //redux
    const {
        users: usersState,
        status,
        permissions,
    } = useAppSelector((state) => state.users);
    const { tenantId, suscription, isOwner } = useAppSelector(
        (state) => state.auth
    );
    const {
        seatsCounter: seatsCounterState,
        purchasedSeats: purchasedSeatsState,
    } = useAppSelector((state) => state.tenant);
    const [seatsCounter, setSeatsCounter] = useState(seatsCounterState);
    const [purchasedSeats, setPurchasedSeats] = useState(purchasedSeatsState);
    const [users, setUsers] = useState(usersState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const getRowId = (row) => row.id;

    const validateLength = (params) => {
        const value = params.props.value;
        const maxLength = 50; 
        const isValid = value.length <= maxLength;
        return { ...params.props, error: !isValid };
    };

    //data table

    const columns = [
        {
            field: "actions",
            disableColumnMenu: true,
            type: "actions",
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => {
                        // eslint-disable-next-line
                        Swal.fire({
                            title: "Are you sure you want to delete the user?",
                            text: "Once deleted you will not have access!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Delete",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                dispatch(
                                    removeUser(params.row.id, tenantId)
                                );
                            }
                        });
                    }}
                />,
            ],
        },
        {
            field: "id",
            headerName: "Id",
            position: 0,
            type: "text",
            editable: false,
        },
        {
            field: "tesseractId",
            headerName: "Tesseract id",
            position: 1,
            type: "text",
            editable: false,
        },
        {
            field: "gender",
            headerName: "Gender",
            position: 2,
            type: "singleSelect",
            valueOptions: [
                { value: 1, label: "Male" },
                { value: 2, label: "Female" },
                { value: 3, label: "Other" },
            ],
            editable: false,
        },
        {
            field: "name",
            headerName: "Name",
            position: 3,
            type: "text",
            editable: false,
        },
        {
            field: "lastName",
            headerName: "Lastname",
            position: 4,
            type: "text",
            editable: false,
        },
        {
            field: "email",
            headerName: "Email",
            position: 5,
            type: "text",
            editable: false,
            width: 200,
        },
        {
            field: "username",
            headerName: "Username",
            position: 6,
            type: "text",
            editable: false,
            width: 200,
        },
        {
            field: "workPosition",
            headerName: "Work Position",
            position: 7,
            type: "text",
            editable: true,
            width: 200,
            preProcessEditCellProps: validateLength,
        },
        {
            field: "alias",
            headerName: "Alias",
            position: 8,
            type: "text",
            editable: true,
            width: 200,
            preProcessEditCellProps: validateLength,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 200,
            position: 9,
            type: "text",
            editable: false,
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            position: 10,
            type: "singleSelect",
            valueOptions: [
                { value: 0, label: "Default" },
                { value: 1, label: "Active" },
                { value: 2, label: "Pending" },
                { value: 3, label: "Deleted" },
                { value: 4, label: "Inactive" },
                { value: 5, label: "Await" },
            ],
            editable: false,
        },
        {
            field: "birthDate",
            headerName: "Birthdate",
            position: 11,
            type: "date",
            editable: false,
        },
        {
            field: "createdDate",
            headerName: "Creation date",
            position: 12,
            type: "date",
            width: 150,
            editable: false,
        },
        {
            field: "modifiedDate",
            headerName: "Edition date",
            position: 13,
            type: "date",
            width: 150,
            editable: false,
        },
    ];

    const [rows, setRows] = useState(users);

    const PaySeat = (user) => {
        dispatch(setSubscription(suscription));
        navigate(
            `/subscriptions/payment/method?type=seat&seatId=${user.seatId}&userId=${user.id}`
        );
    };

    const handleRowUpdate = async (newRow, oldRow) => {
        try {
            const modifiedUser = { ...newRow };
            const updateUser = {
                "tenantId": tenantId,
                "id": modifiedUser.id,
                "alias": modifiedUser.alias,
                "workPosition": modifiedUser.workPosition,
                "token": window.localStorage.getItem("jwt")
            }
            dispatch(modifyUser(updateUser)); 
            return newRow;
        } catch (error) {
            return oldRow; 
        }
    };

    useEffect(() => {
        setPurchasedSeats(purchasedSeatsState);
        setSeatsCounter(seatsCounterState);
    }, [purchasedSeatsState, seatsCounterState]);

    useEffect(() => {
        dispatch(getAllUsers(tenantId));
        dispatch(getAllRoles(tenantId));
        dispatch(getSeats(tenantId));
    }, []);

    useEffect(() => {
        let tempRows = usersState.map((e) => {
            return {
                ...e,
                birthDate: new Date(e.birthDate),
                createdDate: new Date(e.createdDate),
                modifiedDate: new Date(e.modifiedDate),
            };
        });
        setRows(tempRows);
    }, [usersState]);

    return (
        <Paper
            sx={{
                p: { xs: 0, md: 3 },
            }}
        >
            {/* {permissions.canManageUsers === true && isOwner && (
                <>
                    <div
                        style={{
                            margin: "0.5vw",
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => setOpenAddUser(true)}
                        >
                            Nuevo usuario
                            <PersonAddAltIcon />
                        </Button>
                        <MuiGrid>
                            <Typography
                                alignContent="flex-start"
                                marginRight={2}
                            >
                                Sillas disponibles: {seatsCounter}
                            </Typography>
                            <Typography
                                alignContent="flex-start"
                                marginRight={2}
                            >
                                Sillas compradas: {purchasedSeats}
                            </Typography>
                        </MuiGrid>
                    </div>
                </>
            )} */}

            <StyledDataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                processRowUpdate={handleRowUpdate}
                ex
                slots={{
                    toolbar: () =>
                        CustomToolbar(
                            permissions,
                            isOwner,
                            () => setOpenAddUser(true),
                            permissionsList
                        ),
                }}
            />
            {openAddUser === true && (
                <AddUser open={openAddUser} setOpen={setOpenAddUser} />
            )}
        </Paper>
    );
};