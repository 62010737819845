import { Avatar, Box, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ContactByCompany } from '../../redux/contacts/slice'

interface Props {
    members: ContactByCompany[]
}
export const MembersListCard = ({ members }: Props) => {
    const navigate = useNavigate()
    return (
        <Paper
            elevation={2}
            sx={{
                width: "100%",
                height: '100%',

            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2,
                    maxHeight: 350,
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: 16
                    }}
                >
                    Listado de miembros
                </Typography>
                <Box
                    sx={{
                        overflowY: "scroll"
                    }}
                >

                    {
                        members !== null
                            ? members.map((member, zIndex) => {
                                return (
                                    <Box
                                        py={0.5}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => { navigate(`/client/${member.id}`) }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Avatar alt={member.name} src={`data:image/png;base64,`} />
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    color: "#000"
                                                }}
                                            >
                                                {member.name}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ mt: 1 }} />
                                    </Box>
                                )
                            })
                            : <>No data</>
                    }
                </Box>

            </Box>
        </Paper>
    )
}
