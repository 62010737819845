import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
        config.headers!['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'

    }
    return config
})

const specialAxios = axios.create();

specialAxios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
        config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
})

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    put: (url: string, body: {}) => specialAxios.put(url, body).then(responseBody),
}
const tenantsUserAgent = {
    getTenantsUser: () => requests.get<any>(url + `/api/v1/Tenant/User`),
    putTenant: (body : any) => requests.put(url + `/api/v1/Tenant`, body)
}
export default tenantsUserAgent