import { createSlice } from "@reduxjs/toolkit";

export interface QuestionField {
    id: string;
    dynamicColumnId: string;
    dataType: number;
    name: string;
    description: string;
    tooltip: string;
    position: number;
    isMandatory: boolean;
    isVisible: boolean;
    defaultValue: string;
    createdBy: string;
    editedBy: string;
    createdDate: string;
    modifiedDate: string;
}

export interface Report{
    id: string;
    tenantId: string;
    tableId: string;
    name: string;
    description: string | null;
    isPublic: boolean;
    language: string;
    createdBy: string;
    editedBy: string;
    createdDate: string;
    modifiedDate: string;
    questionFields: QuestionField[];
}

export interface ReportData {
    report: Report;
    questionFields: QuestionField[];
  }

  
export interface reportStore {
    status: string;
    reports: Report[];
    currentReport: ReportData  | null;
}

const initialState: reportStore = {
    status: "loading",
    reports: [],
    currentReport: null
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReports: (state, { payload }) => {
            state.status = "success";
            state.reports = payload;
        },
        setReport: (state, { payload }) => {
            state.status = "success";
            state.currentReport = payload;
        },
        loading: (state) => {
            state.status = "loading";
        },
        successState: (state) => {
            state.status = "success";
        },
        errorState: (state) => {
            state.status = "error";
        }
    }
});

export const { loading, errorState, setReports, successState, setReport } = reportsSlice.actions;
