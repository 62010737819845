import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { LoginPage } from '../pages/Login/VerifyLogin'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import MiniDrawer from '../components/drawer'
import { Home } from '../pages/Home'
import { EditContactEmail } from '../pages/Email/EditContactEmail'
import { Tenant } from '../pages/Tenant/Tenant'
import { Tables } from '../pages/Tables/Tables'
import { Accounts } from '../pages/Accounts/Accounts'
import { Roles } from '../pages/Roles/Roles'
import { LoginForm } from '../pages/Login/LoginForm'
import { SubscriptionsPage } from '../pages/Subscriptions'
import { OnBoard } from '../pages/OnBoard'
import { getTenant } from '../redux/tenant/thunk'
import { LoadingCube } from '../components/custom/loader/LoadingCube'
import { ReportPage } from '../pages/Report/ReportPage'
import { UserTable } from '../components/users/table/UserTable'
import { ContactsPage } from '../pages/Contacts/ContactsPage'
import { Payment } from '../pages/Subscriptions/Payment'
import { tokenLogin } from '../redux/auth/thunk'
import { VerifyPayment } from '../pages/Subscriptions/VerifyPayment'
import { ContactDetail } from '../components/Contacts/components/ContactDetail'
import { UserDetail } from '../components/users/table/components/UserDetail'
import { AutorulesPage } from '../pages/Autorules/AutorulesPage'
import { EndUsersPage } from '../pages/EndUsers/EndUsers'
import { CommerceSettingsPage } from '../pages/Commerce/CommerceSettingsPage'
import { CommerceSubscriptionsPage } from '../pages/Commerce/CommerceSubscriptionsPage'
import { CommerceDashboardPage } from '../pages/Commerce/CommerceDashboardPage'
import { PaymentPage } from '../pages/EndUsers/Payment/PaymentPage'
import { LabelsPage } from '../pages/Labels/LabelsPage'
import { DashboardPage } from '../pages/Dashboard/DashboardPage'
import { TenantSelector } from '../pages/TenantManagement/TenantSelector'
import { SettingsPage } from '../pages/Settings/SettingsPage'

interface RouteIterface {
    path: string
    component: JSX.Element
    authorizedSubscriptions: string[]
}
export const AppRouter = () => {
    const dispatch = useAppDispatch()
    const token = window.localStorage.getItem("jwt");
    const localTenantId = window.localStorage.getItem("tenantId");
    const { status, roles, tenantId, isOwner, suscription } = useAppSelector(state => state.auth)
    const tenantData = useAppSelector(state => state.tenant)
    const routes: RouteIterface[] = [
        {
            path: '/:tenantId/organization/cpanel/settings',
            component: <EditContactEmail />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]
        },
        {
            path: '/:tenantId/roles',
            component: <Roles />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/accounts',
            component: <Accounts />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        // {
        //     path: '/:tenantId/users',
        //     component: <UserTable />,
        //     authorizedSubscriptions: [
        //         process.env.REACT_APP_BUSINESS_ID as string,
        //         process.env.REACT_APP_STARTUP_ID as string,
        //         process.env.REACT_APP_ENTERPRISE_ID as string,
        //         process.env.REACT_APP_CORPORATION_ID as string,
        //         process.env.REACT_APP_FREELANCER_ID as string,

        //     ]
        // },
        {
            path: '/:tenantId/tables/dynamicTable/:id',
            component: <Tables />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        }
        ,
        {
            path: '/:tenantId/contacts',
            component: <ContactsPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        }
        ,
        {
            path: '/:tenantId/endusers',
            component: <EndUsersPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/company/:id',
            component: <Tenant />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/contact/:id',
            component: <ContactDetail />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/user/:id',
            component: <UserDetail />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/autorules/:id',
            component: <AutorulesPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/report/:id',
            component: <ReportPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/main/report/public/:id',
            component: <ReportPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/commerce/settings',
            component: <CommerceSettingsPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]
        },
        {
            path: '/:tenantId/commerce/subscriptions',
            component: <CommerceSubscriptionsPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                // process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/commerce/dashboard',
            component: <CommerceDashboardPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]
        },
        {
            path: '/:tenantId/labels',
            component: <LabelsPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        },
        {
            path: '/:tenantId/settings',
            component: <SettingsPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]
        },
        {
            path: '/:tenantId/dashboard/:id',
            component: <DashboardPage />,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,

            ]
        }

    ]
    const location = useLocation()


    useEffect(() => {
        dispatch(tokenLogin());

    }, []);
    useEffect(() => {
        if (status === "logged") {
            if(tenantId !== null) {
                dispatch(getTenant(tenantId))
            }
        }
        else if(status === "loggedTenant"){
            dispatch(getTenant(localTenantId))
        }
    }, [status]);
    if (location.pathname.split("/")[1] === "payment") {
        return (
            <Box
                sx={{
                    backgroundColor: "#f8f8fb",
                    minHeight: "100vh",
                    color: '#fff'
                }}
            >
                <Routes>
                    <Route path={"/payment"} element={<PaymentPage />} />

                </Routes >
            </Box>
        )
    }
    if (status === 'loading') {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                }}
                justifyContent={"center"}
            >
                <LoadingCube />
            </Box>
        )
    }
    if (status != "logged" && status != "loggedTenant") {
        return (
            <Box
                sx={{
                    backgroundColor: "#f8f8fb",
                    minHeight: "100vh",
                    color: '#fff'
                }}
            >
                <Routes>

                    <Route path='/login' element={<LoginForm />} />
                    <Route path={`/redirect-login`} element={<LoginPage />} />
                    <Route path='/main/dashboard/:id' element={<DashboardPage />} />
                    <Route path='/main/report/public/:id' element={<ReportPage />} />
                    <Route path={`/*`} element={<Navigate to="/login"></Navigate>} />
                </Routes >
            </Box>
        )
    }
    else if (tenantData.tenant === null && isOwner && tenantData.status !== "loading" && status === "logged") {
        return (
            <Box
                sx={{
                    backgroundColor: "#f8f8fb",
                    minHeight: "100vh",
                    color: '#fff'
                }}
            >
                <Routes>
                    <Route path={"/onboard"} element={<OnBoard />} />
                    <Route path={`/*`} element={<Navigate to="/onboard"></Navigate>} />

                </Routes >
            </Box>
        )

    }
    else if (status === "logged") {
        return (
            <Box
                sx={{
                    backgroundColor: "#f8f8fb",
                    minHeight: "100vh",
                    color: '#fff'
                }}
            >
                <Routes>
                    <Route path={"/tenant-selector"} element={<TenantSelector/>} />
                    <Route path={`/*`} element={<Navigate to="/tenant-selector"></Navigate>} />
                </Routes >
            </Box>
        )

    }
    else {
        return (
            <Box
                sx={{
                    backgroundColor: "#f8f8fb",
                    minHeight: "100vh",
                    color: '#fff'
                }}
            >
                <MiniDrawer>

                    <Routes>
                        <Route path={`/:tenantId`} element={<Home />} />
                        {
                            routes.map((item, zIndex) => {

                                if (item.authorizedSubscriptions.includes((suscription?.id as string).toUpperCase())) {

                                    return (
                                        <Route path={item.path} element={item.component} key={zIndex} />
                                    )
                                } else {
                                    return (
                                        <Route path={item.path} element={<Navigate to={`/${localTenantId}`}></Navigate>} key={zIndex} />
                                    )
                                }
                            })
                        }
                        <Route path={"/:tenantId/subscriptions/payment/method"} element={<Payment />} />
                        <Route path={"/:tenantId/subscriptions"} element={<SubscriptionsPage />} />
                        <Route path={"/checkout/confirm"} element={<VerifyPayment />} />
                        <Route path={`/*`} element={<Navigate to={`/${localTenantId}`} />} />
                    </Routes >
                </MiniDrawer>
            </Box>
        )
    }

}
