import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { modifyTable } from '../../../redux/tables/thunk';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 10,
    padding: 5,
    paddingBottom: 1
};

export const EditTableName = ({ open, setOpen, tableName, tableId }: { open: boolean, setOpen: any, tableName: string, tableId: string }) => {

    const { tenantId, id: userId } = useAppSelector(state => state.auth)
    const { tablesName } = useAppSelector(state => state.tables)

    const dispatch = useAppDispatch()
    const [table, setTable] = useState<any>({
        tableName: tableName
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { name, value } = event.target;
        setTable({ ...table, [name]: value })
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                Editar nombre de la tabla
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={table.tableName}
                                name='tableName'
                                label="Nombre de la tabla"
                                onChange={handleInputChange}
                                error={table.tableName.length > 150}
                                helperText={table.tableName.length > 150 && "The name cannot contain more than 150 characters"}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                            }}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="outlined"
                            disabled={table.tableName.length > 150}
                            onClick={() => {
                                const veri = tablesName.find((tabCol: any) => tabCol.tableName === table.tableName)
                                if (veri !== undefined) {
                                    Swal.fire({
                                        title: 'Nombre de tabla ya en uso',
                                        text: "Debes elegir otro nombre!",
                                        icon: 'error',
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Borrar'
                                    })
                                } else {
                                    dispatch(modifyTable({
                                        tableName: table.tableName,
                                        tenantId: tenantId,
                                        id: tableId,
                                        editedBy: userId
                                    }))
                                }

                                setOpen(false)
                            }}
                            sx={{
                                backgroundColor: "lightblue",
                            }}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
