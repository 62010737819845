import { Box, Chip, useTheme, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme, AutocompleteInputChangeReason } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getAllCompanies } from '../../../redux/account/thunk'
import { CutomMultiAutocomplete } from '../../custom/CutomMultiAutocomplete';
import axios from 'axios';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
interface Account {
    id: string;
    name: string;
  }
  
  interface ContactEditCellProps {
    value: Account[];
    api: {
      setEditCellValue: (params: { id: string; field: string; value: Account[] }) => void;
      stopCellEditMode: (params: { field: string; id: string }) => void;
    };
    field: string;
    id: string;
    row: any;
    hasFocus: boolean;
  }

export const AccountEditCell = React.forwardRef<HTMLDivElement, ContactEditCellProps>(
    function AccountEditCell(props, ref) {
        const { value, api, field, id, row, hasFocus } = props;
        const tenantId = window.localStorage.getItem("tenantId")
        const [searchTerm, setSearchTerm] = useState<string>("");
        const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]);
        const [inputValue, setInputValue] = useState("");
        const [selectedValue, setSelectedValue] = useState<Account[]>(value || []);

        useEffect(() => {
            const fetchContacts = async () => {
              try {
                let url = `${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/PublicFeature/Accounts?TenantId=${tenantId}&Name=${searchTerm}`;
                const response = await axios.get(url);
                setFilteredAccounts(response.data.result.map((account: { id: string; name: string; }) => ({
                  id: account.id,
                  name: account.name
                })));
              } catch (error) {
                console.error("Error fetching contacts:", error);
              }
            };
      
            fetchContacts();
          }, [searchTerm, tenantId]);
      
          const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: string) => {
            if (reason === 'input') {
              setSearchTerm(value);
              setInputValue(value);
            }
          };
      
          const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: Account[], reason: string) => {
    
              setSelectedValue(newValue)
              const updatedValue = newValue.map(account => ({
                id: account.id,
                name: account.name,
              }));
    
              api.setEditCellValue({ id, field, value: updatedValue });  
          };
      
          useEffect(() => {
            if (!hasFocus) {
              api.stopCellEditMode({ field: field, id: row.id });
            }
          }, [api, field, hasFocus, id, row.id]);
      
          return (
            <CutomMultiAutocomplete
              options={filteredAccounts}
              title="name"
              label="Accounts"
              onInputChange={handleInputChange}
              onChange={handleChange}
              value={selectedValue}
              inputValue={inputValue}
            />
          );
    },
);