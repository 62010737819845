import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, errorState, setReports, successState, setReport} from "./slice";
import reportsAgent from "./agent";
import { getPermissions } from "../permission/thunk";
import Swal from "sweetalert2";

export const checkingReports = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}

export const postTableRowFieldReport = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await reportsAgent.postTableRow(body)
            .then((response) => {
                dispatch(successState());
            })
            .catch((error) => {
                dispatch(errorState())
            })
    }
}

export const postTableRowFileFieldReport = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await reportsAgent.postFileRow(body)
            .then((response) => {
                dispatch(successState())
            })
            .catch((error) => {
                dispatch(errorState())
            })
    }
}

export const getReportsNames = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.getReports(tenantId)
            .then((result) => {
                dispatch(getPermissions());
                dispatch(setReports(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const getReportbyId = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.getReport(id)
            .then((result) => {
                dispatch(setReport(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const createReport = (data: any, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.postReport(data)
            .then((result) => {
                dispatch(
                    getReportsNames(tenantId)
                    )
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const createQuestionField = (data: any, reportId: string, setOpen: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.postQuestionField(data)
            .then((result) => {
                dispatch(getReportbyId(reportId))
                setOpen(false)
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}

export const positionQuestionField = (data: any, reportId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.postPositionQuestionField(data)
            .then((result) => {
                dispatch(getReportbyId(reportId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}

export const updateQuestionField = (data: any, reportId: string,): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.putQuestionField(data)
            .then((result) => {
                dispatch(getReportbyId(reportId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}
export const deleteQuestionField = (id: string, reportId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.deleteQuestionField(id)
            .then((result) => {
                dispatch(getReportbyId(reportId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const deleteReport = (id: string, tenantId: string, navigate: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.deleteReport(id)
            .then((result) => {
                dispatch(getReportsNames(tenantId))
                navigate('/')
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}


export const updateReport = (data: any, reportId: string, tenantId: string, setOpen: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        reportsAgent.putReport(data)
            .then((result) => {
                dispatch(getReportbyId(reportId))
                dispatch(getReportsNames(tenantId))
                setOpen(false)
            }).catch((err) => {
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}