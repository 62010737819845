import TextFormatIcon from '@mui/icons-material/TextFormat';
import NumbersIcon from '@mui/icons-material/Numbers';
import EventIcon from '@mui/icons-material/Event';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PaidIcon from '@mui/icons-material/Paid';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { types } from '../../../components/Enums/ColumnsTypes';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

export const ColumnIcon: any = ({ columnType }: { columnType: number }) => {
    if (columnType === types.Text) {
        return <TextFormatIcon sx={{ color: "grey" }} />
    } else if (columnType === types.Number || columnType === types.Decimal) {
        return <NumbersIcon sx={{ color: "grey" }} />
    } else if (columnType === types.Date) {
        return <EventIcon sx={{ color: "grey" }} />
    } else if (columnType === types.Bool) {
        return <ToggleOnIcon sx={{ color: "grey" }} />
    } else if (columnType === types.File) {
        return <AttachFileIcon sx={{ color: "grey" }} />
    } else if (columnType === types.Contact) {
        return <EmojiPeopleIcon sx={{ color: "grey" }} />
    } else if (columnType === types.Money) {
        return <PaidIcon sx={{ color: "grey" }} />
    } else if (columnType === types.Account) {
        return <ApartmentIcon sx={{ color: "grey" }} />
    } else if (columnType === types.EndUser) {
        return <PersonOutlineIcon sx={{ color: "grey" }} />
    }
}