import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LoginData, UserAuth, error, loading, login, loginTenant, logout } from "./slice";
import AuthAgent from "./agent";
import { parseJwt } from "../../functions/DecodeJWT";
import axios from "axios";

const axiosInstance = axios.create();

export const checkingAuth = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}

export const tokenLogin = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        const token = window.localStorage.getItem("jwt");
        const tenantId = window.localStorage.getItem("tenantId");
        
        axiosInstance.get(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/Authenticate`, { 
            headers: { 
                'Authorization': `Bearer ${token}`,
                'tenantId': tenantId 
            } 
        })
        .then((result: any) => {
            let userData = parseJwt(result.data.result.jwTtoken);
            if (tenantId !== null) {
                dispatch(loginTenant({ ...result.data.result, tesseractId: userData.uid, nexusRoles: userData.nexusRoles }));
            } else {
                dispatch(login({ ...result.data.result, tesseractId: userData.uid, nexusRoles: userData.nexusRoles }));
            }
        })
        .catch((err) => {
            dispatch(logout())
        });
    }
}

export const setUser = (user: UserAuth): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(login(user));
    }
}

export const loginUser = (user: LoginData): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        AuthAgent.login(user)
            .then((result) => {
                let userData = parseJwt(result.result.jwTtoken)

                dispatch(login({ ...result.result, tesseractId: userData.uid, nexusRoles: userData.nexusRoles }));
            }).catch((err) => {
                if (err.response) {
                    //console.log(err.response.data.message);
                    let errorMessage = err.response.data.message
                    dispatch(error(errorMessage))
                } else {
                    dispatch(error("Algo salio mal, revise la informacion ingresada e intentelo nuevamente."))

                }

            });
    }
}

export const loginTenantUser = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        const token = window.localStorage.getItem("jwt");

        axiosInstance.get(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/Authenticate`, { 
            headers: { 
                'Authorization': `Bearer ${token}`,
                'tenantId': tenantId 
            } 
        })
        .then((result: any) => {
            let userData = parseJwt(result.data.result.jwTtoken)
            dispatch(loginTenant({ ...result.data.result, tesseractId: userData.uid, nexusRoles: userData.nexusRoles }));
        })
        .catch((err) => {
            dispatch(logout())
        });
    }
}


export const startLogout = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        // dispatch(loading());
        dispatch(logout())
    }
}
