import { createSlice } from "@reduxjs/toolkit";


export interface Keys {
    status: string
    domainKey: string | null
}
const initialState: Keys = {
    status: "default",
    domainKey: null
}
export const keysSlice = createSlice({
    name: 'keys',
    initialState,
    reducers: {
        loading: (state) => {
            state.status = "loading"
        },
        setDomainKey: (state, {payload}) => {
            state.status = "confirmed"
            state.domainKey = payload
        },
        error: (state, { payload }) => {
            state.status = "error"
        },
    }
})
export const { error, setDomainKey, loading } = keysSlice.actions
