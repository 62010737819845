import React, { useState, useEffect } from 'react';
import MuiGrid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import {
    Plugin, Template, TemplateConnector, TemplatePlaceholder, Action,
} from '@devexpress/dx-react-core';
import {
    TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';


export const DetailContent = ({ row, ...rest }) => {
    const {
        processValueChange,
        applyChanges,
        cancelChanges,
    } = rest;
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={3}>
                <FormGroup>
                    <TextField
                        margin="normal"
                        name="name"
                        label="Nombre de la compañia"
                        value={row.name}
                        onChange={processValueChange}
                    />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={3}>
                <FormGroup>
                    <TextField
                        margin="normal"
                        name="address"
                        label="Dirección"
                        value={row.address}
                        onChange={processValueChange}
                    />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={3}>
                <FormGroup>
                    <TextField
                        margin="normal"
                        name="rut"
                        label="Rut"
                        value={row.rut}
                        onChange={processValueChange}
                    />
                </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={12}>
                <MuiGrid container spacing={3} justifyContent="flex-start" >
                    <MuiGrid item>
                        <IconButton onClick={applyChanges} >
                            <SaveIcon />
                        </IconButton>
                    </MuiGrid>
                    <MuiGrid item >
                        <IconButton onClick={cancelChanges} color='error'>
                            <CancelIcon />
                        </IconButton>
                    </MuiGrid>
                </MuiGrid>
            </MuiGrid>
        </MuiGrid>
    );
};


export const DetailEditCell = () => (
    <Plugin name="detailEdit">
        <Action
            name="toggleDetailRowExpanded"
            action={({ rowId }, { expandedDetailRowIds }, { startEditRows, stopEditRows }) => {
                const rowIds = [rowId];
                const isCollapsing = expandedDetailRowIds.indexOf(rowId) > -1;
                if (isCollapsing) {
                    stopEditRows({ rowIds });
                } else {
                    startEditRows({ rowIds });
                }
            }}
        />
        <Template
            name="tableCell"
            predicate={({ tableRow }) => tableRow.type === TableRowDetail.ROW_TYPE}
        >
            {params => (
                <TemplateConnector>
                    {({
                        tableColumns,
                        createRowChange,
                        rowChanges,
                    }, {
                        changeRow,
                        commitChangedRows,
                        cancelChangedRows,
                        toggleDetailRowExpanded,
                    }) => {
                        if (tableColumns.indexOf(params.tableColumn) !== 0) {
                            return null;
                        }
                        const { tableRow: { rowId } } = params;
                        const row = { ...params.tableRow.row, ...rowChanges[rowId] };

                        const processValueChange = ({ target: { name, value } }) => {
                            const changeArgs = {
                                rowId,
                                change: createRowChange(row, value, name),
                            };
                            changeRow(changeArgs);
                        };

                        const applyChanges = () => {
                            toggleDetailRowExpanded({ rowId });
                            commitChangedRows({ rowIds: [rowId] });
                        };
                        const cancelChanges = () => {
                            toggleDetailRowExpanded({ rowId });
                            cancelChangedRows({ rowIds: [rowId] });
                        };

                        return (
                            <TemplatePlaceholder params={{
                                ...params,
                                row,
                                tableRow: {
                                    ...params.tableRow,
                                    row,
                                },
                                changeRow,
                                processValueChange,
                                applyChanges,
                                cancelChanges,
                            }}
                            />
                        );
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

export const DetailCell = ({
    children, changeRow, editingRowIds, addedRows, processValueChange,
    applyChanges, cancelChanges,
    ...restProps
}) => {
    const { row } = restProps;

    return (
        <TableRowDetail.Cell {...restProps}>
            {React.cloneElement(children, {
                row, changeRow, processValueChange, applyChanges, cancelChanges,
            })}
        </TableRowDetail.Cell>
    );
};
