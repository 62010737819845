import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { LabelObject } from "../../redux/labels/slice";
import { useEffect, useState } from "react";
import axios from "axios";
import { TimerData } from "./TimerWidget";

interface Props {
    item: TimerData
    handleOpen: boolean;
    handleClose: () => void;
    handleMoreTime: (rowId: string, countDown: string, label: string) => void
    timeColumnId: string
}

export const AddMoreTime = ({item, handleOpen, handleClose, handleMoreTime, timeColumnId} :Props) => {
        const [currentLabels, setCurrentLabels] = useState<LabelObject | null>(null)
        const [selectedTime, setSelectedTime] = useState('');
        
        const getLabels = async (columnId: string) => {
          const url = process.env.REACT_APP_NEXUS_BACK_URL;
          let req = await axios.get(
              url + `/api/v1/DynamicColumn/Label?Id=${columnId}`
          );
          let labels = req.data.result;
          return labels;
       };

        useEffect(() => {
          const getLabelData = async () => {
              const labelData = await getLabels(timeColumnId)
              if (labelData)
                  setCurrentLabels(labelData)
  
          }
          if (timeColumnId)
              getLabelData()
      }, [timeColumnId])

        const handleAddTime = () => {
          if (selectedTime) {
            handleMoreTime(item.rowId, item.timeStamp, selectedTime);
            handleClose()
          }
        };

        return (
          <Modal
          open={handleOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add more time
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Select time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Selecciona el tiempo"
                onChange={(e) => setSelectedTime((e.target as HTMLInputElement).value)}
              >
              {
                  currentLabels?.labels.map((e) => {
                      return (
                          <MenuItem key={e.id} value={e.id} sx={{
                              color: e.hexColor
                          }}>{e.tag}</MenuItem>
                      )
                  })
              }
              </Select>
            </FormControl>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleAddTime}  variant="contained">Add</Button>
            </Box>
          </Box>
        </Modal>
        );

}