import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { modifyTable } from '../../../redux/tables/thunk';
import Swal from 'sweetalert2';
import { putAutorule } from '../../../redux/autoRules/thunk';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 10,
    padding: 5,
    paddingBottom: 1
};

export const EditAutoRule = ({ open, setOpen, autoruleName, autoRuleId }: { open: boolean, setOpen: any, autoruleName: string, autoRuleId: string }) => {
    const { tenantId, id: userId } = useAppSelector(state => state.auth)
    const { autoruleNames } = useAppSelector(state => state.autoRules)

    const dispatch = useAppDispatch()
    const [autoRule, setAutoRule] = useState<any>({
        autoruleName: autoruleName
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { name, value } = event.target;
        setAutoRule({ ...autoRule, [name]: value })
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                Editar nombre de la auto rule
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={autoRule.autoruleName}
                                name='autoruleName'
                                label="Nombre de la tabla"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                            }}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="outlined"
                            onClick={() => {
                                const veri = autoruleNames.find((tabCol: any) => tabCol.autoruleName === autoRule.autoruleName)
                                if (veri !== undefined) {
                                    Swal.fire({
                                        title: 'Nombre de tabla ya en uso',
                                        text: "Debes elegir otro nombre!",
                                        icon: 'error',
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Borrar'
                                    })
                                } else {
                                    dispatch(putAutorule({
                                        tenantAutoruleId: autoRuleId,
                                        autoruleName: autoRule.autoruleName,
                                        state: true,
                                        tenantId: tenantId
                                      }))
                                }

                                setOpen(false)
                            }}
                            sx={{
                                backgroundColor: "lightblue",
                            }}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
