import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Theme, IconButton, Avatar, Button} from '@mui/material';
import { makeStyles} from '@mui/styles';
import { EditTenantModal } from './EditTenantModal';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tenant } from '../../models/Teanant';
import { useAppDispatch } from '../../hooks/hooks';
import { loginTenantUser} from '../../redux/auth/thunk';
import { getAllUsers } from '../../redux/user/thunk';
import { getTenant } from '../../redux/tenant/thunk';
import { getPermissions } from '../../redux/permission/thunk';
import { useNavigate } from 'react-router-dom';
import { putTenant } from '../../redux/tenantsUser/thunk';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  avatarContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(-12), 
    right: theme.spacing(-2 ), 
  },
}));

interface GridCardTenantProps {
  tenants: Tenant[] | null;
}

export const GridCardTenant: React.FC<GridCardTenantProps> = ({ tenants }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedTenant, setSelectedTenant] = React.useState<Tenant | null>(null);
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleEditClick = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setOpenModal(true);
  };

  const handleTenantClick = (tenant: Tenant) => {

    window.localStorage.setItem("tenantId", tenant.id);
    window.location.href = `/${tenant.id}`;
    //dispatch(loginTenantUser(tenant.id))
    dispatch(getAllUsers(tenant.id))
    dispatch(getPermissions)   
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = (updatedTenant: Tenant) => {
    const formData = new FormData();

    formData.append('Id', updatedTenant.id);
    formData.append('Name', updatedTenant.name);
    formData.append('Email', updatedTenant.email);
    formData.append('Description', updatedTenant.description);
    formData.append('File', updatedTenant.profileImage);

    dispatch(putTenant(formData));
    setOpenModal(false);
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
          {(tenants ?? []).map((tenant) => (
          <Grid item xs={12} sm={8} md={3} key={tenant.id}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
              <Button onClick={() => handleTenantClick(tenant)} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '90%'}}>
                <div className={classes.avatarContainer}>
                  {tenant.profileImage ? (
                    <Avatar alt={tenant.name} src={tenant.profileImage} className={classes.avatar} />
                  ) : (
                    <Avatar alt={tenant.name} className={classes.avatar}>
                      {tenant.name.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                  <Typography variant="h5" component="h2" style={{ marginLeft: '8px', marginTop: '15px', textTransform: 'none'}}>{tenant.name}</Typography>
                </div>
                </Button>
                <div style={{ position: 'relative' }}> {/* Nuevo contenedor para albergar el IconButton */}
                {(tenant.isOwner || tenant.canManageTenant === "CanManageTenant") && (
                  <IconButton className={classes.editIcon} aria-label="edit" onClick={() => handleEditClick(tenant)}>
                    <SettingsIcon/>
                  </IconButton>
                )}
                </div>
                <div style={{marginTop: '10px'}}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {tenant.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  ID: {tenant.id}
                </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {
        selectedTenant &&
              <EditTenantModal
              open={openModal}
              onClose={handleCloseModal}
              onSave={handleSave}
              tenant={selectedTenant}
            />
      }

    </>
  );
};