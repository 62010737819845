import React, { FormEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getTablesColumns, getTablesNames } from '../../redux/tables/thunk'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { TableNames } from '../../redux/tables/slice'
import { types } from '../Enums/ColumnsTypes'
import axios from 'axios'
import { LabelObject } from '../../redux/labels/slice'
import { createWidget } from '../../redux/dashboards/thunk'
import { useParams } from 'react-router-dom'
import { Colorful } from '@uiw/react-color'

const getLabels = async (columnId: string) => {
    const url = process.env.REACT_APP_NEXUS_BACK_URL;
    let req = await axios.get(
        url + `/api/v1/DynamicColumn/Label?Id=${columnId}`
    );
    let labels = req.data.result;
    return labels;
};
interface Props {
    dashboardId: string
    setOpen: any
}
export const TimerForm = ({ dashboardId, setOpen }: Props) => {
    const { id } = useParams()


    const [table, setTable] = useState<string>('')
    const [currentLabels, setCurrentLabels] = useState<LabelObject | null>(null)
    const [widgetData, setWidgetData] = useState({
        "customDataColumnId": null,
        "timeSpanColumnId": "",
        "timeColumnId": "",
        "statusColumnId": "",
        "contactColumnId": "",
        "controlColumnId": "",
        "siteColumnId": "",
        "siteId": "",
        "backgroundColor": "#ffffff",
        "fontColor": "#131313",
        "finishedColor": "#ffffff",
    })
    const dispatch = useAppDispatch()
    const { tablesName, tablesColumns } = useAppSelector(state => state.tables)
    const { status } = useAppSelector(state => state.dashboards)
    const { tenantId } = useAppSelector(state => state.auth)

    const handleChange = (event: SelectChangeEvent) => {
        let { value, name } = event.target
        setWidgetData({ ...widgetData, [name]: value })
    }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if(widgetData.customDataColumnId === ""){
            widgetData.customDataColumnId = null;
        }

        dispatch(createWidget(
            {
                ...widgetData,
                tableId: table,
                dashboardId: id,
                "xSize": 0,
                "ySize": 0,
                "maxXSize": 0,
                "maxYSize": 0,
                "minXSize": 0,
                "minYSize": 0,
                "xPosition": 0,
                "yPosition": 0,
                "token": "a"
            },
            dashboardId,
            "/Widget"
        ))
    }
    useEffect(() => {
        dispatch(getTablesNames(tenantId))
    }, [])
    useEffect(() => {
        if (table)
            dispatch(getTablesColumns(table))
    }, [table])
    useEffect(() => {
        const getLabelData = async () => {
            const labelData = await getLabels(widgetData.siteColumnId)
            if (labelData)
                setCurrentLabels(labelData)

        }
        if (widgetData.siteColumnId)
            getLabelData()
    }, [widgetData.siteColumnId])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                paddingBottom: '100px'
            }}
            component={'form'}
            onSubmit={handleSubmit}
        >
            <FormControl fullWidth>
                <InputLabel required>Table</InputLabel>
                <Select
                    value={table}
                    label="Table"
                    onChange={(event) => { setTable(event.target.value as string) }}
                    required
                >
                    {
                        tablesName.map((e) => {
                            return (
                                <MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel required>Time column</InputLabel>
                <Select
                    value={widgetData.timeColumnId}
                    label="Time column"
                    name='timeColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.ExactTime)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel required>Real Used Column</InputLabel>
                <Select
                    value={widgetData.timeSpanColumnId}
                    label="Real Used Column"
                    name='timeSpanColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.TimeSpan)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel required>Status Column</InputLabel>
                <Select
                    value={widgetData.statusColumnId}
                    label="Status Column"
                    name='statusColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.Bool)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel required>Contacts Column</InputLabel>
                <Select
                    value={widgetData.contactColumnId}
                    label="Contacts Column"
                    name='contactColumnId'
                    onChange={handleChange}
                    required

                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.Contact)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel required>Control Column</InputLabel>
                <Select
                    value={widgetData.controlColumnId}
                    label="Control Column"
                    name='controlColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.Label)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel required >Site Column</InputLabel>
                <Select
                    value={widgetData.siteColumnId}
                    label="Site Column"
                    name='siteColumnId'
                    onChange={handleChange}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            if (e.type === types.Label)
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>
            {
                currentLabels &&
                <FormControl fullWidth>
                    <InputLabel required>Label Site</InputLabel>
                    <Select
                        value={widgetData.siteId}
                        label="Label Site"
                        name='siteId'
                        onChange={handleChange}
                        required
                    >
                        {
                            currentLabels.labels.map((e) => {
                                return (
                                    <MenuItem key={e.id} value={e.id} sx={{
                                        color: e.hexColor
                                    }}>{e.tag}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            }

            <FormControl fullWidth>
                <InputLabel >Custom Data Column</InputLabel>
                <Select
                    value={widgetData.customDataColumnId || ""}
                    label="Custom Data Column"
                    name='customDataColumnId'
                    onChange={handleChange}
                >
                    <MenuItem value=""> <em>NULL</em></MenuItem>
                    {
                        tablesColumns.map((e) => {
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                )
                        })
                    }
                </Select>
            </FormControl>  

            <FormControl fullWidth>
                <Typography fontSize={18} fontWeight={400} color={"#131313"} mb={1}>
                    Select Background Color:
                </Typography>
                <Box sx={{ border: '1px solid #ced4da', borderRadius: '4px', p: 1 }}>
                    <TextField
                        label="Hex"
                        value={widgetData.backgroundColor}
                        onChange={(e) => setWidgetData({ ...widgetData, backgroundColor: e.target.value })}
                        inputProps={{ maxLength: 7 }}
                        sx={{ width: '150px', marginBottom: 2 }}
                    />
                    <Colorful
                        disableAlpha
                        color={widgetData.backgroundColor}
                        onChange={(color) => setWidgetData({ ...widgetData, backgroundColor: color.hex })}
                    />
                </Box>
            </FormControl>

            <FormControl fullWidth>
                <Typography fontSize={18} fontWeight={400} color={"#131313"} mb={1}>
                    Select Font Color:
                </Typography>
                <Box sx={{ border: '1px solid #ced4da', borderRadius: '4px', p: 1 }}>
                    <TextField
                        label="Hex"
                        value={widgetData.fontColor}
                        onChange={(e) => setWidgetData({ ...widgetData, fontColor: e.target.value })}
                        inputProps={{ maxLength: 7 }}
                        sx={{ width: '150px', marginBottom: 2 }}
                    />
                    <Colorful
                        disableAlpha
                        color={widgetData.fontColor}
                        onChange={(color) => setWidgetData({ ...widgetData, fontColor: color.hex })}
                    />
                </Box>
            </FormControl>

            <FormControl fullWidth>
                <Typography fontSize={18} fontWeight={400} color={"#131313"} mb={1}>
                    Select Finished Color:
                </Typography>
                <Box sx={{ border: '1px solid #ced4da', borderRadius: '4px', p: 1 }}>
                    <TextField
                        label="Hex"
                        value={widgetData.finishedColor}
                        onChange={(e) => setWidgetData({ ...widgetData, finishedColor: e.target.value })}
                        inputProps={{ maxLength: 7 }}
                        sx={{ width: '150px', marginBottom: 2 }}
                    />
                    <Colorful
                        disableAlpha
                        color={widgetData.finishedColor}
                        onChange={(color) => setWidgetData({ ...widgetData, finishedColor: color.hex })}
                    />
                </Box>
            </FormControl>

            <Button type='submit' disabled={status === 'loading'} variant='contained'>
                Create Widget
            </Button>
        </Box>
    )
}
