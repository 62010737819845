import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, setAllClients, errorState, setcurrentContact, setClientsByCompany} from "./slice";
import ClientAgent from "./agent";

export const checkingRoles = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}
export const getAllClients = (TenantId: string, pageSize: number, pageNumber: number, query: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.getAllClients(TenantId, pageSize, pageNumber, query)
            .then((response) => {
                dispatch(setAllClients(response));
            })
            .catch((error) => {
                ////console.log(error);
                dispatch(errorState())
            })
    }
}

export const deleteClient = (ClientId: string, TenantId: string, pageSize: number, pageNumber: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.deletClient(ClientId)
            .then((response) => {

                ClientAgent.getAllClients(TenantId, pageSize, pageNumber, '')
                    .then((response) => {

                        dispatch(setAllClients(response));
                    })
                    .catch((error) => {
                        //console.log(error);
                        dispatch(errorState())
                    })
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const editContact = (Client: any, TenantId: string, pageSize: number, pageNumber: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.putClient(Client)
            .then((response) => {

                dispatch(getAllClients(TenantId, pageSize, pageNumber, ''))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const createClient = (client: any, setOpen: any, pageSize: number, pageNumber: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await ClientAgent.postClient(client)
            .then((response) => {
                ClientAgent.getAllClients(client.tenantId, pageSize, pageNumber, '')
                    .then((response) => {

                        dispatch(setAllClients(response));
                        setOpen(false)
                    })
                    .catch((error) => {
                        dispatch(errorState())
                    })
            })
            .catch((error) => {
                dispatch(errorState())
            })
    }
}

export const getClientById = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.getClientById(id)
            .then((response) => {
                dispatch(setcurrentContact(response.result));
            })
            .catch((error) => {
                ////console.log(error);
                dispatch(errorState())
            })
    }
}

export const getClientByCompanyId = (companyId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.getClientsByCompany(companyId)
            .then((response) => {
                dispatch(setClientsByCompany(response.result));
            })
            .catch((error) => {
                ////console.log(error);
                dispatch(setClientsByCompany([]))
                dispatch(errorState())
            })
    }
}