  import { Card, CardContent, Typography, IconButton, Box, Button, FormControlLabel, Checkbox, TextField, Switch } from '@mui/material';
  import EditIcon from '@mui/icons-material/Edit';
  import DeleteIcon from '@mui/icons-material/Delete';
  import { types } from '../Enums/ColumnsTypes'
  import { BooleanType, ClientsDropDown, CompanyDropDown, EndUserDropDown, FileHandle, LabelDropDown, MoneyType, NumberType, StringType } from './FieldsComponents';
  import { DateRangeTypeReport, DateTimeTypeReport, DateTypeReport } from './DateTypeReport';
  import { useState } from 'react';
  import SaveIcon from '@mui/icons-material/Save';
  import CloseIcon from '@mui/icons-material/Close';
  import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
  import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
  import { DefaultQuestionAccount, DefaultQuestionBoolean, DefaultQuestionContact, DefaultQuestionDate, DefaultQuestionDateRange, DefaultQuestionDateTime, DefaultQuestionEndUser, DefaultQuestionLabel, DefaultQuestionMoney, DefaultQuestionNumber, DefaultQuestionText } from './DefaultQuestionTypes';
  interface QuestionProps {
    question: {
      id: string;
      name: string;
      description: string;
      tooltip: string;
      isVisible: boolean;
      isMandatory: boolean;
      defaultValue: string;
      dataType: number;
      position: number;
      dynamicColumnId:string;
    };
    propValue: any;
    onValueChange: (id: string, value: string) => void;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    onEditSave: (id: string, updatedData: any) => void;
    cancelEdit: () => void;  
    editingQuestionId : string | null;
    isReportEditing: boolean;
    tenantId : string
    moveQuestion: (questionId: string, direction: string) => void;
    roleReport : string | '';
    canEditQuestion : string | '';
    canDeleteQuestion : string | '';
    reset: boolean;
    lenghtPosition: number;
  }

  const QuestionField: React.FC<QuestionProps> = ({ question, propValue, onValueChange, onEditClick, onDeleteClick, onEditSave, cancelEdit, editingQuestionId, isReportEditing, tenantId, moveQuestion, roleReport, canEditQuestion, canDeleteQuestion, reset, lenghtPosition}) => {

      const getFieldComponent = (dataType: number, value: any, tooltip: string, isMandatory: boolean) => {
        const commonProps = {
          value: value, 
          name: question.name,
          tooltip: question.tooltip,
          isMandatory: question.isMandatory,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => onValueChange(question.id, event.target.value),
          column: question.dynamicColumnId,
          tenantId : tenantId,
          reset: reset
        };

      switch (dataType) {
        case types.TimeSpan:
          return <DateRangeTypeReport {...commonProps} />
        case types.ExactTime:
          return <DateTimeTypeReport {...commonProps} />;
        case types.Text:
          return <StringType {...commonProps} />;
        case types.Number:
          return <NumberType {...commonProps} />;
        case types.Date:
          return <DateTypeReport {...commonProps} />;
        case types.Bool:
          return <BooleanType {...commonProps} />;
        case types.File:
          return <FileHandle {...commonProps} />;
        case types.Contact:
          return <ClientsDropDown {...commonProps} />;
        case types.Money:
          return <MoneyType {...commonProps} />;
        case types.Account:
          return <CompanyDropDown {...commonProps} />;
        case types.EndUser:
          return <EndUserDropDown {...commonProps} />;
        case types.Decimal:
          return <MoneyType {...commonProps} />;
        case types.Id:
          return <StringType {...commonProps} />;
        case types.Label:
          return <LabelDropDown {...commonProps} />;
        default:
          return <div>Unsupported Data Type</div>;
      }
    };

    const getQuestionDefaultComponent = (dataType: number) => {
      
    switch (dataType) {
      case types.TimeSpan:
        return <DefaultQuestionDateRange
                defaultValue={editData.defaultValue} 
                handleFieldChange={handleDefaultValueChange} 
                tenantId={tenantId} 
                name={'defaultValue'}
                reset={reset}
                />;
      case types.ExactTime:
        return <DefaultQuestionDateTime
                defaultValue={editData.defaultValue} 
                handleFieldChange={handleDefaultValueChange} 
                tenantId={tenantId} 
                name={'defaultValue'}
                reset={reset}
                />;
      case types.Account:
        return <DefaultQuestionAccount
                defaultValue={editData.defaultValue} 
                handleFieldChange={handleDefaultValueChange} 
                tenantId={tenantId} 
                name={'defaultValue'}
                reset={reset}
                />;
      case types.Text:
        return <DefaultQuestionText
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.Number:
          return <DefaultQuestionNumber 
          defaultValue={editData.defaultValue} 
          handleFieldChange={handleDefaultValueChange} 
          tenantId={tenantId} 
          name={'defaultValue'}
          reset={reset}
          />;
      case types.Date:
        return <DefaultQuestionDate
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.Bool:
        return <DefaultQuestionBoolean
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.File:
        return <div>Unsupported Data Type</div>;
      case types.Contact:
        return <DefaultQuestionContact
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.Money:
        return <DefaultQuestionMoney 
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.EndUser:
        return <DefaultQuestionEndUser
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.Decimal:
        return <DefaultQuestionMoney 
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        />;
      case types.Id:
        return <div>Unsupported Data Type</div>;
      case types.Label:
        return <DefaultQuestionLabel
        defaultValue={editData.defaultValue} 
        handleFieldChange={handleDefaultValueChange} 
        tenantId={tenantId} 
        name={'defaultValue'}
        reset={reset}
        column = {question.dynamicColumnId}
        />;
      default:
        return <div>Unsupported Data Type</div>;
    }
  };

    const [editData, setEditData] = useState({
      id : question.id,
      name : question.name,
      description : question.description,
      tooltip: question.tooltip,
      isMandatory: question.isMandatory,
      isVisible: question.isVisible,
      defaultValue: question.defaultValue,
      editDefaultValue: false,
      token: ''
    });

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setEditData({ ...editData, [name]: value });
      onValueChange(question.id, value);
    };

    const handleDefaultValueChange = (name: string, value: any) => {
      setEditData({ ...editData, [name]: value });
      onValueChange(question.id, value);
    };


    const handleSave = () => {
        onEditSave(question.id, editData);
        cancelEdit();
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
    
      if (name === "isMandatory" && checked) {
        setEditData({ ...editData, isMandatory: true, isVisible: false });
      } else if (name === "isVisible" && checked) {
        setEditData({ ...editData, isMandatory: false, isVisible: true });
      } else {
        setEditData({ ...editData, [name]: checked });
      }

      if (name === "editDefaultValue") {
        setEditData({ ...editData, [name]: checked });
      }
    };

    const handleCancel = () => {

      setEditData({
        id: question.id,
        name: question.name,
        description: question.description,
        tooltip: question.tooltip,
        isMandatory: question.isMandatory,
        isVisible: question.isVisible,
        defaultValue: question.defaultValue,
        editDefaultValue: false, 
        token: ''
      });
      
      cancelEdit();
    };
    

    const isEditing = editingQuestionId === question.id;

    return (
            <Box sx={{
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center', 
        }}>
      <Card variant="outlined" sx={{ width: '70%', mb: 2 , marginBottom: 6}}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'  }}>
              <Typography variant="h6" component="div">
                {isEditing ? (
                  <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Question"
                    value={editData.name}
                    onChange={handleFieldChange}
                    name="name"
                  />
                ) : (
                  `${question.name}`
                )}
              </Typography>
              <Box>
                  {isReportEditing && (canEditQuestion || roleReport) && !isEditing && (
                  <IconButton onClick={() => onEditClick(question.id)}>
                    <EditIcon />
                  </IconButton>
                  )}
                {isEditing ? (
                  <>
                    <IconButton onClick={handleSave} color="primary">
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={handleCancel}>
                      <CloseIcon />
                    </IconButton>
                  </>
                  ) : (
                  isReportEditing && (canDeleteQuestion || roleReport) && (
                    <IconButton onClick={() => onDeleteClick(question.id)}>
                      <DeleteIcon />
                    </IconButton>
                  )
                  )}
                  {
                    isReportEditing && (canEditQuestion || roleReport) && !isEditing && (question.position < lenghtPosition -1) &&(
                      <IconButton onClick={() => moveQuestion(question.id, 'down')}>
                        <ArrowDownwardOutlinedIcon />
                      </IconButton>
                  )}
                  {
                    isReportEditing && (canEditQuestion || roleReport) && !isEditing && (question.position > 0) &&(
                      <IconButton onClick={() => moveQuestion(question.id, 'up')}>
                        <ArrowUpwardOutlinedIcon />
                      </IconButton>
                  )}
              </Box>
            </Box>
            {
            isEditing ? (
              <Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Description"
                value={editData.description}
                onChange={handleFieldChange}
                name="description"
                multiline
              />
              {(question.dataType !== types.Bool) && (
                  <TextField              
                  variant="outlined"
                  placeholder="Tooltip"
                  value={editData.tooltip}
                  onChange={handleFieldChange}
                  name="tooltip"
                  multiline
                  style={{ marginTop: '16px' }}
                  />
              )}

              </Box>
            ) : (
              <Typography sx={{ fontSize: 14, color: 'text.secondary', mb: 2 }}>
                {question.description}
              </Typography>          
            )}
            {!isEditing && (
              <Box sx={{ fontSize: 14, color: 'text.secondary', mb: 2 }}>
                {getFieldComponent(question.dataType, propValue, question.tooltip, question.isMandatory)}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editData.isMandatory || false}
                    onChange={handleSwitchChange}
                    name="isMandatory"
                  />
                }
                label="Mandatory"
                sx={{ display: isEditing ? 'block' : 'none' }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editData.isVisible || false}
                    onChange={handleSwitchChange}
                    name="isVisible"
                  />
                }
                label="Visible"
                sx={{ display: isEditing ? 'block' : 'none' }}
              />
              {
                (question.dataType !== types.File || question.dataType !== types.Id) && (
                  <FormControlLabel
                  control={
                    <Switch
                      checked={editData.editDefaultValue || false}
                      onChange={handleSwitchChange}
                      name="editDefaultValue"
                    />
                  }
                  label="Default Value"
                  sx={{ display: isEditing ? 'block' : 'none' }}
                />
              )}
            </Box>
            {
              isEditing && editData.editDefaultValue && getQuestionDefaultComponent(question.dataType)
            }
          </Box>
        </CardContent>
      </Card>
      </Box>
    );
  };

  export default QuestionField;
