import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import { TextField} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Box } from '@mui/material';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

export const DateTypeReport = ({ value, name, onChange, tooltip, isMandatory }) => {
    let newValue = value ? dayjs(value) : null;

    const label = isMandatory ? `${tooltip} *` : tooltip;
    const borderColor = isMandatory && !newValue ? 'red' : 'grey';

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker
                label={label}
                value={newValue}
                onChange={(val) => {
                    const formattedValue = val ? val.format("YYYY-MM-DDTHH:mm:ss.SSS") : null;
                    onChange({
                        target: { name, value: formattedValue },
                    });
                }}
                required={isMandatory}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        error={isMandatory && !newValue}
                        helperText={isMandatory && !newValue ? 'This field is required.' : params.helperText}
                        sx={{
                            '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: borderColor,
                            },
                            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: borderColor,
                            },
                            '& .MuiFormHelperText-root.Mui-error': {
                                color:
                                borderColor,
                            },
                        }}
                    />
                )}
            />
        </LocalizationProvider>
        );
};


export const DateTimeTypeReport = ({ value, name, onChange, tooltip, isMandatory }) => {
    let newValue = value ? dayjs(value) : null;

    const label = isMandatory ? `${tooltip} *` : tooltip;
    const borderColor = isMandatory && !newValue ? 'red' : 'grey';

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DateTimePicker
                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                            ampm={false}
                label={label}
                value={newValue}
                onChange={(val) => {
                    const formattedValue = val ? val.format("YYYY-MM-DDTHH:mm:ss.SSS") : null;
                    onChange({
                        target: { name, value: formattedValue },
                    });
                }}
                timeSteps={{ minutes: 1, seconds: 1 }}
                required={isMandatory}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        error={isMandatory && !newValue}
                        helperText={isMandatory && !newValue ? 'This field is required.' : params.helperText}
                        sx={{
                            '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: borderColor,
                            },
                            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: borderColor,
                            },
                            '& .MuiFormHelperText-root.Mui-error': {
                                color: borderColor,
                            },
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};


export const DateRangeTypeReport = ({ value, name, onChange, tooltip, isMandatory, column, reset }) => {
    const defaultValue = value ? value.split(';').map(date => new Date(date)) : [null, null];
    const [startDate, setStartDate] = useState(defaultValue[0]);
    const [endDate, setEndDate] = useState(defaultValue[1]);
    const [isError, setIsError] = useState(isMandatory && (startDate === null || endDate === null));
  
    useEffect(() => {
      setIsError(isMandatory && (startDate === null || endDate === null));
    }, [isMandatory, startDate, endDate]);


    useEffect(() => {
      if (reset) {
        setStartDate(defaultValue[0]);
        setEndDate(defaultValue[1]);
      }
    }, [reset, defaultValue]);
  
    const handleDateTimeChange = (date, isStart) => {
      if (isStart) {
        setStartDate(date);
      } else {
        setEndDate(date);

        handleDateRangeEditComplete(date);
      }
    };
  
    const handleDateRangeEditComplete = (endDateValue) => {
      if (startDate !== null && endDateValue !== null) {
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDateValue.toISOString();
        onChange({
          target: { name, value: `${formattedStartDate};${formattedEndDate}` },
        });
      }

    };
  
    const label = isMandatory ? `${tooltip} *` : tooltip;
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem', marginTop: '1rem', marginRight: 500}}>
          <DateTimePicker
            views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
            value={startDate}
            onChange={(date) => handleDateTimeChange(date, true)}
            ampm={false}
            label={label}
            timeSteps={{ minutes: 1, seconds: 1 }}
          />
          <DateTimePicker
            views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
            value={endDate}
            onChange={(date) => handleDateTimeChange(date, false)}
            ampm={false}
            label={label}
            timeSteps={{ minutes: 1, seconds: 1 }}
          />
        </div>
      </LocalizationProvider>
    );
  };