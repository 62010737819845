import * as React from 'react';
import { useDemoData } from '@mui/x-data-grid-generator';
import { Theme, alpha, styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import PaginationItem from '@mui/material/PaginationItem';
import { blue } from '@mui/material/colors';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
    DataGridPro,
    useGridApiContext,
    useGridSelector,
    gridPageSelector,
    gridPageCountSelector,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridCellEditStopReasons,
    GridCellParams,
    GridRowId,
    GridColumnMenuItemProps,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Menu, Snackbar, ListItemIcon, ListItemText, MenuItem, Modal, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { createTableRow, delMultipleTableRow, delTableColumn, getTableNameById, getTablesColumns, getTablesData, modifyColumnPosition, modifyTableRow, modifyTableRowFileField, postTableRowField, postTableRowFileField } from '../../../redux/tables/thunk';
import { useParams } from 'react-router-dom';
import { types } from '../../../components/Enums/ColumnsTypes';
import { FileHandle } from '../../../components/Reports/FieldsComponents';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FilterColumnsArgs, GetColumnForNewFilterArgs, GridColumnMenu, GridFilterModel } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { ImportTable } from './Import/ImportTable';
import { ExportModal } from './Export/ExportModal';
import { UploadFileModal } from './UploadFileModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RuleIcon from '@mui/icons-material/Rule';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import SecurityIcon from '@mui/icons-material/Security';
import { ClockIcon } from "@mui/x-date-pickers";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRangeModal } from './DateRangeModal';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface MenuItemWithSubmenuProps {
    menuItem: any; // Aquí puedes definir un tipo más específico si es necesario
    handleCopy: (columnField: string, subItemField: string | null) => void;
    handleOpenSubmenu: (event: React.MouseEvent<HTMLElement>, index: number) => void;
    index: number;
    anchorEl: HTMLElement | null;
    openSubmenu: number | null;
    setOpenSubmenu: React.Dispatch<React.SetStateAction<number | null>>;
    handleCloseMenu: () => void;
}

interface PaginationModel {
    page: number;
    pageSize: number;
    rowsCount: number;  // Hacer rowsCount opcional
}

interface CustomPaginationProps {
    paginationModel: PaginationModel;
    setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
    totalCount: number;
    loading: boolean
}



function DeleteColumn(props: GridColumnMenuItemProps) {
    const { handleDeleteColumn } = props;
    return (
        <MenuItem onClick={handleDeleteColumn}>
            <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete Column</ListItemText>
        </MenuItem>
    );
}

function PermissionColumn(props: GridColumnMenuItemProps) {
    const { handlePermissionAction } = props;

    return (
        <MenuItem onClick={handlePermissionAction}>
            <ListItemIcon>
                <SecurityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Permission</ListItemText>
        </MenuItem>
    );
}

function EditColumnName(props: GridColumnMenuItemProps) {
    const { handleEditColumn } = props;
    return (
        <MenuItem onClick={handleEditColumn}>
            <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit column name</ListItemText>
        </MenuItem>
    );
}

function CustomColumnMenu(props: any, roles: string[], apiRefTable: React.MutableRefObject<GridApiPro>, pageNumber: number, pageSize: number) {
        const dispatch = useAppDispatch();
        const { id } = useParams();
        const handleDeleteColumn = () => {            
            dispatch(delTableColumn({
                columnId: props.colDef.id,
                tableId: id
            }, pageNumber, pageSize));
        };
        
        const handlePermissionAction = () => {
            apiRefTable.current.hideColumnMenu();
            props.handleOpenColumnPermissions(props.colDef.id);
        };

        return (
            <GridColumnMenu
                {...props}  
                slots={{
                    delete: DeleteColumn,
                    permission: roles.includes("CanManageTables") ? PermissionColumn : null,
                    columnMenuColumnsItem: null,
                    columnMenuFilterItem: null
                }}
                slotProps={{
                    delete: {
                        displayOrder: 15,
                        handleDeleteColumn: handleDeleteColumn,
                    },
                    permission: {
                        displayOrder: 16,
                        handlePermissionAction: handlePermissionAction
                    }
                }}
            />
        );
    }

function MenuItemWithSubmenu(props: MenuItemWithSubmenuProps) {
    const { menuItem, handleCopy, handleOpenSubmenu, index, anchorEl, openSubmenu, setOpenSubmenu } = props;

    return (
        <>
            <MenuItem
                onClick={menuItem.items && menuItem.items.length > 0
                    ? (event) => handleOpenSubmenu(event, index)
                    : () => handleCopy(menuItem.label, null)}
                aria-controls={`submenu-${index}`}
                aria-haspopup="true"
            >
                <ListItemIcon>
                    {menuItem.leftIcon}
                </ListItemIcon>
                <ListItemText primary={menuItem.label} />
                {menuItem.items && menuItem.items.length > 0 && <ArrowRightIcon />}
            </MenuItem>
            {menuItem.items && (
                <Menu
                    id={`submenu-${index}`}
                    anchorEl={openSubmenu === index ? anchorEl : null}
                    keepMounted
                    open={Boolean(openSubmenu === index)}
                    onClose={() => setOpenSubmenu(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    style={{
                        marginTop: '1.5%', // Ajusta este valor si es necesario
                        marginLeft: '4%', // Ajusta este valor para mover el submenú hacia la derecha
                    }}
                >
                    {menuItem.items.map((subMenuItem: any) => (
                        <MenuItem key={subMenuItem.label} onClick={() => handleCopy(menuItem.label, subMenuItem.label)}>
                            <ListItemText primary={subMenuItem.label} />
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
}

function firstLetterToLowerCase(str: string) {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
}

const CustomToolbar = (apiRefTable: React.MutableRefObject<GridApiPro>, rows: any[], handleCreteColumn: any, setSelectedRows: React.Dispatch<React.SetStateAction<GridRowId[]>>, selectedRows: GridRowId[], handleOpenImportModal: () => void, handleOpenExportModal: () => void, handleOpenPermissions: () => void, permissionsObject: string[], roles: string[]) => {
    const { id: tableId } = useParams();
    const [showDeleteRows, setShowDeleteRows] = React.useState(false)
    const [showCopyRows, setShowCopyRows] = React.useState(false)
    const dispatch = useAppDispatch()
    const { tablesData, status } = useAppSelector(state => state.tables)
    const addColumnHandler = () => {
        if (status !== "loading")
            dispatch(createTableRow(tablesData.id, rows.length, tablesData.pageNumber, tablesData.pageSize))
    };
    const handleDeleteRows = () => {
        Swal.fire({
            title: "Are you sure you want to delete the rows?",
            text: "Once deleted there is no going back!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                if (status !== "loading")
                    dispatch(delMultipleTableRow({
                        tableId: tableId,
                        rows: selectedRows
                    }, tableId, tablesData.pageNumber, tablesData.pageSize))
            }
        });
    };

    const menuItemsData = {
        items: tablesData.columns.map(e => {

            if (e.type === types.Account) {
                return (

                    {
                        label: e.name,
                        leftIcon: <ApartmentIcon />,
                        items: [

                            {
                                label: 'Name',
                                // leftIcon: <KeyIcon />,
                                onClick: () => handleCopy(e.name, 'name')
                            },
                            {
                                label: 'BusinessName',
                                // leftIcon: <KeyIcon />,
                                onClick: () => handleCopy(e.name, 'businessName')
                            },
                            {
                                label: 'CountryId',
                                // leftIcon: <KeyIcon />,
                                onClick: () => handleCopy(e.name, 'countryId')
                            },
                            {
                                label: 'Address',
                                // leftIcon: <SignpostIcon />,
                                onClick: () => handleCopy(e.name, 'address')

                            },

                            {
                                label: 'Email',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'email')
                            },
                            {
                                label: 'PhoneNumber',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'phoneNumber')
                            },
                            {
                                label: 'Website',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'websitename')
                            },
                            {
                                label: 'LinkedIn',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'linkedIn')
                            },
                            {
                                label: 'Instragram',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'Instragram')
                            },
                            {
                                label: 'Facebook',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'facebook')
                            },
                            {
                                label: 'Twitter',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'twitter')
                            },
                            {
                                label: 'TikTok',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'tikTok')
                            },
                            {
                                label: 'YouTube',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'youTube')
                            },
                            {
                                label: 'Threads',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'threads')
                            },
                            {
                                label: 'Twitch',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'twitch')
                            },
                            {
                                label: 'Notes',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'notes')
                            },
                            {
                                label: 'Size',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'size')
                            },
                        ]
                    }
                )
            } else if (e.type === types.Contact) {
                return (
                    {
                        label: e.name,
                        leftIcon: <PersonIcon />,
                        items: [
                            {
                                label: 'Name',
                                // leftIcon: <KeyIcon />,
                                onClick: () => handleCopy(e.name, 'name')
                            },
                            {
                                label: 'WorkPosition',
                                // leftIcon: <KeyIcon />,
                                onClick: () => handleCopy(e.name, 'workPosition')
                            },
                            {
                                label: 'CountryId',
                                // leftIcon: <KeyIcon />,
                                onClick: () => handleCopy(e.name, 'countryId')
                            },
                            {
                                label: 'Address',
                                // leftIcon: <SignpostIcon />,
                                onClick: () => handleCopy(e.name, 'address')
                            },

                            {
                                label: 'Email',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'email')
                            },
                            {
                                label: 'PhoneNumber',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'phoneNumber')
                            },
                            {
                                label: 'Website',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'website')
                            },
                            {
                                label: 'LinkedIn',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'linkedin')
                            },
                            {
                                label: 'Instragram',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'instragram')
                            },
                            {
                                label: 'Facebook',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'facebook')
                            },
                            {
                                label: 'Twitter',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'twitter')
                            },
                            {
                                label: 'TikTok',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'tiktok')
                            },
                            {
                                label: 'YouTube',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'youtube')
                            },
                            {
                                label: 'Twitch',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'twitch')
                            },
                            {
                                label: 'Threads',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'threads')
                            },
                            {
                                label: 'Notes',
                                // leftIcon: <NumbersIcon />,
                                onClick: () => handleCopy(e.name, 'notes')
                            },
                        ]
                    }
                )
            }
            else if (e.type === types.Text) {
                return (
                    {
                        label: e.name,
                        leftIcon: <FormatColorTextIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.Number || e.type === types.Decimal) {
                return (
                    {
                        label: e.name,
                        leftIcon: <NumbersIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.Date) {
                return (
                    {
                        label: e.name,
                        leftIcon: <CalendarMonthIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.Bool) {
                return (
                    {
                        label: e.name,
                        leftIcon: <RuleIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.Money) {
                return (
                    {
                        label: e.name,
                        leftIcon: <AttachMoneyIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            }
            else if (e.type === types.EndUser) {
                return (
                    {
                        label: e.name,
                        leftIcon: <SupervisedUserCircleIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.Id) {
                return (
                    {
                        label: e.name,
                        leftIcon: <SupervisedUserCircleIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.TimeSpan) {
                return (
                    {
                        label: e.name,
                        leftIcon: <DateRangeIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            } else if (e.type === types.ExactTime) {
                return (
                    {
                        label: e.name,
                        leftIcon: <ClockIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            }
            else {
                return (
                    {
                        label: e.name,
                        leftIcon: <AddBoxIcon />,
                        onClick: () => handleCopy(e.name, null)
                    }
                )
            }
        }),
    };


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const [openSubmenu, setOpenSubmenu] = React.useState<number | null>(null);

    const handleOpenSubmenu = (
        event: React.MouseEvent<HTMLElement>,
        index: number
    ) => {
        setOpenSubmenu(openSubmenu === index ? null : index);
    };

    const apiRef = useGridApiRef();

    const handleCopy = (columnField: string, subItemField: string | null) => {
        apiRefTable.current.setRowSelectionModel([]);
        setSelectedRows([])
        let copyText = selectedRows.map(rowId => {
            let row = rows.find(r => r.id === rowId);
            if (!row) return '';

            let value = row[columnField];
            let columnType = tablesData.columns.find(column => column.name === columnField)?.type;

            if (columnType === types.Label && typeof value === 'object' && value !== null) {
                return value.tag;
            } else if (columnType === types.Account && typeof value === 'object' && value !== null && subItemField !== null) {
                const formattedSubItemField = firstLetterToLowerCase(subItemField);
                const fieldValue = value[formattedSubItemField] ? value[formattedSubItemField].toString() : '';
                return `${fieldValue}`;
            } else if (columnType === types.Contact && Array.isArray(value) && subItemField !== null) {
                return value.map(contact => {
                    const formattedSubItemField = firstLetterToLowerCase(subItemField);
                    return contact[formattedSubItemField];
                }).join(';');
            } else if (value instanceof Date) {
                return value.toLocaleDateString();
            } else if (columnType === types.Bool) {
                if (value !== null){
                    return 'true'
                }
                return 'false'
            } else if (columnType === types.File) {
                if(value !== null && value.props !== null){
                    return value.props.url;
                }
                return '';                         
            } else if (typeof value === 'object' && value !== null) {
                return JSON.stringify(value);
            } else {
                return value ? value.toString() : '';
            }
        }).filter(text => text.trim() !== '').join(';');
        setOpenSubmenu(null);
        handleCloseMenu();
        navigator.clipboard.writeText(copyText).then(() => {
            setOpenSnackbar(true);
            apiRef.current.selectRows([], false);
        }).catch(err => {
        });
    };



    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };


    React.useEffect(() => {
        if (selectedRows.length > 0) {
            setShowDeleteRows(true)
        } else {
            setShowDeleteRows(false)
        }
    }, [selectedRows])

    React.useEffect(() => {
        if (selectedRows.length > 0) {
            setShowCopyRows(true)
        } else {
            setShowCopyRows(false)
        }
    }, [selectedRows])

    return (
        <GridToolbarContainer
            sx={{
                mb: 4
            }}
        >

            {/* <GridToolbarColumnsButton /> */}
            <GridToolbarFilterButton />
            {
                (permissionsObject.includes('CanCreate') ||
                    permissionsObject.length == 0) &&
                <>
                    <Button size='small' onClick={addColumnHandler} disabled={status === "loading"}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1
                            }}
                        >
                            <TableRowsRoundedIcon fontSize='small' />
                            Add Row
                        </Box>
                    </Button>
                    <Button size='small' onClick={handleCreteColumn} disabled={status === "loading"}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1
                            }}
                        >
                            <ViewColumnRoundedIcon fontSize='small' />
                            Create column
                        </Box>
                    </Button>
                </>
            }

            {
                (showDeleteRows && (permissionsObject.includes('CanDeleteData') ||
                    permissionsObject.length == 0)) &&
                <Button size='small' onClick={handleDeleteRows} disabled={status === "loading"}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}
                    >

                        <DeleteForeverIcon fontSize='small' />
                        Delete selected rows
                    </Box>
                </Button>
            }
            <Button size='small' onClick={handleOpenImportModal} disabled={status === "loading"}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1
                    }}
                >
                    <UploadIcon fontSize='small' />
                    Import CSV
                </Box>
            </Button>
            <Button size='small' disabled={status === "loading"} onClick={handleOpenExportModal}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1
                    }}
                >
                    <GetAppIcon fontSize='small' />
                    Export Table
                </Box>
            </Button>
            {
                roles.includes("CanManageRoles") &&
                <Button size='small' disabled={status === "loading"} onClick={handleOpenPermissions}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}
                    >
                        <PermIdentityIcon fontSize='small' />
                        Permissions
                    </Box>
                </Button>
            }
            {
                showCopyRows && (
                    <>
                        <Button size='small' onClick={handleOpenMenu} disabled={status === "loading"}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <ContentCopyIcon fontSize='small' />
                                Copy
                            </Box>
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            {menuItemsData.items.map((menuItem, index) => (
                                <MenuItemWithSubmenu
                                    key={menuItem.label}
                                    menuItem={menuItem}
                                    handleCopy={handleCopy}
                                    handleOpenSubmenu={handleOpenSubmenu}
                                    index={index}
                                    anchorEl={anchorEl}
                                    openSubmenu={openSubmenu}
                                    setOpenSubmenu={setOpenSubmenu}
                                    handleCloseMenu={handleCloseMenu}
                                />
                            ))}
                        </Menu>
                    </>
                )
            }

        </GridToolbarContainer>
    );
};
function customCheckbox(theme: Theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
}

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .even`]: {
        backgroundColor: blue[50],
        '&:hover, &.Mui-hovered': {
            backgroundColor: blue[50],
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    border: 0,
    minHeight: 500,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',

    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
    },
    '& .MuiDataGrid-cell': {
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0,
    },

    ...customCheckbox(theme),
}));
function reubicarObjeto(array: any, id: string, nuevaPosicion: number): { id: number; position: number }[] {
    // Encuentra el objeto que coincide con el ID

    const objeto = array.find((item: any) => item.id === id);

    if (objeto) {
        // Elimina el objeto del array original
        array = array.filter((item: any) => item.id !== id);

        // Actualiza la posición del objeto
        objeto.position = nuevaPosicion;

        // Inserta el objeto en la nueva posición
        array.splice(nuevaPosicion, 0, objeto);

        // Actualiza las posiciones de los otros objetos
        array.forEach((item: any, index: number) => (item.position = index));
    }

    return array;
}

function CustomPagination({ paginationModel, setPaginationModel, totalCount, loading }: CustomPaginationProps) {
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
        setPaginationModel(prev => ({ ...prev, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPaginationModel(prev => ({ ...prev, pageSize: parseInt(event.target.value, 10), page: 0 }));
    };

    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
            <select
                style={{
                    marginRight: '10px',
                    padding: '5px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    background: loading ? '#f3f3f3' : '#fff',
                    color: loading ? '#999' : '#333',
                    cursor: loading ? 'not-allowed' : 'pointer'
                }}
                value={paginationModel.pageSize}
                onChange={handleChangeRowsPerPage}
                disabled={loading}
            >
                {[5, 10, 25, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    {pageSize}
                    </option>
                ))}
            </select>
            
            <IconButton
                onClick={(event) => handleChangePage(event, paginationModel.page - 1)}
                disabled={paginationModel.page === 0 || loading}
                sx={{
                    margin: 1, 
                    padding: 1, 
                    '&:disabled': {
                        background: '#f3f3f3',
                        cursor: 'not-allowed',
                    },
                    borderColor: 'divider',
                }}
            >
                <ArrowBackIosIcon />
            </IconButton>
            <IconButton
                onClick={(event) => handleChangePage(event, paginationModel.page + 1)}
                disabled={paginationModel.page >= Math.ceil(totalCount / paginationModel.pageSize) - 1 || loading}
                sx={{
                    margin: 1, 
                    padding: 1,
                    '&:disabled': {
                        background: '#f3f3f3',
                        cursor: 'not-allowed',
                    },
                    borderColor: 'divider', 
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
}


interface ColumnData {
    id: string,
    field: string,
    value: any,
    type: string
}
interface ColumnDataFile {
    id: string,
    field: string,
    value: any,
    type: string,
    name: string,
    row: any
}
interface ColumnDataDateRange {
    id: string,
    field: string,
    value: any,
    type: string,
    name: string,
    row: any
}
interface FilterItem {
    operator: string;
    value: string;
    columnId: string;
}
interface FilterModel {
items: FilterItem[];
}
interface FilterParams {
    [key: string]: string;
  }
interface props {
    handleCreateColumn: () => void
    rows: any[],
    columns: any[],
    openFileModal: boolean,
    openDateRangeModal: boolean,
    handleCloseFileModal: () => void,
    handleCloseDateRangeModal: () => void,
    handleOpenPermissions: () => void,
    permissionsObject: string[],
    roles: string[]
    apiRefTable: React.MutableRefObject<GridApiPro>,
    handleOpenColumnPermissions: () => void

}
export default function MUIXTable({ handleCreateColumn, rows, columns, openFileModal, openDateRangeModal, handleCloseFileModal, handleCloseDateRangeModal, handleOpenPermissions, permissionsObject, roles, apiRefTable, handleOpenColumnPermissions }: props) {
    const dispatch = useAppDispatch()
    const { tablesData } = useAppSelector(state => state.tables)
    const { id: tableIdAux } = useParams();
    const { tenantId, id: userId } = useAppSelector((state) => state.auth);

    const [openImportModal, setOpenImportModal] = React.useState<boolean>(false)
    const [openExportModal, setOpenExportModal] = React.useState<boolean>(false)
    const handleCloseImportModal = () => {
        setOpenImportModal(false)
    }
    const handleOpenImportModal = () => {
        setOpenImportModal(true)
    }

    const handleCloseExportModal = () => {
        setOpenExportModal(false)
    }
    const handleOpenExportModal = () => {
        setOpenExportModal(true)
    }

    const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([])
    const [columnIdEditing, setColumnIdEditing] = React.useState<ColumnData>({
        id: "",
        field: "",
        value: "",
        type: ""
    })
    const [columnIdEditingFile, setColumnIdEditingFile] = React.useState<ColumnDataFile>({
        id: "",
        field: "",
        value: "",
        type: "",
        name: "",
        row: {}
    })
    const [columnIdEditingDateRange, setColumnIdEditingDateRange] = React.useState<ColumnDataDateRange>({
        id: "",
        field: "",
        value: "",
        type: "",
        name: "",
        row: {}
    })

    //Pagination 
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
        rowsCount: 0,
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    //Filter Columns

    const filterColumns = ({ field, columns, currentFilters } : FilterColumnsArgs) => {
        const filteredFields = currentFilters?.map((item) => item.field);
        return columns.filter(
          (colDef) =>
            colDef.filterable &&
            (colDef.field === field || !filteredFields.includes(colDef.field)),
        ).map((column) => column.field);
      };

    const getColumnForNewFilter = ({ currentFilters, columns } : GetColumnForNewFilterArgs) => {
        if (currentFilters?.length >= 4) {
            return null; 
        }    
        const filteredFields = currentFilters?.map(({ field }) => field);
        const columnForNewFilter = columns.find(
          (colDef) => colDef.filterable && !filteredFields.includes(colDef.field),
        );
        return columnForNewFilter?.field ?? null;
      };

    //Filter
    const [filterModel, setFilterModel] = React.useState<FilterModel>({ items: [] });
    const [logicOperator, setLogicOperator] = React.useState<string>('');
    const handleFilterChange = (model: GridFilterModel): void => {
        const newFilterModel: FilterModel = transformGridFilterModelToFilterModel(model);
        setFilterModel(newFilterModel);
        setLogicOperator(model.logicOperator!)
    };

    const transformGridFilterModelToFilterModel = (model: GridFilterModel): FilterModel => {
        const items = model.items.map(item => {
            const column = tablesData.columns.find(column => column.name === item.field);

            return {
            operator: item.operator,
            value: item.value || '',
            columnId: column?.id || ''

        }});
        return { items };
    };  

    const getFilterParams = (filters: FilterModel): string => {
        const filterColumnIds: string[] = [];
        const filterValues: string[] = [];
        const filterOperators: string[] = [];

        let params = ''
        if (Array.isArray(filters.items)) {

            filters.items.forEach((filter) => {
                if (filter.columnId) {
                    filterColumnIds.push(filter.columnId);
                    filterValues.push(encodeURIComponent(filter.value));
                    filterOperators.push(filter.operator);
                }
            });

            params = `FilterColumnIds=${filterColumnIds.join('&FilterColumnIds=')}&FilterOperators=${filterOperators.join('&FilterOperators=')}&FilterValues=${filterValues.join('&FilterValues=')}`;
        }
        
        return params;
    };

    const fetchFilteredData = (page: number, pageSize: number, filters: FilterModel): void => {
        setLoading(true);
        const filterParams: string = getFilterParams(filters);
        dispatch(getTablesData(tableIdAux, page, pageSize, filterParams, logicOperator)).finally(() => {setLoading(false)});
    };

    const [newFile, setNewFile] = React.useState<any>(null)
    const [dateRangeValue, setDateRangeValue] = React.useState<string | null>(null);

    const onCreateField = (params: any) => {

        const colId = params.colDef.id
        const rowId = params.row.rowId
        const position = params.row.position

        if (params.reason !== GridCellEditStopReasons.escapeKeyDown) {

            setColumnIdEditing({
                id: colId,
                field: params.field,
                value: params.value,
                type: params.colDef.type
            })

        }
    }
    const processRowUpdate = (newRow: any) => {
        if (columnIdEditing.id != "" && columnIdEditing.field != "") {
            if (columnIdEditing.value === null) {

                if (columnIdEditing.type === 'date') {
                    dispatch(
                        postTableRowField(
                            {
                                "columnId": columnIdEditing.id,
                                "rowId": newRow.id,
                                "position": newRow.position,
                                "value": newRow[columnIdEditing.field],
                                "token": "a",
                                tableId: tableIdAux

                            },
                            tablesData.pageNumber,
                            tablesData.pageSize,
                        )
                    )
                }
                else if(columnIdEditing.type === 'exactTime'){
                    dispatch(
                        postTableRowField(
                            {
                                "columnId": columnIdEditing.id,
                                "rowId": newRow.id,
                                "position": newRow.position,
                                "value": newRow[columnIdEditing.field],
                                "token": "a",
                                tableId: tableIdAux

                            },
                            tablesData.pageNumber,
                            tablesData.pageSize,
                        )
                    )
                }
                else if (columnIdEditing.type === 'boolean') {
                    let currentColumn = tablesData.columns.find(
                        (column) => column.name === columnIdEditing.field
                    );
                    let field = currentColumn?.fields.find((field) => field.rowId === newRow.id)

                    if (field)
                        dispatch(modifyTableRow({
                            "columnId": columnIdEditing.id,
                            "id": field?.id,
                            "position": newRow.position,
                            "value": `true`,
                            "token": "a",
                            tableId: tableIdAux
                        }, tablesData.pageNumber, tablesData.pageSize))
                    else {
                        dispatch(
                            postTableRowField(
                                {
                                    "columnId": columnIdEditing.id,
                                    "rowId": newRow.id,
                                    "position": newRow.position,
                                    "value": `${newRow[columnIdEditing.field]}`,
                                    "token": "a",
                                    tableId: tableIdAux,

                                }
                            )
                        )
                    }
                }
                else if (columnIdEditing.type === 'singleSelect') {

                    if (newRow[columnIdEditing.field].id) {

                        dispatch(
                            postTableRowField(
                                {
                                    "columnId": columnIdEditing.id,
                                    "rowId": newRow.id,
                                    "position": newRow.position,
                                    "value": `${newRow[columnIdEditing.field] === null ? "" : newRow[columnIdEditing.field].id}`,
                                    "token": "a",
                                    tableId: tableIdAux

                                }
                                ,
                            tablesData.pageNumber,
                            tablesData.pageSize,
                            )
                        )
                    }
                    else {

                        if (newRow[columnIdEditing.field].length && typeof newRow[columnIdEditing.field] != 'string') {
                            let ids = newRow[columnIdEditing.field].map((e: any) => e.id)

                            dispatch(postTableRowField({
                                "columnId": columnIdEditing.id,
                                "position": newRow.position,
                                "contacts": ids,
                                "rowId": newRow.id,

                                "value": "a",
                                "token": "a",
                                tableId: tableIdAux
                            },
                            tablesData.pageNumber,
                            tablesData.pageSize,))
                        } else {
                            dispatch(postTableRowField(
                                {
                                    "columnId": columnIdEditing.id,
                                    "rowId": newRow.id,
                                    "position": newRow.position,
                                    "value": `${newRow[columnIdEditing.field] === null ? "" : newRow[columnIdEditing.field]}`,
                                    "token": "a",
                                    tableId: tableIdAux

                                },
                                tablesData.pageNumber,
                                tablesData.pageSize,
                            ))
                        }
                    }
                }
                else {
                    dispatch(
                        postTableRowField(
                            {
                                "columnId": columnIdEditing.id,
                                "rowId": newRow.id,
                                "position": newRow.position,
                                "value": `${newRow[columnIdEditing.field] === null ? "" : newRow[columnIdEditing.field]}`,
                                "token": "a",
                                tableId: tableIdAux

                            },
                            tablesData.pageNumber,
                            tablesData.pageSize,
                        )
                    )
                }
            }
            else {

                let currentColumn = tablesData.columns.find(
                    (column) => column.id === columnIdEditing.id
                );
                let field = currentColumn?.fields.find((field) => field.rowId === newRow.id)

                if (columnIdEditing.type === 'date') {
                    dispatch(modifyTableRow({
                        "columnId": columnIdEditing.id,
                        "id": field?.id,
                        "position": newRow.position,
                        "value": newRow[columnIdEditing.field],
                        "token": "a",
                        tableId: tableIdAux
                    }, tablesData.pageNumber, tablesData.pageSize))
                }
                else if(columnIdEditing.type === 'exactTime'){
                    dispatch(modifyTableRow({
                        "columnId": columnIdEditing.id,
                        "id": field?.id,
                        "position": newRow.position,
                        "value": newRow[columnIdEditing.field],
                        "token": "a",
                        tableId: tableIdAux
                    }, tablesData.pageNumber, tablesData.pageSize))
                }
                else if (columnIdEditing.type === 'singleSelect') {
                    if (newRow[columnIdEditing.field].id)
                        dispatch(modifyTableRow({
                            "columnId": columnIdEditing.id,
                            "id": field?.id,
                            "position": newRow.position,
                            "value": `${newRow[columnIdEditing.field].id}`,
                            "token": "a",
                            tableId: tableIdAux
                        }, tablesData.pageNumber, tablesData.pageSize))
                    else {
                        if (newRow[columnIdEditing.field].length && typeof newRow[columnIdEditing.field] != 'string') {
                            let ids = newRow[columnIdEditing.field].map((e: any) => e.id)

                            dispatch(modifyTableRow({
                                "columnId": columnIdEditing.id,
                                "id": field?.id,
                                "position": newRow.position,
                                "contacts": ids,
                                "value": "a",
                                "token": "a",
                                tableId: tableIdAux
                            }, tablesData.pageNumber, tablesData.pageSize))
                        } else
                            dispatch(modifyTableRow({
                                "columnId": columnIdEditing.id,
                                "id": field?.id,
                                "position": newRow.position,
                                "value": `${newRow[columnIdEditing.field]}`,
                                "token": "a",
                                tableId: tableIdAux
                            }, tablesData.pageNumber, tablesData.pageSize))
                    }
                }
                else
                    dispatch(modifyTableRow({
                        "columnId": columnIdEditing.id,
                        "id": field?.id,
                        "position": newRow.position,
                        "value": `${newRow[columnIdEditing.field]}`,
                        "token": "a",
                        tableId: tableIdAux
                    }, tablesData.pageNumber, tablesData.pageSize))
            }
        } else {

        }
        return newRow;
    };

    interface handleChageProps {
        target: {
            name: string,
            value: any
        }
    }
    const HandleChangeFile = ({ target }: handleChageProps) => {

        setNewFile(target.value)
    }
    const handleUploadCellFile = () => {

        if (columnIdEditingFile.value === undefined || columnIdEditingFile.value === null) {

            dispatch(postTableRowFileField({
                columnId: columnIdEditingFile.id,
                position: columnIdEditingFile.row.position,
                value: newFile,
                tableId: tableIdAux,
                token: "a",
                rowId: columnIdEditingFile.row.id,
            }, handleCloseFileModal, tablesData.pageNumber, tablesData.pageSize))
        } else {
            let currentColumn = tablesData.columns.find(
                (column) => column.name === columnIdEditingFile.field
            );
            let field = currentColumn?.fields.find((field) => field.rowId === columnIdEditingFile.row.id)


            if (field) {

                dispatch(
                    modifyTableRowFileField({
                        id: field.id,
                        columnId: columnIdEditingFile.id,
                        position: columnIdEditingFile.row.position,
                        value: newFile,
                        editedBy: userId,
                        tableId: tableIdAux,
                        token: "a",
                    }, handleCloseFileModal, tablesData.pageNumber, tablesData.pageSize),
                );
            }

        }

    }

    const handleDateRangeEditCell = (dateRange: string) => {

        if (columnIdEditingDateRange.value === undefined || columnIdEditingDateRange.value === null) {
            dispatch(
                postTableRowField(
                    {
                        "columnId": columnIdEditingDateRange.id,
                        "rowId": columnIdEditingDateRange.row.id,
                        "position": columnIdEditingDateRange.row.position,
                        "value": dateRange,
                        "token": "a",
                        tableId: tableIdAux

                    },
                    tablesData.pageNumber,
                    tablesData.pageSize,
                )
            )
            handleCloseDateRangeModal()
        } else {

            let currentColumn = tablesData.columns.find(
                (column) => column.name === columnIdEditingDateRange.field
            );
            let field = currentColumn?.fields.find((field) => field.rowId === columnIdEditingDateRange.row.id)

            if (field) {

            dispatch(modifyTableRow({
                "columnId": columnIdEditingDateRange.id,
                "id": field?.id,
                "position": columnIdEditingFile.row.position,
                "value": dateRange,
                tableId: tableIdAux
            }, tablesData.pageNumber, tablesData.pageSize))

            handleCloseDateRangeModal()

        }

        }

    }

    React.useEffect(() => {        
        if (Array.isArray(filterModel.items) && filterModel.items.length > 0) {
            fetchFilteredData(paginationModel.page, paginationModel.pageSize, filterModel)
        }
        else {
            setLoading(true);
            dispatch(getTablesData(tableIdAux, paginationModel.page, paginationModel.pageSize, '', '')).finally(() => {setLoading(false)});
        }
    }, [paginationModel, filterModel]);


    return (
        <Box sx={{ height: "600px", width: '100%', "&  .MuiDataGrid-cell:focus .file-cell svg": { display: 'block', opacity: 1 } }}>

            <StyledDataGrid
                loading={loading}
                checkboxSelection
                disableRowSelectionOnClick
                //disableColumnMenu
                apiRef={apiRefTable}
                onColumnOrderChange={(e: any) => {

                    let tempColumns = [...columns]
                    let newOrder = reubicarObjeto(tempColumns.splice(1), e.column.id as string, e.targetIndex - 2)

                    dispatch(modifyColumnPosition({
                        "tableId": tableIdAux,
                        "columnId": e.column.id,
                        "newPosition": e.targetIndex - 2,
                        "token": "a"
                    }))
                }}
                onRowSelectionModelChange={(rowSelectionModel) => {
                    setSelectedRows(rowSelectionModel);
                }}
                pageSizeOptions={[5,10, 25, 50]}
                paginationMode="server"
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={(model, details) => {

                    if(loading === false){

                        const updatedModel = {
                            ...model,
                            rowsCount: tablesData.totalCount,
                            page: model.page,
                            pageSize: model.pageSize
                        };
                        setPaginationModel(updatedModel);
                    }
                }}
                rowCount={tablesData.totalCount}   
                filterMode="server"
                onFilterModelChange={handleFilterChange}
                slots={{
                    //pagination: CustomPagination,
                    pagination: () => (
                        <CustomPagination
                            paginationModel={paginationModel}
                            setPaginationModel={setPaginationModel}
                            totalCount={tablesData.totalCount}
                            loading={loading}
                        />
                    ),
                    toolbar: () => CustomToolbar(apiRefTable, rows, handleCreateColumn, setSelectedRows, selectedRows, handleOpenImportModal, handleOpenExportModal, handleOpenPermissions, permissionsObject, roles),
                    columnMenu: (props) => CustomColumnMenu({ ...props, handleOpenColumnPermissions }, roles, apiRefTable, paginationModel.page, paginationModel.pageSize),
                    
                }}
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                    filterPanel: {
                        filterFormProps: {
                            filterColumns, 
                          },
                        getColumnForNewFilter,
                    }
                }}
                // onCellEditStart={onCreateField}
                onCellClick={(cell: any) => {
                    // if (process.env.NODE_ENV === 'development')
                    //     // console.log(apiRefTable.current.stopCellEditMode({}));
                    if (cell.colDef.columnType === types.File) {
                        setColumnIdEditingFile({
                            id: cell.colDef.id,
                            field: cell.colDef.field,
                            value: cell.value,
                            type: "file",
                            name: cell.colDef.name,
                            row: cell.row
                        })

                    }
                    if (cell.colDef.columnType === types.TimeSpan) {
                        setColumnIdEditingDateRange({
                            id: cell.colDef.id,
                            field: cell.colDef.field,
                            value: cell.value,
                            type: "timeSpan",
                            name: cell.colDef.name,
                            row: cell.row
                        })

                    }
                    else if (cell.isEditable && cell.cellMode !== "edit" && (cell.value === null || cell.value === "")) {
                        apiRefTable.current.startCellEditMode({ id: cell.row.id, field: cell.colDef.field })

                    }
                }}


                onCellEditStop={(params) => {
                    // if (params.value) {
                    return onCreateField(params)
                    // }
                }}
                processRowUpdate={processRowUpdate}
                initialState={{ pinnedColumns: { left: ['Position'], } }}
            />
            <UploadFileModal
                open={openFileModal}
                fileHandleChange={HandleChangeFile}
                fileName={columnIdEditingFile.name}
                fileValue={columnIdEditingFile.value}
                handleClose={handleCloseFileModal}
                handleSubmit={handleUploadCellFile}
            />
            <DateRangeModal
                open={openDateRangeModal}
                name={columnIdEditingDateRange.field}
                value={columnIdEditingDateRange.value}
                handleClose={handleCloseDateRangeModal}
                handleSubmit={(dateRangeString) => {
                    handleDateRangeEditCell(dateRangeString);
                  }}
            />
            <Modal open={openImportModal} onClose={handleCloseImportModal}>
                <ImportTable handleCloseImportModal={handleCloseImportModal} />
            </Modal>
            <Modal open={openExportModal} onClose={handleCloseExportModal}>
                <ExportModal handleCloseExportModal={handleCloseExportModal} />
            </Modal>
        </Box >
    );
}