import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, IconButton, InputLabel, List, ListItem, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { Label, LabelGroup, LabelObject } from '../../redux/labels/slice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Circle, Colorful } from '@uiw/react-color';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { deleteLabel, dragableLabel, updateLabel } from '../../redux/labels/thunk';
import { useMeasurePosition } from './Hooks/useMeasurePosition';
import { PanInfo, motion } from "framer-motion";
import Swal from 'sweetalert2';

interface DraggableItemProps {
    item: Label
    index: number;
    currentPermissions: any
    roles: string[]
}
export const DraggableItem = ({ i, height, updatePosition, updateOrder, label, currentPermissions, currentLabelGroup, editingLabel, setEditingLabel, roles }: any) => {
    const dispatch = useAppDispatch()
    const { status } = useAppSelector(state => state.labels)
    const { tenantId } = useAppSelector(state => state.auth)

    const [editing, setEditing] = useState(false)
    const [labelData, setLabelData] = useState(label)


    const [isDragging, setDragging] = useState(false);

    const ref = useMeasurePosition((pos: number) => updatePosition(i, pos));


    const handleChangeColor = (color: string) => {
        setLabelData({ ...labelData, hexColor: color })
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setLabelData({ ...labelData, tag: input });
    };
    const handleSave = () => {
        dispatch(updateLabel(
            { ...labelData, token: '' }, tenantId, setEditingLabel
        ))
    }
    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure you want to delete this Label?",
            text: "Once deleted it is not possible to recover the information",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteLabel(
                    labelData.id, tenantId
                ))
            }
        });

    }
    const handleEdit = () => {
        setEditingLabel(label.id)
    }

    const handleDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        dispatch(dragableLabel({
            "labelGroupId": currentLabelGroup.labelGroup.id,
            "id": label.id,
            "newState": i
        }, tenantId))
        setDragging(false)
    }
    return (
        <li
            style={{
                padding: 0,
                width: '100%',
                // height,
                // If we're dragging, we want to set the zIndex of that item to be on top of the other items.
                zIndex: isDragging ? 10 : 1
            }}
        >
            <motion.div
                ref={ref}
                layout
                initial={false}
                style={{
                    background: "white",
                    // height,
                    borderRadius: 5,
                    color: '#000',
                    width: '100%',

                }}
                whileHover={{
                    scale: 1,
                    boxShadow: "0px 3px 3px rgba(0,0,0,0.15)"
                }}
                whileTap={{
                    scale: 1.001,
                    boxShadow: "0px 5px 5px rgba(0,0,0,0.1)"
                }}
                drag="y"
                onDragStart={() => setDragging(true)}
                onDragEnd={handleDragEnd}
                onViewportBoxUpdate={(_viewportBox, delta) => {
                    isDragging && updateOrder(i, delta.y.translate);
                }}
            >
                <Paper elevation={3} style={{ padding: 10, width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            editingLabel === label.id ?
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <TextField
                                        value={labelData.tag}
                                        variant='standard'
                                        size='small'
                                        onChange={handleChange}
                                    />
                                </Box>
                                :
                                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                                    <Typography fontSize={20} fontWeight={500}>
                                        #{i}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <span className="dot" style={{ backgroundColor: labelData.hexColor }}></span>
                                        <Typography>
                                            {labelData.tag}
                                        </Typography>
                                    </Box>
                                </Box>
                        }
                        <Box>
                            {
                                editingLabel === label.id ?
                                    <Box sx={{ display: 'flex', gap: 0 }}>
                                        <Tooltip title={'Save'} >
                                            <IconButton onClick={handleSave} disabled={status === 'loading'}>
                                                <SaveIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Cancel'} >
                                            <IconButton onClick={() => { setEditingLabel('') }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    :
                                    <Box
                                        sx={{ display: 'flex', gap: 0 }}
                                    >

                                        {
                                            ((currentPermissions.permissions.includes('CanUpdateData')) || (roles.includes('CanManageLabels') && currentPermissions.permissions.length === 0)) &&
                                            <Tooltip title={'Edit'}>
                                                <IconButton onClick={handleEdit}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            ((currentPermissions.permissions.includes('CanDeleteData')) || (roles.includes('CanManageLabels') && currentPermissions.permissions.length === 0)) &&
                                            <Tooltip title={'Delete'} >
                                                <IconButton onClick={handleDelete} disabled={status === 'loading'}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }

                                    </Box>
                            }
                        </Box>
                    </Box>
                    {
                        editingLabel === label.id &&
                        <Box>
                            <InputLabel
                                sx={{ fontSize: '1rem', fontWeight: 600, mb: 2 }}
                                required
                            >
                                Label color
                            </InputLabel>
                            <Box
                                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
                            >
                                <Colorful
                                    // style={{ marginLeft: 20 }}
                                    disableAlpha
                                    color={labelData.hexColor}
                                    onChange={(color) => {
                                        handleChangeColor(color.hex);
                                    }}
                                />
                                <Box>

                                    <Circle
                                        colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"]}
                                        style={{ marginLeft: 20, maxWidth: 250 }}
                                        color={labelData.hexColor}
                                        onChange={(color) => {
                                            handleChangeColor(color.hex);
                                        }}
                                    />
                                </Box>

                            </Box>
                        </Box>
                    }
                </Paper>
            </motion.div>
        </li >
    );
}
