import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, setAllCompanies, errorState, setCurrentCompany } from "./slice";
import CompaniesAgent from "./agent";

export const checkingCompanies = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}

export const getAllCompanies = (tenantId: string, pageSize: number, pageNumber: number, query: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await CompaniesAgent.getAllCompanies(tenantId, pageSize, pageNumber, query)
            .then((response) => {
                dispatch(setAllCompanies(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}

export const getById = (companyId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await CompaniesAgent.getById(companyId)
            .then((response) => {
                dispatch(setCurrentCompany(response.result));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })

    }
}

export const setCompany = (body: any, setOpen: any, pageSize: number, pageNumber: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await CompaniesAgent.addCompany(body)
            .then((response) => {
                dispatch(getAllCompanies(body.tenantId, pageSize, pageNumber, ''))
                setOpen(false)
            })
            .catch((error) => {
                dispatch(errorState())
            })
    }
}

export const deleteCompany = (body: any, pageSize: number, pageNumber: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await CompaniesAgent.delCompany(body.companyId)
            .then((response) => {
                dispatch(getAllCompanies(body.tenantId, pageSize, pageNumber, ''))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })

    }
}

export const modifyCompany = (body: any, pageSize: number, pageNumber: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await CompaniesAgent.putCompany(body)
            .then((response) => {
                dispatch(getAllCompanies(body.tenantId, pageSize, pageNumber, ''))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })

    }
}