import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { errorState, loading, setAutoRulesData, setAutoRulesName } from "./slice";
import AutoRulesAgent from "./agent";
import Swal from 'sweetalert2'
import { getPermissions } from "../permission/thunk";
export const checkingAutoRules = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}

//auto rules
export const getAutorulesnames = (TenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await AutoRulesAgent.getAutoRulesNames(TenantId)
            .then((response) => {
                dispatch(getPermissions());

                dispatch(setAutoRulesName(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}

export const postAutorule = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.postAutoRule(body)
            .then((response) => {
                if (!response.success) {
                    Swal.fire({
                        title: "Something went wrong",
                        text: response.message,
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
                dispatch(getPermissions())
                dispatch(getAutorulesnames(body.tenantId));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const delAutorule = (autoRuleId: string, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.delAutoRule(autoRuleId)
            .then((response) => {
                dispatch(getAutorulesnames(tenantId));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const putAutorule = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.putAutoRule(body)
            .then((response) => {
                dispatch(getAutorulesnames(body.tenantId));
                dispatch(getAutorulesData(response.result))
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}



//auto rules data
export const getAutorulesData = (autoRuleId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await AutoRulesAgent.getAutoRules(autoRuleId)
            .then((response) => {

                dispatch(setAutoRulesData(response));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState());
            })
    }
}


export const putAutoruleSettingName = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.putAutoRuleSettingName(body)
            .then((response) => {
                dispatch(getAutorulesData(body.autoRuleId))
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const postAutoruleBoxReport = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.postReportBox(body)
            .then((response) => {
                dispatch(getAutorulesData(body.tenantAutoruleId));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const postAutoruleBoxEmail = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.postEmailBox(body)
            .then((response) => {

                dispatch(getAutorulesData(body.tenantAutoruleId));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const putAutoruleEmailSetting = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.putEmailBox(body)
            .then((response) => {

                dispatch(getAutorulesData(body.autoRuleId))
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const delAutoruleSetting = (autoRuleSettingId: string, autoRuleId: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.delAutoRuleSetting(autoRuleSettingId)
            .then((response) => {
                dispatch(getAutorulesData(autoRuleId));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}

export const OnOffAutoRuleSettings = (state: boolean, id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await AutoRulesAgent.onOffAutoRule({
            "tenantAutoruleId": id,
            "state": state,
            "token": "a"
        })
            .then((response) => {
                dispatch(getAutorulesData(id));
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })
    }
}