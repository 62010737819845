import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { AutorulesComponent } from '../../components/Automation/AutorulesComponent'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/hooks'
import { PermissionsSidePanel } from '../Roles/SidePanel/PermissionsSidePanel'

export const AutorulesPage = () => {
  const voidArray: string[] = []
  const [open, setOpen] = useState(false)
  const [roles, setRoles] = useState<string[]>([])
  const [permissions, setPermissions] = useState<string[]>([])
  const handleClose = () => { setOpen(!open) }
  const { id } = useParams();
  const { permissionsList } = useAppSelector(state => state.permissions)
  useEffect(() => {
    if (permissionsList && id) {
      let permissionsData = permissionsList?.backend.autorules.find((e) => e.id === id)

      if (permissionsData) {
        setPermissions(permissionsData.permissions)
      }
      if (permissionsList.backend.roles)
        setRoles(permissionsList.backend.roles)
    }
  }, [id, permissionsList])
  return (
    <Box
      sx={{
        display: 'flex',
        // maxWidth:'100%'
        height: '100%',
        mt: 2,
        position: 'relative'
      }}
    >

      {
        (permissions.includes('CanRead') || permissions !== voidArray) &&
        <>
          <AutorulesComponent roles={roles} handleOpenPermissions={handleClose} permissionsObject={permissions} />

          {
            id &&
            <PermissionsSidePanel dataName='node' objectName='Autorule' objectId={id} objectType={3} open={open} handleClose={handleClose}></PermissionsSidePanel>
          }
        </>
      }
    </Box>
  )
}
