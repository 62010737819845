import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, green, purple, red, yellow } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, MenuItem, TextField, Tooltip } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import { EmailEdit } from '../Components/EmailEdit';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getTablesColumns } from '../../../redux/tables/thunk';
import { types } from '../../Enums/ColumnsTypes';
import SaveIcon from '@mui/icons-material/Save';
import { delAutoruleSetting, putAutoruleEmailSetting, putAutoruleSettingName } from '../../../redux/autoRules/thunk';
import { useParams } from 'react-router-dom';
import { replaceIdsWithNames } from '../functions/ReplaceIdOnHtml';
import Swal from 'sweetalert2';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { Link } from 'react-router-dom';
import { getAllRoles } from '../../../redux/roles/thunk';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface content {
    autoruleTaskId: string,
    sequencePosition: number,
    taskName: string,
    setting: any,
}

export const MiddlewareBox = ({ content, tableId, deleteComp }: { content: content, tableId: string, deleteComp: number }) => {

    const { id: autoRuleId } = useParams();
    const dispatch = useAppDispatch();
    const { tablesColumns: tableColumnsState, tablesName } = useAppSelector((state) => state.tables);
    const tableName: any = tablesName.find((table: any) => table.id === content.setting.tableId)
    const { tenantId } = useAppSelector(state => state.auth)
    const { roles: rolesState } = useAppSelector(state => state.role)

    const [expanded, setExpanded] = React.useState(false);
    const [openTitle, setOpenTitle] = React.useState(false)
    const [openEditEmail, setOpenEditEmail] = React.useState(false)
    const [item, setItem] = React.useState(content)
    const [settings, setSettings] = React.useState(content.setting)

    const handleOpenEditTitle = () => {
        setOpenTitle(true);
    };
    const handleCloseEditTitle = () => {
        setOpenTitle(false);
    };

    const handleOpenEditEmail = () => {
        setOpenEditEmail(true);
    };
    const handleCloseEditEmail = () => {
        setOpenEditEmail(false);
    };


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleInputChangeSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSettings({ ...settings, [name]: value })
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setItem({ ...item, [name]: value })
    }

    const onSave = () => {
        handleCloseEditTitle()
        dispatch(putAutoruleSettingName({
            autoruleTaskId: item.autoruleTaskId,
            taskName: item.taskName,
            autoRuleId: autoRuleId
        }))
    };

    const onSaveEmaiLSetting = () => {
        /*dispatch(putAutoruleEmailSetting({
            automationSettingId: emailSettings.automationSettingId,
            emailReceiver: emailSettings.emailReceiver,
            emailIssue: emailSettings.emailIssue,
            emailBody: emailSettings.emailBody,
            emailAttachment: emailSettings.emailAttachement,
            token: "A",
            autoRuleId: autoRuleId
        }))*/
    };

    const getColName = (columns: any, id: string) => {
        const name = columns.find((table: any) => table.id === id)
        return name?.columnName
    }

    React.useEffect(() => {
        dispatch(getTablesColumns(tableId));
        dispatch(getAllRoles(tenantId))
    }, [tableId])

    React.useEffect(() => {
        setItem(content)
        setSettings(content.setting)
    }, [content])

    return (
        <Card sx={{ width: "40vw" }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={2}>
                        <Avatar sx={{ bgcolor: green[500] }} >
                            <DisplaySettingsIcon />
                        </Avatar>
                    </Grid>
                    <Grid item md={10}>
                        {
                            openTitle === false
                                ?
                                <Grid sx={{ display: "flex" }}>
                                    <Typography alignContent="flex-start">
                                        {content.taskName}
                                    </Typography>
                                    <IconButton onClick={handleOpenEditTitle}>
                                        <EditIcon />
                                    </IconButton>
                                    {
                                        deleteComp - 1 === content.sequencePosition &&
                                        <IconButton onClick={() => {
                                            Swal.fire({
                                                title: "¿Seguro quieres borrar el componente auto rule?",
                                                text: "Una vez borrada no hay vuelta atras!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Borrar",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    dispatch(delAutoruleSetting(item.autoruleTaskId, autoRuleId))
                                                }
                                            });
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </Grid>

                                : <>
                                    <TextField
                                        variant="standard"
                                        value={item.taskName}
                                        name="taskName"
                                        onChange={handleInputChange}
                                    />
                                    <IconButton onClick={onSave}>
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={handleCloseEditTitle}>
                                        <CloseIcon />
                                    </IconButton>
                                </>
                        }
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
                <Typography variant="body2" color="text.secondary">
                    Detalles de la configuración
                </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {
                        openEditEmail === false
                            ? <>
                                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 0 }} >
                                    Tabla asociada
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 1 }} >
                                    <Link to={`/main/tables/dynamicTable/${content.setting.tableId}`} rel="noopener">
                                        {tableName?.tableName}
                                    </Link>
                                </Typography>

                                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 0 }} >
                                    Roles permitidos
                                </Typography>
                                {
                                    content.setting.roles.map((rol: string) => {
                                        const roleName = rolesState.find(role => role.id === rol)
                                        return (
                                            <Typography variant="body1" sx={{ marginBottom: 1 }} >
                                                {roleName?.name}
                                            </Typography>
                                        )
                                    })
                                }

                                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 0 }} >
                                    Variables asociadas
                                </Typography>
                                {
                                    content.setting.data.map((id: string) => {
                                        return (
                                            <Typography variant="body1" sx={{ marginBottom: 1 }} >
                                                {
                                                    getColName(tableColumnsState, id)
                                                }
                                            </Typography>
                                        )
                                    })
                                }


                            </>
                            : <>
                                {/*<Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }} >
                                    Destinatario:
                                </Typography>
                                <TextField
                                    margin="normal"
                                    select
                                    label="Columnas de cliente"
                                    helperText="Please select the server to which the role will be directed"
                                    variant="standard"
                                    fullWidth
                                    autoFocus
                                    name='emailReceiver'
                                    onChange={handleInputChangeSetting}
                                    value={emailSettings.emailReceiver}
                                >
                                    {tableColumnsState.filter((col: any) => col.columnType === types.Contact).map((option: any) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.columnName}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>*/}

                                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }} >
                                    Asunto del correo:
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    value={""}
                                    name="emailIssue"
                                    onChange={handleInputChangeSetting}
                                    sx={{
                                        marginBottom: 2
                                    }}
                                />
                            </>

                    }

                </CardContent>
            </Collapse>
        </Card>
    );
}
