import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React, { useState, useEffect, memo } from 'react'
import { TimerData } from './TimerWidget'
import { TimerCountDown } from './TimerCountDown'
import { useAppSelector } from '../../hooks/hooks'
import { AddMoreTime } from './AddMoreTime'

interface Props {
    item: TimerData
    handleDesactive: (rowId: string) => void
    handleMoreTime: (rowId: string, countDown: string, label: string) => void
    timeColumnId: string
    backgroundColor: string
    fontColor: string
    finishedColor: string
    fontFamily: string
}
export const TimerItem = ({ item, handleDesactive, handleMoreTime, timeColumnId, backgroundColor, fontColor, finishedColor, fontFamily }: Props) => {
    const [finished, setFinished] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const { token } = useAppSelector(state => state.auth)

    const isUrlImage = (url: string) => {
        return /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp))/.test(url);
      };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleMoreTimeClick = () => {
        openModal();
    };


    return (
        <Paper elevation={4}
            sx={{
                p: 1,
                // backgroundColor: finished ? '#f7bd50' : ''
                background: finished ? finishedColor : backgroundColor,
                fontFamily: fontFamily
            }}>

            <Grid container
                spacing={2}
                sx={{
                    "& .MuiGrid-item": {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }}
            >
                <Grid item xs={12}  md={3}>
                    <Box>
                        {item.contacts.map((contact) => {
                            return (
                                <Typography fontSize={20} color={fontColor} sx={{fontFamily: fontFamily}}> • {contact}</Typography>
                            )
                        })}
                    </Box>
                </Grid>
                <Grid item xs={12}  md={3}>
                    <TimerCountDown targetDate={item.timeStamp} setFinished={setFinished} fontColor={fontColor} fontFamyly={fontFamily} />
                </Grid>
                <Grid xs={12}  md={4}>
                    {isUrlImage(item.customData) ? (
                            <Box sx={{mt: 2, marginLeft: 30}}>
                                <img src={item.customData} alt="Custom Data" style={{ maxWidth: '50%', height: 'auto'}} />
                            </Box>
                    ) : (
                        <Box sx={{mt: 2, marginLeft: 30}}>
                                <Typography fontSize={50} color={fontColor} sx={{fontFamily: fontFamily}}>{item.customData}</Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={2} container direction="column" alignItems="center" justifyContent="center">
                {token && ( //a
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Button 
                                variant="contained" 
                                onClick={() => handleDesactive(item.rowId)}   
                                style={{
                                    backgroundColor: fontColor,
                                    color: backgroundColor,
                                    fontFamily: fontFamily
                                }}>
                                    Desactive
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                variant="contained" 
                                onClick={() => handleMoreTimeClick()}
                                style={{
                                    backgroundColor: fontColor,
                                    color: backgroundColor,
                                    fontFamily: fontFamily
                                }}
                                >
                                    More Time
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <AddMoreTime item={item} handleOpen={modalOpen} handleClose={closeModal} handleMoreTime={handleMoreTime} timeColumnId={timeColumnId}/>

        </Paper>
    )
}
