import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react'
import { CutomAutocomplete } from '../custom/CutomAutocomplete';
import { TimerForm } from './TimerForm';
import { LeaderboardForm } from './LeaderboardForm';
import { useAppSelector } from '../../hooks/hooks';
import { RightSidePanel } from '../custom/CustomRightSidePanel';
import { ActivityForm } from './ActivityForm';
interface Props {
    dashboardId: string
    color : string
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 180,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

export const AddWidgetButton = ({ dashboardId, color }: Props) => {
    const [widgetType, setWidgetType] = useState('')
    const [open, setOpen] = useState(false)
    const [openSideBar, setOpenSideBar] = useState(false)
    const [widget, setWidget] = useState('');

    const renderComponent = (widget: string) => {
        switch (widget) {
            case '0':
                return <TimerForm dashboardId={dashboardId} setOpen={setOpenSideBar} />;
            case '1':
                return <LeaderboardForm dashboardId={dashboardId} setOpen={setOpenSideBar} />;
            // case '2':
            //     return <ActivityForm dashboardId={dashboardId} setOpen={setOpenSideBar} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Tooltip title={'Add widget'}>
                <IconButton onClick={() => setOpen(true)} style={{ color: color }}>
                    <AddIcon sx={{ width: 50, height: 50 }} />
                </IconButton>
            </Tooltip>
            <RightSidePanel
                    handleOpen={openSideBar}
                    handleClose={() => setOpenSideBar(false)}
                    customComponent={renderComponent(widget)}
            />
            <Modal
                open={open}
                onClose= {() => setOpen(false)}
            >
                <Box sx={{...style, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Widget</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={widget}
                        label="Widget"
                        onChange={(event) => setWidget(event.target.value as string)}
                    >
                        <MenuItem value={'0'}>Timer</MenuItem>
                        <MenuItem value={'1'}>Leaderboard</MenuItem>
                        {/* <MenuItem value={'2'}>Activity</MenuItem> */}
                        {/* <MenuItem value={2}>Thirty</MenuItem> */}
                    </Select>
                    </FormControl>
                    <Button sx={{mt: 3, alignSelf: 'center' }} variant="outlined" onClick={() => {setOpenSideBar(true); setOpen(false)}}>Create Widget</Button>
                </Box>
            </Modal>
        </>
        
    )
}
