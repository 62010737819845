import React, { useEffect, useState } from 'react'
import { Box, Typography, List, ListItem, ListItemButton, ListItemText, Accordion, Menu, MenuItem } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
interface Items {
    path: string,
    name: string
}
interface Props {
    items: Items[],
    title: string,
    icon: React.ReactNode,
    openDrawer: boolean
    handleOpenDrawer: () => void
}
export const ListDrawer = ({ items, title, openDrawer, handleOpenDrawer, icon }: Props) => {
    const location = useLocation()
    const path = location.pathname
    const [open, setOpen] = useState(false)

    const maxHeight = (items.length + 2) * 40 + "px"
    useEffect(() => {
        if (!openDrawer)
            setOpen(false)
    }, [openDrawer])

    return (
        <Box
            my={1}
            sx={{
                maxHeight: open ? maxHeight : "40px",
                transition: 'ease-in 0.15s all',
            }}
        // onMouseLeave={handleCloseMenu}

        >
            <Box onClick={() => {
                setOpen(!open)
                if (!openDrawer)
                    handleOpenDrawer()
            }}
                px={1}
                py={1}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    transition: "ease-in 0.25s",
                    justifyContent: openDrawer ? 'space-between' : "center",
                    color: items.some(obj => obj.path === path) ? `#fff` : "#6a7187",
                    minHeight: '40px',
                    borderRadius: 2,
                    ":hover": {
                        color: "#fff",
                    }
                }}
            // onMouseEnter={handleOpenMenu}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1
                    }}

                >
                    {icon}
                    {
                        openDrawer &&
                        <Typography>
                            {title}
                        </Typography>
                    }
                </Box>
                {
                    openDrawer &&
                    <ExpandMoreIcon sx={{
                        transform: open ? 'rotate(180deg)' : "",
                        transition: 'ease-in 0.25s transform'
                    }} />
                }

            </Box>
            {
                openDrawer &&
                <Box
                    sx={{
                        maxHeight: open ? maxHeight : "0px",
                        opacity: open ? "1" : "0",
                        // display: open ? "block" : "none",
                        overflow: 'hidden',
                        // transition: 'ease-in 1s all'
                        transition: "all 0.25s ease-in"

                    }}
                >
                    <List
                        sx={{
                            "a": {
                                color: '#6a7187',
                                textDecoration: 'none',
                            },
                            pl: 2,
                            // display: open ? "block" : "none",
                        }}
                    >
                        {items.map((text: Items, zIndex) => (
                            <Link to={text.path} key={zIndex}>

                                <Box key={text.name}
                                    sx={{
                                        pl: 2,
                                        transition: "ease-in 0.25s",
                                        borderRadius: 2,
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: text.path === path ? `#fff` : "#6a7187",
                                        ":hover": {
                                            // backgroundColor: "rgba(255,255,255,0.2)",
                                            color: "#fff"
                                        }
                                    }}
                                >
                                    {/* <ListItemButton> */}
                                    <ListItemText primary={text.name} />
                                    {/* </ListItemButton> */}
                                </Box>

                            </Link>

                        ))}
                    </List>
                </Box>
            }

        </Box>
    )
}
