import { createSlice } from "@reduxjs/toolkit";


export interface EndUser {
    username: string,
    endUserId: string,
    tesseractId: string,
    name: string,
    lastName: string,
    email: string,
    birthDate: string,
    gender: string,
    phoneNumber: string,
    lastLoginDate: string
    // createdDate: string,
    // updatedDate: string,
    // lastmodifier: { name: string, id: string }
}
export interface EndUserRecord {
    "userLogins": number,
    "endUserLogins": number,
    "userRegistrations": number,
    "endUserRegistrations": number,
    "date": string
}

export interface EndUserStore {
    status: string,
    endUser: EndUser[],
    endUserRecord: EndUserRecord[]
}
const initialState: EndUserStore = {
    status: "initial",
    endUser: [],
    endUserRecord: []
}
export const endUserSlice = createSlice({
    name: 'EndUser',
    initialState,
    reducers: {
        setAllEndUsers: (state, { payload }) => {
            state.status = "confirmed"
            state.endUser = payload
        },
        setRecords: (state, { payload }) => {
            state.status = "confirmed"
            state.endUserRecord = payload
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setAllEndUsers, setRecords, loading, errorState } = endUserSlice.actions

