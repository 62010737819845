import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { LoadingCube } from '../custom/loader/LoadingCube';
import { CustomButton } from '../custom/CustomButton';
import { isEmail } from '../../functions/EmailVerificator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setNewTenantData } from '../../redux/tenant/thunk';


export const OnBoarding = () => {
    const [loading, setLoading] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [tenantData, setTenantData] = useState({
        company: "",
        email: "",
        tenantId: ""
    })

    const { name, lastname, tenantId, token } = useAppSelector(state => state.auth)
    const { status } = useAppSelector(state => state.tenant)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTenantData({ ...tenantData, [name]: value });
    };
    const goNext = () => {
        setLoading(true);
        setTimeout(() => {
            setActiveIndex(activeIndex + 1);
            setLoading(false);
        }, 500);
    }
    const goBack = () => {
        setLoading(true);
        setTimeout(() => {
            setActiveIndex(activeIndex - 1);
            setLoading(false);
        }, 500);
    }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const tenantDataToSend = { ...tenantData, tenantId: tenantId }
        dispatch(setNewTenantData(tenantDataToSend))
    }
    const renderItems = [
        () =>
            <Box
                sx={{
                    color: "#000"
                }}
            >
                <Typography variant='h2' fontWeight={400} fontSize={40}>
                    Bienvenido a Nexus {name} {lastname}.
                </Typography>
                <Typography variant='body1' fontWeight={400} fontSize={20}>
                    Comencemos con la configuración de tu organización
                </Typography>
                <CustomButton color='secondary' label='¡Comencemos!' onClick={goNext} type='button'
                    disabled={false}
                ></CustomButton>
            </Box>,
        () =>
            <Box
                sx={{
                    color: "#000",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                }}
                component={"form"}
                onSubmit={goNext}
            >
                <Typography variant='h2' fontWeight={400} fontSize={40}>
                    Para comenzar, ¿Cuál es el nombre de su empresa?
                </Typography>
                <TextField
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "90%",
                            md: "60%",
                            lg: "50%",
                            xl: "40%"
                        }
                    }}
                    color='secondary'
                    label="Nombre"
                    name='company'
                    onChange={handleChange}
                    value={tenantData.company}
                    // disabled={activeIndex != zIndex}
                    required
                ></TextField>
                <Box>
                    <CustomButton color='secondary' label='Confirmar nombre' onClick={() => { }} type='submit'
                        disabled={tenantData.company.length < 3}
                    ></CustomButton>
                </Box>
                {/* <CustomButton color='secondary' label='¡Comencemos!' onClick={() => scrollToComponent(zIndex + 1)} type='button' ></CustomButton> */}
            </Box>,
        () =>
            <Box
                sx={{
                    color: "#000",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                }}
                component={"form"}
                onSubmit={goNext}
            >
                <Typography variant='h2' fontWeight={400} fontSize={40}>
                    ¿Cuál es el correo de contacto de dicha empresa?
                </Typography>
                <TextField
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "90%",
                            md: "60%",
                            lg: "50%",
                            xl: "40%"
                        }
                    }}
                    color='secondary'
                    label="Correo"
                    name='email'
                    type='email'
                    onChange={handleChange}
                    value={tenantData.email}

                    // disabled={activeIndex != zIndex}
                    error={!isEmail(tenantData.email) && tenantData.email.length > 0}
                    helperText={!isEmail(tenantData.email) && tenantData.email.length > 0 ? 'Ingrese un correo valido' : ''}
                    required
                ></TextField>
                <Box>
                    <CustomButton color='secondary' label='Confirmar correo' onClick={() => { }} type='submit'
                        disabled={!isEmail(tenantData.email)}
                    ></CustomButton>
                </Box>
                {/* <CustomButton color='secondary' label='¡Comencemos!' onClick={() => scrollToComponent(zIndex + 1)} type='button' ></CustomButton> */}
            </Box>,
        () =>
            <Box
                sx={{
                    color: "#000",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                }}
                component={'form'}
                onSubmit={handleSubmit}
            >
                <Typography variant='h2' fontWeight={400} fontSize={40}>
                    Ya casi terminamos, solo queda confirmar sus cambios.
                </Typography>
                <Box>
                    <CustomButton color='secondary' label='Confirmar Tenant' onClick={() => { }} type='submit'
                        disabled={status === 'loading'}
                    ></CustomButton>
                </Box>
                {/* <CustomButton color='secondary' label='¡Comencemos!' onClick={() => scrollToComponent(zIndex + 1)} type='button' ></CustomButton> */}
            </Box>,
        () =>
            <Box
                sx={{
                    color: "#000",
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    justifyItems: 'center',
                    alignItems: 'center',
                    // opacity: activeIndex != zIndex ? 0 : 1
                }}
            // onSubmit={handleSubmit}
            >
                <Typography variant='h2' fontWeight={400} fontSize={40} >
                    Estamos trabajando en sus cambios.
                </Typography>
                <Box>
                    <LoadingCube />
                </Box>
            </Box>,
    ]
    if (loading)
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                }}
                justifyContent={"center"}
            >
                <LoadingCube />
            </Box>
        )
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                p: 4,
                position: 'relative'
            }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: "5%",
                    display: activeIndex === 0 ? "none" : "block"
                }}
            >
                <ArrowBackIcon
                    onClick={goBack}
                    sx={{
                        color: "#ffa400",
                        height: 40,
                        width: 40,
                        cursor: "pointer",
                        transition: "all ease-in 0.2s",
                        ":hover": {
                            color: "#df9000"
                        }
                    }}
                ></ArrowBackIcon>
            </Box>
            {
                renderItems[activeIndex]()
            }
        </Box>
    )
}
