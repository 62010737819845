import { createSlice } from "@reduxjs/toolkit";

export interface WidgetTimer {
    id: string
    tableId: string
    timeColumnId: string
    statusColumnId: string
    contactColumnId: string
    controlColumnId: string
    timeSpanColumnId: string
    siteColumnId: string
    siteId: string
    backgroundColor: string
    fontColor: string
    customDataColumnId: string | null,
    finishedColor: string
}

export interface WidgetLeaderboard {
    id: string
    tableId: string
    contactColumnId: string
    numberColumnId: string
    siteColumnId: string
    backgroundColor: string
    fontColor: string
}

export interface WidgetActivity {
    id: string
    tableActivityId: string
    contactActivityColumnId: string
    exactTimeActivityColumnId: string
    labelColumnId: string
    labelActivityId: string
    tableRecordId: string
    boolColumnId: string
    exactTimeRecordId: string
    contactRecordColumnId: string
    labelRecordColumnId: string
    timeSpanColumnId: string
    boolTimerColumnId: string
    controlColumnId: string
    stringColumnId: string
    controlLabelId: string
    tokenValue: number
}

export interface WidgetBase {
    widgetType: number;
    widget: WidgetTimer | WidgetLeaderboard | WidgetActivity;
}

export interface Dashboard {
    id: string
    tenantId: string
    name: string
    backgroundColor: string
    fontColor: string
    fontStyle: string
    widgets: WidgetBase[]
}

export interface dashboardStore {
    status: string,
    dashboards: Dashboard[],
    currentDashboard: Dashboard | null
}
const initialState: dashboardStore = {
    status: "loading",
    dashboards: [],
    currentDashboard: null
}

export const dashboardsSlice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        setDashboards: (state, { payload }) => {
            state.status = "success"
            state.dashboards = payload
        },
        setDashboard: (state, { payload }) => {
            state.status = "success"
            state.currentDashboard = payload
        },
        loading: (state) => {
            state.status = "loading"
        },
        successState: (state) => {
            state.status = "success"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { loading, errorState, setDashboards, successState, setDashboard } = dashboardsSlice.actions
