import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, purple, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, useTheme, FormControl, Select, Theme, Box, Button, Grid, InputLabel, Menu, MenuItem, Paper, TextField, Tooltip, OutlinedInput, SelectChangeEvent, Chip } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import { EmailEdit } from '../Components/EmailEdit';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getTablesColumns } from '../../../redux/tables/thunk';
import { types } from '../../Enums/ColumnsTypes';
import SaveIcon from '@mui/icons-material/Save';
import { delAutoruleSetting, putAutoruleEmailSetting, putAutoruleSettingName } from '../../../redux/autoRules/thunk';
import { useParams } from 'react-router-dom';
import { replaceIdsWithNames } from '../functions/ReplaceIdOnHtml';
import Swal from 'sweetalert2';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import "./BoxStyles.css"
import RichTextEditor from '../../custom/CustomTextEditor/TextEditor';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export interface Option {
  label: string;
  value: string;
}

interface AutocompleteDropdownProps {
  options: Option[];
  onSelect: (value: string | null, name: string) => void;
  label: string,
  value: string,
  name: string
}

const AutocompleteDropdown: React.FC<AutocompleteDropdownProps> = ({
  options,
  onSelect,
  label,
  value,
  name
}) => {
  return (
    <Autocomplete

      // sx={{ m: 1 }}
      value={value}
      options={options}
      getOptionLabel={(option) => { if (typeof option === "string") { return option } else return option.label }}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label={label}
          variant="standard"
        />
      )}
      onInputChange={(_, newValue) => {

        onSelect(newValue, name);
      }}
      freeSolo  // Permite ingresar opciones que no estén en la lista
      selectOnFocus  // Selecciona el texto en el cuadro de entrada al obtener el foco
      // clearOnBlur  // Limpia el texto al perder el foco si no se selecciona una opción
      clearOnEscape  // Limpia el texto al presionar la tecla Esc
    />
  );
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface content {
  autoruleTaskId: string,
  sequencePosition: number,
  taskName: string,
  setting: any,
}

export const EmailBox = ({ content, tableId, deleteComp, permissions, roles }: { content: content, tableId: string, deleteComp: number, permissions: string[], roles: string[] }) => {
  const { id: autoRuleId } = useParams();
  const dispatch = useAppDispatch();
  const { tablesColumns: tableColumnsState } = useAppSelector((state) => state.tables);



  const [expanded, setExpanded] = React.useState(false);
  const [openTitle, setOpenTitle] = React.useState(false)
  const [openEditEmail, setOpenEditEmail] = React.useState(false)
  const [item, setItem] = React.useState(content)
  const [emailSettings, setEmailSettings] = React.useState(content.setting)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleOpenEditTitle = () => {
    setOpenTitle(true);
  };
  const handleCloseEditTitle = () => {
    setOpenTitle(false);
  };

  const handleOpenEditEmail = () => {
    setOpenEditEmail(true);
  };
  const handleCloseEditEmail = () => {
    setOpenEditEmail(false);
  };


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInputChangeSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmailSettings({ ...emailSettings, [name]: value })
  }
  const handleChangueBody = (body: string) => {

    setEmailSettings({ ...emailSettings, emailBody: body })

  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value })
  }

  const onSave = () => {
    handleCloseEditTitle()
    dispatch(putAutoruleSettingName({
      autoruleTaskId: item.autoruleTaskId,
      taskName: item.taskName,
      autoRuleId: autoRuleId
    }))
  };

  const onSaveEmaiLSetting = () => {
    const idClient = tableColumnsState.filter((col: any) => col.type === types.Contact).find(e => e.name === emailSettings.emailReceiver)
    if (idClient) {
      dispatch(putAutoruleEmailSetting({
        automationSettingId: emailSettings.id,
        emailReceiver: idClient.id,
        emailIssue: emailSettings.emailIssue,
        emailBody: emailSettings.emailBody,
        emailAttachment: columnsIds.join(';'),
        token: "A",
        autoRuleId: autoRuleId
      }))
    } else
      dispatch(putAutoruleEmailSetting({
        automationSettingId: emailSettings.id,
        emailReceiver: emailSettings.emailReceiver,
        emailIssue: emailSettings.emailIssue,
        emailBody: emailSettings.emailBody,
        emailAttachment: columnsIds.join(';'),
        token: "A",
        autoRuleId: autoRuleId
      }))
  };

  const getColName = (columns: any, id: string) => {

    const name = columns.find((table: any) => table.id === id)
    if (name)
      return name?.name
    else
      return id
  }

  const theme = useTheme();
  const [columnsIds, setColumnsIds] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof columnsIds>) => {
    const {
      target: { value },
    } = event;
    setColumnsIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  React.useEffect(() => {
    dispatch(getTablesColumns(tableId));
  }, [tableId])

  React.useEffect(() => {
    setItem(content)
    setEmailSettings(content.setting)
    setColumnsIds(content.setting.emailAttachment.split(';'))
  }, [content])

  return (
    <Card className='node-box'>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            marginLeft: 1,
            justifyContent: "space-between",
            alignItems: "center"

          }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center"
            }}
          >
            <Avatar sx={{ bgcolor: purple[500] }} >
              <EmailIcon />
            </Avatar>
            <Box>
              <Typography variant='caption'>
                CORREO
              </Typography>
              <Typography sx={{ display: "flex" }} variant='h6'>
                {content.taskName}
              </Typography>
            </Box>
          </Box>
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreHorizIcon></MoreHorizIcon>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {/* <MenuItem onClick={handleOpenEditTitle}>
                Editar <EditIcon sx={{ height: 18, width: 18, ml: 1 }} />
              </MenuItem> */}

              {
                (permissions.includes("CanDeleteData") ||
                  permissions.length == 0) &&
                deleteComp - 1 === content.sequencePosition &&
                <MenuItem onClick={() => {
                  Swal.fire({
                    title: "¿Seguro quieres borrar el componente auto rule?",
                    text: "Una vez borrada no hay vuelta atras!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Borrar",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      dispatch(delAutoruleSetting(item.autoruleTaskId, autoRuleId))
                    }
                  });
                }}>
                  Eliminar <DeleteIcon sx={{ height: 18, width: 18, ml: 1 }} />
                </MenuItem>
              }

            </Menu>
          </div>
        </Box>

      </CardContent>
      <CardActions disableSpacing>
        <Typography variant="body2" color="text.secondary">
          Detalles del correo
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit className='nodrag'>
        <CardContent>
          {
            openEditEmail === false
              ? <Box sx={{
                ".MuiTypography-body2": {
                  color: blue[500]
                }
              }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 0, fontWeight: 500 }} >
                    Destinatario:
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }} >
                    {
                      getColName(tableColumnsState, emailSettings.emailReceiver)
                    }
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>

                  <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1, fontWeight: 500 }} >
                    Asunto del correo:
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }} >
                    {content.setting.emailIssue}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>

                  <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1, fontWeight: 500 }} >
                    Archivos adjunto del correo:
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }} >
                    {
                      emailSettings.emailAttachment.split(';').map((e: any, zIndex: number) =>
                        getColName(tableColumnsState, e) + `${zIndex < emailSettings.emailAttachment.split(';').length - 1 ? ", " : ""}`
                      )
                    }
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>

                  <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1, fontWeight: 500 }} >
                    Cuerpo del correo:
                  </Typography>
                  {
                    (permissions.includes("CanUpdate") ||
                      (permissions.length == 0 &&
                        roles.includes(
                          "CanManageAutorules"
                        ))) &&
                    <IconButton onClick={handleOpenEditEmail}>
                      <ModeIcon />
                    </IconButton>
                  }
                </Box>

                <Paper elevation={1} sx={{ p: 1 }}>
                  <div dangerouslySetInnerHTML={{ __html: replaceIdsWithNames(content.setting.emailBody, tableColumnsState) }}></div>
                </Paper>

              </Box>
              : <>
                <Typography variant="body2" color={blue[500]} sx={{ marginBottom: 1 }} >
                  Destinatario:
                </Typography>
                <AutocompleteDropdown
                  label='Columnas de cliente o escriba un correo especifico'
                  options={tableColumnsState.filter((col: any) => col.type === types.Contact).map(e => {
                    return ({ label: e.name, value: e.id })
                  })}
                  name='emailReceiver'
                  onSelect={(value, name) => {
                    setEmailSettings({ ...emailSettings, [name]: value })
                  }}
                  value={emailSettings.emailReceiver}
                />
                {/* <TextField
                  margin="normal"
                  select
                  label="Columnas de cliente"
                  helperText="Please select the server to which the role will be directed"
                  variant="standard"
                  fullWidth
                  autoFocus
                  name='emailReceiver'
                  onChange={handleInputChangeSetting}
                  value={emailSettings.emailReceiver}
                >
                  {tableColumnsState.filter((col: any) => col.type === types.Contact).map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))
                  }
                </TextField> */}

                <Typography variant="body2" color={blue[500]} sx={{ marginBottom: 1 }} >
                  Asunto del correo:
                </Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  value={emailSettings.emailIssue}
                  name="emailIssue"
                  onChange={handleInputChangeSetting}
                  sx={{
                    marginBottom: 2
                  }}
                />

                <Typography variant="body2" color={blue[500]} >
                  Archivos adjunto del correo:
                </Typography>
                {/* <TextField
                  margin="normal"
                  select
                  label="Columnas de archivo"
                  helperText="Please select the server to which the role will be directed"
                  variant="standard"
                  fullWidth
                  autoFocus
                  name='emailAttachment'
                  onChange={handleInputChangeSetting}
                  value={emailSettings.emailAttachment}
                  sx={{
                    marginTop: 0
                  }}
                >
                  {tableColumnsState.filter((col: any) => col.type === types.File).map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))
                  }
                </TextField> */}
                <FormControl sx={{ my: 1 }} fullWidth>
                  <InputLabel id="demo-multiple-chip-label">Columnas de archivo</InputLabel>
                  <Select
                    id="demo-multiple-chip"
                    multiple
                    value={columnsIds}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Columnas de archivo" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const columnData = tableColumnsState.find((e) => e.id === value)
                          if (columnData)
                            return (
                              <Chip key={value} label={columnData.name} />
                            )
                        }
                        )}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {tableColumnsState.filter((col: any) => col.type === types.File).map((column) => (
                      <MenuItem
                        key={column.id}
                        value={column.id}
                        style={getStyles(column.name, columnsIds, theme)}
                      >
                        {column.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body2" color={blue[500]}>
                  Cuerpo del correo:
                </Typography>
                <RichTextEditor tablesColumns={tableColumnsState} content={emailSettings.emailBody} handleChangueBody={handleChangueBody} />
                {/* <EmailEdit content={emailSettings.emailBody} onChange={handleInputChangeSetting} tablesColumns={tableColumnsState} /> */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "flex-end",
                    mt: 1
                  }}
                >
                  <Button onClick={onSaveEmaiLSetting} color='primary'>
                    Guardar cambios <SaveIcon sx={{ ml: 1 }} />
                  </Button>
                  <Button onClick={() => handleCloseEditEmail()} color='error'>
                    Cancelar <CloseIcon sx={{ ml: 1 }} />
                  </Button>
                </Box>
              </>

          }

        </CardContent>
      </Collapse>
    </Card>
  );
}