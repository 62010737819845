import React, { useState } from 'react';
import oneClick from '../../assets/WebpayOneclick.png'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from '../../hooks/hooks';
const url = process.env.REACT_APP_NEXUS_BACK_URL;

interface PaymentMethod {
    id: number;
    name: string;
    imageUrl: string;
}


const paymentMethods: PaymentMethod[] = [
    { id: 1, name: 'OneClick', imageUrl:  oneClick},

];

const steps = ['Confirm', 'Select method pay'];

export const ManageCard = () => {
    const { email, username } = useAppSelector(state => state.auth)
    const [activeStep, setActiveStep] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);
    const navigate = useNavigate();
  
    const handleNext = async () => {
      if (activeStep === steps.length - 1) {

        const tenantId = window.localStorage.getItem("tenantId")
        const token = window.localStorage.getItem("jwt")

        window.localStorage.getItem("")
        try {
            const body= ({
                tenantId: tenantId,
                email: email,
                userName: username,
                token: token
            })

            let response = await axios.post(`${url}/api/v1/Payment/CardTransaction`, body);
            if (response.status === 200) {
                const url = response.data.result.url
                const paymentToken = response.data.result.token
                const redirect = `${url}?TBK_TOKEN=${paymentToken}`
                window.location.replace(redirect)
            }
        }
        catch{
            setSelectedMethod(null);
        }

      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedMethod(null);
    };
  
    const handleSelectMethod = (method: PaymentMethod) => {
      setSelectedMethod(method);
    };
  
    const getStepContent = (step: number) => {
      switch (step) {
        case 0:
          return (
            <Typography variant="subtitle1" gutterBottom>
                Click next to select the page method
            </Typography>
          );
        case 1:
          return (
            <Grid container spacing={0} justifyContent="center">
              {paymentMethods.map((method) => (
                <Grid item key={method.id} xs={6} sm={4}>
            <Paper
            elevation={selectedMethod?.id === method.id ? 12 : 2}
            onClick={() => handleSelectMethod(method)}
            sx={{
                cursor: 'pointer',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                border: selectedMethod?.id === method.id ? '2px solid black' : 'none', // Highlight the border if selected
                transform: selectedMethod?.id === method.id ? 'scale(1.05)' : 'none', // Zoom effect if selected
                transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out', // Smooth the transition for transform and border
                '&:hover': {
                boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
                },
            }}
            >
            <img src={method.imageUrl} alt={method.name} style={{ maxWidth: '100%', maxHeight: '100px' }} />
            </Paper>
                </Grid>
              ))}
            </Grid>
          );
        default:
          return 'Unknown step';
      }
    };
  
    return (
      <Box sx={{ width: '100%', '.MuiStepper-root': { padding: '24px 0' } }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ '& .MuiStepIcon-root': { fontSize: 40, color: 'blue' } }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2, height: '200px' , overflowY: 'auto'}}> 
          {getStepContent(activeStep)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: -15}}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
        <Button
        variant="contained"
        onClick={handleNext}
        disabled={activeStep === 1 && !selectedMethod}
        >
        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
        </Box>
      </Box>
    );
  };