import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
    options: any[],
    title: string,
    label: string,
    onChange: (event: React.SyntheticEvent<Element, Event>, value: any[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void,
    onInputChange: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void,
    value: any[],
    disabled?: boolean
    inputValue: string
}

export const CutomMultiAutocomplete = ({ options, title, label, onInputChange, onChange, value, disabled, inputValue}: Props) => {
    return (
        <Autocomplete
            multiple
            disabled={disabled}
            value={Array.isArray(value) ? value : []}
            id="checkboxes-tags-demo"
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={onChange}
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option[title]}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option[title]}
                </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
                <TextField {...params} label={label} />
            )}
        />
    );
}
