import { createSlice } from "@reduxjs/toolkit";
import { Tenant } from "../../models/Teanant";

export interface tenantUserStore {
    status: string,
    tenants: Tenant[] | null;
}

const initialState: tenantUserStore = {
    status: "loading",
    tenants: null
}

export const tenantUserSlice = createSlice({
    name: "tenantUser",
    initialState,
    reducers: {
      setTenants: (state, { payload }) => {
        state.status = "success";
        state.tenants = payload;
      },
      loading: (state) => {
        state.status = "loading";
      },
      successState: (state) => {
        state.status = "success";
      },
      errorState: (state) => {
        state.status = "error";
      },
    },
  });
  
  export const { loading, errorState, setTenants, successState } = tenantUserSlice.actions;
  
  export default tenantUserSlice.reducer;

