import { Box, Divider, Switch, Typography } from '@mui/material'
import React from 'react'

interface Props {
    name: string,
    value: boolean,
    description: string,
    param: string,
    handleChangePermissions: (permission: string, value: boolean) => void
    disabled: boolean
}
export const PermissionComponent = ({ name, value, description, param, handleChangePermissions, disabled }: Props) => {
    return (
        <Box
            sx={{
                maxWidth: 400
            }}
        >
            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography
                    sx={{
                        fontSize: "1rem",
                        fontWeight: 500
                    }}
                >
                    {name}
                </Typography>
                <Switch disabled={disabled} value={value} checked={value} onChange={(e) => handleChangePermissions(param, e.target.checked)} />
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        color: 'rgba(0, 0, 0, 0.6)'
                    }}
                >
                    {description}
                </Typography>
            </Box>
            <Divider sx={{ my: 1 }}></Divider>
        </Box>
    )
}
