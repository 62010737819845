import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { modifyTableColumnName } from '../../../redux/tables/thunk';
import Swal from 'sweetalert2'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 10,
    padding: 5,
    paddingBottom: 1
};

interface Props {
    columnName: string,
    columnId: string,
    tableId: string,
    position: number
}

export const EditColumnName = ({ open, setOpen, data }: { open: boolean, setOpen: any, data: Props }) => {
    const { tenantId, id: userId } = useAppSelector(state => state.auth)
    const { tablesColumns }: { tablesData: any, tablesColumns: any } = useAppSelector((state) => state.tables);

    const dispatch = useAppDispatch()
    const [table, setTable] = useState<any>({
        columnName: data.columnName
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { name, value } = event.target;
        setTable({ ...table, [name]: value });
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                Editar nombre de la columna
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={table.columnName}
                                name='columnName'
                                label="Nombre actual de la columna"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                            }}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="outlined"
                            onClick={() => {
                                setOpen(false)
                                const veri = tablesColumns.find((tabCol: any) => tabCol.columnName === table.columnName)
                                if (veri !== undefined) {
                                    Swal.fire({
                                        title: 'Nombre de columna ya en uso',
                                        text: "Debes elegir otro nombre!",
                                        icon: 'error',
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Borrar'
                                    })
                                } else {
                                    dispatch(modifyTableColumnName({
                                        columnName: table.columnName,
                                        editedBy: userId,
                                        id: data.columnId,
                                        position: data.position,
                                        tableId: data.tableId
                                    }))
                                }

                            }}
                            sx={{
                                backgroundColor: "lightblue",
                            }}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
