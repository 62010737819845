import { Backdrop, Box, Button, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import webpayImg from '../../assets/WebpayLogo.png'
import oneClick from '../../assets/WebpayOneclick.png'
import axios from 'axios'
import Swal from 'sweetalert2'
import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { userStatus } from '../../components/Enums/UserStatus'
import { getDomainKey, setVoidDomainKey } from '../../redux/keys/thunk'
import { createPaymentOrder, getFullPrice } from '../../redux/payment/thunk'
import { PaymentMethods } from '../../components/Enums/PaymentMethods'
import { getAllSubscriptions, getSubscriptionsid } from '../../redux/subscriptionPayment/thunk'

export function redondearHaciaArriba(numero: number): string {
    const multiplicador = Math.pow(10, 0); // Multiplicador para obtener dos dígitos después de la coma
    const numeroMultiplicado = numero * multiplicador;
    const numeroRedondeado = Math.ceil(numeroMultiplicado);
    const resultado = numeroRedondeado / multiplicador;
    return resultado.toFixed(2); // Formatea el resultado con dos dígitos decimales
}


export const paymentMethods = [
    // {
    //     name: 'WebPay',
    //     currencyAllowed: ["USD"],
    //     img: webpayImg
    // },
    {
        name: 'OneClick',
        currencyAllowed: ["CLP"],
        img: oneClick
    },
]

export const currencies = [
    "USD", "CLP"
]
export const Payment = () => {
    const dispatch = useAppDispatch()
    const [currentMethod, setCurrentMethod] = useState({
        currency: 'CLP',
        paymentMethod: PaymentMethods['OneClick'],
    })
    const [seatsQuantity, setSeatsQuantity] = useState(0)
    const { subscription } = useAppSelector(state => state.subscriptionPayment)
    const { status, currentPrice, subscriptionPrice } = useAppSelector(state => state.payment)
    const { token, username, email, tenantId } = useAppSelector(state => state.auth)
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const type = query.get('type');

    const handleSubmit = () => {
        if (subscription)
            dispatch(
                createPaymentOrder({
                    token,
                    userName: username,
                    email,
                    suscriptionId: subscription.id,
                    tenantId,
                    seatsQuantity: seatsQuantity
                })
            )
    }
    const updatePrice = (price: number) => {
        if (subscription) {
            window.localStorage.setItem("subscription", subscription.id)
            window.localStorage.setItem("seats", `${seatsQuantity}`)
            dispatch(getFullPrice(
                {

                    paymentMethod: 1,
                    suscriptionId: subscription.id,
                    seatsQuantity: seatsQuantity,
                    currency: currencies.indexOf(currentMethod.currency)

                }
            ))
        }
    }
    useEffect(() => {
        if (type === "suscription" && subscription) {
            updatePrice(parseFloat(redondearHaciaArriba((subscription.seatPrice * seatsQuantity) + subscription.priceMonth)))
        } else if (type === "seat" && subscription) {
            updatePrice(parseFloat(redondearHaciaArriba((subscription.seatPrice * seatsQuantity))))
        }
    }, [seatsQuantity, currentMethod.currency, subscription])

    useEffect(() => {
        if (currentMethod.paymentMethod > 0)
            // console.log(paymentMethods[currentMethod.paymentMethod - 1]);

            if (!paymentMethods[currentMethod.paymentMethod - 1].currencyAllowed.includes(currentMethod.currency)) {
                setCurrentMethod({ ...currentMethod, paymentMethod: 0 })
            }
    }, [currentMethod.currency])
    useEffect(() => {
        const subscriptionId = window.localStorage.getItem("subscription");
        const seats = window.localStorage.getItem("seats");
        const tenantId = window.localStorage.getItem("tenantId");
        if (subscriptionId && seats && tenantId) {
            setSeatsQuantity(+ seats)
            dispatch(getSubscriptionsid(subscriptionId))
        }
        else {
            navigate("/subscriptions")
        }
    }, [])


    if (subscription)
        return (
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    sx={{
                        minHeight: 600,
                        width: { xs: '85%', md: "80%", lg: '60%' },
                        p: 2

                    }}
                    elevation={4}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4
                        }}
                    >
                        <Paper
                            sx={{
                                minHeight: 150,
                                backgroundColor: '#F8F8FB',
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 3
                            }}
                        >{
                                type === 'suscription' &&
                                <Grid container
                                    sx={{ display: 'flex', width: '100%' }}
                                >

                                    <Grid item xs={12} md={10}
                                    >
                                        <Typography variant='h3' fontSize={28} fontWeight={450}>
                                            Suscripción {subscription?.name}
                                        </Typography>
                                        <Grid container sx={{ width: 300 }}>
                                            <Grid item xs={6} fontSize={12}>•{`${subscription?.storage} GB Almacenamiento`}</Grid>
                                            <Grid item xs={6} fontSize={12}>•{`${subscription && subscription?.apiCalls < 0 ? "Ilimitadas" : subscription?.apiCalls} llamadas API`}</Grid>
                                            <Grid item xs={6} fontSize={12}>•{`${subscription?.autorules} automatizaciones`}</Grid>
                                            <Grid item xs={6} fontSize={12}>•{`${subscription?.roles} roles`}</Grid>
                                            <Grid item xs={6} fontSize={12}>•{`${subscription?.tables} tablas`}</Grid>
                                            <Grid item xs={6} fontSize={12}>•{`${subscription?.maxSeats} personas`}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2}
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                            {
                                                currentMethod.currency === 'CLP' ?
                                                    `$ ${currentPrice ? Number(redondearHaciaArriba(currentPrice?.priceMonth)).toLocaleString('es-CL') : ""}`
                                                    :
                                                    `$ ${currentPrice?.priceMonth}`
                                            }

                                        </Typography>

                                    </Grid>
                                </Grid>
                            }
                            <Grid container
                                sx={{ display: 'flex', width: '100%' }}
                            >
                                <Grid item xs={12} md={10} sx={{ display: 'flex', gap: 3 }}>
                                    <Typography variant='h3' fontSize={28} fontWeight={450}>
                                        Sillas <span style={{ fontSize: 12, fontWeight: 400 }}>(valor por silla {
                                            currentMethod.currency === 'CLP' ?
                                                `$ ${currentPrice ? currentPrice?.pricePerSeat.toLocaleString('es-CL') : ""}`
                                                :
                                                `$ ${currentPrice ? (currentPrice?.pricePerSeat) : ""}`
                                        })</span>
                                    </Typography>
                                    {
                                        type !== 'seat' ?
                                            <Box
                                                sx={{
                                                    fontSize: 32,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    ".SeatButton, .SeatButtonDisabled": {
                                                        color: '#ffa400',
                                                        height: 30,
                                                        width: 30,
                                                        cursor: "pointer"
                                                    },
                                                    ".SeatButton:hover": {
                                                        color: '#df9000',

                                                    },
                                                    ".SeatButtonDisabled": {
                                                        color: "#D2D2D2"
                                                    }
                                                }}
                                            >
                                                <IconButton onClick={() => { if (seatsQuantity > 0) setSeatsQuantity(seatsQuantity - 1) }} disabled={status === 'loading'}>
                                                    <RemoveIcon className={seatsQuantity === 0 ? "SeatButtonDisabled" : "SeatButton"} />
                                                </IconButton>
                                                {seatsQuantity}
                                                <IconButton onClick={() => { if (subscription && seatsQuantity !== subscription?.maxSeats - 1) setSeatsQuantity(seatsQuantity + 1) }} disabled={status === 'loading'}>
                                                    <AddIcon className={subscription && seatsQuantity === subscription?.maxSeats - 1 ? "SeatButtonDisabled" : "SeatButton"} />
                                                </IconButton>

                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    fontSize: 32,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    ".SeatButton, .SeatButtonDisabled": {
                                                        color: '#ffa400',
                                                        height: 30,
                                                        width: 30,
                                                        cursor: "pointer"
                                                    },
                                                    ".SeatButton:hover": {
                                                        color: '#df9000',

                                                    },
                                                    ".SeatButtonDisabled": {
                                                        color: "#D2D2D2"
                                                    }
                                                }}
                                            >
                                                {seatsQuantity}
                                            </Box>
                                    }

                                </Grid>
                                <Grid item xs={12} md={2}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            currentMethod.currency === 'CLP' ?
                                                `$ ${currentPrice ? (currentPrice?.seatPrice).toLocaleString('es-CL') : ""}`
                                                :
                                                `$ ${currentPrice ? currentPrice?.seatPrice : ""}`
                                        }

                                    </Typography>

                                </Grid>
                            </Grid>
                            <Grid container

                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ maxWidth: 300 }} fullWidth>
                                        <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentMethod.currency}
                                            label="Currency"
                                            onChange={
                                                (event) => {
                                                    setCurrentMethod(
                                                        {
                                                            ...currentMethod,
                                                            currency: event.target.value as string
                                                        }
                                                    )
                                                }
                                            }
                                        >
                                            {
                                                currencies.map(e =>
                                                    <MenuItem value={e}>{e}</MenuItem>
                                                )
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        textAlign: 'end'
                                    }}
                                >
                                    <Typography variant='h3' fontSize={22} fontWeight={450}>
                                        Subtotal
                                    </Typography>

                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            currentMethod.currency === 'CLP' ?
                                                `$ ${currentPrice ? (currentPrice?.subTotal).toLocaleString('es-CL') : ""}`
                                                :
                                                `$ ${currentPrice ? currentPrice?.subTotal : ""}`
                                        }
                                    </Typography>


                                </Grid>
                            </Grid>
                            <Grid container
                                sx={{
                                    display: 'flex', width: '100%',
                                    textAlign: 'end'
                                }}
                            >
                                <Grid item xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography variant='h3' fontSize={22} fontWeight={450}>
                                        Taxes
                                    </Typography>

                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            currentMethod.currency === 'CLP' ?
                                                `$ ${currentPrice ? (currentPrice?.taxAmount).toLocaleString('es-CL') : ""}`
                                                :
                                                `$ ${currentPrice ? currentPrice?.taxAmount : ""}`
                                        }
                                    </Typography>


                                </Grid>
                            </Grid>
                            <Grid container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    textAlign: 'end'
                                }}
                            >
                                <Grid item xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography variant='h3' fontSize={22} fontWeight={450}>
                                        Total
                                    </Typography>

                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            currentMethod.currency === 'CLP' ?
                                                `$ ${currentPrice ? (currentPrice?.total).toLocaleString('es-CL') : ""}`
                                                :
                                                `$ ${currentPrice ? (currentPrice?.total) : ""}`
                                        }
                                    </Typography>


                                </Grid>
                            </Grid>
                        </Paper>
                        <Box
                        >
                            <Typography variant='h3' fontSize={28} fontWeight={450}>
                                Seleccionar metodo de pago
                            </Typography>
                            <Grid container mt={2} spacing={4}>
                                {
                                    paymentMethods.map((e, index) => {
                                        return (
                                            <Grid item md={6} xs={12}>
                                                <Button
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    onClick={() => setCurrentMethod({ ...currentMethod, paymentMethod: index + 1 })}
                                                    disabled={status === 'loading' || !e.currencyAllowed.includes(currentMethod.currency)}
                                                >
                                                    <Paper
                                                        sx={{
                                                            width: "100%",
                                                            backgroundColor: '#F8F8FB',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            p: 2,
                                                            transition: "all ease-in 0.4s",
                                                            ":hover": {
                                                                cursor: "pointer",
                                                                backgroundColor: '#fff'
                                                            },
                                                            border: index + 1 === currentMethod.paymentMethod ? "solid #ffa400 1px" : "",
                                                            opacity: !e.currencyAllowed.includes(currentMethod.currency) ? 0.3 : 1
                                                        }}
                                                        elevation={4}
                                                    >
                                                        <img src={e.img} alt={`${e.name} logo`} height={'70px'} />
                                                    </Paper>
                                                </Button>

                                            </Grid>
                                        )
                                    })
                                }

                            </Grid>
                        </Box>
                        <Box
                            display={'flex'}
                        >
                            <Button
                                sx={{ m: '0 auto ' }}
                                variant='contained'
                                color='success'
                                disabled={status === 'loading' || currentMethod.paymentMethod === 0}
                                onClick={handleSubmit}
                            >
                                Pay now!
                            </Button>
                        </Box>
                    </Box>


                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={status === "loading"}
                    // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper >

            </Box >
        )
    else {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoadingCube />
            </Box>
        )
    }
}
