import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/hooks';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';

interface datasets {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
}

export const EnUsersRegisterChart = () => {
    const [labels, setLabels] = useState<string[]>([])
    const [datasets, setDataSets] = useState<datasets[]>([])
    const { endUserRecord } = useAppSelector((state) => state.endUser);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `End users register per date`,
            },
        },
    };

    useEffect(() => {
        if (endUserRecord) {

            let labelsR = endUserRecord.map((e) => dayjs(e.date).format('DD/MM/YYYY'))
            setLabels(labelsR)

            let yDate = endUserRecord.map((e) => e.endUserRegistrations)

            setDataSets(
                [{
                    label: 'EndUsers registrations',
                    data: yDate,
                    borderColor: '#1ea6ce',
                    backgroundColor: '#34dbe3',
                }]
            )
        }

    }, [endUserRecord])
    return (
        <Line options={options} data={{ labels, datasets }} />
    )
}
