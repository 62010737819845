import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { FormEvent, useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { DateType } from '../../../components/fields/DateType';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setCompany } from '../../../redux/account/thunk';
import { MuiTelInput } from 'mui-tel-input'
import { AccountSizes } from '../../../components/Enums/AccountSizes';

export const AddAccount = ({ open, setOpen }: { open: boolean, setOpen: any }) => {

    const { tenantId, token } = useAppSelector(state => state.auth)
    const { status, pageSize, pageNumber } = useAppSelector(state => state.accounts)

    const dispatch = useAppDispatch();
    const [newCompany, setNewCompany] = useState({
        countryId: "",
        "name": "",
        "businessName": "",
        "email": "",
        "address": "",
        "phoneNumber": "",
        "website": "",
        "linkedin": "",
        "instagram": "",
        "facebook": "",
        "twitter": "",
        "tikTok": "",
        "youtube": "",
        "twitch": "",
        "threads": "",
        "notes": "",
        "labels": "",
        "size": 0,
    })

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        dispatch(setCompany({
            ...newCompany,
            tenantId: tenantId,
            token: ""
        },
            setOpen,
            pageSize,
            pageNumber
        ))
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewCompany({ ...newCompany, [name]: value })
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 10,
                padding: 2,
                paddingBottom: 1,
                maxWidth: 500,
                width: "100%",
                // height: 600,
                overflowY: 'scroll'
            }} component="form" onSubmit={handleSubmit}>
                <Typography variant='h4' fontSize={25} fontWeight={500}>
                    New Account
                </Typography>
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={12} >
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.name}
                                name='name'
                                label="Account name"
                                required
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.businessName}
                                name='businessName'
                                label="Business name"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.countryId}
                                name='countryId'
                                label="Country Id"
                                // required
                                error={newCompany.countryId.length > 50}
                                helperText={newCompany.countryId.length > 50 && "The maximum length allowed is 50 characters"}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.address}
                                name='address'
                                label="Address"
                                // required
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.email}
                                name='email'
                                label="Email"
                                // required
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <MuiTelInput
                                margin="normal"
                                name='phoneNumber'
                                value={newCompany.phoneNumber}
                                onChange={(value) => { setNewCompany({ ...newCompany, phoneNumber: value }) }}
                                label="Phone Number"
                                defaultCountry='CL'
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.website}
                                name='website'
                                label="Website"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.linkedin}
                                name='linkedin'
                                label="LinkedIn URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.instagram}
                                name='instagram'
                                label="Instagram URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.facebook}
                                name='facebook'
                                label="Facebook URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.twitter}
                                name='twitter'
                                label="Twitter URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.tikTok}
                                name='tikTok'
                                label="TikTok URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.youtube}
                                name='youtube'
                                label="Youtube URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.twitch}
                                name='twitch'
                                label="Twitch URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.threads}
                                name='threads'
                                label="Threads URL"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                value={newCompany.notes}
                                name='notes'
                                label="Notes"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: 0 }}>
                        <FormControl fullWidth>
                            <InputLabel id="account-size-label">Account Size</InputLabel>
                            <Select
                                labelId="account-size-label"
                                id="account-size-select"
                                value={newCompany.size}
                                label="Account Size"
                                onChange={(event) => {
                                    setNewCompany({ ...newCompany, size: parseInt(event.target.value as string) });
                                }}
                            >
                                {Object.keys(AccountSizes)
                                    .filter((key) => !isNaN(Number(key)))
                                    .map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {AccountSizes[key as keyof typeof AccountSizes]}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                            }}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="outlined"
                            disabled={newCompany.countryId.length > 50 || status === 'loading'}
                            type='submit'
                            sx={{
                                backgroundColor: "lightblue",
                            }}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
