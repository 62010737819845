// import { blue, pink } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: blue[500],
            contrastText: "#fff",
            light: "#4084ff",
            dark: "#0044bf"
        },
        secondary: {
            main: '#ffa400',
            contrastText: "#fff",
            light: "#ffbb40",
            dark: "#bf7b00"
        },
    },
});