import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

interface imagePropComponent {
    url: string
}

export function ImageItemColumn({ url }: imagePropComponent) {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <div>

            <Zoom>
                <img
                    alt=""
                    src={url}
                    width="50"
                />
            </Zoom>
        </div>
    )
}