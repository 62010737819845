import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const axiosInstance = axios.create();
const axiosInstanceWithOutHeader = axios.create();


axiosInstance.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
        config.headers!['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'

    }
    return config
})
const token = window.localStorage.getItem("jwt");

const requests = {
    get: <T>(url: string) => axiosInstance.get<T>(url, { headers: { ['Authorization']: `Bearer ${token}` } }).then(responseBody),
    post: <T>(url: string, body: {}) => axiosInstance.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axiosInstance.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody),
}
const CommerceAgent = {
    getById: (tenantId: string) => requests.get<any>(url + `/api/v1/TenantCommerce/${tenantId}`),
    createSettings: (body: any) => requests.post<any>(url + `/api/v1/TenantCommerce`, body),
    editSettings: (body: any) => requests.put<any>(url + `/api/v1/TenantCommerce`, body),
    deleteSettings: (tenantId: string) => requests.del<any>(url + `/api/v1/TenantCommerce/${tenantId}`),

    createSubscription: (body: any) => requests.post<any>(url + `/api/v1/TenantPaymentSuscriptions`, body),
    getSubscriptions: () => requests.get<any>(url + `/api/v1/TenantPaymentSuscriptions?isEndUser=false`),
    editSubscriptions: (body: any) => requests.put<any>(url + `/api/v1/TenantPaymentSuscriptions`, body),


}
export default CommerceAgent