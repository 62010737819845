import React from 'react'
import { Box, Paper, Typography } from "@mui/material"
import VerifiedIcon from '@mui/icons-material/Verified';
import { FeatureSubscription } from './FeatureSubscription';
import { CustomButton } from '../custom/CustomButton';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { setSub } from '../../redux/subscriptionPayment/thunk';
import { Subscription } from '../../redux/subscriptionPayment/slice';

interface Props {
    suscription: Subscription
    isRenderButton: boolean
}
export const SubscriptionCard = ({ suscription, isRenderButton}: Props) => {
    const auth = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleSelectSub = () => {
        dispatch(setSub(suscription))
        if (auth.suscription?.id === suscription.id) {
            navigate("payment/method?type=seat")
        }
        else {
            navigate("payment/method?type=suscription")
        }
    }

    return (
        <Paper
            elevation={4}
            sx={{
                minHeight: 300,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                overflow: 'visible',
                flexGrow: 1,
                justifyContent: 'space-between'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Typography variant='h2' fontSize={24} fontWeight={500} textAlign={'center'}>
                    {suscription.name}
                </Typography>
                <Typography color={"#ffa400"} variant='body1' fontSize={38} fontWeight={600} textAlign={'center'}>
                    ${suscription.priceMonth.toFixed(2)}
                </Typography>
                <Typography color={"#6a7187"} variant='body1' fontSize={18} fontWeight={500} textAlign={'center'}>
                    ${suscription.seatPrice} extra por persona
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1
                    }}
                >
                    <FeatureSubscription feature={`${suscription.storage} GB Almacenamiento`} />
                    <FeatureSubscription feature={`${suscription.apiCalls < 0 ? "Ilimitadas" : suscription.apiCalls}  llamadas API`} />
                    <FeatureSubscription feature={`${suscription.autorules} automatizaciones`} />
                    <FeatureSubscription feature={`${suscription.roles} roles`} />
                    <FeatureSubscription feature={`${suscription.tables} tablas`} />
                    <FeatureSubscription feature={`Hasta ${suscription.maxSeats} personas`} />
                </Box>
            </Box>
            {/* {
                auth.suscription?.suscriptionId === suscription.suscriptionId &&
                <Typography textAlign={"center"} variant='overline'>Sillas actuales: 20</Typography>
            } */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                
                {
                isRenderButton && (auth.suscription?.id !== "f04e86f2-fbe9-480a-b53c-6e542721808d"
                        ? <CustomButton disabled={true}
                            color={auth.suscription?.id === suscription.id ? "success" : 'secondary'}
                            label={auth.suscription?.id === suscription.id ? "Suscripcion Actual" : 'Adquirir subscripción'}
                            type='button' onClick={handleSelectSub} />
                        : <CustomButton disabled={false} color={"secondary"} label={'Adquirir subscripción'} type='button' onClick={handleSelectSub} />
                )}

                {/*
                <CustomButton disabled={auth.suscription?.suscriptionId === suscription.suscriptionId && true} 
                    color={auth.suscription?.suscriptionId === suscription.suscriptionId ? "success" : 'secondary'} 
                    label={auth.suscription?.suscriptionId === suscription.suscriptionId ? "Adquirir silla" : 'Adquirir subscripción'} 
                    type='button' onClick={handleSelectSub} />
                */}



            </Box>
        </Paper>
    )
}
