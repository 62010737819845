import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppRouter } from './router';
import { LicenseInfo } from '@mui/x-license-pro';
import { LoginModal } from './pages/Login/LoginModal';
import axios from 'axios';
import { parseJwt } from './functions/DecodeJWT';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSING as string);

function App() {

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const setupAxiosInterceptors = () => {
      axios.interceptors.request.use((request) => {
        const token = window.localStorage.getItem("jwt");
        if (token) {
          const payload = parseJwt(token);
          const currentTime = Date.now() / 1000;
          if (payload.exp < currentTime) {
            setModalOpen(true);
          }
        }
        return request; 
      }, (error) => {
        return Promise.reject(error);
      });
    };

    setupAxiosInterceptors();
  }, []); 

  return (
    <>
        <AppRouter /> 
    {
      modalOpen && 
      <LoginModal setModalOpen={setModalOpen}/>
    }
    </>
  );
}
{/*<AppRouter/>*/ }
export default App;
