import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { createSettings, editSettings, getByTenantId } from '../../redux/commerce/thunk'
import { Settings } from '../../redux/commerce/slice'
import EditNoteIcon from '@mui/icons-material/EditNote';
import { CustomButton } from '../custom/CustomButton'

export const SettingsView = () => {
    const [editable, setEditable] = useState(false)
    const [settings, setSettings] = useState<Settings>({ webpayMallCode: "", oneclickMallCode: "", correctRedirect: "", incorrectRedirect: "" })
    const dispatch = useAppDispatch()
    const { settings: settingsState } = useAppSelector(state => state.commerce)
    const { tenantId } = useAppSelector(state => state.auth)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => { setSettings({ ...settings, [event.target.name]: event.target.value as string }) }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (settingsState !== null) {
            dispatch(editSettings({ ...settings, token: 'a' }, setEditable))
        } else {
            dispatch(createSettings({ ...settings, token: 'a' }))
        }
    }
    useEffect(() => {
        dispatch(getByTenantId(tenantId))
    }, [])
    useEffect(() => {
        if (settingsState)
            setSettings(settingsState)
    }, [settingsState])

    return (
        <Paper elevation={6}
            sx={{ height: 400 }}
        >
            <Box component={'form'} onSubmit={handleSubmit} sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                <Box

                    sx={{ display: 'flex', flexDirection: 'column', px: 2, gap: 2, alignItems: 'center', justifyContent: 'center' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                            // gap: 1
                        }}
                    >
                        <Typography variant='h5'

                        >
                            Commerce Settings
                        </Typography>
                        <EditNoteIcon
                            sx={{
                                cursor: 'pointer',
                                display: editable ? 'none' : "block",
                                ml: 1
                            }}
                            onClick={() => setEditable(true)}
                        />
                    </Box>

                    <TextField
                        label="Webpay mall code"
                        name='webpayMallCode'
                        value={settings.webpayMallCode}
                        type={editable ? "text" : "password"}
                        disabled={!editable}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Oneclick mall code"
                        name='oneclickMallCode'
                        onChange={handleChange}
                        type={editable ? "text" : "password"}
                        disabled={!editable}
                        value={settings.oneclickMallCode}
                    />
                    <TextField
                        label="Correct payment redirect"
                        name='correctRedirect'
                        onChange={handleChange}
                        type={editable ? "text" : "password"}
                        disabled={!editable}
                        value={settings.correctRedirect}
                    />
                    <TextField
                        label="Incorrect payment redirect"
                        name='incorrectRedirect'
                        onChange={handleChange}
                        type={editable ? "text" : "password"}
                        disabled={!editable}
                        value={settings.incorrectRedirect}
                    />
                    {
                        editable &&
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 4
                            }}
                        >
                            <Box
                            >
                                <CustomButton color='error' disabled={false} label='Cancelar' onClick={() => { setEditable(false) }} type='button' />
                            </Box>
                            <Box
                            >
                                <CustomButton color='success' disabled={settings.oneclickMallCode.length === 0 || settings.webpayMallCode.length === 0 || settings.correctRedirect.length === 0 || settings.incorrectRedirect.length === 0} label='Save changes' onClick={() => { }} type='submit' />
                            </Box>
                        </Box>
                    }

                </Box>
            </Box>
        </Paper>
    )
}
