import { createSlice } from "@reduxjs/toolkit";


export interface Account {
    "id": string,
    "tenantId": string,
    "countryId": string | null,
    "name": string | null,
    "businessName": string | null,
    "email": string | null,
    "address": string | null,
    "phoneNumber": string | null,
    "profileImage": string | null,
    "website": string | null,
    "linkedin": string | null,
    "instagram": string | null,
    "facebook": string | null,
    "twitter": string | null,
    "tikTok": string | null,
    "youtube": string | null,
    "twitch": string | null,
    "threads": string | null,
    "notes": string | null,
    "labels": string | null,
    "size": number | null,
    "createdBy": string | null,
    "editedBy": string | null,
    "createdDate": string | null,
    "modifiedDate": string | null
}

export interface accountStore {
    status: string
    accounts: Account[] | []
    currentAccount: Account | null
    totalCount: number
    pageSize: number,
    pageNumber: number
}

const initialState: accountStore = {
    status: "loading",
    accounts: [],
    currentAccount: null,
    totalCount: 0,
    pageSize: 10,
    pageNumber: 0
}
export const accountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {
        setAllCompanies: (state, { payload }) => {
            state.status = "confirmed"
            state.accounts = payload.result
            state.totalCount = payload.totalCount
            state.pageNumber = payload.pageNumber
            state.pageSize = payload.pageSize
        },
        setCurrentCompany: (state, { payload }) => {
            state.status = "confirmed"
            state.currentAccount = payload
        },
        loading: (state) => {
            state.status = "loading"
        },
        errorState: (state) => {
            state.status = "error"
        }
    }
})
export const { setCurrentCompany, setAllCompanies, loading, errorState } = accountSlice.actions
