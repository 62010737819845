import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import CommerceAgent from "./agent";
import { parseJwt } from "../../functions/DecodeJWT";
import axios from "axios";
import { loading, setSetting, setSubscriptions } from "./slice";
import Swal from "sweetalert2";

const axiosInstance = axios.create();


export const getByTenantId = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        CommerceAgent.getById(tenantId)
            .then((result) => {
                dispatch(setSetting(result))
            }).catch((err) => {
                console.log(err);
            });
    }
}


export const createSettings = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        CommerceAgent.createSettings(body)
            .then((result) => {
                Swal.fire({
                    title: 'Settings created!',
                    showCancelButton: false,
                    text: `The configuration for your Commerce has been created successfully`,
                    icon: 'success',
                    confirmButtonText: 'Ok',

                })

            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
            });
    }
}


export const editSettings = (body: any, setEditable: React.Dispatch<React.SetStateAction<boolean>>): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        CommerceAgent.editSettings(body)
            .then((result) => {
                Swal.fire({
                    title: 'Settings edited!',
                    showCancelButton: false,
                    text: `The configuration for your Commerce has been edited successfully`,
                    icon: 'success',
                    confirmButtonText: 'Ok',

                })
                setEditable(false)

            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
            });
    }
}


export const createSubscription = (body: any, setOpen: React.Dispatch<React.SetStateAction<boolean>>): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        CommerceAgent.createSubscription(body)
            .then((result) => {
                dispatch(getSubscriptions())
                setOpen(false)
            }).catch((err) => {
                // console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
            });
    }
}


export const editSubscriptions = (body: any, setOpen: React.Dispatch<React.SetStateAction<boolean>>): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        CommerceAgent.editSubscriptions(body)
            .then((result) => {
                // console.log(result);
                Swal.fire({
                    title: 'Subscription edited!',
                    showCancelButton: false,
                    text: `The Subscription for your Commerce has been edited successfully`,
                    icon: 'success',
                    confirmButtonText: 'Ok',

                })
                dispatch(getSubscriptions())
                setOpen(false)

            }).catch((err) => {
                // console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
            });
    }
}


export const getSubscriptions = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())
        CommerceAgent.getSubscriptions()
            .then((result) => {
                dispatch(setSubscriptions(result))
            }).catch((err) => {
                console.log(err);
            });
    }
}