import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
        config.headers!['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'

    }
    return config
})

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const CompaniesAgent = {
    //getAllCompanies: (tenantId: string, pageSize: number, pageNumber: number, query: string) => requests.get<any>(url + `/api/v1/Account?TenantId=${tenantId}&PageNumber=${pageNumber}&PageSize=${pageSize}`),
    getAllCompanies: (tenantId: string, pageSize: number, pageNumber: number, query: string) => {
        let queryString = `/api/v1/Account?TenantId=${tenantId}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
        if (query) {
          queryString += `&${query}`;
        }
        return requests.get<any>(url + queryString);
      },
    getById: (companyId: string) => requests.get<any>(url + `/api/v1/Account/${companyId}`),
    addCompany: (body: any) => requests.post<any>(url + `/api/v1/Account`, body),
    delCompany: (companyId: string) => requests.del<any>(url + `/api/v1/Account/${companyId}`),
    putCompany: (body: any) => requests.put<any>(url + `/api/v1/Account`, body)
}
export default CompaniesAgent