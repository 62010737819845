import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_NEXUS_BACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
        config.headers!['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'

    }
    return config
})

const specialAxios = axios.create();

specialAxios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
        config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    else {
        config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With, privatekey'
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
})

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    postFile: (url: string, body: {}) => specialAxios.post(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const reportsAgent = {
    postTableRow: (body: {}) => requests.post(url + "/api/v1/DynamicRow/Create", body),
    postFileRow: (body: {}) => requests.postFile(url + "/api/v1/DynamicRow/Tempfile", body),
    getReports: (tenantId: string) => requests.get<any>(url + `/api/v1/Report?TenantId=${tenantId}`),
    getReport: (id: string) => requests.get<any>(url + `/api/v1/Report/${id}`),
    postReport: (data: any) => requests.post<any>(url + `/api/v1/Report`, data),
    postQuestionField: (data: any) => requests.post<any>(url + `/api/v1/QuestionField`, data),
    postPositionQuestionField: (data: any) => requests.post<any>(url + `/api/v1/QuestionField/Position`, data),
    putQuestionField: (data: any) => requests.put<any>(url + `/api/v1/QuestionField`, data),
    putReport: (data: any) => requests.put<any>(url + `/api/v1/Report`, data),
    deleteQuestionField: (id: string) => requests.del<any>(url + `/api/v1/QuestionField/${id}`),
    deleteReport: (id: string) => requests.del<any>(url + `/api/v1/Report/${id}`),
}
export default reportsAgent