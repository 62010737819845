export const Renewal = {
    Daily: 0,
    Weekly: 1,
    Biweekly: 2,
    Monthly: 3,
};
export const PaymentStatus = {
    Completed: 0,
    Active: 1,
    Deleted: 2,
    Pending: 3
};
export const Currency = {
    USD: 0,
    CLP: 1
}