import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { PaymentMethods } from '../../../components/Enums/PaymentMethods'
import { currencies, paymentMethods, redondearHaciaArriba } from '../../Subscriptions/Payment'
import axios from 'axios'
import { Subscriptions } from '../../../redux/commerce/slice'
import { LoadingCube } from '../../../components/custom/loader/LoadingCube'
import { Price } from '../../../redux/payment/slice'
const axiosInstance = axios.create();
export const PaymentPage = () => {
    const dispatch = useAppDispatch()
    const [currentMethod, setCurrentMethod] = useState(-1)
    const [subscription, setSubscription] = useState<Subscriptions>({
        currency: 0,
        id: '',
        name: '',
        price: 0,
        renewal: 0,
        state: 0
    })
    const [price, setPrice] = useState<Price>({
        paymentMethod: 0,
        priceMonth: 0,
        seatPrice: 0,
        tax: 0,
        taxAmount: 0,
        total: 0,
        subTotal: 0,
        pricePerSeat: 0
    })
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>('loading')
    const [priceStatus, setPriceStatus] = useState<string>('loading')
    const { status, currentPrice, subscriptionPrice } = useAppSelector(state => state.payment)

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tokenQuery = query.get('token');
    const token = tokenQuery?.split('"')[1]
    const subId = query.get('subId');
    // console.log(token?.split('"')[1], subId);
    const handleSubmit = () => {
        setPriceStatus('loading')
        if (subId) {
            axiosInstance.post(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/Payment/Tenant`,
                {
                    "suscriptionId": subId,
                    "token": token
                }
                ,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((result) => {
                    const url = result.data.result.url
                    const paymentToken = result.data.result.token
                    const redirect = `${url}?TBK_TOKEN=${paymentToken}`
                    window.location.replace(redirect)
                }).catch((err) => {

                });
        }

    }
    useEffect(() => {
        // Set subscription by id
        axiosInstance.get(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/TenantPaymentSuscriptions?isEndUser=true`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((result) => {
                setSubscriptionStatus('success')
                setSubscription(result.data.result.find((e: Subscriptions) => e.id === subId))

            }).catch((err) => {

            });
    }, [])
    useEffect(() => {
        if (subscriptionStatus === 'success') {
            axiosInstance.post(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/Payment/TenantPrice`,
                {
                    "suscriptionId": subId,
                    "paymentMethod": 0
                }
                ,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((result) => {
                    setPriceStatus('success')
                    setPrice(result.data.result)
                }).catch((err) => {

                });
        }
    }, [subscriptionStatus])

    if (subscriptionStatus === 'loading') {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoadingCube />
            </Box>
        )
    }
    else
        return (

            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    sx={{
                        minHeight: 600,
                        width: { xs: '85%', md: "80%", lg: '60%' },
                        p: 2

                    }}
                    elevation={4}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4
                        }}
                    >
                        <Paper
                            sx={{
                                minHeight: 150,
                                backgroundColor: '#F8F8FB',
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 3
                            }}
                        >
                            <Grid container
                                sx={{ display: 'flex', width: '100%' }}
                            >

                                <Grid item xs={12} md={10}
                                >
                                    <Typography variant='h3' fontSize={28} fontWeight={450}>
                                        {subscription.name}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} md={2}
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            subscription.currency == 1 ?
                                                `$ ${Number(redondearHaciaArriba(subscription.price)).toLocaleString('es-CL')}`
                                                :
                                                `$ ${subscription.price.toLocaleString('en-US')}`
                                        }
                                    </Typography>

                                </Grid>
                            </Grid>


                            <Grid container

                            >
                                <Grid item xs={6}>

                                </Grid>
                                <Grid item xs={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        textAlign: 'end'
                                    }}
                                >
                                    <Typography variant='h3' fontSize={22} fontWeight={450}>
                                        Subtotal
                                    </Typography>

                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            subscription.currency == 1 ?
                                                `$ ${Number(redondearHaciaArriba(subscription.price)).toLocaleString('es-CL')}`
                                                :
                                                `$ ${subscription.price.toLocaleString('en-US')}`
                                        }
                                    </Typography>


                                </Grid>
                            </Grid>
                            <Grid container
                                sx={{
                                    display: 'flex', width: '100%',
                                    textAlign: 'end'
                                }}
                            >
                                <Grid item xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography variant='h3' fontSize={22} fontWeight={450}>
                                        Taxes
                                    </Typography>

                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            subscription.currency == 1 ?
                                                `$ ${Number(redondearHaciaArriba(price.taxAmount)).toLocaleString('es-CL')}`
                                                :
                                                `$ ${price.taxAmount.toLocaleString('en-US')}`
                                        }
                                    </Typography>


                                </Grid>
                            </Grid>
                            <Grid container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    textAlign: 'end'
                                }}
                            >
                                <Grid item xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography variant='h3' fontSize={22} fontWeight={450}>
                                        Total
                                    </Typography>

                                    <Typography variant='h3' fontSize={24} color={"#ffa400"} fontWeight={600}>
                                        {
                                            subscription.currency == 1 ?
                                                `$ ${Number(redondearHaciaArriba(price.total)).toLocaleString('es-CL')}`
                                                :
                                                `$ ${price.total.toLocaleString('en-US')}`
                                        }
                                    </Typography>


                                </Grid>
                            </Grid>
                        </Paper>
                        <Box
                        >
                            <Typography variant='h3' fontSize={28} fontWeight={450}>
                                Seleccionar metodo de pago
                            </Typography>
                            <Grid container mt={2} spacing={4}>
                                {
                                    paymentMethods.map((e, index) => {
                                        if (e.currencyAllowed.includes(currencies[subscription.currency]))
                                            return (
                                                <Grid item md={6} xs={12}>
                                                    <Button
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                        onClick={() => setCurrentMethod(index)}
                                                    // disabled={status === 'loading'}
                                                    >
                                                        <Paper
                                                            sx={{
                                                                width: "100%",
                                                                backgroundColor: '#F8F8FB',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                p: 2,
                                                                transition: "all ease-in 0.4s",
                                                                ":hover": {
                                                                    cursor: "pointer",
                                                                    backgroundColor: '#fff'
                                                                },
                                                                border: index === currentMethod ? "solid #ffa400 1px" : "",
                                                                // opacity: !e.currencyAllowed.includes(currentMethod.currency) ? 0.3 : 1
                                                            }}
                                                            elevation={4}
                                                        >
                                                            <img src={e.img} alt={`${e.name} logo`} height={'70px'} />
                                                        </Paper>
                                                    </Button>

                                                </Grid>
                                            )
                                    })
                                }

                            </Grid>
                        </Box>
                        <Box
                            display={'flex'}
                        >
                            <Button
                                sx={{ m: '0 auto ' }}
                                variant='contained'
                                color='success'
                                disabled={currentMethod === -1}
                                onClick={handleSubmit}
                            >
                                Pay now!
                            </Button>
                        </Box>
                    </Box>


                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={priceStatus === "loading"}
                    // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper >

            </Box >
        )
}
