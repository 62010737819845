import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, errorState, successState, setDashboards, setDashboard } from "./slice";
import dashboardsAgent from "./agent";
import Swal from "sweetalert2";
import TablesAgent from "../tables/agent";



export const getDashboardsNames = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.getDashboards(tenantId)
            .then((result) => {
                dispatch(setDashboards(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const getDashboardbyId = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.getDashboard(id)
            .then((result) => {
                dispatch(setDashboard(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const createDashboard = (data: any, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.postDashboards(data)
            .then((result) => {
                dispatch(getDashboardsNames(tenantId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}
export const createWidget = (data: any, dashboardId: string, endpoint: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.postWidget(data, endpoint)
            .then((result) => {
                dispatch(getDashboardbyId(dashboardId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}
export const updateWidget = (data: any, dashboardId: string, endpoint: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.putWidget(data, endpoint)
            .then((result) => {
                dispatch(getDashboardbyId(dashboardId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}
export const deleteWidget = (id: string, dashboardId: string, endpoint: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.deleteWidget(id, endpoint)
            .then((result) => {
                dispatch(getDashboardbyId(dashboardId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}
export const desactiveTimer = (data: any, dashboardId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        await dashboardsAgent.desactiveWidget(data)
            .then((result) => {
                dispatch(getDashboardbyId(dashboardId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const moreTime = (data: any, dashboardId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        await dashboardsAgent.moreTimeWidget(data)
            .then((result) => {
                console.log('Si pasa')
                dispatch(getDashboardbyId(dashboardId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const deleteDashboard = (id: string, tenantId: string, navigate: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.deleteDashboard(id)
            .then((result) => {
                dispatch(getDashboardsNames(tenantId))
                navigate('/')
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}


export const updateDashboard = (data: any, dashboardId: string, tenantId: string, setOpen: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dashboardsAgent.putDashboard(data)
            .then((result) => {
                dispatch(getDashboardbyId(dashboardId))
                dispatch(getDashboardsNames(tenantId))
                setOpen(false)
            }).catch((err) => {
                Swal.fire({
                    title: "Ups! Something's wrong",
                    showCancelButton: true,
                    cancelButtonText: "Go back",
                    // text: `Error Message: ${err.response.data.message}`,
                    icon: 'error',
                    showConfirmButton: false
                })
                dispatch(errorState())
            });
    }
}