import { createSlice } from "@reduxjs/toolkit";

interface Fields {
    id: string,
    columnId: string
    rowId: string
    position: number
    value: string
}
interface Columns {
    id: string,
    tableId: string,
    name: string,
    type: number
    position: number,
    fields: Fields[]
}
export interface Rows {
    id: string
    position: number
}
export interface TableNames {
    id: string
    tenantId: string
    name: string
}
export interface TableColumns {
    id: string
    tableId: string
    name: string
    type: number
    position: number
}
interface State {
    status: string,
    statusData: string,
    tablesData: {
        id: string,
        columns: Columns[],
        rows: Rows[],
        name: string,
        tenantId: string,
        totalCount: number,
        pageSize: number,
        pageNumber: number
    },
    tablesName: TableNames[],
    tablesColumns: TableColumns[],
    currentTableName: {
        id: string,
        tenantId: string,
        name: string
        rows: null
        columns: null
    },
}
const initialState: State = {
    status: "loading",
    statusData: "loading",
    tablesData: {
        id: "",
        columns: [],
        rows: [],
        name: "",
        tenantId: "",
        pageSize: 10,
        pageNumber: 0,
        totalCount: 0
    },
    tablesName: [],
    tablesColumns: [],
    currentTableName: {
        id: "",
        tenantId: "",
        name: "",
        rows: null,
        columns: null
    },
};
export const tablesSlice = createSlice({
    name: "tables",
    initialState,
    reducers: {
        setTablesName: (state, { payload }) => {
            state.status = "confirmed";
            state.tablesName = payload.result;
        },
        setCurrentTableName: (state, { payload }) => {
            state.status = "confirmed";
            state.currentTableName = payload.result;
        },
        setTablesColumns: (state, { payload }) => {

            state.status = "confirmed";
            state.tablesColumns = payload.result;


        },
        cleanData: (state) => {
            state.statusData = "confirmed";
            state.status = "confirmed";
            state.tablesData.rows = [];
            state.tablesData.columns = [];
        },
        setTablesData: (state, { payload }) => {
            if (window.location.pathname.split("/")[4] === payload.result.id) {
                state.statusData = "confirmed";
                state.status = "confirmed";
                state.tablesData = payload.result;
                state.tablesData.pageNumber = payload.pageNumber;
                state.tablesData.totalCount = payload.totalCount;
                state.tablesData.pageSize = payload.pageSize;
            }
        },
        loading: (state) => {
            state.status = "loading";
        },
        deleting: (state) => {
            state.status = "deleting";
        },
        loadingData: (state) => {
            state.statusData = "loading";
        },
        readyState: (state) => {
            state.status = "confirmed";
        },
        errorState: (state) => {
            state.status = "error";
        },
    },
});
export const {
    setTablesData,
    setTablesName,
    loading,
    errorState,
    setTablesColumns,
    setCurrentTableName,
    cleanData,
    loadingData,
    deleting,
    readyState,
} = tablesSlice.actions;
