export function replaceIdsWithNames(inputString, tablesColumn) {
    // Define la expresión regular para buscar IDs en el formato {{@ID}}
    const idRegex = /{{@([^}]+)}}/g;

    // Reemplaza los IDs en el string con los nombres correspondientes
    const outputString = inputString.replace(idRegex, (match, id) => {
        // Verifica si el ID tiene un nombre asociado en el mapa
        let Realid = id.split(".")[0];
        let property = id.split(".")[1];
        const name = tablesColumn.find((table) => table.id === Realid);
        //return name ? name : match; // Devuelve el nombre o el ID original si no se encontró el nombre
        if (property) {
            return `
          <span style="font-weight:600;">
            (Column Name: ${name?.name}.${property})
          </span>
        `;
        } else
            return `
        <span style="font-weight:600;">
          (Column Name: ${name?.name})
        </span>
      `;
    });

    return outputString;
}
