import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, errorState, successState, setLabelsGroup } from "./slice";
import labelsAgent from "./agent";
import Swal from "sweetalert2";

export const checkingRoles = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}

export const getLabels = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.getLabels(tenantId)
            .then((result) => {
                dispatch(setLabelsGroup(result.result))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const UpdateLabelGroup = (data: any, tenantId: string, setEditing: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.putLabelGroup(data)
            .then((result) => {
                setEditing('')
                dispatch(getLabels(tenantId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}
export const deleteLabelGroup = (labelGroupId: string, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.deleteLabelGroup(labelGroupId)
            .then((result) => {
                dispatch(getLabels(tenantId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}
export const createLabelGroup = (data: any, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.postLabelGroup(data)
            .then((result) => {
                dispatch(getLabels(tenantId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}
export const createLabel = (data: any, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.postLabel(data)
            .then((result) => {
                dispatch(getLabels(tenantId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}
export const updateLabel = (data: any, tenantId: string, setEditing: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.putLabel(data)
            .then((result) => {
                dispatch(getLabels(tenantId))
                setEditing('')
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const deleteLabel = (labelId: string, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.deleteLabel(labelId)
            .then((result) => {
                dispatch(getLabels(tenantId))
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}

export const dragableLabel = (data: any, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        labelsAgent.dragablePostLabel(data)
            .then((result) => {
                dispatch(successState())
            }).catch((err) => {
                if (process.env.NODE_ENV === 'development')
                    console.log(err);
                dispatch(errorState())
            });
    }
}