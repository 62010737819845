
import React, { Component, useEffect, useState } from 'react';
import SignpostIcon from '@mui/icons-material/Signpost';
import {
    // Editor,
    EditorState,
    ContentBlock,
    CompositeDecorator,
    convertToRaw,
    Modifier,
    ContentState,
    genKey,
    Entity,
    SelectionState,
    BlockMapBuilder,
} from 'draft-js';
import { List, OrderedSet, Map } from 'immutable';
import CloseIcon from '@mui/icons-material/Close';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Box } from '@mui/material';
import Editor, { createEditorStateWithText, composeDecorators } from '@draft-js-plugins/editor';
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    CodeButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    CodeBlockButton,
} from '@draft-js-plugins/buttons';
import editorStyles from './editorStyles.module.css';
import { TableColumns } from '../../../redux/tables/slice';
import { NestedDropdown, MenuItemData } from 'mui-nested-menu';
import { types } from '../../Enums/ColumnsTypes';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RuleIcon from '@mui/icons-material/Rule';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { ClockIcon } from "@mui/x-date-pickers";
import DateRangeIcon from '@mui/icons-material/DateRange';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

const TypesIcons = [
    <FormatColorTextIcon sx={{ verticalAlign: 'middle', height: 15, width: 15 }} />,
    <NumbersIcon sx={{ verticalAlign: 'middle' }} />,
    <CalendarMonthIcon sx={{ verticalAlign: 'middle' }} />,
    <RuleIcon sx={{ verticalAlign: 'middle' }} />,
    <RuleIcon sx={{ verticalAlign: 'middle' }} />,
    <PersonIcon sx={{ verticalAlign: 'middle' }} />,
    <AttachMoneyIcon sx={{ verticalAlign: 'middle' }} />,
    <ApartmentIcon sx={{ verticalAlign: 'middle' }} />,
    <SupervisedUserCircleIcon sx={{ verticalAlign: 'middle' }} />,
    <NumbersIcon sx={{ verticalAlign: 'middle' }} />,
    <SupervisedUserCircleIcon sx={{ verticalAlign: 'middle' }} />,
]


const HeadlinesPicker = (props: any) => {
    useEffect(() => {
        const onWindowClick = () => {
            // Call `onOverrideContent` again with `undefined`
            // so the toolbar can show its regular content again.
            props.onOverrideContent(undefined);
        };

        window.addEventListener('click', onWindowClick);

        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    }, [props]);

    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];

    return (
        <div>
            {buttons.map((Button, i) => (
                <Button key={i} {...props} />
            ))}
        </div>
    );
};

const HeadlinesButton = (props: any) => {
    const onClick = () => {
        // A button can call `onOverrideContent` to replace the content
        // of the toolbar. This can be useful for displaying sub
        // menus or requesting additional information from the user.
        props.onOverrideContent(HeadlinesPicker);
    };

    return (
        <div className={editorStyles.headlineButtonWrapper}>
            <button onClick={onClick} className={editorStyles.headlineButton}>
                H
            </button>
        </div>
    );
};


function CustomComponent(props: any, tablesColumns: TableColumns[]) {
    const patron = /{{@(.*?)}}/;
    const resultado = props.decoratedText.match(patron);
    var id = resultado[1].split(".")[0] === undefined ? resultado[1] : resultado[1].split(".")[0]
    var variable = resultado[1].split(".")[1]
    let item = tablesColumns.find(e => e.id === id)

    return (
        <span
            style={{ backgroundColor: '#2196f3', border: '1px solid black', color: '#fff', fontSize: 16 }}
            data-offset-key={props.offsetKey}
        >

            {item
                &&
                TypesIcons[item?.type]
            }
            {
                item
                &&
                item.name
            }
            {variable && <span>
                : {variable}
            </span>}

            <span style={{ color: '#2196f3', fontSize: 0 }} >
                {props.decoratedText}
            </span>
        </span>
    );
}

interface Props {
    tablesColumns: TableColumns[],
    content: string,
    handleChangueBody: (body: string) => void
}
function RichTextEditor({ tablesColumns, content, handleChangueBody }: Props) {
    // Convierte el HTML en un objeto ContentState
    const blocksFromHtml = htmlToDraft(content);
    const initialContentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
    );



    const customDecorator = new CompositeDecorator([
        {
            strategy: (contentBlock, callback) => {
                const text = contentBlock.getText();
                const matches = text.match(/{{@(.*?)}}/g);
                if (matches) {
                    matches.forEach(match => {
                        const start = text.indexOf(match);
                        callback(start, start + match.length);
                    });
                }
            },
            component: (props) => CustomComponent({ ...props }, tablesColumns),
        },
    ]);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(initialContentState)
    );

    const onRemoveComponent = (blockKeyToRemove: string) => {
        // Obtiene el contenido actual del editor
        const contentState = editorState.getCurrentContent();

        // Encuentra el bloque que corresponde a la clave blockKeyToRemove
        const blockToRemove = contentState.getBlockForKey(blockKeyToRemove);

        if (blockToRemove) {
            // Crea un nuevo `ContentState` sin el bloque a eliminar.

            const newContentState = contentState
                .getBlockMap()
                .delete(blockKeyToRemove);

            const temp = ContentState.createFromBlockArray(newContentState.toArray());
            // Crea un nuevo `EditorState` con el nuevo `ContentState`.
            const newEditorState = EditorState.push(
                editorState,
                temp,
                'remove-range' // Utiliza el valor apropiado aquí
            );

            // Establece el nuevo estado del editor
            setEditorState(newEditorState);
        }
    };
    const insertCustomComponent = (data: string, name: string) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();

        const text = `{{@${data}}}`;

        // Cree un nuevo ContentState con la entidad del componente personalizado.
        const contentStateWithEntity = contentState.createEntity(
            'CUSTOM',
            'IMMUTABLE',
            { content: 'Custom Component' }
        );
        const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newContentState = Modifier.insertText(
            contentState,
            selection,
            text,
            undefined,
            newEntityKey
        );

        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'apply-entity'
        );

        // Mueva el cursor al final del nuevo bloque.
        const newSelection = selection.merge({
            anchorOffset: selection.getAnchorOffset() + text.length,
            focusOffset: selection.getAnchorOffset() + text.length,
        });

        const newEditorStateWithSelection = EditorState.forceSelection(
            newEditorState,
            newSelection
        );

        setEditorState(newEditorStateWithSelection);
    };

    const onChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
    };

    const editorContent = convertToRaw(editorState.getCurrentContent());
    const menuItemsData: MenuItemData = {
        label: 'Dynamic data',
        items:
            tablesColumns.map((e) => {
                if (e.type === types.Account) {
                    return (

                        {
                            label: e.name,
                            leftIcon: <ApartmentIcon />,
                            items: [

                                {
                                    label: 'Name',
                                    // leftIcon: <KeyIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.name`, e.name)
                                },
                                {
                                    label: 'Business Name',
                                    // leftIcon: <KeyIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.businessName`, e.name)
                                },
                                {
                                    label: 'Country Id',
                                    // leftIcon: <KeyIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.countryId`, e.name)
                                },
                                {
                                    label: 'Address',
                                    // leftIcon: <SignpostIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.address`, e.name)
                                },

                                {
                                    label: 'Email',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.email`, e.name)
                                },
                                {
                                    label: 'Phone Number',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.phoneNumber`, e.name)
                                },
                                {
                                    label: 'Website',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.website`, e.name)
                                },
                                {
                                    label: 'LinkedIn',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.linkedin`, e.name)
                                },
                                {
                                    label: 'Instragram',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.instragram`, e.name)
                                },
                                {
                                    label: 'Facebook',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.facebook`, e.name)
                                },
                                {
                                    label: 'Twitter',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.twitter`, e.name)
                                },
                                {
                                    label: 'TikTok',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.tiktok`, e.name)
                                },
                                {
                                    label: 'YouTube',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.youtube`, e.name)
                                },
                                {
                                    label: 'Threads',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.threads`, e.name)
                                },
                                {
                                    label: 'Twitch',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.twitch`, e.name)
                                },
                                {
                                    label: 'Notes',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.notes`, e.name)
                                },
                                {
                                    label: 'Size',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.size`, e.name)
                                },
                            ]
                        }
                    )
                } else if (e.type === types.Contact) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <PersonIcon />,
                            items: [
                                {
                                    label: 'Name',
                                    // leftIcon: <KeyIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.name`, e.name)
                                },
                                {
                                    label: 'Work Position',
                                    // leftIcon: <KeyIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.businessName`, e.name)
                                },
                                {
                                    label: 'Country Id',
                                    // leftIcon: <KeyIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.countryId`, e.name)
                                },
                                {
                                    label: 'Address',
                                    // leftIcon: <SignpostIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.address`, e.name)
                                },

                                {
                                    label: 'Email',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.email`, e.name)
                                },
                                {
                                    label: 'Phone Number',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.phoneNumber`, e.name)
                                },
                                {
                                    label: 'Website',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.website`, e.name)
                                },
                                {
                                    label: 'LinkedIn',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.linkedin`, e.name)
                                },
                                {
                                    label: 'Instragram',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.instragram`, e.name)
                                },
                                {
                                    label: 'Facebook',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.facebook`, e.name)
                                },
                                {
                                    label: 'Twitter',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.twitter`, e.name)
                                },
                                {
                                    label: 'TikTok',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.tiktok`, e.name)
                                },
                                {
                                    label: 'YouTube',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.youtube`, e.name)
                                },
                                {
                                    label: 'Twitch',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.twitch`, e.name)
                                },
                                {
                                    label: 'Threads',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.threads`, e.name)
                                },
                                {
                                    label: 'Notes',
                                    // leftIcon: <NumbersIcon />,
                                    callback: (event, item) => insertCustomComponent(`${e.id}.notes`, e.name)
                                },
                            ]
                        }
                    )
                }
                else if (e.type === types.Text) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <FormatColorTextIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.Number || e.type === types.Decimal) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <NumbersIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.Date) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <CalendarMonthIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.Bool) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <RuleIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.Money) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <AttachMoneyIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                }
                else if (e.type === types.EndUser) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <SupervisedUserCircleIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.Id) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <SupervisedUserCircleIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.ExactTime) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <ClockIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                } else if (e.type === types.TimeSpan) {
                    return (
                        {
                            label: e.name,
                            leftIcon: <DateRangeIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                }
                else {
                    return (
                        {
                            label: e.name,
                            leftIcon: <AddBoxIcon />,
                            callback: (event, item) => insertCustomComponent(e.id, e.name)
                        }
                    )
                }
            })
        ,
    };
    useEffect(() => {
        const renderContent = draftToHtml(editorContent)
        handleChangueBody(renderContent)
    }, [editorContent])

    return (
        <div className={editorStyles.editor}>

            <Toolbar>
                {
                    // may be use React.Fragment instead of div to improve perfomance after React 16
                    (externalProps) => (
                        <Box
                            sx={{ display: 'flex', gap: 1 }}
                        >
                            <BoldButton {...externalProps} />
                            <ItalicButton {...externalProps} />
                            <UnderlineButton {...externalProps} />
                            <CodeButton {...externalProps} />

                            {/* <Separator {...externalProps} /> */}
                            {/* <HeadlinesButton {...externalProps} /> */}
                            <UnorderedListButton {...externalProps} />
                            <OrderedListButton {...externalProps} />
                            <BlockquoteButton {...externalProps} />
                            <CodeBlockButton {...externalProps} />
                            <Box>
                                {/* <button onClick={insertCustomComponent}>Insert Custom Component</button> */}
                                <NestedDropdown
                                    menuItemsData={menuItemsData}
                                    MenuProps={{ elevation: 3 }}
                                    ButtonProps={{ variant: 'outlined' }}
                                />
                            </Box>
                        </Box>
                    )
                }
            </Toolbar>
            <Editor
                editorState={editorState}
                onChange={onChange}
                plugins={plugins}
                decorators={[customDecorator]}
            />
        </div>
    );
}

export default RichTextEditor;