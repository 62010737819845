import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth/slice'
import { paymentSlice } from './payment/slice'
import { roleSlice } from './roles/slice'
import { usersSlice } from './user/slice'
import { tenantSlice } from './tenant/slice'
import { accountSlice } from './account/slice'
import { contactSlice } from './contacts/slice'
import { cpanleSlice } from './cpanel/slice'
import { tablesSlice } from './tables/slice'
import { subscriptionPaymentSlice } from './subscriptionPayment/slice'
import { keysSlice } from './keys/slice'
import { reportsSlice } from './reports/slice'
import { autoRuleSlice } from './autoRules/slice'
import { endUserSlice } from './endUser/slice'
import { commerceSlice } from './commerce/slice'
import { permissionSlice } from './permission/slice'
import { labelsSlice } from './labels/slice'
import { dashboardsSlice } from './dashboards/slice'
import { tenantUserSlice } from './tenantsUser/slice'
import { seatSlice } from './seat/slice'
import { orderSlice } from './order/slice'

export const store = configureStore({
    reducer: {
        role: roleSlice.reducer,
        payment: paymentSlice.reducer,
        auth: authSlice.reducer,
        users: usersSlice.reducer,
        accounts: accountSlice.reducer,
        contacts: contactSlice.reducer,
        tenant: tenantSlice.reducer,
        cpanel: cpanleSlice.reducer,
        tables: tablesSlice.reducer,
        subscriptionPayment: subscriptionPaymentSlice.reducer,
        keys: keysSlice.reducer,
        reports: reportsSlice.reducer,
        autoRules: autoRuleSlice.reducer,
        endUser: endUserSlice.reducer,
        commerce: commerceSlice.reducer,
        permissions: permissionSlice.reducer,
        labels: labelsSlice.reducer,
        dashboards: dashboardsSlice.reducer,
        tenantsUser: tenantUserSlice.reducer,
        seat: seatSlice.reducer,
        order: orderSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch