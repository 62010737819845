import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Paper, Typography, Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getTenant } from '../../redux/tenant/thunk'
import { genders } from '../../components/Enums/Genders'
import { GridItem } from '../../components/custom/Grid/GridItem'
import GaugeChart from 'react-gauge-chart'
import { getSubscriptionConsumption } from '../../redux/subscriptionPayment/thunk'
import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import TextEditor from '../../components/custom/CustomTextEditor/TextEditor'

export const Home = () => {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [sizeValue, setSizeValue] = useState<number>(0)

    const dispatch = useAppDispatch()
    const { name, profileImage, lastname, roles, tenantId, gender, isOwner, suscription, } = useAppSelector(state => state.auth)
    const { tenant } = useAppSelector(state => state.tenant)
    const { status, subscriptionConsumption } = useAppSelector(state => state.subscriptionPayment)


    const handleResize = () => {
        const element = elementRef.current;
        if (element) {
            const width = element.getBoundingClientRect().width;
            setSizeValue((width / 12))
        }
    };
    useEffect(() => {
        const element = elementRef.current;

        if (element) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(element);

            return () => {
                resizeObserver.unobserve(element);
            };
        }
    }, []);
    useEffect(() => {
        dispatch(getSubscriptionConsumption(tenantId))
    }, [])




    return (
        <Box p={2}>
            {
                status === 'loading' ?
                    <Box
                        sx={{
                            minHeight: "100vh",
                            display: "flex",
                            alignItems: "center",
                        }}
                        justifyContent={"center"}
                    >
                        <LoadingCube />
                    </Box>
                    :
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: "wrap",
                            ".custom-grid-item": {
                                padding: 2
                            }
                        }}
                        ref={elementRef}
                    >

                        <GridItem sizeValue={sizeValue}
                            width={{
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 5,
                                xl: 4
                            }}
                            height={{
                                xs: 12,
                                sm: 5,
                                md: 4,
                                lg: 3,
                                xl: 2
                            }}
                        >
                            <Paper elevation={4}
                                sx={{
                                    height: '100%',

                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: 125,
                                        backgroundColor: "rgba( 255, 164, 0 ,0.4)",
                                        width: "100%",
                                        alignItems: 'center',
                                        // justifyContent: 'space-evenly',
                                        borderRadius: "4px 4px 0px 0px",
                                        p: 2
                                    }}
                                >
                                    <Box>

                                        <Typography
                                            variant='h6'
                                            fontSize={16}
                                            fontWeight={500}
                                            color={"#bf7b00"}
                                        >
                                            {parseInt(gender) === genders.Masculino && "Bienvenido"}{parseInt(gender) === genders.Femenino && "Bienvenida"}{parseInt(gender) === genders.Otro && "Bienvenid@"} {name}
                                        </Typography>
                                        <Typography
                                            variant='caption'
                                            color={"#bf7b00"}

                                        >
                                            Nexus Plataform
                                        </Typography>
                                    </Box>


                                </Box>
                                <Box
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // alignItems: 'center',
                                        position: 'relative'
                                    }}
                                >

                                    <Avatar
                                        alt={`${name} ${lastname}`}
                                        src={`data:image/png;base64, ${profileImage}`}
                                        sx={{
                                            border: "solid 4px #ffa400",
                                            height: 60,
                                            width: 60,
                                            position: 'absolute',
                                            left: "5%",
                                            top: { xs: "-30%", sm: "-40%" }
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            mt: 2,
                                            px: 2
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                p: 1
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    fontWeight={500}
                                                    fontSize={15}
                                                >
                                                    {name} {lastname}
                                                </Typography>
                                                <Typography
                                                    variant='caption'
                                                    color={"#74788d"}

                                                >
                                                    {roles[0]}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant='caption'
                                                    color={"#74788d"}

                                                >
                                                    Compañia
                                                </Typography>
                                                <Typography
                                                    fontWeight={500}
                                                    fontSize={15}
                                                >
                                                    {tenant?.name}
                                                </Typography>

                                            </Box>
                                            {
                                                isOwner &&
                                                <Box>
                                                    <Typography
                                                        variant='caption'
                                                        color={"#74788d"}

                                                    >
                                                        Suscripción actual
                                                    </Typography>
                                                    <Typography
                                                        fontWeight={500}
                                                        fontSize={15}
                                                    >
                                                        {suscription?.name}
                                                    </Typography>

                                                </Box>
                                            }

                                        </Box>
                                    </Box>

                                </Box>


                            </Paper>
                        </GridItem>
                        {subscriptionConsumption
                            &&
                            <GridItem sizeValue={sizeValue}
                                width={{
                                    xs: 12,
                                    sm: 9,
                                    md: 6,
                                    lg: 4,
                                    xl: 3
                                }}
                                height={{
                                    xs: 12,
                                    sm: 9,
                                    md: 4,
                                    lg: 3,
                                    xl: 2
                                }}
                            >
                                <Paper sx={{ height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', p: 2 }}>
                                    <GaugeChart id="gauge-chart2"
                                        nrOfLevels={20}
                                        percent={(subscriptionConsumption.consumedAccountStorage / subscriptionConsumption.suscription.storage)}
                                        textColor='#000'
                                        formatTextValue={(value) => {
                                            return ("")
                                        }}
                                    />
                                    <Typography fontSize={30}>
                                        {subscriptionConsumption.consumedAccountStorage}/{subscriptionConsumption.suscription.storage}
                                    </Typography>
                                    <Typography variant='caption' fontSize={20}>GB</Typography>
                                </Paper>
                            </GridItem>
                        }
                        {subscriptionConsumption
                            &&
                            <GridItem sizeValue={sizeValue}
                                width={{
                                    xs: 12,
                                    sm: 9,
                                    md: 6,
                                    lg: 4,
                                    xl: 3
                                }}
                                height={{
                                    xs: 12,
                                    sm: 9,
                                    md: 4,
                                    lg: 3,
                                    xl: 2
                                }}
                            >
                                <Paper sx={{ height: '100%', p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                    <GaugeChart id="gauge-chart2"
                                        nrOfLevels={20}
                                        percent={(subscriptionConsumption.consumedApiCalls / subscriptionConsumption.suscription.apiCalls)}
                                        textColor='#000'
                                        formatTextValue={(value) => {
                                            return ("")
                                        }}
                                    />
                                    <Typography fontSize={30}>
                                        {subscriptionConsumption.consumedApiCalls}/{subscriptionConsumption.suscription.apiCalls}
                                    </Typography>
                                    <Typography variant='caption' fontSize={20}>Api Calls</Typography>
                                </Paper>
                            </GridItem>
                        }
                        {subscriptionConsumption
                            &&
                            <GridItem sizeValue={sizeValue}
                                width={{
                                    xs: 12,
                                    sm: 9,
                                    md: 6,
                                    lg: 4,
                                    xl: 3
                                }}
                                height={{
                                    xs: 12,
                                    sm: 9,
                                    md: 4,
                                    lg: 3,
                                    xl: 2
                                }}
                            >
                                <Paper sx={{ height: '100%', p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                    <GaugeChart id="gauge-chart2"
                                        nrOfLevels={20}
                                        percent={(subscriptionConsumption.consumedAutorules / subscriptionConsumption.suscription.autorules)}
                                        textColor='#000'
                                        formatTextValue={(value) => {
                                            return ("")
                                        }}
                                    />
                                    <Typography fontSize={30}>
                                        {subscriptionConsumption.consumedAutorules}/{subscriptionConsumption.suscription.autorules}
                                    </Typography>
                                    <Typography variant='caption' fontSize={20}>Autorules</Typography>
                                </Paper>
                            </GridItem>
                        }
                        {subscriptionConsumption
                            &&
                            <GridItem sizeValue={sizeValue}
                                width={{
                                    xs: 12,
                                    sm: 9,
                                    md: 6,
                                    lg: 4,
                                    xl: 3
                                }}
                                height={{
                                    xs: 12,
                                    sm: 9,
                                    md: 4,
                                    lg: 3,
                                    xl: 2
                                }}
                            >
                                <Paper sx={{ height: '100%', p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                    <GaugeChart id="gauge-chart2"
                                        nrOfLevels={20}
                                        percent={(subscriptionConsumption.consumedRoles / subscriptionConsumption.suscription.roles)}
                                        textColor='#000'
                                        formatTextValue={(value) => {
                                            return ("")
                                        }}
                                    />
                                    <Typography fontSize={30}>
                                        {subscriptionConsumption.consumedRoles}/{subscriptionConsumption.suscription.roles}
                                    </Typography>
                                    <Typography variant='caption' fontSize={20}>Roles</Typography>
                                </Paper>
                            </GridItem>
                        }
                        {subscriptionConsumption
                            &&
                            <GridItem sizeValue={sizeValue}
                                width={{
                                    xs: 12,
                                    sm: 9,
                                    md: 6,
                                    lg: 4,
                                    xl: 3
                                }}
                                height={{
                                    xs: 12,
                                    sm: 9,
                                    md: 4,
                                    lg: 3,
                                    xl: 2
                                }}
                            >
                                <Paper sx={{ height: '100%', p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                    <GaugeChart id="gauge-chart2"
                                        nrOfLevels={20}
                                        percent={(subscriptionConsumption.consumedTables / subscriptionConsumption.suscription.tables)}
                                        textColor='#000'
                                        formatTextValue={(value) => {
                                            return ("")
                                        }}
                                    />
                                    <Typography fontSize={30}>
                                        {subscriptionConsumption.consumedTables}/{subscriptionConsumption.suscription.tables}
                                    </Typography>
                                    <Typography variant='caption' fontSize={20}>Tables</Typography>
                                </Paper>
                            </GridItem>
                        }
                    </Box>
            }

        </Box>
        // <Grid container
        //     sx={{
        //         px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 },
        //         mt: 1
        //     }}
        //     spacing={4}
        // >
        //     <Grid item xs={12} md={6}>

        //     </Grid>
        // </Grid>
    )
}
