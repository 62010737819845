import React from 'react';
import QuestionField from './QuestionField';
import { ro } from 'date-fns/locale';


interface QuestionType {
  id: string;
  name: string;
  description: string;
  tooltip: string;
  isVisible: boolean;
  isMandatory: boolean;
  defaultValue: string;
  dataType: number;
  position: number;
  dynamicColumnId:string;
}

interface QuestionFieldListProps {
  questions: QuestionType[];
  answers: { [key: string]: any };
  onValueChange: (id: string, value: any) => void;
  onEditQuestion: (id: string) => void;
  onDeleteQuestion: (id: string) => void;
  onEditSave: (id: string, updatedData: any) => void;
  cancelEdit: () => void;   
  editingQuestionId: string | null;
  isReportEditing: boolean;
  tenantId: string;
  moveQuestion: (questionId: string, direction: string) => void;
  roleReport : string | '';
  canEditQuestion : string | '';
  canDeleteQuestion : string | '';
  reset: boolean;
  lenghtPosition: number;
}

const QuestionFieldList: React.FC<QuestionFieldListProps> = ({ 
  questions, 
  answers,
  onValueChange, 
  onEditQuestion, 
  onDeleteQuestion,
  onEditSave,
  cancelEdit,
  editingQuestionId,
  isReportEditing,
  tenantId,
  moveQuestion,
  roleReport,
  canEditQuestion,
  canDeleteQuestion,
  reset,
  lenghtPosition
}) => {
  return (
    <div>
      {questions.map((question) => (
        <QuestionField
          key={question.id}
          question={question}
          propValue = {answers[question.id] ?? ''}
          onValueChange={onValueChange}
          onEditClick={onEditQuestion}
          onDeleteClick={onDeleteQuestion}
          onEditSave={onEditSave}
          cancelEdit={cancelEdit}
          editingQuestionId={editingQuestionId}
          isReportEditing={isReportEditing}
          tenantId= {tenantId}
          moveQuestion = {moveQuestion}
          roleReport= {roleReport}
          canEditQuestion= {canEditQuestion}
          canDeleteQuestion= {canDeleteQuestion}
          reset={reset}
          lenghtPosition={lenghtPosition}
        />
      ))}
    </div>
  );
};

export default QuestionFieldList;
