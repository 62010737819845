import { Box, Button, Paper, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { EditableInput } from '../../components/custom/EditableInput'
import { appTheme } from '../../styles/MUI/Theme'
import EditNoteIcon from '@mui/icons-material/EditNote';
import { CustomButton } from '../../components/custom/CustomButton';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getSettings, setSettings } from '../../redux/cpanel/thunk';
import { isEmail } from '../../functions/EmailVerificator';
import { validarCadena } from '../../functions/PortVerificator';
import { LoadingCube } from '../../components/custom/loader/LoadingCube';
export const EditContactEmail = () => {
    const [editable, setEditable] = useState(false)
    const [data, setData] = useState({
        SMTPPort: "",
        SMTPHost: "",
        SMTPUser: "",
        SMTPPassword: ""
    })
    const { tenantId, id } = useAppSelector(state => state.auth)
    const { status, host, password, port, user } = useAppSelector(state => state.cpanel)

    const dispatch = useAppDispatch()
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setEditable(false)
        dispatch(setSettings({ ...data, SMTPPort: parseInt(data.SMTPPort), tenantId: tenantId, lastModifier: id, token: "a" }, { host, password, port, user }))
    }
    useEffect(() => {
        dispatch(getSettings(tenantId))
    }, [])
    useEffect(() => {
        // console.log(host, password, port, user);

        if (port || host || user)
            setData({ ...data, SMTPPort: port, SMTPHost: host, SMTPUser: user })
    }, [status])

    if (status === 'loading') {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                }}
                justifyContent={"center"}
            >
                <LoadingCube />
            </Box>
        )
    }
    return (
        <ThemeProvider theme={appTheme}>

            <Box
                sx={{
                    mt: 5,
                    px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 },

                }}
            >
                <Paper
                    sx={{
                        // height: 150,
                        maxWidth: 350,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        // alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                            // gap: 1
                        }}
                    >
                        <Typography variant='h5'

                        >
                            Configuración CPanel
                        </Typography>
                        <EditNoteIcon
                            sx={{
                                cursor: 'pointer',
                                display: editable ? 'none' : "block"
                            }}
                            onClick={() => setEditable(true)}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            alignItems: 'center'
                        }}
                        component={'form'}
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            label="Host"
                            value={data.SMTPHost}
                            name='SMTPHost'
                            onChange={handleChange}
                            disabled={!editable}
                            required

                        />
                        <TextField
                            label="Port"
                            value={data.SMTPPort}
                            name='SMTPPort'
                            // type='number'
                            onChange={handleChange}
                            disabled={!editable}
                            required
                            error={!validarCadena(data.SMTPPort) && data.SMTPPort.length > 0}
                            helperText={!validarCadena(data.SMTPPort) && data.SMTPPort.length > 0 ? 'El port debe contener solo numeros' : ''}
                        />
                        <TextField
                            label="User"
                            value={data.SMTPUser}
                            name='SMTPUser'
                            onChange={handleChange}
                            disabled={!editable}
                            required
                            error={!isEmail(data.SMTPUser) && data.SMTPUser.length > 0}
                            helperText={!isEmail(data.SMTPUser) && data.SMTPUser.length > 0 ? 'Ingrese un correo valido' : ''}
                        />
                        <TextField
                            label="Password"
                            value={editable ? data.SMTPPassword : "......."}
                            name='SMTPPassword'
                            onChange={handleChange}
                            disabled={!editable}
                            type={editable ? "text" : "password"}
                            required={host === "" && port === "" && user === ""}
                        />
                        {
                            editable &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: 4
                                }}
                            >
                                <Box
                                >
                                    <CustomButton color='error' disabled={false} label='Cancelar' onClick={() => { setEditable(false) }} type='button' />
                                </Box>
                                <Box
                                >
                                    <CustomButton color='success' disabled={status === 'loading' || !isEmail(data.SMTPUser) || !validarCadena(data.SMTPPort)} label='Guardar' onClick={() => { }} type='submit' />
                                </Box>
                            </Box>
                        }
                    </Box>
                    {/* <EditableInput
                        label='Host'
                        value={data.host}
                        name='host'
                        handleChange={handleChange}
                        labelButton='Guardar cambios'
                        type='text'
                    />

                    <EditableInput
                        label='Port'
                        value={data.port}
                        name='port'
                        handleChange={handleChange}
                        labelButton='Guardar cambios'
                        type='text'
                    />
                    <EditableInput
                        label='User'
                        value={data.user}
                        name='user'
                        handleChange={handleChange}
                        labelButton='Guardar cambios'
                        type='text'
                    />
                    <EditableInput
                        label='Password'
                        value={data.password}
                        name='password'
                        handleChange={handleChange}
                        labelButton='Guardar cambios'
                        type='password'
                    /> */}
                </Paper>
            </Box>
        </ThemeProvider>

    )
}
