import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { EditColumnName } from './EditColumnName';
import { useAppDispatch } from '../../../hooks/hooks';
import { delTableColumn } from '../../../redux/tables/thunk';
import { ColumnIcon } from '../functions/ColumnsIcons';

interface Column {
    name: string,
    id: string,
    tableId: string,
    position: number,
    type: number
}


export const ColumnMenuItem = ({ column }: { id: string, column: Column }) => {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openEditTable, setOpenEditTable] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container spacing={2} sx={{
            display: "flex", alignItems: "center"
        }}>
            <Grid item xs={5} >
                <Typography sx={{ display: "flex", alignContent: "center" }}>
                    <ColumnIcon columnType={column.type} />
                    {column.name}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
            </Grid>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    setOpenEditTable(true)
                }}>
                    <DriveFileRenameOutlineIcon />
                    Editar nombre
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    dispatch(delTableColumn({
                        columnId: column.id,
                        tableId: column.tableId
                    }, 0, 10))
                }}>
                    <DeleteForeverIcon />
                    Eliminar
                </MenuItem>
            </Menu>
            < EditColumnName
                open={openEditTable}
                setOpen={setOpenEditTable}
                data={{
                    columnName: column.name,
                    columnId: column.id,
                    tableId: column.tableId,
                    position: column.position
                }}
            />
        </Grid>
    )
}
