import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper } from "@mui/material"
import { SubscriptionCard } from '../../components/subscriptions/SubscriptionCard'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getAllSubscriptions } from '../../redux/subscriptionPayment/thunk'
import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import { Navigate } from 'react-router-dom'
import { Subscription } from '../../redux/subscriptionPayment/slice'
export const SubscriptionsPage = () => {
    const [suscriptionOrder, setSuscriptionsOrder] = useState<Subscription[]>([])
    const { subscriptions, status } = useAppSelector(state => state.subscriptionPayment)
    const { isOwner } = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getAllSubscriptions())
    }, [])
    useEffect(() => {
        if (subscriptions) {
            let temp = [...subscriptions]
            const compararPorPrecio = (a: Subscription, b: Subscription) => a.priceMonth - b.priceMonth;
            let sus = temp?.sort(compararPorPrecio);
            setSuscriptionsOrder(sus)

        }

    }, [status])

    if (!isOwner) return (<Navigate to="/"></Navigate>)
    if (status === "loading")
        return (
            <Box
                sx={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <LoadingCube />
            </Box>
        )
    return (
        <Box
            mt={5}
            p={1}
        >
        
            <Grid container spacing={4}
                sx={{
                    display: 'flex',
                    // alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {
                    suscriptionOrder.map((item, zIndex) => {
                        if (item.name !== "Freelancer" && item.name !== "Corporation")
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={zIndex} display={"flex"}>
                                    <SubscriptionCard key={zIndex} suscription={item} isRenderButton={true} />
                                </Grid>
                            )
                    }
                    )
                }

            </Grid>
        </Box>
    )

}
