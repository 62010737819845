import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, errorState, setAllEndUsers, setRecords } from "./slice";
import EndUserAgent from "./agent";

export const checkingRoles = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}
export const getAllEndUsers = (TenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await EndUserAgent.getAllEndUsers(TenantId)
            .then((response) => {
                dispatch(setAllEndUsers(response.result));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const getEndUserRecords = (TenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await EndUserAgent.getRecordsEndUsers(TenantId)
            .then((response) => {
                
                dispatch(setRecords(response.result));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
/*
export const deleteClient = (ClientId: string, TenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.deletClient(ClientId)
            .then((response) => {

                ClientAgent.getAllClients(TenantId)
                    .then((response) => {

                        dispatch(setAllClients(response.result));
                    })
                    .catch((error) => {
                        //console.log(error);
                        dispatch(errorState())
                    })
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const editClient = (Client: any, TenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await ClientAgent.putClient(Client)
            .then((response) => {

                ClientAgent.getAllClients(TenantId)
                    .then((response) => {

                        dispatch(setAllClients(response.result));
                    })
                    .catch((error) => {
                        //console.log(error);
                        dispatch(errorState())
                    })
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const createClient = (client: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await ClientAgent.postClient(client)
            .then((response) => {
                ClientAgent.getAllClients(client.tenantId)
                    .then((response) => {

                        dispatch(setAllClients(response.result));
                    })
                    .catch((error) => {
                        //console.log(error);
                        dispatch(errorState())
                    })
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
*/