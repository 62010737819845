import React, { FormEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getTablesColumns, getTablesNames } from '../../redux/tables/thunk'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { types } from '../Enums/ColumnsTypes'
import axios from 'axios'
import { LabelObject } from '../../redux/labels/slice'
import { createWidget } from '../../redux/dashboards/thunk'
import { useParams } from 'react-router-dom'
import { createQuestionField } from '../../redux/reports/thunk'
import { setTablesColumns } from '../../redux/tables/slice'


interface Props {
    reportId: string
    tableId: string 
    setOpen: any
}
export const QuestionFieldForm = ({ reportId, tableId, setOpen }: Props) => {
    const { id } = useParams()

    const { tablesColumns } = useAppSelector(state => state.tables)

    const [questionFieldData, setQuestionFieldData] = useState({
        "dynamicColumnId": "",
        "dataType": 0,
        "name": "Here you can change the name of your question",
        "description": "Here you can change the description of your question",
        "tooltip": "Default tooltip",
        "isMandatory": false,
        "isPublic": true,
        "defaultValue": "Here you can set a default value"
    })

    const dispatch = useAppDispatch()

    const { status } = useAppSelector(state => state.reports)

    const handleChange = (event: SelectChangeEvent) => {
        let { value, name } = event.target
        setQuestionFieldData({ ...questionFieldData, [name]: value })
    }

    const handleChangeColumn = (event: SelectChangeEvent) => {
        let { value, name } = event.target;
    
        const selectedColumn = tablesColumns.find(column => column.id === value);
        const columnType = selectedColumn ? selectedColumn.type : 0;

        setQuestionFieldData({ 
            ...questionFieldData, 
            [name]: value,
            dataType: columnType
        });
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        dispatch(createQuestionField(
            {
                ...questionFieldData,
                reportId: id,
                "token": "a"
            },
            reportId,
            setOpen
        ))
    }
    useEffect(() => {
        dispatch(getTablesColumns(tableId))
    }, [tablesColumns])
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
            component={'form'}
            onSubmit={handleSubmit}
        >
            <FormControl fullWidth>
                <InputLabel required>ColumnId</InputLabel>
                <Select
                    value={questionFieldData.dynamicColumnId}
                    label="ColumnId"
                    name= 'dynamicColumnId'
                    onChange= {handleChangeColumn}
                    required
                >
                    {
                        tablesColumns.map((e) => {
                            return (
                                <MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>

            <Button type='submit' disabled={status === 'loading'} variant='contained'>
                Create Question Field
            </Button>
        </Box>
    )
}