import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, IconButton, Modal, Paper, Switch, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getAllRoles } from '../../../redux/roles/thunk'
import { getAllUsers } from '../../../redux/user/thunk'
import { CutomAutocomplete } from '../../../components/custom/CutomAutocomplete'
import { createPermission } from '../../../redux/permission/thunk'

interface Props {
    openAddPermission: boolean,
    setOpenAddPermission: React.Dispatch<React.SetStateAction<boolean>>
    objectType: number
    objectId: string
}

export const CreateModal = ({ openAddPermission, setOpenAddPermission, objectType, objectId }: Props) => {
    const dispatch = useAppDispatch()
    const { tenantId } = useAppSelector(state => state.auth)
    const { users } = useAppSelector(state => state.users)
    const { roles } = useAppSelector(state => state.role)
    const { status } = useAppSelector(state => state.permissions)
    const [inputValue, setInputValue] = useState('');
    const [type, setType] = useState(0)
    const [selected, setSelected] = useState<any>()

    const handleAddPermission = () => {
        dispatch(createPermission(
            {
                "authorityId": selected.id,
                "authorityType": type,
                "canCreate": false,
                "canRead": true,
                "canUpdate": false,
                "canUpdateData": false,
                "canDelete": false,
                "canDeleteData": false,
                "canReadRows": false,
                "objectPermission": {
                    "objectId": objectId,
                    "objectType": objectType
                },
                "token": ""
            },
            setOpenAddPermission
        ))
    }

    useEffect(() => {
        dispatch(getAllRoles(tenantId))
        dispatch(getAllUsers(tenantId))
    }, [openAddPermission])
    useEffect(() => {
        setSelected(undefined)
    }, [type])

    return (
        <Modal open={openAddPermission} onClose={() => setOpenAddPermission(false)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1059
            }}
        >
            <Paper elevation={6}
                sx={{
                    width: { xs: '90vw', sm: '80vw', md: '50vw', lg: '30vw', xl: '20vw' }
                }}
            >
                <Box component={'form'} sx={{ display: 'flex', justifyContent: 'center', height: '100%', p: 2 }}>
                    <Box

                        sx={{ display: 'flex', flexDirection: 'column', px: 2, gap: 2, alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: "center"
                            }}
                        >
                            <Typography>
                                Users
                            </Typography>
                            <Switch
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setType(1)
                                    } else {
                                        setType(0)
                                    }
                                }}
                            />
                            <Typography>
                                Roles
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "100%"
                            }}
                        >
                            {
                                type == 0 &&
                                <CutomAutocomplete
                                    label='Users'
                                    onChange={(event, value) => { setSelected(value) }}
                                    options={users}
                                    title='username'
                                    value={selected}
                                    inputValue={inputValue} 
                                    onInputChange={(event, newValue) => setInputValue(newValue)}
                                />
                            }
                            {
                                type == 1 &&
                                <CutomAutocomplete
                                    label='Roles'
                                    onChange={(event, value) => { setSelected(value) }}
                                    options={roles}
                                    title='name'
                                    value={selected}
                                    inputValue={inputValue} 
                                    onInputChange={(event, newValue) => setInputValue(newValue)}
                                />
                            }
                        </Box>
                        <Box>
                            <Button variant='contained' onClick={handleAddPermission} disabled={status === "loading"}>Add permission</Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    )
}
