import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Subscription, error, loading, setSubscription, setSubscriptionConsumption, setSuscriptions } from "./slice";
import AuthAgent from "./agent";
import { parseJwt } from "../../functions/DecodeJWT";
import suscriptionsAgent from "./agent";
import Swal from "sweetalert2";


export const setSub = (subscription: Subscription): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading());
        dispatch(setSubscription(subscription))
    }
}


export const getAllSubscriptions = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())

        await suscriptionsAgent.getAllSubscriptions()
            .then((response) => {
                dispatch(setSuscriptions(response.result));
            })
            .catch((e) => {
                dispatch(error())
                //console.log(error);
            })

    }
}

export const getSubscriptionsid = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())

        await suscriptionsAgent.getAllSubscriptions()
            .then((response) => {
                let subscription = response.result.find((sub: Subscription) => sub.id === id)
                dispatch(setSuscriptions(response.result));
                dispatch(setSubscription(subscription))

            })
            .catch((e) => {
                dispatch(error())
                //console.log(error);
            })

    }
}

export const getSubscriptionConsumption = (tentantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(loading())

        await suscriptionsAgent.getSubscriptionConsumption(tentantId)
            .then((response) => {

                dispatch(setSubscriptionConsumption(response.result));
            })
            .catch((e) => {
                dispatch(error())
                //console.log(error);
            })

    }
}
