import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DataGridPro, DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import {
    randomCreatedDate,
    randomPrice,
    randomCurrency,
    randomCountry,
    randomCity,
    randomEmail,
    randomInt,
    randomAddress,
    randomCommodity,
} from '@mui/x-data-grid-generator';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material';

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .even`]: {
        backgroundColor: blue[50],
        '&:hover, &.Mui-hovered': {
            backgroundColor: blue[50],
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    border: 0,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',

    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
    },
    '& .MuiDataGrid-cell': {
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0,
    },

}));

function DetailPanelContent({ row: rowProp }: { row: Customer }) {
    return (
        <Stack
            sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
            direction="column"
        >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6">{`Order #${rowProp.id}`}</Typography>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body2" color="textSecondary">
                                Customer information
                            </Typography>
                            <Typography variant="body1">{rowProp.customer}</Typography>
                            <Typography variant="body1">{rowProp.email}</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body2" align="right" color="textSecondary">
                                Shipping address
                            </Typography>
                            <Typography variant="body1" align="right">
                                {rowProp.address}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="right"
                            >{`${rowProp.city}, ${rowProp.country.label}`}</Typography>
                        </Grid>
                    </Grid>
                    <DataGridPro
                        density="compact"
                        columns={[
                            { field: 'name', headerName: 'Product', flex: 1 },
                            {
                                field: 'quantity',
                                headerName: 'Quantity',
                                align: 'center',
                                type: 'number',
                            },
                            { field: 'unitPrice', headerName: 'Unit Price', type: 'number' },
                            {
                                field: 'total',
                                headerName: 'Total',
                                type: 'number',
                                valueGetter: ({ row }) => row.quantity * row.unitPrice,
                            },
                        ]}
                        rows={rowProp.products}
                        sx={{ flex: 1 }}
                        hideFooter
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Order ID' },
    { field: 'customer', headerName: 'Customer', width: 200 },
    { field: 'date', type: 'date', headerName: 'Placed at' },
    { field: 'currency', headerName: 'Currency' },
    {
        field: 'total',
        type: 'number',
        headerName: 'Total',
        valueGetter: ({ row }) => {
            const subtotal = row.products.reduce(
                (acc: number, product: any) => product.unitPrice * product.quantity,
                0,
            );
            const taxes = subtotal * 0.05;
            return subtotal + taxes;
        },
    },
];

function generateProducts() {
    const quantity = randomInt(1, 5);
    return [...Array(quantity)].map((_, index) => ({
        id: index,
        name: randomCommodity(),
        quantity: randomInt(1, 5),
        unitPrice: randomPrice(1, 1000),
    }));
}

const rows = [
    {
        id: 1,
        customer: 'Matheus',
        email: randomEmail(),
        date: randomCreatedDate(),
        address: randomAddress(),
        country: randomCountry(),
        city: randomCity(),
        currency: randomCurrency(),
        products: generateProducts(),
    },
    {
        id: 2,
        customer: 'Olivier',
        email: randomEmail(),
        date: randomCreatedDate(),
        address: randomAddress(),
        country: randomCountry(),
        city: randomCity(),
        currency: randomCurrency(),
        products: generateProducts(),
    },
    {
        id: 3,
        customer: 'Flavien',
        email: randomEmail(),
        date: randomCreatedDate(),
        address: randomAddress(),
        country: randomCountry(),
        city: randomCity(),
        currency: randomCurrency(),
        products: generateProducts(),
    },
    {
        id: 4,
        customer: 'Danail',
        email: randomEmail(),
        date: randomCreatedDate(),
        address: randomAddress(),
        country: randomCountry(),
        city: randomCity(),
        currency: randomCurrency(),
        products: generateProducts(),
    },
    {
        id: 5,
        customer: 'Alexandre',
        email: randomEmail(),
        date: randomCreatedDate(),
        address: randomAddress(),
        country: randomCountry(),
        city: randomCity(),
        currency: randomCurrency(),
        products: generateProducts(),
    },
];

type Customer = (typeof rows)[number];

export default function PaymentTableOrder() {
    const getDetailPanelContent = React.useCallback<
        NonNullable<DataGridProProps['getDetailPanelContent']>
    >(({ row }) => <DetailPanelContent row={row} />, []);

    const getDetailPanelHeight = React.useCallback(() => 400, []);

    return (
        <Box sx={{ width: '100%', height: 400 }}>
            <StyledDataGrid
                columns={columns}
                rows={rows}
                rowThreshold={0}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />
        </Box>
    );
}
