import { Box, Paper, Typography, IconButton, Input, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import zIndex from '@mui/material/styles/zIndex';
import { types } from '../../../../components/Enums/ColumnsTypes';
import Papa from 'papaparse';
import { validate } from 'uuid';

import ErrorIcon from '@mui/icons-material/Error';
import { importCSVThunk } from '../../../../redux/tables/thunk';
import moment from 'moment';

function isValidDate(str: string): string | null {
    // Expresión regular para los formatos válidos
    const datePattern = /^(?:\d{2}\/\d{2}\/\d{4}|\d{2}-\d{2}-\d{4}|\d{2}-\d{2}-\d{4} 00:00:00|\d{2}\/\d{2}\/\d{4} 00:00:00)$/;

    if (datePattern.test(str)) {
        // Elimina la hora si está presente
        str = str.replace(/ 00:00:00$/, '');

        // Separa la fecha en día, mes y año
        const parts = str.split(/[\/-]/);

        // Formatea la fecha como DD-MM-YYYY
        const formattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;

        return formattedDate;
    }

    return null; // Devuelve null si la fecha no es válida
}
function stringToBoolean(str: string): boolean | undefined {
    const lowerCaseStr = str.toLowerCase();

    if (lowerCaseStr === "true" || lowerCaseStr === "1" || lowerCaseStr === "verdadero" || lowerCaseStr === "si" || lowerCaseStr === "yes") {
        return true;
    } else if (lowerCaseStr === "false" || lowerCaseStr === "0" || lowerCaseStr === "falso" || lowerCaseStr === "no" || lowerCaseStr === "no") {
        return false;
    }
    return undefined; // Valor predeterminado si no se reconoce el valor
}
interface Props {
    handleCloseImportModal: () => void
}
export const ImportTable = ({ handleCloseImportModal }: Props) => {
    const dispatch = useAppDispatch()
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [csvData, setCsvData] = useState([]);
    const [checked, setCheked] = useState(false);
    const [createLabel, setCreateLabel] = useState(false);
    const [formatedData, setFormatedData] = useState<any[][]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("selecting");

    const { tablesData, status: tableStatus } = useAppSelector(state => state.tables)

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const allowedExtensions = ['.csv']; // Agrega las extensiones que desees permitir
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

            if (allowedExtensions.includes(`.${fileExtension}`)) {
                setStatus('selected')

                setSelectedFile(file);
            } else {
                alert('Tipo de archivo no permitido. Por favor, selecciona un archivo con una extensión válida.');
            }
        }
    };
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            // Verifica las extensiones permitidas
            const allowedExtensions = ['.csv']; // Agrega las extensiones que desees permitir
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

            if (allowedExtensions.includes(`.${fileExtension}`)) {
                setStatus('selected')

                setSelectedFile(file);
            } else {
                alert('Tipo de archivo no permitido. Por favor, selecciona un archivo con una extensión válida.');
            }
        }
    };
    const handleSubmit = () => {

        if (checked) {
            const errorsTemp: string[] = []

            if (selectedFile) {
                Papa.parse(selectedFile, {
                    complete: (result) => {
                        let data: any = result.data.slice(1)

                        data = data.filter(function (subarray: any) {
                            return !subarray.every(function (element: any) {
                                return element === null;
                            });
                        });
                        let formatedData: any = []
                        data.map((e: any, zIndex: any) => {
                            e.map((value: any, i: any) => {

                                if (formatedData.length < i + 1) {
                                    formatedData.push([value])
                                } else {
                                    formatedData[i].push(value)
                                }
                            })

                        })

                        const sendData: any = []



                        if (data[0].length != tablesData.columns.length) {
                            errorsTemp.push(`Error: Las cantidades de columnas no son iguales.`);
                        }
                        if (errorsTemp.length === 0)
                            data.map((e: any, index: any) => {
                                let tempRow: any = []
                                e.map((value: any, i: any) => {
                                    const column = tablesData.columns[i]
                                    if (column.type === types.Text) {

                                        const analisis = typeof value === 'string' || typeof value === 'number'
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": ''
                                                })
                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": value
                                                })
                                            }
                                        }
                                    }
                                    else if (column.type === types.Label) {
                                        const analisis = typeof value === 'string' || typeof value === 'number';
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna.`);
                                        } else {
                                            if (value !== null && value.toString().length > 100) {
                                                errorsTemp.push(`Error: El valor de la fila ${index + 2} en la columna ${column.name} supera los 100 caracteres.`);
                                            } else {
                                                if (value === null) {
                                                    tempRow.push({
                                                        columnId: tablesData.columns[i].id,
                                                        "columnType": tablesData.columns[i].type,
                                                        "value": ''
                                                    });
                                                } else {
                                                    tempRow.push({
                                                        columnId: tablesData.columns[i].id,
                                                        "columnType": tablesData.columns[i].type,
                                                        "value": value
                                                    });
                                                }
                                            }
                                        }
                                    }
                                    else if (column.type === types.Number || column.type === types.Money || column.type === types.Decimal) {
                                        const analisis = typeof value === 'number'
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {

                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": value
                                                })
                                            }
                                        }
                                    }
                                    else if (column.type === types.Date) {

                                        const date = isValidDate(value)
                                        if (!date && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                            } else {
                                                let newDate = moment(date, "DD-MM-YYYY")
                                                if (newDate)
                                                    tempRow.push({
                                                        columnId: tablesData.columns[i].id,
                                                        "columnType": tablesData.columns[i].type,
                                                        "value": new Date(newDate.toDate())
                                                    })
                                            }
                                        }
                                    }
                                    else if (column.type === types.Bool) {
                                        var analisis
                                        if (typeof value === "string") {
                                            // console.log(value);

                                            analisis = stringToBoolean(value)
                                        }
                                        else if (typeof value === "boolean") {
                                            // console.log(value);

                                            analisis = value
                                        }
                                        else {
                                            analisis = false
                                        }
                                        if (analisis === undefined && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": analisis
                                                })
                                            }
                                        }
                                    }
                                    else if (column.type === types.File) {
                                        // console.log(formatedData[zIndex]);
                                        const analisis = value === null
                                        if (!analisis) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna. Por favor deje la columna tipo archivos vacia.`);
                                        } else {

                                        }

                                    }
                                    else if (column.type === types.Contact || column.type === types.EndUser || column.type === types.Id || column.type === types.Account) {
                                        const analisis = validate(value)
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 2} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": value
                                                })
                                            }
                                        }
                                    }

                                })
                                sendData.push(tempRow)

                            })
                        setErrors(errorsTemp)

                        setFormatedData(sendData as string[][]);
                        if (errorsTemp.length === 0) {
                            dispatch(importCSVThunk(
                                {
                                    tableId: tablesData.id,
                                    rows: sendData.map((e: any) => e.map((element: any) => {
                                        if (element.columnType != types.Date) {
                                            return ({ ...element, value: `${element.value}` })
                                        }
                                        else {
                                            return (element)
                                        }
                                    })),
                                    createTag: createLabel,
                                    token: 'a'
                                }, handleCloseImportModal))
                        }

                    },
                    header: false, // Si la primera fila contiene encabezados
                    dynamicTyping: true, // Intenta convertir los valores en tipos numéricos o booleanos
                    delimiter: ';'
                });

            }

        } else {
            const errorsTemp: string[] = []

            if (selectedFile) {
                Papa.parse(selectedFile, {
                    complete: (result) => {
                        let data: any = result.data

                        let formatedData: any = []
                        data.map((e: any, zIndex: any) => {
                            e.map((value: any, i: any) => {

                                if (formatedData.length < i + 1) {
                                    formatedData.push([value])
                                } else {
                                    formatedData[i].push(value)
                                }
                            })

                        })

                        const sendData: any = []



                        if (data[0].length != tablesData.columns.length) {
                            errorsTemp.push(`Error: Las cantidades de columnas no son iguales.`);
                        }
                        if (errorsTemp.length === 0)
                            data.map((e: any, index: any) => {
                                let tempRow: any = []
                                e.map((value: any, i: any) => {
                                    const column = tablesData.columns[i]
                                    if (column.type === types.Text) {

                                        const analisis = typeof value === 'string'
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": ''
                                                })
                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": value
                                                })
                                            }
                                        }
                                    }
                                    else if (column.type === types.Label) {
                                        const analisis = typeof value === 'string' || typeof value === 'number';
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna.`);
                                        } else {
                                            if (value !== null && value.toString().length > 100) {
                                                errorsTemp.push(`Error: El valor de la fila ${index + 1} en la columna ${column.name} supera los 100 caracteres.`);
                                            } else {
                                                if (value === null) {
                                                    tempRow.push({
                                                        columnId: tablesData.columns[i].id,
                                                        "columnType": tablesData.columns[i].type,
                                                        "value": ''
                                                    });
                                                } else {
                                                    tempRow.push({
                                                        columnId: tablesData.columns[i].id,
                                                        "columnType": tablesData.columns[i].type,
                                                        "value": value
                                                    });
                                                }
                                            }
                                        }
                                    }
                                    else if (column.type === types.Number || column.type === types.Money || column.type === types.Decimal) {
                                        const analisis = typeof value === 'number'
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {

                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": value
                                                })
                                            }
                                        }
                                    }
                                    else if (column.type === types.Date) {

                                        const date = isValidDate(value)
                                        if (!date && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                            } else {
                                                let newDate = moment(date, "DD-MM-YYYY")
                                                if (newDate)
                                                    tempRow.push({
                                                        columnId: tablesData.columns[i].id,
                                                        "columnType": tablesData.columns[i].type,
                                                        "value": new Date(newDate.toDate())
                                                    })
                                            }
                                        }
                                    }
                                    else if (column.type === types.Bool) {
                                        var analisis
                                        if (typeof value === "string") {

                                            analisis = stringToBoolean(value)
                                        }
                                        else {
                                            analisis = false
                                        }
                                        if (analisis === undefined && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": analisis
                                                })
                                            }
                                        }
                                    }
                                    else if (column.type === types.File) {
                                        // console.log(formatedData[zIndex]);
                                        const analisis = value === null
                                        if (!analisis) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna. Por favor deje la columna tipo archivos vacia.`);
                                        } else {

                                        }

                                    }
                                    else if (column.type === types.Contact || column.type === types.EndUser || column.type === types.Id || column.type === types.Account) {
                                        const analisis = validate(value)
                                        if (!analisis && value != null) {
                                            errorsTemp.push(`Error: Se encontro un tipo de dato no valido para la columna ${column.name}. El valor de la fila ${index + 1} no es valido para esta columna.`);
                                        } else {
                                            if (value === null) {
                                            } else {
                                                tempRow.push({
                                                    columnId: tablesData.columns[i].id,
                                                    "columnType": tablesData.columns[i].type,
                                                    "value": value
                                                })
                                            }
                                        }
                                    }

                                })
                                sendData.push(tempRow)

                            })
                        setErrors(errorsTemp)

                        setFormatedData(sendData as string[][]);
                        if (errorsTemp.length === 0) {
                            dispatch(importCSVThunk(
                                {
                                    tableId: tablesData.id,
                                    rows: sendData.map((e: any) => e.map((element: any) => {
                                        if (element.columnType != types.Date) {
                                            return ({ ...element, value: `${element.value}` })
                                        }
                                        else {
                                            return (element)
                                        }
                                    })),
                                    createTag: createLabel,
                                    token: 'a'
                                }, handleCloseImportModal))
                        }

                    },
                    header: false, // Si la primera fila contiene encabezados
                    dynamicTyping: true, // Intenta convertir los valores en tipos numéricos o booleanos
                });

            }

        }
    }

    useEffect(() => {

        setErrors([])
    }, [selectedFile])

    return (
        <Paper
            elevation={6}
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: {
                    xs: '95vw',
                    sm: '90vw',
                    md: '60vw',
                    lg: '40vw',
                    xl: '30vw'
                },
                pt: 2,
                px: 4,
                pb: 3,
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: 2
            }}>
            {
                !selectedFile && status === 'selecting' &&
                <Box>
                    <Typography variant='h3' fontSize={'2em'} my={2}
                        sx={{
                            'span': {
                                fontWeight: '500'
                            }
                        }}
                    >
                        Import an <span>Excel</span> or <span>CSV</span> file
                    </Typography>
                    <Box
                        border={1}
                        borderRadius={1}
                        borderColor="primary.main"
                        p={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        minHeight={200}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <>
                            <Typography variant="body1">Arrastra el archivo aquí</Typography>
                            <Typography variant="body1"
                                sx={{
                                    'label': {
                                        color: "#2196f3",
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                o
                                <label htmlFor="file-input">
                                    <IconButton component="span">
                                        <CloudUploadIcon />
                                    </IconButton>
                                    Haz click aqui para examinar
                                </label>
                                <input
                                    id="file-input"
                                    accept='.csv'
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </Typography>
                        </>
                    </Box>
                </Box>
            }
            {
                selectedFile && status === 'selected' && errors.length === 0 &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <CheckCircleOutlineIcon color='success' sx={{ height: 75, width: 75 }} />
                    <Typography variant='h3' fontSize={'1.8em'} my={2}>
                        Data found successfully!
                    </Typography>
                    <FormControlLabel
                        label="Skip the first row (in case they are headers)"
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(event) => { setCheked(event.target.checked) }}
                            />
                        }
                    />
                    <FormControlLabel
                        label="If the tag does not exist, you want to create it"
                        control={
                            <Checkbox
                                checked={createLabel}
                                onChange={(event) => { setCreateLabel(event.target.checked) }}
                            />
                        }
                    />
                    <Button variant='contained' sx={{ mb: 1 }} onClick={handleSubmit} disabled={tableStatus === 'loading'}>
                        Confirm import
                    </Button>
                    <Button variant='outlined' color='secondary' onClick={() => { setSelectedFile(null); setStatus('selecting') }}>
                        Change file
                    </Button>
                </Box>
            }
            {
                selectedFile && status === 'selected' && errors.length != 0 &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <ErrorIcon color='error' sx={{ height: 75, width: 75 }} />
                    <Typography variant='h3' fontSize={'1.8em'} my={2}>
                        Something went wrong
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            maxHeight: 400,
                            overflowY: 'scroll'
                        }}
                    >
                        {
                            errors.map((e) => {
                                return (
                                    <Typography color={'red'} textAlign={'justify'}>
                                        • {e}
                                    </Typography>
                                )
                            })
                        }
                    </Box>
                    <Button variant='contained' sx={{ mb: 1 }} onClick={() => { }} disabled>
                        Confirm import
                    </Button>
                    <Button variant='outlined' color='secondary' onClick={() => { setSelectedFile(null); setStatus('selecting') }}>
                        Change file
                    </Button>
                </Box>
            }
            {
                selectedFile && status === 'configuration' &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1,
                        width: '100%'
                    }}
                >
                    <SettingsIcon color='info' sx={{ height: 75, width: 75 }} />
                    <Typography variant='h3' fontSize={'1.8em'} my={2}>
                        Let's configure the data!
                    </Typography>
                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',

                            }}
                        >
                            <Typography fontSize={'1.2em'} fontWeight={500}>
                                File Columns
                            </Typography>
                            <Typography fontSize={'1.2em'} fontWeight={500}>

                                Tesseract Columns
                            </Typography>
                        </Box>
                        {
                            formatedData.map((e, zIndex) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',

                                        }}
                                    >

                                        <Box>
                                            <Typography fontSize={'1em'}>
                                                Column {zIndex + 1}
                                            </Typography>
                                            <Typography fontSize={'0.7em'} color={'#808080'}>
                                                {
                                                    e.map((item, zIndex) => zIndex < 2 && `${item}${zIndex != 1 ? ', ' : ''}`)
                                                }
                                            </Typography>
                                        </Box>
                                        <FormControl sx={{ width: '40%' }}>
                                            <InputLabel required>Column</InputLabel>
                                            <Select
                                                // value={newUser.role}
                                                label="Column"
                                                name='column'
                                                // onChange={handleChangeDropdown}
                                                required
                                            >
                                                {
                                                    tablesData.columns.map(e => {
                                                        return (
                                                            <MenuItem value={'admin'}>{e.name}</MenuItem>

                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )

                            })
                        }
                    </Box>


                </Box>
            }

        </Paper>
    )
}
