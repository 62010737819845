import { Box, Grid, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { Handle, Position } from "reactflow";
import { EmailBox } from "../../Boxes/EmailBox";
import AddIcon from "@mui/icons-material/Add";

export function EmailNode({ data, isConnectable }: any) {

    const onChange = useCallback((evt: any) => {
    }, []);
    return (
        <div className="text-updater-node" style={{ height: "100%" }}>
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={isConnectable}
                id="a"
            />
            <Grid item margin={0} key={data.value.autoruleTaskId}>
                {/* Render email box */}
                <EmailBox content={data.value} tableId={data.tableId}
                    deleteComp={data.allTasks.length} permissions={data.permissions} roles={data.roles}
                />
            </Grid>
            {/* {data.allTasks.length < 5 && data.allTasks.length - 1 === data.value.sequencePosition && (
                <Box
                    className={"add-btn"}
                    sx={{
                        position: "absolute",
                        color: "rgba(0, 0, 0, 0.54)",
                        left: "50%",
                        transform: "translateX(-50%)",
                        cursor: "pointer",
                    }}
                    onClick={data.onClick}
                >
                    <Tooltip title={"Agregar componente"}>
                        <AddIcon fontSize="large" />
                    </Tooltip>
                </Box>
            )} */}
            {/* <Handle
                type="source"
                position={Position.Bottom}
                id="b"
                isConnectable={isConnectable}
            /> */}

        </div>
    );
}