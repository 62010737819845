import { Box, Typography } from '@mui/material';
import React from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface Props {
    status: string,
    response: any
}
export const Confirmation = ({ status, response }: Props) => {
    if (status === 'error' && response) {
        let errorMessage = response.response.data.message
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <Typography textAlign={'center'} variant='h4' fontSize={25}>
                    Algo salio mal
                </Typography>
                <ErrorIcon color='error' sx={{ height: 100, width: 100 }} />
                <Typography textAlign={'center'} variant='body1' fontSize={20}>
                    Mensaje: {errorMessage}
                </Typography>
            </Box>
        )
    } else {
        let responseMessage = response.data.message
        
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <Typography textAlign={'center'} variant='h4' fontSize={25}>
                    Usuario creado con exito
                </Typography>
                <CheckCircleIcon color='success' sx={{ height: 100, width: 100 }} />
                {
                    responseMessage &&
                    <Typography textAlign={'center'} variant='body1' fontSize={20}>
                        Mensaje: {responseMessage}
                    </Typography>
                }
            </Box>
        )
    }
}
