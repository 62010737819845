  import React, { useState } from 'react'; // Importa useState
  import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
  import { Avatar, Button, IconButton } from '@mui/material';
  import SettingsIcon from '@mui/icons-material/Settings';
  import { EditTenantModal } from './EditTenantModal';
  import { Tenant } from '../../models/Teanant';
import { useAppDispatch } from '../../hooks/hooks';
import { loginTenantUser } from '../../redux/auth/thunk';
import { getTenant } from '../../redux/tenant/thunk';
import { getAllUsers } from '../../redux/user/thunk';
import { getPermissions } from '../../redux/permission/thunk';
import { putTenant } from '../../redux/tenantsUser/thunk';

  interface DataGridTenantProps {
    tenants: Tenant[] | null;
  }

  export const DataGridTenant: React.FC<DataGridTenantProps> = ({ tenants }) => {
    const rows = (tenants ?? []).map(tenant => ({ ...tenant, id: tenant.id.toString(), edit: '' }));
    const [openModal, setOpenModal] = useState(false); 
    const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
    const dispatch = useAppDispatch()
    
    const handleEditClick = (tenant: Tenant) => {
      setSelectedTenant(tenant);
      setOpenModal(true);
    };

    const handleTenantClick = (tenant: Tenant) => {

      window.localStorage.setItem("tenantId", tenant.id);
      window.location.href = `/${tenant.id}`;
      //dispatch(loginTenantUser(tenant.id))
      dispatch(getAllUsers(tenant.id))
      dispatch(getPermissions)   
    };

    const handleSave = (updatedTenant: Tenant) => {
      const formData = new FormData();
  
      formData.append('Id', updatedTenant.id);
      formData.append('Name', updatedTenant.name);
      formData.append('Email', updatedTenant.email);
      formData.append('Description', updatedTenant.description);
      formData.append('File', updatedTenant.profileImage);
  
      dispatch(putTenant(formData));
      setOpenModal(false);
    };

    const renderEditCell = (params: GridRenderCellParams) => {
      const tenant: Tenant = params.row as Tenant;
    
      const handleEditClick = () => {
        setSelectedTenant(tenant);
        setOpenModal(true);
      };
    
      return (
        <>
          {(selectedTenant !== null || tenant.isOwner || tenant.canManageTenant === "CanManageTenant") && (
            <IconButton aria-label="editar" onClick={handleEditClick}>
              <SettingsIcon />
            </IconButton>
          )}
        </>
      );
    };
    
    const renderNameCell = (params: GridRenderCellParams) => {
      const tenant: Tenant = params.row as Tenant;
      return (
        <Button onClick={() => handleTenantClick(tenant)} style={{ textTransform: 'none', display: 'flex', alignItems: 'center'}}>
          {tenant.profileImage ? (
            <Avatar alt={tenant.name} src={tenant.profileImage} sx={{ width: 40, height: 40, marginRight: 1}} />
          ) : (
            <Avatar alt={tenant.name} sx={{ width: 40, height: 40}}>
              {tenant.name.charAt(0).toUpperCase()}
            </Avatar>
          )}
          {tenant.name}
        </Button>
      );
    };
    
    
    const columns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 300 },
      { field: 'name', headerName: 'Nombre', width: 200, renderCell: renderNameCell },
      { field: 'description', headerName: 'Descripción', width: 300 },
      { field: 'edit', headerName: '', width: 100, renderCell: renderEditCell },
    ];

    return (
      <>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
        {
          (selectedTenant !== null) &&
          <EditTenantModal open={openModal} onClose={() => setOpenModal(false)} tenant={selectedTenant} onSave={handleSave} />
        }
      </>
    );
  };
