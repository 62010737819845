import React, { useState } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description'; // Importa los iconos que vas a usar
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTheme } from '@mui/material/styles';
import { UserTable } from '../../components/users/table/UserTable';
import { SettingTabs } from '../../components/Setting/SettingTabs';

// Componentes de ejemplo para cada opción del menú
const InvoicingComponent = () => <SettingTabs/>;
const UsersComponent = () => <UserTable/>

export const SettingsPage = () => {
    const theme = useTheme();
    const [selectedOption, setSelectedOption] = useState('Invoicing'); // Estado para la opción seleccionada

    // Ejemplo de opciones del menú
    const menuOptions = [
        { icon: <DescriptionIcon />, text: 'Billing', component: InvoicingComponent },
        { icon: <ManageAccountsIcon />, text: 'Users', component: UsersComponent },
    ];

    // Renderiza el componente seleccionado
    const renderComponent = () => {
        const activeOption = menuOptions.find(option => option.text === selectedOption);
        return activeOption ? <activeOption.component /> : null;
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh', pt: `${theme.mixins.toolbar.minHeight}px` }}>
            <Box
                sx={{
                    width: "250px",
                    bgcolor: "background.paper",
                    p: 2,
                    boxShadow: theme.shadows[5],
                    zIndex: theme.zIndex.appBar + 1, 
                    overflow: 'auto',
                    mt: -5
                }}  
            >
                <Divider />
                <List>
                    {menuOptions.map((option, index) => (
                        <ListItem 
                            button 
                            key={index}
                            selected={selectedOption === option.text}
                            onClick={() => setSelectedOption(option.text)}
                            sx={{ 
                                bgcolor: selectedOption === option.text ? 'action.selected' : '',
                                '& .MuiListItemText-primary': {
                                    color: selectedOption === option.text ? 'primary.main' : 'text.primary', // Cambia 'primary.main' y 'text.primary' según tus colores
                                },
                                '& .MuiListItemIcon-root': {
                                    color: selectedOption === option.text ? 'primary.main' : 'text.secondary', // Ajusta los colores de los iconos de la misma manera
                                }
                            }}
                        >
                            <ListItemIcon>{option.icon}</ListItemIcon>
                            <ListItemText primary={option.text} color={'#131313'} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    p: 2,
                }}
            >
                {renderComponent()}
            </Box>
        </Box>
    );
};
