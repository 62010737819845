import React from 'react'
import { Box, ThemeProvider, Typography, Grid, Button } from "@mui/material"
import { appTheme } from '../../styles/MUI/Theme'
import gif from '../../assets/TenantSettings.gif'
import { ScrollButton } from '../../components/onBoarding/ScrollButton'
import { OnBoarding } from '../../components/onBoarding/OnBoarding'
import { useAppDispatch } from '../../hooks/hooks'
import LogoutIcon from '@mui/icons-material/Logout';
import { startLogout } from '../../redux/auth/thunk'
import { cleanTenantData } from '../../redux/tenant/thunk'
import { cleanCPanelData } from '../../redux/cpanel/thunk'
import { useNavigate } from 'react-router-dom'

export const OnBoard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleLogout = () => {
    window.localStorage.removeItem("jwt")
    dispatch(startLogout());
    dispatch(cleanTenantData());
    dispatch(cleanCPanelData())
    navigate('/')
  }
  return (
    <ThemeProvider theme={appTheme}>
      <Grid container minHeight={'100vh'}>
        <Grid item xs={12} md={10}>
          <OnBoarding />
        </Grid>
        <Grid item xs={0} md={2}
          sx={{
            backgroundColor: 'rgba( 255, 164, 0 ,0.6)'
          }}
        >
          <Grid sx={{
            display: "flex",
            flexDirection: "row-reverse",
            margin: 1
          }}>
            <Button
              onClick={handleLogout}
              variant='contained'
              sx={{
                color: "grey",
                backgroundColor: "white",
                borderColor: "black",
                ":hover": {
                  backgroundColor: "white",
                  color: "#ffa400"
                }
              }}>
              Logout
              &nbsp;<LogoutIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
