import React, { useState, useEffect } from 'react'
import { GridRowId, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Box, Button, Paper } from '@mui/material';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from "@mui/x-data-grid";
import { StyledDataGrid } from '../../pages/Tables/Componentes/TableMUIX';
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Link } from 'react-router-dom';
import { Renewal, PaymentStatus, Currency } from '../Enums/CommersEnums';
import { AddSubscriptionModal } from './AddSubscriptionModal';
import { EditSubscriptionModal } from './EditSubscriptionModal';
import { editSubscriptions, getSubscriptions } from '../../redux/commerce/thunk';

const CustomToolbar = (rows: any[], handleCreateRow: () => void, permissions: any) => {

    return (
        <GridToolbarContainer
            sx={{
                mb: 4
            }}
        >
            {/* <GridToolbarColumnsButton /> */}
            <GridToolbarFilterButton />
            {permissions.canManageClients === true && (
                <Button size='small' onClick={handleCreateRow}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}
                    >
                        <TableRowsRoundedIcon fontSize='small' />
                        Add Subscription
                    </Box>
                </Button>
            )}

        </GridToolbarContainer>
    );
};


export const SubscriptionTable = () => {
    const { subscriptions } = useAppSelector(state => state.commerce)

    //open add user modal
    const [openAddUser, setOpenAddUser] = useState(false);

    //open edit user modal
    const [openEditUser, setOpenEditUser] = useState(false);
    const [editingRow, setEditingRow] = useState<any>();


    const [columns, setColumns] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);

    const dispatch = useAppDispatch();
    const { permissions, users } = useAppSelector((state) => state.users);
    const { tenantId, id, token } = useAppSelector((state) => state.auth);



    useEffect(() => {
        const columns = [
            permissions.canManageClients === true && {
                field: 'actions',
                type: 'actions',
                width: 100,
                getActions: (params: any) => [
                    <GridActionsCellItem icon={<EditIcon />} onClick={(row) => {
                        setEditingRow(params.row)
                        // console.log(clientsState.find(e => e.id === params.row.id));


                        setOpenEditUser(true)
                    }} label="Edit" />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                        // eslint-disable-next-line
                        Swal.fire({
                            title: "¿Seguro quieres borrar la fila?",
                            text: "Una vez borrada no hay vuelta atras!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Borrar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                dispatch(
                                    editSubscriptions({ ...params.row, state: PaymentStatus.Deleted }, setOpenEditUser)
                                );

                            }
                        });
                    }} />,
                ],
            },
            {
                field: "name",
                headerName: "Name",
                position: 0,
                type: "text",
                editable: false,
                renderCell: (params: any) => {
                    return <Link to={`/client/${params.row.id}`} rel="noopener">{params.row[params.field]}</Link>;
                },
            },

            {
                field: "price",
                headerName: "Price",
                position: 1,
                type: "number",
                editable: false,
                width: 200,
                renderCell: (params: any) => {
                    return <Box>$ {params.row[params.field]}</Box>;
                },
            },
            {
                field: "currency",
                headerName: "Currency",
                width: 200,
                position: 2,
                type: "number",
                editable: false,
                renderCell: (params: any) => {
                    const valorEnum = Object.keys(Currency)[params.row[params.field]];
                    return <Box>{valorEnum}</Box>;
                },
            },
            {
                field: "renewal",
                headerName: "Renewal",
                position: 3,
                type: "number",
                editable: false,
                renderCell: (params: any) => {
                    const valorEnum = Object.keys(Renewal)[params.row[params.field]];
                    return <Box>{valorEnum}</Box>;
                },
            },
            {
                field: "state",
                headerName: "State",
                position: 4,
                type: "text",
                width: 150,
                editable: false,
                renderCell: (params: any) => {
                    const valorEnum = Object.keys(PaymentStatus)[params.row[params.field]];
                    return <Box>{valorEnum}</Box>;
                },
            }
        ];
        setColumns(columns);
        let tempRows = subscriptions.map((e) => {
            return ({
                ...e,
                name: e.name,
                currency: e.currency,
                state: e.state,
                price: e.price,
                renewal: e.renewal,
                id: e.id
            });
        });

        setRows(tempRows);
    }, [subscriptions]);
    useEffect(() => {
        dispatch(getSubscriptions())
    }, [])

    return (
        <Paper
            sx={{
                p: { xs: 0, md: 3 }
            }}
        >

            <StyledDataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                slots={{
                    toolbar: () => CustomToolbar(rows, () => { setOpenAddUser(true) }, permissions)
                }}
            />
            {openAddUser === true && (
                <AddSubscriptionModal open={openAddUser} setOpen={setOpenAddUser} />
            )}
            {openEditUser === true && (
                <EditSubscriptionModal open={openEditUser} setOpen={setOpenEditUser} preData={editingRow} />
            )}
        </Paper>
    )
}
