import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { StyledDataGrid } from "../../pages/Tables/Componentes/TableMUIX";
import { getAllOrders } from "../../redux/order/thunk";
import moment from "moment";
import { jsPDF } from "jspdf";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import axios from "axios";
const url = process.env.REACT_APP_NEXUS_BACK_URL;

const generateInvoicePDF = async (invoice : any) => {

    const body ={
        "html": invoice.htmlContent,
        "email": invoice.emailTo
    }

    try {
        const response = await axios.post(`${url}/api/v1/Payment/Pdf`, body, { responseType: 'blob' });
        
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'invoice.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {

    }
    

};

export const OrderTable = () => {
    
    const tenantId = window.localStorage.getItem("tenantId");
    const [columns, setColumns] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const { orders: ordersState, status } = useAppSelector(
        (state) => state.order
    );

    useEffect(() => {
        if(tenantId){      
            dispatch(getAllOrders(tenantId));
        } 
    }, []);

    useEffect(() => {
        const columns = 
        [
            {
                headerName: "Buy Order",
                field: "buyOrderId",
                width: 200,
                filterable: false, 
                editable: false,
                type: "text",
                sortable: false,
            },
            {
                headerName: "Date",
                field: "date",
                width: 200,
                filterable: false, 
                editable: false,
                type: "date",
                sortable: false,
                valueFormatter: ({ value }: any) =>
                    value ? moment(value).format('DD/MM/YYYY') : "",
            },
            {
                headerName: "Reason",
                field: "reason",
                width: 200,
                filterable: false, 
                editable: false,
                type: "text",
                sortable: false,
            },
            {
                field: "status",
                headerName: "Status",
                width: 100,
                type: "singleSelect",
                valueOptions: [
                    { value: 0, label: "Completed" },
                    { value: 1, label: "Active" },
                    { value: 2, label: "Deleted" },
                    { value: 3, label: "Pending" },
                ],
                editable: false,
            },
            {
                field: "Invoice",
                headerName: "Invoice",
                width: 70,
                renderCell: (params : any) => {
                    const onClick = () => {
                        const invoice = params.row.invoice;
                        generateInvoicePDF(invoice);
                    };
                    return <ArrowCircleDownIcon onClick={onClick}></ArrowCircleDownIcon>;
                },
            }
        ]
        setColumns(columns);

        let tempRows = ordersState.map((e) => {
            return ({
                ...e
            });
        });

        setRows(tempRows);
    }, [ordersState])

    return(
        <StyledDataGrid
        rows={rows}
        columns={columns}
        />
    )

}