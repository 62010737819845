import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Button, Divider, FormControl, FormGroup, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material'
import { Account } from '../../../redux/account/slice'
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getAllCompanies, modifyCompany, setCompany } from '../../../redux/account/thunk';
import { MuiTelInput } from 'mui-tel-input';
import { AccountSizes } from '../../../components/Enums/AccountSizes';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getClientByCompanyId } from '../../../redux/contacts/thunk';
import { Contact } from '../../../redux/contacts/slice';
import { ContactModal } from '../../../components/Contacts/components/ContactModal';
interface Props {
    open: boolean
    setOpen: () => void
    account: Account
}


export const AccountModal = ({ open, setOpen, account }: Props) => {
    const { permissionsList } = useAppSelector((state) => state.permissions);
    const { tenantId, token, id } = useAppSelector(state => state.auth)
    const { contactsByCompany, contacts } = useAppSelector(state => state.contacts)
    const [contactsList, setContactsList] = useState<Contact[]>([])
    const [tempImage, setTempImage] = useState<string | null>(null);
    const [newAccount, setNewAccount] = useState({
        "id": account.id,
        "tenantId": account.tenantId,
        "countryId": account.countryId != null ? account.countryId : '',
        "name": account.name != null ? account.name : '',
        "businessName": account.businessName != null ? account.businessName : '',
        "email": account.email != null ? account.email : '',
        "address": account.address != null ? account.address : '',
        "phoneNumber": account.phoneNumber != null ? account.phoneNumber : '',
        "profileImage": account.profileImage != null ? account.profileImage : '',
        "website": account.website != null ? account.website : '',
        "linkedin": account.linkedin != null ? account.linkedin : '',
        "instagram": account.instagram != null ? account.instagram : '',
        "facebook": account.facebook != null ? account.facebook : '',
        "twitter": account.twitter != null ? account.twitter : '',
        "tikTok": account.tikTok != null ? account.tikTok : '',
        "youtube": account.youtube != null ? account.youtube : '',
        "twitch": account.twitch != null ? account.twitch : '',
        "threads": account.threads != null ? account.threads : '',
        "notes": account.notes != null ? account.notes : '',
        "labels": account.labels != null ? account.labels : '',
        "size": account.size != null ? account.size : 0,
    })
    const dispatch = useAppDispatch()
    const onSubmit = () => {
        // dispatch(
        //     modifyCompany({
        //         ...newAccount,
        //         token: "",
        //     })
        // );
        setOpen()
    };

    const {pageNumber, pageSize} = useAppSelector(
        (state) => state.accounts
    );

    // Selected Contact
    const [selectedContact, setSelectedContact] = useState<null | Contact>(null);
    const handleCloseContactModal = () => {
        setSelectedContact(null);
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleEditClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];

            handleSubmitPhoto(selectedFile);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewAccount({ ...newAccount, [name]: value })
    }
    const handleSubmitPhoto = async (value: string | File) => {
        const token = window.localStorage.getItem('jwt') as string;
        const data = new FormData()
        data.append('File', value);
        data.append('Id', account.id);
        data.append('Token', token);

        const url = process.env.REACT_APP_NEXUS_BACK_URL as string

        try {
            await axios.post(`${url}/api/v1/Account/Image`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            dispatch(getAllCompanies(tenantId, pageNumber, pageSize, ''))
            const objectUrl = URL.createObjectURL(value as File);
            setTempImage(objectUrl);
            return true

        } catch (error) {
            Swal.fire({
                title: "Ups! Something's wrong",
                showCancelButton: true,
                cancelButtonText: "Go back",
                // text: `Error Message: ${err.response.data.message}`,
                icon: 'error',
                showConfirmButton: false
            })
            return false

        }
    }
    useEffect(() => {
        dispatch(getClientByCompanyId(account.id))
    }, [])
    useEffect(() => {
        if (contactsByCompany) {

            var result = contacts.filter(item1 => contactsByCompany.some(item2 => item2.id === item1.id));
            setContactsList(result)
        }
    }, [contactsByCompany])


    return (
        <Modal open={open} onClose={() => { setOpen() }}>
            <Paper

                elevation={6}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "95%",
                    maxHeight: '95vh',
                    overflowY: 'scroll',
                    p: 4,
                    // alignItems: 'center',
                    // justifyContent: "center",
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        height: 'inherit'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            onClick={handleEditClick}
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                cursor: 'pointer',
                                "&:hover .container-editIcon": {
                                    opacity: 100
                                }
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                            {
                                tempImage ?
                                    <Avatar alt={`${account.name ? account.name : ''}`} src={tempImage} sx={{ width: 80, height: 80 }} />
                                    :
                                    <Avatar alt={`${account.name ? account.name : ''}`} src={`${account.profileImage ? account.profileImage : ''}`} sx={{ width: 80, height: 80 }} />

                            }

                            <Box
                                className='container-editIcon'
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    background: '#fff',
                                    borderRadius: '50%',
                                    padding: '4px',
                                    opacity: 0
                                }}
                            >
                                <EditIcon />
                            </Box>
                        </Box>
                        <Typography variant='h3' fontSize={25}>
                            {account.name}
                        </Typography>
                    </Box>
                    <Grid container sx={{ height: '100%', mt: 2 }} spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Paper elevation={6}
                                sx={{
                                    // borderRadius: '8px',
                                    // border: 'solid 1px #4b4e69',
                                    height: '100%'

                                }}
                            >

                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Paper elevation={6}
                                sx={{
                                    // borderRadius: '8px',
                                    // border: 'solid 1px #4b4e69',
                                    height: '100%'

                                }}
                            >
                                <Box sx={{
                                    width: "100%",
                                    p: 2,
                                    "input.Mui-disabled": {
                                        WebkitTextFillColor: '#000 !important'
                                    }
                                }}
                                    component="form"
                                    onSubmit={onSubmit}
                                >

                                    <Grid container spacing={2} mb={2} >
                                        <Grid item xs={12}  >
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.name}
                                                    name='name'
                                                    label="Account name"
                                                    required
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.businessName}
                                                    name='businessName'
                                                    label="Business name"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}

                                                    margin="normal"
                                                    value={newAccount.countryId}
                                                    name='countryId'
                                                    label="Country Id"
                                                    // required
                                                    error={newAccount.countryId != null && newAccount.countryId.length > 50}
                                                    helperText={newAccount.countryId != null && newAccount.countryId.length > 50 && "The maximum length allowed is 50 characters"}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}

                                                    margin="normal"
                                                    value={newAccount.address}
                                                    name='address'
                                                    label="Address"
                                                    // required
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.email}
                                                    name='email'
                                                    label="Email"
                                                    // required
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <MuiTelInput
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    name='phoneNumber'
                                                    value={newAccount.phoneNumber}
                                                    onChange={(value) => { setNewAccount({ ...newAccount, phoneNumber: value }) }}
                                                    label="Phone Number"
                                                    defaultCountry='CL'
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.website}
                                                    name='website'
                                                    label="Website"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.linkedin}
                                                    name='linkedin'
                                                    label="LinkedIn URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.instagram}
                                                    name='instagram'
                                                    label="Instagram URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.facebook}
                                                    name='facebook'
                                                    label="Facebook URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.twitter}
                                                    name='twitter'
                                                    label="Twitter URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.tikTok}
                                                    name='tikTok'
                                                    label="TikTok URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.youtube}
                                                    name='youtube'
                                                    label="Youtube URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.twitch}
                                                    name='twitch'
                                                    label="Twitch URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.threads}
                                                    name='threads'
                                                    label="Threads URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newAccount.notes}
                                                    name='notes'
                                                    label="Notes"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="account-size-label"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                >Account Size</InputLabel>
                                                <Select
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    labelId="account-size-label"
                                                    id="account-size-select"
                                                    value={newAccount.size}
                                                    label="Account Size"
                                                    onChange={(event) => {
                                                        setNewAccount({ ...newAccount, size: parseInt(event.target.value as string) });
                                                    }}
                                                >
                                                    {Object.keys(AccountSizes)
                                                        .filter((key) => !isNaN(Number(key)))
                                                        .map((key) => (
                                                            <MenuItem key={key} value={key}>
                                                                {AccountSizes[key as keyof typeof AccountSizes]}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Button variant="outlined"
                                                disabled={newAccount.countryId != null && newAccount.countryId.length > 50 || !permissionsList?.backend.roles.includes("CanManageEntities")}
                                                type='submit'
                                                sx={{
                                                    backgroundColor: "lightblue",
                                                }}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ height: '100%', mt: 2 }} spacing={3}>
                        <Grid item xs={12} md={8}>

                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Paper elevation={6}
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    maxHeight: 500,
                                    overflowY: 'scroll'
                                }}
                            >
                                <Typography variant='h6' fontSize={20}>
                                    Contact List
                                </Typography>
                                {
                                    contactsList.map((contact) => {
                                        return (
                                            <Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 1,
                                                        alignItems: 'center',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setSelectedContact(contact);
                                                    }}
                                                >
                                                    <Avatar src={contact.profileImage}></Avatar>
                                                    {contact.name}

                                                </Box>
                                                <Divider sx={{
                                                    my: 1
                                                }}></Divider>
                                            </Box>

                                        )
                                    })
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                    }}
                    onClick={() => { setOpen() }}
                >
                    <CloseIcon />
                </IconButton>
                {selectedContact != null && (
                    <ContactModal
                        open={selectedContact != null}
                        setOpen={handleCloseContactModal}
                        contact={selectedContact}
                    ></ContactModal>
                )}
            </Paper>

        </Modal >
    )
}
