import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Button, Chip, FormControl, FormGroup, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select, SelectChangeEvent, TextField, Theme, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { MuiTelInput } from 'mui-tel-input';
import { AccountSizes } from '../../../components/Enums/AccountSizes';
import { Contact } from '../../../redux/contacts/slice';
import { editContact, getAllClients } from '../../../redux/contacts/thunk';
import { getAllCompanies } from '../../../redux/account/thunk';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Swal from 'sweetalert2';
import { CutomMultiAutocomplete } from '../../custom/CutomMultiAutocomplete';
import { DateType } from '../../fields/DateType';


const url = process.env.REACT_APP_NEXUS_BACK_URL;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface Props {
    open: boolean
    setOpen: () => void
    contact: Contact
}

interface Account {
    id: string;
    name: string;
}

export const ContactModal = ({ open, setOpen, contact }: Props) => {
    const {pageSize, pageNumber} = useAppSelector((state) => state.contacts)
    const { permissionsList } = useAppSelector((state) => state.permissions);
    const { tenantId, token } = useAppSelector(state => state.auth)
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountList, setAccountList] = useState<string[]>(contact.accounts.map(account => account.id))
    const [newContact, setNewContact] = useState({
        "id": contact.id,
        "tenantId": contact.tenantId,
        "countryId": contact.countryId != null ? contact.countryId : '',
        "name": contact.name != null ? contact.name : '',
        "email": contact.email != null ? contact.email : '',
        "address": contact.address != null ? contact.address : '',
        "phoneNumber": contact.phoneNumber != null ? contact.phoneNumber : '',
        "profileImage": contact.profileImage != null ? contact.profileImage : '',
        "website": contact.website != null ? contact.website : '',
        "linkedin": contact.linkedin != null ? contact.linkedin : '',
        "instagram": contact.instagram != null ? contact.instagram : '',
        "facebook": contact.facebook != null ? contact.facebook : '',
        "twitter": contact.twitter != null ? contact.twitter : '',
        "tikTok": contact.tikTok != null ? contact.tikTok : '',
        "youtube": contact.youtube != null ? contact.youtube : '',
        "twitch": contact.twitch != null ? contact.twitch : '',
        "threads": contact.threads != null ? contact.threads : '',
        "notes": contact.notes != null ? contact.notes : '',
        "labels": contact.labels != null ? contact.labels : '',
        "workPosition": contact.workPosition != null ? contact.workPosition : '',
        'birthdate': contact.birthdate != null ? contact.birthdate : '',

    })
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const [tempImage, setTempImage] = useState<string | null>(null);
    const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                let response = await axios.get(`${url}/api/v1/PublicFeature/Accounts?TenantId=${tenantId}&Name=${inputValue}`);
                setAccounts(response.data.result);
            } catch (error) {

            }
        };

        fetchAccounts();
    }, [tenantId, inputValue]); 


    const onSubmit = () => {
        dispatch(
            editContact(
                {
                    "id": newContact.id,
                    "countryId": newContact.countryId,
                    "name": newContact.name,
                    "workPosition": newContact.workPosition,
                    "email": newContact.email,
                    "address": newContact.address,
                    "phoneNumber": newContact.phoneNumber,
                    "website": newContact.website,
                    "linkedin": newContact.linkedin,
                    "instagram": newContact.instagram,
                    "facebook": newContact.facebook,
                    "twitter": newContact.twitter,
                    "tikTok": newContact.tikTok,
                    "youtube": newContact.youtube,
                    "twitch": newContact.twitch,
                    "threads": newContact.threads,
                    "notes": newContact.notes,
                    "labels": newContact.labels,
                    accountsIds: accountList,
                    token: "",
                },
                tenantId, pageSize, pageNumber)
        );
        setOpen()
    };
    const handleListChange = (event: React.SyntheticEvent<Element, Event>, value: any[]) => {

        setSelectedAccounts(value);

        setAccountList(value.map(account => account.id));
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewContact({ ...newContact, [name]: value })
    }
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleEditClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];

            handleSubmitPhoto(selectedFile);
        }
    };
    const handleSubmitPhoto = async (value: string | File) => {
        const token = window.localStorage.getItem('jwt') as string;
        const data = new FormData()
        data.append('File', value);
        data.append('Id', contact.id);
        data.append('Token', token);

        const url = process.env.REACT_APP_NEXUS_BACK_URL as string

        try {
            await axios.post(`${url}/api/v1/Contact/Image`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            dispatch(getAllClients(tenantId, pageSize, pageNumber, ''))
            const objectUrl = URL.createObjectURL(value as File);
            setTempImage(objectUrl);
            return true

        } catch (error) {
            Swal.fire({
                title: "Ups! Something's wrong",
                showCancelButton: true,
                cancelButtonText: "Go back",
                // text: `Error Message: ${err.response.data.message}`,
                icon: 'error',
                showConfirmButton: false
            })
            return false

        }
    }

    const handleAutocompleteInputChange = (event: any, newInputValue: any, reason: any) => {
        if (reason === "input") setInputValue(newInputValue);
    };

    useEffect(() => {
        const accountsMapped = contact.accounts.map(account => ({
            id: account.id,
            name: account.name || '', // Proporciona un string vacío si 'name' es null
        }));
    
        setSelectedAccounts(accountsMapped);
        setAccountList(accountsMapped.map(account => account.id));
    }, [contact]);

    return (
        <Modal open={open} onClose={() => { setOpen() }}>
            <Paper

                elevation={6}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "95%",
                    maxHeight: '95vh',
                    overflowY: 'scroll',
                    p: 4,
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        height: 'inherit'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            onClick={handleEditClick}
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                cursor: 'pointer',
                                "&:hover .container-editIcon": {
                                    opacity: 100
                                }
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                            />
                            {
                                tempImage ?
                                    <Avatar alt={contact.name} src={tempImage} sx={{ width: 80, height: 80 }} />
                                    :
                                    <Avatar alt={contact.name} src={contact.profileImage} sx={{ width: 80, height: 80 }} />

                            }

                            <Box
                                className='container-editIcon'
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    background: '#fff',
                                    borderRadius: '50%',
                                    padding: '4px',
                                    opacity: 0
                                }}
                            >
                                <EditIcon />
                            </Box>
                        </Box>
                        <Typography variant='h3' fontSize={25}>
                            {contact.name}
                        </Typography>
                    </Box>
                    <Grid container sx={{ height: '100%', mt: 2 }} spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Paper elevation={6}
                                sx={{
                                    // borderRadius: '8px',
                                    // border: 'solid 1px #4b4e69',
                                    height: '100%'

                                }}
                            >

                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Paper elevation={6}
                                sx={{
                                    // borderRadius: '8px',
                                    // border: 'solid 1px #4b4e69',
                                    height: '100%'

                                }}
                            >
                                <Box sx={{
                                    width: "100%",
                                    p: 2
                                }}
                                    component="form"
                                    onSubmit={onSubmit}
                                >

                                    <Grid container spacing={1} sx={{ my: 2 }}>
                                        <Grid item xs={12}  >
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.name}
                                                    name='name'
                                                    label="Contact name"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    required
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.workPosition}
                                                    name='workPosition'
                                                    label="Work position"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.countryId}
                                                    name='countryId'
                                                    label="Country Id"
                                                    // required
                                                    error={newContact.countryId.length > 50}
                                                    helperText={newContact.countryId.length > 50 && "The maximum length allowed is 50 characters"}
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.address}
                                                    name='address'
                                                    label="Address"
                                                    // required
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <DateType value={newContact.birthdate} name={'BirthDate'} onChange={handleInputChange} />
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.email}
                                                    name='email'
                                                    label="Email"
                                                    // required
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <MuiTelInput
                                                    margin="normal"
                                                    name='phoneNumber'
                                                    value={newContact.phoneNumber}
                                                    onChange={(value) => { setNewContact({ ...newContact, phoneNumber: value }) }}
                                                    label="Phone Number"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    defaultCountry='CL'
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.website}
                                                    name='website'
                                                    label="Website"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.linkedin}
                                                    name='linkedin'
                                                    label="LinkedIn URL"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.instagram}
                                                    name='instagram'
                                                    label="Instagram URL"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.facebook}
                                                    name='facebook'
                                                    label="Facebook URL"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.twitter}
                                                    name='twitter'
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    label="Twitter URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    value={newContact.tikTok}
                                                    name='tikTok'
                                                    label="TikTok URL"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    value={newContact.youtube}
                                                    name='youtube'
                                                    label="Youtube URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    value={newContact.twitch}
                                                    name='twitch'
                                                    label="Twitch URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    margin="normal"
                                                    value={newContact.threads}
                                                    name='threads'
                                                    label="Threads URL"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <FormGroup>
                                                <TextField
                                                    margin="normal"
                                                    disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                    value={newContact.notes}
                                                    name='notes'
                                                    label="Notes"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                            <CutomMultiAutocomplete
                                                options={accounts}
                                                disabled={!permissionsList?.backend.roles.includes("CanManageEntities")}
                                                title='name'
                                                label="Accounts"
                                                value={selectedAccounts}
                                                inputValue={inputValue}
                                                onChange={handleListChange}
                                                onInputChange={handleAutocompleteInputChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                                            <Button variant="outlined"
                                                onClick={() => setOpen()}
                                                sx={{
                                                    backgroundColor: "lightgrey",
                                                    color: "black",
                                                    borderColor: "lightgrey",
                                                }}>
                                                Cerrar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Button variant="outlined"
                                                disabled={newContact.countryId.length > 50}
                                                type='submit'
                                                sx={{
                                                    backgroundColor: "lightblue",
                                                }}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                    }}
                    onClick={() => { setOpen() }}
                >
                    <CloseIcon />
                </IconButton>
            </Paper>

        </Modal >
    )
}
