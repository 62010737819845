import { Box, Chip, useTheme, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getAllCompanies } from '../../../redux/account/thunk'
import { CutomAutocomplete } from '../../../components/custom/CutomAutocomplete';
import axios from 'axios';


export const LabelEditCell = React.forwardRef<HTMLDivElement, {value: any; api: any; field: string, id: string, row: any, hasFocus: boolean, columnId: string}>(
    function LabelEditCell(props, ref) {
        const { value, api, field, id, row, hasFocus, columnId } = props;
        const [label, setLabel] = useState<any | null>(value);
        const [filteredLabels, setFilteredLabels] = useState<any[]>([]);
        const [searchTerm, setSearchTerm] = useState<string>("");

        useEffect(() => {
        const fetchContacts = async () => {
          try {
            let url = `${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/PublicFeature/Labels?Id=${columnId}&Tag=${searchTerm}`;
            const response = await axios.get(url);
            setFilteredLabels(response.data.result.labels)
          } catch (error) {
            console.error("Error fetching contacts:", error);
          }
        };
  
        fetchContacts();
      }, [searchTerm, columnId]);

        const handleChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
            setLabel(value)
            if (value)
                setTimeout(() => {
                    api.setEditCellValue({ id, field, value: value });
                }, 0);
            else
                setTimeout(() => {
                    api.setEditCellValue({ id, field, value: "" });
                }, 0);
            // api.setEditCellValue({ id, field, value: value });

        };

        const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
            setSearchTerm(newInputValue);
        };

        useEffect(() => {
            if (!hasFocus) {
                if (value) {
                    api.setEditCellValue({
                        id,
                        field,
                        value: value,
                    });
                } else
                    api.setEditCellValue({
                        id,
                        field,
                        value: [],
                    });
                api.stopCellEditMode({ field: field, id: row.id })
            }

        }, [hasFocus])


        return (
            <CutomAutocomplete
                options={filteredLabels}
                title='tag'
                label="Label"
                onChange={handleChange}
                value={label}
                inputValue={searchTerm}
                onInputChange={handleInputChange}
            />
        );
    },
);