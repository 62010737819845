import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/Tables/Componentes/TableMUIX"
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import moment from "moment";
import { GridItem } from "../custom/Grid/GridItem";
import { deleteSeat, getAllSeats } from "../../redux/seat/thunk";
import AddTaskIcon from '@mui/icons-material/AddTask';
import axios from "axios";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
const url = process.env.REACT_APP_NEXUS_BACK_URL;


const CustomToolbar = (

) => {
    const { token } = useAppSelector((state) => state.auth);
    const tenantId  = window.localStorage.getItem("tenantId");
    const [seats, setSeats] = useState({
        used: 0,
        available: 0,
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/Tenant/seat/${tenantId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setSeats({
                    used: response.data.result.seatsOcuped,
                    available: response.data.result.seatsAvailable,
                });
            })
            .catch((error) => {});
    }, []);

    const handleOpenAddModal = async () => {
        Swal.fire({
            title: "Are you sure you want to pay seat?",
            text: "Charges will be made according to the details of your subscription.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const body = {
                        token: token
                    }
                    let response = await axios.post(`${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/Seat/Pay`, body);
                    dispatch(getAllSeats(tenantId!));

                    if(response.status === 200){
                        Swal.fire({
                            text: "Seat paid successfully",
                            icon: "success",
                        })
                    }
                    
                } catch (error) {
                    Swal.fire({
                        text: "Error proceeding with payment. Please try again. If the error persists, please contact us.",
                        icon: "error",
                    })            
                }
            }
        });
    };

    const handleOpenInviteModal = () => {
        setOpenInviteModal(true);
    };

    const handleCloseInviteModal = () => {
        setOpenInviteModal(false);
    };

    const handleInvite = async (email: string) => {
        const body = {
            tenantId: tenantId,
            email: email,
            token: ""
        }

        try {

            let response = await axios.post(`${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/User/UserEmail`, body);

            dispatch(getAllSeats(tenantId!));
            
        } catch (error) {
            
        }
        
    }

    return (
        <>
            <GridToolbarContainer
                sx={{
                    mb: 4,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: '3px',
                            fontWeight: 'bold',
                            boxShadow: 1,
                        }}
                    >
                        Seats available: {seats.available}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            padding: '6px 16px',
                            marginRight: '8px',
                            borderRadius: '2px',
                            fontWeight: 'bold',
                            boxShadow: 1,
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                        }}
                        onClick={handleOpenAddModal}
                    >
                        Add seat
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: '2px',
                            fontWeight: 'bold',
                            boxShadow: 1,
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                        }}
                        onClick={handleOpenInviteModal}
                    >
                        Invite
                    </Button>
                </Box>
            </GridToolbarContainer>

            <Dialog
                open={openInviteModal}
                onClose={handleCloseInviteModal}
                PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    const email = formJson.email;
                    handleInvite(email);
                    handleCloseInviteModal();
                },
                }}
            >
            <DialogTitle>Invite User</DialogTitle>
            <DialogContent>
            <DialogContentText>
            Enter the email of the user you want to invite to your Nexus Backend
            </DialogContentText>
            <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseInviteModal}>Cancel</Button>
            <Button type="submit">Invite</Button>
            </DialogActions>
        </Dialog>

        </>
    );
};

export const SeatTable = () => {

    const tenantId = window.localStorage.getItem("tenantId");
    const [columns, setColumns] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    const { seats: seatsState, status } = useAppSelector(
        (state) => state.seat
    );

    
    const ActivateButton = (props: any) => {
        const { onClick } = props;
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={onClick}
            >
                Activate
            </Button>
        );
    };

    const getGridActions = (params: any) => {
        if (params.row.status === 4) {
            return [
                <GridActionsCellItem
                    icon={<ActivateButton onClick={() => {
                        Swal.fire({
                            title: "Are you sure you want to activate the seat?",
                            text: "If you have a seat available it will be assigned otherwise you will be charged",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Active",
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                const body = {
                                    "id": params.row.id,
                                    "tenantId": tenantId,
                                };
                                try {
                                    let response = await axios.post(`${url}/api/v1/Seat/Activate`, body);
                                    if(response.status === 200){
                                        Swal.fire({
                                            title:"The seat has been activated successfully."
                                        });
                                    }
                                } catch (error) {
                                    Swal.fire({
                                        title:"The seat has not been activated successfully.",
                                        text: "If the problem persists, contact us",
                                        icon: "error"
                                    });
                                }
                            }
                        });
                    }} />}
                    label="Activate"
                />
            ];
        }
        return [];
    };

    useEffect(() => {
        if(tenantId){      
            dispatch(getAllSeats(tenantId));
        } 
    }, []);

    useEffect(() => {
        const columns = 
        [
            {
                field: 'actions',
                type: 'actions',
                width: 50,
                getActions: (params: any) => [
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                        // eslint-disable-next-line
                        Swal.fire({
                            title: "Are you sure you want to cancel the seat?",
                            text: "Once deleted, the charge for this chair will no longer be made, nor will the associated user be able to enter Nexus.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Borrar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if(tenantId){
                                dispatch(
                                    deleteSeat(params.row.id, tenantId)
                                );
                                }
                            }
                        });
                    }} />,
                ],
            },
            {
                headerName: "Current User",
                field: "name",
                width: 200,
                filterable: false, 
                editable: false,
                type: "text",
                sortable: false,
            },
            {
                headerName: "Activation Date",
                field: "activationDate",
                width: 200,
                filterable: false, 
                editable: false,
                type: "date",
                sortable: false,
                valueFormatter: ({ value }: any) =>
                    value ? moment(value).format('DD/MM/YYYY') : "",
            },
            {
                field: "status",
                headerName: "Status",
                width: 100,
                type: "singleSelect",
                valueOptions: [
                    { value: 0, label: "Default" },
                    { value: 1, label: "Active" },
                    { value: 2, label: "Pending" },
                    { value: 3, label: "Deleted" },
                    { value: 4, label: "Inactive" },
                    { value: 5, label: "Await" },
                ],
                editable: false,
            },
            {
                field: 'activations',
                type: 'actions',
                width: 150,
                getActions: getGridActions,
            }
        ]
        setColumns(columns);

        let tempRows = seatsState.map((e) => {
            return ({
                ...e
            });
        });

        setRows(tempRows);
    }, [seatsState])
    
   return (
    <StyledDataGrid
            rows={rows}
            columns={columns}
            slots={{
                toolbar: () =>
                    CustomToolbar(
                    ),
            }}
    />
   )
}