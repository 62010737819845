import { Box, Chip, useTheme, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme, AutocompleteInputChangeReason } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getAllCompanies } from '../../../redux/account/thunk'
import { CutomAutocomplete } from '../../../components/custom/CutomAutocomplete';
import { CutomMultiAutocomplete } from '../../../components/custom/CutomMultiAutocomplete';
import axios from 'axios';

  interface Contact {
    id: string;
    name: string;
  }
  
  interface AccountEditCellProps {
    value: Contact[];
    api: {
      setEditCellValue: (params: { id: string; field: string; value: Contact[] }) => void;
      stopCellEditMode: (params: { field: string; id: string }) => void;
    };
    field: string;
    id: string;
    row: any;
    hasFocus: boolean;
  }

  export const ContactEditCell = React.forwardRef<HTMLDivElement, AccountEditCellProps>(
    function ContactEditCell(props, ref) {
      const { value, api, field, id, row, hasFocus } = props;
      const tenantId = window.localStorage.getItem("tenantId");
      const [searchTerm, setSearchTerm] = useState<string>("");
      const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
      const [inputValue, setInputValue] = useState("");
      const [selectedValue, setSelectedValue] = useState<Contact[]>(value || []);

      useEffect(() => {
        const fetchContacts = async () => {
          try {
            let url = `${process.env.REACT_APP_NEXUS_BACK_URL}/api/v1/PublicFeature/Contacts?TenantId=${tenantId}&Name=${searchTerm}`;
            const response = await axios.get(url);
            setFilteredContacts(response.data.result.map((contact: { id: string; name: string; }) => ({
              id: contact.id,
              name: contact.name
            })));
          } catch (error) {
            console.error("Error fetching contacts:", error);
          }
        };
  
        fetchContacts();
      }, [searchTerm, tenantId]);
  
      const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: string) => {
        if (reason === 'input') {
          setSearchTerm(value);
          setInputValue(value);
        }
      };
  
      const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: Contact[], reason: string) => {

          setSelectedValue(newValue)
          const updatedValue = newValue.map(contact => ({
            id: contact.id,
            name: contact.name,
          }));

          api.setEditCellValue({ id, field, value: updatedValue });  
      };
  
      useEffect(() => {
        if (!hasFocus) {
          api.stopCellEditMode({ field: field, id: row.id });
        }
      }, [api, field, hasFocus, id, row.id]);
  
      return (
        <CutomMultiAutocomplete
          options={filteredContacts}
          title="name"
          label="Contacts"
          onInputChange={handleInputChange}
          onChange={handleChange}
          value={selectedValue}
          inputValue={inputValue}
        />
      );
    },
  );