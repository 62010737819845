import React, { useEffect, useState } from 'react'
import { Navigate, Route, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { Alert, Box, Button, Checkbox, IconButton, Menu, MenuItem, Paper, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import { LoadingCube } from '../../components/custom/loader/LoadingCube'
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { deleteQuestionField, deleteReport, getReportbyId, positionQuestionField, postTableRowFieldReport, postTableRowFileFieldReport, updateQuestionField, updateReport } from '../../redux/reports/thunk'
import QuestionList from '../../components/Reports/QuestionFieldList'
import QuestionFieldList from '../../components/Reports/QuestionFieldList'
import { AddWidgetButton } from '../../components/Dashboard/AddWidgetButton'
import { AddQuestionFieldButton } from '../../components/Reports/AddQuestionField'
import { langaugeTypes } from '../../components/Enums/LanguageEnums'
import { types } from '../../components/Enums/ColumnsTypes'
import Swal from 'sweetalert2'
import { PermissionsSidePanel } from '../Roles/SidePanel/PermissionsSidePanel'
import { QuestionField } from '../../redux/reports/slice'
import axios from 'axios'


const url = process.env.REACT_APP_NEXUS_BACK_URL as string

interface Answers {
    [key: string]: any;
}


export const ReportPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { currentReport, status } = useAppSelector(state => state.reports)
    const { token, tenantId } = useAppSelector(state => state.auth)
    const [reportName, setReportName] = useState(currentReport?.report.name)
    const [reportDescription, setReportDescription] = useState(currentReport?.report.description)
    const [reportLanguage, setReportLanguage] = useState<string | number>(currentReport?.report.language || 0);
    const [reportIsPublic, setReportIsPublic] = useState(currentReport?.report.isPublic)
    const [reportTenantId, setReportTenantId] = useState(currentReport?.report.tenantId)
    const [answers, setAnswers] = useState<Answers>({});
    const [editingQuestionId, setEditingQuestionId] = useState<string | null>(null);
    const { permissionsList } = useAppSelector(state => state.permissions)
    const [roles, setRoles] = useState<string[]>([])
    const [permissions, setPermissions] = useState<string[]>([])
    const [isPermissionsPanelOpen, setIsPermissionsPanelOpen] = useState(false);
    const [tableId, setTableId] = useState(currentReport?.report.tableId)
    const [openEdit, setOpenEdit] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [resetFields, setResetFields] = useState(false);
    const [displayedQuestionFields, setDisplayedQuestionFields] = useState<QuestionField[]>([]);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleOpenPermissionsPanel = () => {
        setIsPermissionsPanelOpen(true);
        handleClose()
    };
    const handleClosePermission = () => { setIsPermissionsPanelOpen(!isPermissionsPanelOpen) }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDeleteReport = () => {
        Swal.fire({
            title: "Are you sure you want to delete this report",
            text: "Once deleted it is not possible to recover the information",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {        
                dispatch(deleteReport(id as string, tenantId, navigate))
            }
        });
    }

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }

        setSnackbarOpen(false);
      };

    const handleEditReport = () => {
        dispatch(updateReport(
            {
                "id": id,   
                "name": reportName, 
                "description": reportDescription,
                "isPublic": reportIsPublic,
                "language": reportLanguage,
                "token": ""
            },
            id as string,
            tenantId,
            setOpenEdit
        ))
        setEditingQuestionId(null);
    }
    const handleCancelEditReport = () => {
        setOpenEdit(false); 
        if (editingQuestionId) {
          setEditingQuestionId(null);
        }
        setAnswers({});
        setResetFields(true)
      };

    const onValueChange = (id: string, newValue: any) => {
        const valueString = typeof newValue === 'boolean' ? newValue.toString() : newValue;

        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [id]: valueString
        }));
    };

    const postFileWithAxios = async (file : any) => {
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await axios.post(url
                + '/api/v1/DynamicRow/Tempfile',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            // Retornar el mensaje del response data
            return response.data.message;
        } catch (error) {
            throw error;
        }
    };

    const prepareDataForSubmission = async () => {
        let fields = [];

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        for (const [dynamicColumnId, value] of Object.entries(answers)) {
            const question = currentReport?.questionFields.find(q => q.id === dynamicColumnId);
            if (question?.dataType === types.File && value) {
                const response = await postFileWithAxios(value)
                    fields.push({
                        columnId: question?.dynamicColumnId,
                        columnType: question?.dataType,
                        value: response,
                    });
            }
            else if (question?.dataType === types.Contact && value){
                fields.push({
                    columnId: question?.dynamicColumnId,
                    columnType: question?.dataType,
                    contacts: value.map((e : any) => e.id),
                    value: "a",
                }); 
            } 
            else if (question?.dataType === types.Bool){
                fields.push({
                    columnId: question?.dynamicColumnId,
                    columnType: question?.dataType,
                    value: value.toString(),
                }); 
            } 
            else {
                fields.push({
                    columnId: question?.dynamicColumnId,
                    columnType: question?.dataType,
                    value: value,
                });
            }
        }
    
        return {
            fields: fields,
            tableId: tableId,
            token: 'a',
            timeZone: timeZone,
            id: id
        };
    };
    
    

    const handleSendReport = async () => {
        try {
            const reportData = await prepareDataForSubmission();
            dispatch(postTableRowFieldReport(reportData));
            handleOpenSnackbar();
            setAnswers({});
            setResetFields(true);
        } catch (error) {
            // Manejo de errores
        }
    };
    
    

    const handleEditQuestion = (questionFieldId : string) => {
        setEditingQuestionId(questionFieldId);
    };
    const handleCancelQuestion = () => {
        setEditingQuestionId(null);
        setAnswers([])
        setResetFields(true)
    };

    const handleSaveEdit = (questionFieldId: string, updatedData: any) => {
        dispatch(updateQuestionField(updatedData, id!));
        setAnswers([])
        setResetFields(true)
        setEditingQuestionId(null);        
    };

    const handleDeleteQuestion = (questionFieldId : string) => {
        Swal.fire({
            title: "Are you sure you want to delete this question",
            text: "Once deleted it is not possible to recover the information",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                setAnswers(prevAnswers => {
                    const newAnswers = { ...prevAnswers };
                    delete newAnswers[questionFieldId];
                    return newAnswers;
                });
        
                dispatch(deleteQuestionField(questionFieldId, id!))
            }
        });
    };

    const copyToClipboard = () => {
        const currentUrl = window.location.href;
        const parts = currentUrl.split('/');
        const filteredParts = parts.filter(part => !part.match(/[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}/));
        let baseUrl = filteredParts.slice(0, 3).join('/'); // Tomar las primeras 3 partes
        if (baseUrl.endsWith('main')) {
            baseUrl = filteredParts.slice(0, 2).join('/'); // Si la tercera parte es "main", solo tomar las primeras 2 partes
        }
        if (id) {
            const publicUrl = `${baseUrl}/main/report/public/${id}`; // Agregar "main" y construir la URL pública
            const tempInput = document.createElement('input');
            tempInput.value = publicUrl;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        }
    };
    


    const sortedQuestionFields = currentReport?.questionFields
    .slice() 
    .sort((a, b) => a.position - b.position); 

    useEffect(() => {
        const sortedFields = currentReport?.questionFields
            .slice()
            .sort((a, b) => a.position - b.position);
    
        const filteredFields = openEdit 
            ? sortedFields 
            : sortedFields?.filter(q => q.isMandatory || q.isVisible);
    
        setDisplayedQuestionFields(filteredFields ?? []);
    }, [currentReport, openEdit]);
    


    const moveQuestion = (questionId: string, direction: string) => {
        // Asegurarse de que sortedQuestionFields no es undefined
        if (!sortedQuestionFields) return;
      
        const index = sortedQuestionFields.findIndex(q => q.id === questionId);
        if (index === -1) return; // Si no se encuentra la pregunta, no hacer nada
      
        // Calcular el nuevo índice
        let newIndex = direction === 'up' ? index - 1 : index + 1;
      
        // Verificar límites
        if (newIndex < 0 || newIndex >= sortedQuestionFields.length) return;
      
        // Obtener los IDs para las posiciones actual y objetivo
        const currentPositionId = sortedQuestionFields[index].id;
        const targetPositionId = sortedQuestionFields[newIndex].id;
      
        // Crear el objeto de datos para el dispatch
        const data = {
          currentPositionId: currentPositionId,
          targetPositionId: targetPositionId
        };
      
        // Llamar al dispatch para actualizar las posiciones
        dispatch(positionQuestionField(data, id!)); 
      };

      const areAllMandatoryQuestionsAnswered = () => {
        return currentReport?.questionFields.every((question) => {
            if (!question.isMandatory) {
                return true;
            }
    
            const answer = answers[question.id];
            return typeof answer === 'string' ? answer.trim() !== '' : answer != null;
        });
    };
    
    useEffect(() => {
        if (resetFields) {
            setResetFields(false);
        }
    }, [resetFields]);

    useEffect(() => {
        if (token && permissionsList && id) {
            let permissionsData = permissionsList?.backend.reports.find((e) => e.id === id)
    
            if (permissionsData) {
                setPermissions(permissionsData.permissions)
            }
            if (permissionsList.backend.roles) {
                setRoles(permissionsList.backend.roles)
            }
        } else {

            setPermissions([]);
            setRoles([]);
        }
    }, [token, id, permissionsList])
    
    const reportPermissions = permissionsList?.backend.reports.find(report => report.id === id)?.permissions || [];
    const reportRole = permissionsList?.backend.roles.find(report => report === "CanManageReports") || '';
    const rolePermission = permissionsList?.backend.roles.find(report => report === "CanManageRoles") || '';

    useEffect(() => {
        if (id)
            dispatch(getReportbyId(id))
    }, [id])
    useEffect(() => {
        if (currentReport?.report) {
          setReportName(currentReport.report.name);
          setTableId(currentReport.report.tableId);
          setReportDescription(currentReport.report.description);
          setReportLanguage(currentReport.report.language);
          setReportIsPublic(currentReport.report.isPublic);
          if (currentReport.report.tenantId) {
            setReportTenantId(currentReport.report.tenantId);
        } 
        }
      }, [currentReport]);

    useEffect(() => {
        if (currentReport && !currentReport.report.isPublic && !token) {
            setShouldRedirect(true);
        }
    }, [currentReport, token]);


    if (status === "loading" && currentReport === null) {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoadingCube />
            </Box>
        );
    }else if(shouldRedirect){
        return <Navigate to="/login" />;
    } 
    else {
        return (
            <Box
                p={2}
                color={"#000"}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    mt: 3
                }}
            >
                <Paper elevation={4} sx={{ p: 2 }}>
                        {
                                id &&
                                <Box sx={{ position: 'sticky', top: 0, right: 0, zIndex: 1300 }}>
                                <PermissionsSidePanel
                                    dataName='question'
                                    objectName='Report'
                                    objectId={id}
                                    objectType={1}
                                    open={isPermissionsPanelOpen}
                                    handleClose={handleClosePermission}
                                ></PermissionsSidePanel>
                                </Box>
                        }
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1
                        }}
                    >

                        {
                            openEdit ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start', width: '100%'}}>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Typography>Name:</Typography>
                                        <TextField
                                            value={reportName}
                                            variant='standard'
                                            size='small'
                                            onChange={(event) => { setReportName(event.target.value) }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Typography>Description:</Typography>
                                        <TextField
                                            value={reportDescription}
                                            variant='standard'
                                            size='small'
                                            onChange={(event) => { setReportDescription(event.target.value) }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Typography>Public:</Typography>
                                        <Checkbox 
                                            checked={reportIsPublic}
                                            onChange={(event) => { setReportIsPublic(event.target.checked) }}
                                        /> 
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <Typography>Language:</Typography>
                                        <Select 
                                            value={reportLanguage.toString()} // Convertir el número a cadena para el valor del Select
                                            label="Language"
                                            name='language'
                                            onChange={(event) => {
                                                // Convertir el valor seleccionado de cadena a número antes de actualizar el estado
                                                const value = event.target.value;
                                                setReportLanguage(Number(value)); // Asegúrate de convertir el valor a número
                                            }}
                                            required
                                        >
                                            {
                                                Object.entries(langaugeTypes).map(([key, value]) => (
                                                    <MenuItem value={value.toString()} key={value}>{key}</MenuItem> // Convertir el valor a cadena aquí
                                                ))
                                            }
                                        </Select>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0 }}>
                                        <Tooltip title={'Save'} >
                                            <IconButton onClick={handleEditReport} disabled={status === 'loading'}>
                                                <SaveIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Cancel'} >
                                            <IconButton onClick={handleCancelEditReport}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                :
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                                    <Typography fontSize={30} fontWeight={500}>
                                        {currentReport?.report.name}
                                    </Typography>
                                    <Typography fontSize={16} gutterBottom>
                                        <strong>Description:</strong> {currentReport?.report.description}
                                    </Typography>
                                    {  token && (tenantId === reportTenantId) && (

                                    <Typography fontSize={16} gutterBottom>
                                    <strong>Status:</strong> {currentReport?.report.isPublic ? 'Public' : 'Private'}
                                    </Typography>
                                    )}
                                    <Typography fontSize={16} gutterBottom>
                                        <strong>Language:</strong> {currentReport?.report.language === 'ES' ? 'Spanish' : 'English'}
                                    </Typography>
                                </Box>
                        }
                        {
                            (token && (tenantId === reportTenantId) && !openEdit) &&
                            <Box>

                                {
                                    (reportPermissions.includes("CanUpdate") || reportRole) && (reportPermissions.includes("CanDelete") || reportRole) && rolePermission &&
                                    <IconButton
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                }
                                { 
                                    reportIsPublic &&
                                    <Tooltip title='Copy public url'>
                                    <IconButton
                                        onClick={copyToClipboard}
                                    >
                                        <ScreenShareIcon />
                                    </IconButton>
                                    </Tooltip>
                                } 
                            </Box>

                        }

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                        {
                            token && (reportPermissions.includes("CanUpdate") || reportRole) && (tenantId === reportTenantId) &&
                            <MenuItem onClick={() => { setOpenEdit(true); handleClose() }}>Edit</MenuItem>
                        }
                        {
                            token && (reportPermissions.includes("CanDelete") || reportRole) && (tenantId === reportTenantId) &&
                            <MenuItem onClick={handleDeleteReport}>Delete</MenuItem>
                        }
                        {
                            token && rolePermission && (tenantId === reportTenantId) &&
                            <MenuItem onClick={handleOpenPermissionsPanel}>Permissions</MenuItem>
                        }
                        </Menu>
                    </Box>
                </Paper>
                {
                    reportTenantId && displayedQuestionFields  && (
                        <QuestionFieldList
                            questions={displayedQuestionFields }
                            answers={answers}
                            onValueChange ={(id, value) => {
                                setAnswers(prev => ({ ...prev, [id]: value }));
                              }}
                            onEditQuestion={handleEditQuestion}
                            onDeleteQuestion={handleDeleteQuestion}
                            onEditSave={handleSaveEdit}
                            cancelEdit={() => handleCancelQuestion()}
                            editingQuestionId={editingQuestionId}
                            isReportEditing={openEdit}
                            tenantId={reportTenantId!}
                            moveQuestion={moveQuestion}
                            roleReport = {reportRole}
                            canEditQuestion={reportPermissions.includes("CanUpdateData") ?? ''}
                            canDeleteQuestion={reportPermissions.includes("CanDeleteData"?? '')}
                            reset={resetFields ?? ''}
                            lenghtPosition={displayedQuestionFields.length}
                        />
                    )
                }
                {
                    token && (reportPermissions.includes("CanCreate") || reportRole) && openEdit && (tenantId === reportTenantId) &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {id && tableId && (
                            <AddQuestionFieldButton reportId={id} tableId={tableId}/>
                        )}
                    </Box>
                }
                {
                    !openEdit && (displayedQuestionFields.length > 0) &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                    <Button
                        variant="contained"
                        onClick={handleSendReport}
                        disabled={!areAllMandatoryQuestionsAnswered()} // Disable button based on the validation function
                    >
                        Send Report
                    </Button>
                    </Box>
                }
                {
                    !token &&
                    <Box
                        sx={{
                        }}
                    >
                        <Typography variant="h2" component="h3" sx={{ color: "#6a7187", fontSize: "0.875rem", textAlign: "center" }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com" target='_blank'>Tesseract</a> ® Nexus Backend</Typography>
                    </Box>
                }
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleCloseSnackbar} severity="success" elevation={6} variant="filled">
                    Report sent successfully
                    </Alert>
                </Snackbar>
            </Box>
            
        )
    }

}