import React, { forwardRef, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box } from '@mui/material';
import { de, enGB, zhCN } from 'date-fns/locale';
import moment from 'moment';

export const DateTimeEditCell = forwardRef<HTMLDivElement, {
  value: any;
  onUpdate: (newValue: any) => void;
  onEditComplete: (id: string, field: string) => void;
  id: string; 
  field: string;
}>(
  function DateTimeEditCell(props, ref) {
    const { value, onUpdate, onEditComplete, id, field } = props;
    const [selectedDateTime, setSelectedDateTime] = useState(value);

    const handleDateTimeChange = (newValue: any) => {
      setSelectedDateTime(newValue); 
    };

    const handleAccept = () => {
      const localDateTime = moment(selectedDateTime).format('YYYY-MM-DDTHH:mm:ss');
      onUpdate(localDateTime)
      onEditComplete(id, field); 
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <DateTimePicker
            views={['day','month', 'year', 'hours', 'minutes', 'seconds']}
            value={value}
            onChange={handleDateTimeChange}
            onAccept={handleAccept}
            ampm={false}
            timeSteps={{ minutes: 1, seconds: 1 }} 
          />
        </LocalizationProvider>
    );
  }
);

