import React, { useEffect, useRef, useState } from "react";
import { GridItem } from "../../components/custom/Grid/GridItem";
import { RoleComponent } from './Componentes/RoleComponent'
import { role } from './interfaces/RoleInterface'
import { Alert, Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import { RolesList } from "./Componentes/RolesList";
import CloseIcon from '@mui/icons-material/Close';
import { Role } from "../../redux/roles/slice";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { deleteRole, getAllRoles, getUserPerRole, modifyRole, postRoles } from "../../redux/roles/thunk";
import Swal from "sweetalert2";
import { UserComponent } from "./Componentes/UserComponent";
import { UsersList } from "./Componentes/UsersList";




export const Roles = () => {
  const dispatch = useAppDispatch()


  const { permissionsList } = useAppSelector(state => state.permissions)

  const { tenantId } = useAppSelector(state => state.auth)
  const { roles, status, rolesUsers } = useAppSelector(state => state.role)

  const contentRef = useRef<HTMLDivElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [sizeValue, setSizeValue] = useState<number>(0)
  const [selectedRole, setSelectedRole] = useState<Role>({
    id: "",
    tenantId: "",
    name: "",
    nameLocked: "",
    hexColor: "",
    canManageRoles: false,
    canManageTables: false,
    canManageBlobRecords: false,
    canManageTenant: false,
    canManageUsers: false,
    canManageSuscriptions: false,
    canManageEntities: false,
    canManageReports: false,
    canManageAutorules: false,
    canManageSettings: false,
    canManageLabels: false,
    createdDate: "",
    updatedDate: "",
    lastModifier: ""
  })
  const [selectedRoleEdited, setSelectedRoleEdited] = useState<Role>({
    id: "",
    tenantId: "",
    name: "",
    nameLocked: "",
    hexColor: "",
    canManageRoles: false,
    canManageTables: false,
    canManageBlobRecords: false,
    canManageTenant: false,
    canManageUsers: false,
    canManageSuscriptions: false,
    canManageEntities: false,
    canManageLabels: false,
    canManageReports: true,
    canManageAutorules: true,
    canManageSettings: false,
    createdDate: "",
    updatedDate: "",
    lastModifier: ""
  })

  const handleChangeSelectedRole = (role: Role) => {
    setSelectedRole(role)
    setSelectedRoleEdited(role)
    return
  }
  const handleChangeName = (name: string) => {
    setSelectedRoleEdited({ ...selectedRoleEdited, name: name })
    return
  }
  const handleChangeColor = (color: string) => {
    setSelectedRoleEdited({ ...selectedRoleEdited, hexColor: color })
    return
  }
  const handleCancelChanges = () => {
    setSelectedRoleEdited(selectedRole)
    return
  }
  const handleSaveChanges = () => {
    dispatch(modifyRole({ ...selectedRoleEdited, roleId: selectedRoleEdited.id }, tenantId))
    setSelectedRole(selectedRoleEdited)
    return
  }
  const handleCreateRole = () => {
    dispatch(postRoles({ name: "New role", hexColor: "#ffa400", tenantId }))
    return
  }
  const handleDeleteRole = () => {
    Swal.fire({
      title: "Are you sure you want to delete this role?",
      text: "Once deleted it is not possible to recover the information",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(deleteRole({ ...selectedRoleEdited, roleId: selectedRoleEdited.id, tenantId }))
      }
    });
  }
  const handleChangePermissions = (permission: string, value: boolean) => {
    setSelectedRoleEdited({ ...selectedRoleEdited, [permission]: value })
    return
  }

  const handleResize = () => {

    const element = elementRef.current;
    if (element) {
      const width = element.getBoundingClientRect().width;
      setSizeValue((width / 12))
    }
  };
  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, []);

  useEffect(() => {
    dispatch(getAllRoles(tenantId))
  }, [])
  useEffect(() => {
    if (roles.length > 0 && !roles.find(e => e.id === selectedRole.id)) {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setSelectedRole(roles[0])
      setSelectedRoleEdited(roles[0])
    }
  }, [roles])
  useEffect(() => {

    if (selectedRole.id != "") {
      dispatch(getUserPerRole(selectedRole.id))
      if (contentRef.current) {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }, [selectedRole])

  return (
    <Box p={2} >
      {
        permissionsList?.backend.roles.includes("CanManageRoles")
        &&
        <Box
          sx={{
            display: 'flex',
            flexWrap: "wrap",
            // overflowY: 'scroll',
            justifyContent: 'center',
            ".custom-grid-item": {
              padding: 2,
            },
          }}
          ref={elementRef}
        >
          <GridItem
            sx={{ position: 'relative' }}
            sizeValue={sizeValue}
            width={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 7,
              xl: 6
            }}
            height={{
              xs: 20,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 5
            }}
          >
            <RolesList
              handleChangeSelectedRole={handleChangeSelectedRole}
              handleChangeName={handleChangeName}
              handleCreateRole={handleCreateRole}
              handleDeleteRole={handleDeleteRole}
              handleChangePermissions={handleChangePermissions}
              roleData={selectedRoleEdited}
              roles={roles}
              contentRef={contentRef}
              handleChangeColor={handleChangeColor}
            />
            {
              selectedRole != selectedRoleEdited &&
              <Alert
                severity="info"
                style={{ position: 'absolute', bottom: '0', right: '50%', transform: "translate(50%, 0%)", display: 'flex', alignItems: 'center', zIndex: 1000 }}
                action={
                  // <IconButton
                  //   aria-label="close"
                  //   color="inherit"
                  //   size="small"
                  // // onClick={() => {
                  // //     setOpen(false);
                  // // }}
                  // >
                  //   <CloseIcon fontSize="inherit" />
                  //   Save changes
                  // </IconButton>
                  <Box>
                    <Button variant="outlined" color="info" sx={{ m: 1 }} onClick={handleCancelChanges}>
                      Cancel
                    </Button>
                    <Button variant="contained" color="success" onClick={handleSaveChanges}>
                      Save changes
                    </Button>
                  </Box>
                }
                sx={{ mb: 2 }}
              >
                Do you wish to save changes?
              </Alert>
            }
          </GridItem>
          <GridItem

            sizeValue={sizeValue}
            width={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 7,
              xl: 6
            }}
            height={{
              xs: 20,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 5
            }}
          >
            <UsersList rolesUsers={rolesUsers} selectedRole={selectedRole}></UsersList>

          </GridItem>

        </Box>
      }


    </Box >
  )
}
