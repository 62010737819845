import { Avatar, Box, Button, Checkbox, Divider, FormControl, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, OutlinedInput, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { User } from '../../../redux/user/slice';
import { UserComponent } from './UserComponent';
import { Role } from '../../../redux/roles/slice';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setUsersRole } from '../../../redux/roles/thunk';
import { getAllUsers } from '../../../redux/user/thunk';
interface Props {
    rolesUsers: User[]
    selectedRole: Role
}
export const UsersList = ({ rolesUsers, selectedRole }: Props) => {
    const { users, status } = useAppSelector(state => state.users)
    const tenantId = window.localStorage.getItem("tenantId");
    const dispatch = useAppDispatch()

    const [searchString, setSearchString] = useState<string>("")
    const [fildredUsers, setFiltredUsers] = useState<User[]>([])
    const [checked, setChecked] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    if(status == "loading" && tenantId){

        dispatch(getAllUsers(tenantId))
    }


    const handleToggle = (id: string) => () => {
        const currentIndex = checked.indexOf(id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const handleSetUsers = () => {

        const body = {
            "roleId": selectedRole.id,
            "userIds": checked,
            "token": "a"
        }
        dispatch(setUsersRole(body, handleClose))

    };
    useEffect(() => {
        setChecked([])
        setFiltredUsers([])

    }, [selectedRole, rolesUsers])
    useEffect(() => {

        setFiltredUsers(rolesUsers)
    }, [rolesUsers])
    useEffect(() => {
        setFiltredUsers(rolesUsers.filter(e => `${e.name.toLowerCase()} ${e.lastName.toLowerCase()}`.includes(searchString)))
    }, [searchString])
    return (
        <Paper elevation={3} sx={{ height: '100%', p: 2, display: 'flex', flexDirection: "column", gap: 2 }}>

            <Typography variant='h2' sx={{ fontSize: '2rem', fontWeight: 500 }} >Manage role users</Typography>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                <FormControl sx={{ width: '80%' }} variant="outlined">
                    <InputLabel >Search user</InputLabel>
                    <OutlinedInput
                        value={searchString}
                        onChange={(e) => { setSearchString(e.target.value.toLowerCase()) }}
                        type={'text'}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        label="Search user"
                    />
                </FormControl>
                <Button
                    onClick={handleOpen}
                    variant="contained"
                    color="success"
                >
                    Add user
                </Button>
            </Box>
            <Box>
                {
                    fildredUsers.length === 0 &&
                    <Box>
                        <Typography variant='overline' sx={{ px: 1, fontSize: '1rem' }}>
                            No users found
                        </Typography>
                    </Box>
                }
                {
                    fildredUsers.map(e => <UserComponent user={e} role={selectedRole} />)
                }
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={2} sx={
                    {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }
                }>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add user
                    </Typography>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                        <AddModeratorIcon sx={{ color: selectedRole.hexColor }} />
                        {selectedRole.name}
                    </Box>

                    <List sx={{ my: 2, maxHeight: 400, overflowY: 'scroll' }}>
                        {

                            users.map(user => {
                                if (!rolesUsers.find(e => e.id === user.id)) {
                                    return <ListItem
                                        key={user.id}
                                        disablePadding
                                        sx={{
                                            ":hover": {
                                                backgroundColor: "rgba(33, 150, 243, 0.08)"
                                            },
                                            transition: 'ease-in 0.25s all'
                                        }}
                                    >
                                        <ListItemButton
                                            role={undefined}
                                            onClick={handleToggle(user.id)}
                                            dense>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(user.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                                                <ListItemAvatar>
                                                    <Avatar alt={`${user.name} ${user.lastName}`} src="" />
                                                </ListItemAvatar>
                                                <ListItemText id={user.id} primary={`${user.name} ${user.lastName}`} />
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                }
                            }
                            )
                        }

                    </List>
                    <Divider />
                    <Box
                        sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}
                    >
                        <Box>
                            <Button variant='contained' color='inherit' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button variant='contained' color='success' onClick={handleSetUsers} >
                                Confirm changes
                            </Button>
                        </Box>

                    </Box>
                </Paper>
            </Modal>
        </Paper >
    )
}
