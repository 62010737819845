import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { LoadingCube } from '../../../custom/loader/LoadingCube'
import { UserMainCard } from './UserMainCard'
import { getUserById } from '../../../../redux/user/thunk'
export const UserDetail = () => {
    const { id } = useParams()
    const { currentUser, status } = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (id != undefined) {
            dispatch(getUserById(id))
        }
    }, [id])

    if (status === "loading") {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                }}
                justifyContent={"center"}
            >
                <LoadingCube />
            </Box>
        )
    }
    return (
        <Box
            sx={{
                px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 },
                mt: 3
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {
                        currentUser &&
                        <UserMainCard user={currentUser} />
                    }
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <MembersListCard members={tempMembers} />
                </Grid> */}
            </Grid>
        </Box>
    )
}