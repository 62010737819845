import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { WidgetLeaderboard } from '../../redux/dashboards/slice';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SettingsIcon from '@mui/icons-material/Settings'; 
import { RightSidePanel } from '../custom/CustomRightSidePanel';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditLeaderboard } from './EditLeaderboard';
import Swal from 'sweetalert2';
import { useAppDispatch } from '../../hooks/hooks';
import { deleteWidget } from '../../redux/dashboards/thunk';

const url = process.env.REACT_APP_NEXUS_BACK_URL;

interface Props {
    dashboardId: string
    widget: WidgetLeaderboard
    isShow: boolean
    fontFamily: string
}


interface Player {
    name: string;
    score: number;
  }

export const LeaderboardTable = ({dashboardId, widget, isShow, fontFamily }: Props) => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [showSettings, setShowSettings] = useState<boolean>(isShow);
  const [openSidePanel, setSidePanelOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch()
  
  const handleDeleteWidget = () => {
    Swal.fire({
        title: "Are you sure you want to delete this widget?",
        text: "Once deleted it is not possible to recover the information",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
    }).then((result) => {
        if (result.isConfirmed) {

            dispatch(deleteWidget(widget.id, dashboardId, "/Widget/Leaderboard"))

        }
    });
}

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${url}/api/v1/Widget/Leaderboard?Id=${widget.id}`);
            setPlayers(response.data.result);
        } catch (error) {
        }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000); 

    return () => clearInterval(interval);
  }, []);


  return (
    <>
    <TableContainer component={Paper} sx={{position: 'relative', backgroundColor: widget.backgroundColor, fontFamily: fontFamily }}>
        { showSettings && 
            (
              <IconButton onClick={() => {setSidePanelOpen(true)}}>
                <SettingsIcon sx={{ position: 'relative', color: widget.fontColor, fontFamily: fontFamily}}  />
              </IconButton>
            )
        } 
        { showSettings && 
            (
              <IconButton onClick={handleDeleteWidget}>
                <DeleteIcon sx={{ position: 'relative', color: widget.fontColor, fontFamily: fontFamily}}  />
              </IconButton>
            )
        } 
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: widget.backgroundColor, '& > th': { borderBottom: `1px solid ${widget.fontColor}` }, fontFamily: fontFamily }}>
            <TableCell sx={{ color: widget.fontColor, fontFamily: fontFamily }}>Rank</TableCell>
            <TableCell sx={{ color: widget.fontColor, fontFamily: fontFamily }}>Name</TableCell>
            <TableCell sx={{ color: widget.fontColor, fontFamily: fontFamily }}>Score</TableCell>
          </TableRow>
        </TableHead>      
        <TableBody>
          {players.map((player, index) => (
            <TableRow sx={{ backgroundColor: widget.backgroundColor, fontFamily: fontFamily } } key={player.name} >
              <TableCell sx={{ color: widget.fontColor , borderBottom: `1px solid ${widget.fontColor}`, fontFamily: fontFamily }} >{index + 1}</TableCell>
              <TableCell sx={{ color: widget.fontColor , borderBottom: `1px solid ${widget.fontColor}`, fontFamily: fontFamily }}>{player.name}</TableCell>
              <TableCell sx={{ color: widget.fontColor , borderBottom: `1px solid ${widget.fontColor}`, fontFamily: fontFamily}}>{player.score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {
        showSettings &&
        <RightSidePanel
        handleOpen={openSidePanel}
        handleClose={() => setSidePanelOpen(false)}
        customComponent={<EditLeaderboard dashboardId={dashboardId} widget={widget}/>}
        />
    }
    </>
  );
};