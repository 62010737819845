import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, setAllRoles, errorState, setUsers } from "./slice";
import RoleAgent from "./agent";
import { getPermissions } from "../permission/thunk";

export const checkingRoles = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}
export const getAllRoles = (tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await RoleAgent.getAllRoles(tenantId)
            .then((response) => {
                dispatch(setAllRoles(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const getUserPerRole = (roleId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await RoleAgent.getUsers(roleId)
            .then((response) => {
                dispatch(setUsers(response));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const setUsersRole = (body: any, handleClose: () => void): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await RoleAgent.setUsersRole(body)
            .then((response) => {
                dispatch(getUserPerRole(body.roleId))
                handleClose()
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const disassocieteUserRole = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await RoleAgent.disassocieteUserRole(body)
            .then((response) => {
                dispatch(getPermissions())
                dispatch(getUserPerRole(body.roleId))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })
    }
}
export const postRoles = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())
        await RoleAgent.addRole(body)
            .then((response) => {
                dispatch(getAllRoles(body.tenantId))
                dispatch(getPermissions())
            })
            .catch((error) => {
                // console.log(error);
                dispatch(errorState())
            })

    }
}

export const deleteRole = (body: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await RoleAgent.delRole(body.id)
            .then((response) => {
                dispatch(getAllRoles(body.tenantId))
                dispatch(getPermissions())

            })
            .catch((error) => {
                //console.log(error);
                dispatch(errorState())
            })

    }
}

export const modifyRole = (body: any, tenantId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading())

        await RoleAgent.putRole(body)
            .then((response) => {
                dispatch(getPermissions())
                dispatch(getAllRoles(tenantId))
            })
            .catch((error) => {
                console.log(error);
                dispatch(errorState())
            })

    }
}